import { ExportData } from "@/interfaces/export";
import ExportService from "@/services/export-service";

export const ExportModule = {
	namespaced: true,
	state: () => ({
		dataExports: [] as Array<ExportData>,
		loading: false,
	}),
	mutations: {
		ADD_EXPORT_DATA(state: any, exportData: ExportData) {
			let index = state.dataExports.findIndex(
				(x: ExportData) => x.id == exportData.id
			);
			if (index > -1) {
				state.dataExports[index] = exportData;
			} else {
				state.dataExports.push(exportData);
			}
		},
		SET_LOADING(state: any, loading: Boolean = false) {
			state.loading = loading;
		},
	},
	getters: {
		getDataExports(state: any): Array<ExportData> {
			return state.dataExports;
		},
		exportIsLoading(state: any): Boolean {
			return state.loading;
		},
	},
	actions: {
		setLoading({ commit }: any, loading: Boolean = false) {
			return new Promise((resolve) => {
				commit("SET_LOADING", loading);
				resolve(loading);
			});
		},
		addExportData({ commit }: any, exportData: ExportData) {
			return new Promise((resolve) => {
				commit("ADD_EXPORT_DATA", exportData);
				resolve(exportData);
			});
		},

		/**
		 * Export File
		 * @param id  Id del elemento DOM a exportar
		 * @returns
		 */
		async exportPdf({ commit }: any, id: string) {
			commit("SET_LOADING", true);
			await ExportService.exportPdf(id);
			commit("SET_LOADING", false);
		},
	},
};
