
import Vue from "vue";
import { isEmpty } from "lodash";

export default Vue.extend({
	name: "ThumbnailGrid",
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {

        hasThumbnail(){
            return !isEmpty(this.params.data.creative_thumbnail_url);
        },

        getThumbnail(){
            return this.params.data.creative_thumbnail_url;
        }

	},
	methods: {},
	watch: {},
});
