
import Vue from "vue";
//@ts-ignore
import { debounce, isNull } from "lodash";
//@ts-ignore
import { SortingOption } from "../../../interfaces/paginated";

export default Vue.extend({
	name: "Filterable",
	props: {},
	components: {},
	data: () => ({
        filter: ""
    }),
	created() {},
	mounted() {},
	computed: {
		getFieldFilter(){
            return this.params.colDef.field;
        },

		getFieldName(){
            return this.params.colDef.headerName;
        },

		getFilters(){
            return this.params.filters;
        },

		getOptions(){
            return this.params.options;
        },

		isFiltersNull(){
			return isNull(this.params.filters);
		},

		isSortNull(){
			return isNull(this.params.options);
		}
	},
	methods: {
		selectedOption: debounce(function () {
            this.params.context.componentParent.selectedOption(this.getParams());
		}, 500),

		isFilterActive(){},

		getParams(){
			let params = {
				filter: this.filter,
				options: {
					sort: this.getFieldFilter,
					order: "desc",
				} as SortingOption,
			};
			if(!this.isFiltersNull){
				params['key'] = this.getFilters[this.getFieldFilter];
			}
			if(!this.isSortNull){
				params.options.sort = this.getOptions[this.getFieldFilter];
			}
			return params;
		},

        invokeParentRemoveMethod() {
            this.params.context.componentParent.removeOption();
        },
	},
	watch: {

	},
});
