import moment, { Moment } from "moment-timezone";
import {
	addDays,
	addMonths,
	addYears,
	setHours,
	format,
	setMinutes,
	setSeconds,
	subDays,
	subMonths,
	subYears,
	isValid,
	isToday,
	differenceInDays,
} from "date-fns";
import { es, enUS, ptBR } from "date-fns/locale";

/**
 * Language for Date FNS
 */
const matchLanguage = {
	es: es,
	en: enUS,
	pt: ptBR,
};

/**
 * Language for Calendar
 */
const matchLanguageCalendar = {
	es: "es",
	en: "en-US",
	pt: "pt-PT",
};

import store from "@/store/index";

export const storeLanguage: string =
	store?.getters["internationalization/getLanguage"];
export const matchedLocale: Locale = matchLanguage[storeLanguage];
export const matchedLanguageCalendar: any =
	matchLanguageCalendar[storeLanguage];
export const localeOption: any = { locale: matchedLocale };

moment.locale(storeLanguage);

// console.log("DateService", {
// 	matchLanguage,
// 	storeLanguage,
// 	matchedLocale,
// 	matchedLanguageCalendar,
// 	localeOption,
// });

// DATE FNS FORMAT
export const DEFAULT_FNS_SQL_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const DEFAULT_FNS_SQL_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_FNS_SHORT_FORMAT = "EEE d MMM";
export const DEFAULT_FNS_YEAR_FORMAT = "yyyy";
export const DEFAULT_FNS_SHORT_YEAR_FORMAT = "d MMM yyyy";
export const DEFAULT_FNS_SHORT_TIME_FORMAT = "HH:mm:ss";

// MOMENT FORMAT
export const DEFAULT_MOMENT_SQL_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_MOMENT_SQL_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_MOMENT_SHORT_FORMAT = "ddd D MMM";
export const DEFAULT_MOMENT_YEAR_FORMAT = "YYYY";

/**
 * date-fns
 * Add Days
 * @param date
 * @param days
 * @returns
 */
export function fnsAddDays(date: Date, days: number) {
	return addDays(date, days);
}

/**
 * date-fns
 * Subtract Days
 * @param date
 * @param days
 * @returns
 */
export function fnsSubtractDays(date: Date, days: number) {
	return subDays(date, days);
}

/**
 * date-fns
 * Add Months
 * @param date
 * @param months
 * @returns
 */
export function fnsAddMonths(date: Date, months: number) {
	return addMonths(date, months);
}

/**
 * date-fns
 * Subtract Months
 * @param date
 * @param months
 * @returns
 */
export function fnsSubtractMonths(date: Date, months: number) {
	return subMonths(date, months);
}

/**
 * date-fns
 * Add Years
 * @param date
 * @param years
 * @returns
 */
export function fnsAddYears(date: Date, years: number) {
	return addYears(date, years);
}

/**
 * date-fns
 * Add Years
 * @param date
 * @param years
 * @returns
 */
export function fnsSubtractYears(date: Date, years: number) {
	return subYears(date, years);
}

/**
 * date-fns
 * Set Hour
 * @param date
 * @param hours
 * @returns
 */
export function fnsSetHours(date: Date, hours: number) {
	return setHours(date, hours);
}

/**
 * date-fns
 * Set Minutes
 * @param date
 * @param minutes
 * @returns
 */
export function fnsSetMinutes(date: Date, minutes: number) {
	return setMinutes(date, minutes);
}

/**
 * date-fns
 * Set Seconds
 * @param date
 * @param seconds
 * @returns
 */
export function fnsSetSeconds(date: Date, seconds: number) {
	return setSeconds(date, seconds);
}

/**
 * date-fns
 * Set Fill Time
 * @param date
 * @param isEnd
 * @returns
 */
export function fnsFillTime(date: Date, isEnd: Boolean = false) {
	date = fnsSetHours(date, isEnd ? 23 : 0);
	date = fnsSetMinutes(date, isEnd ? 59 : 0);
	date = fnsSetSeconds(date, isEnd ? 59 : 0);
	return date;
}

/**
 * date-fns
 * Set Fill Current Time
 * @param date
 * @returns
 */
export function fnsFillCurrentTime(date: Date) {
	date = fnsSetHours(date, date.getHours());
	date = fnsSetMinutes(date, date.getMinutes());
	date = fnsSetSeconds(date, date.getSeconds());
	return date;
}

export function diffDays(start: Date, end: Date, rounded: Boolean = false) {
	return differenceInDays(end, start) + (rounded ? 1 : 0);
}

// Formatters

// ### MOMENT
export function getToday(format: string = DEFAULT_MOMENT_SQL_FORMAT) {
	return moment().format(format);
}

export function parseDateToString(
	date: Date,
	format: string = DEFAULT_MOMENT_SQL_FORMAT
) {
	return moment(date).format(format);
}

export function isTagetCurrentYear(
	targetYear: number,
	currentDate: Moment
): boolean {
	return targetYear === currentDate.year();
}

export function getTargetMonth(
	isCurrentYear: boolean,
	isEnd: boolean,
	currentDate: Moment
): number {
	return isCurrentYear ? (isEnd ? currentDate.month() : 0) : isEnd ? 11 : 0;
}

export function getTargetDay(
	isCurrentYear: boolean,
	isEnd: boolean,
	currentDate: Moment
): number {
	return isCurrentYear ? (isEnd ? currentDate.date() : 1) : isEnd ? 31 : 1;
}

export function createMomentInstance(
	year: number,
	month: number,
	day: number
): Moment {
	return moment({
		year: year,
		month: month,
		day: day,
	});
}

export function getFormattedDate(targetYear: number, isEnd: boolean): string {
	const currentDate = moment();
	const isCurrentYear = isTagetCurrentYear(targetYear, currentDate);

	const month = getTargetMonth(isCurrentYear, isEnd, currentDate);
	const day = getTargetDay(isCurrentYear, isEnd, currentDate);

	const formattedDate = createMomentInstance(targetYear, month, day);

	return formattedDate.format(DEFAULT_MOMENT_SQL_FORMAT);
}

export function getDateLastYear(tipo: "start_date" | "end_date"): string {
	// Obtener la fecha actual
	const currentDate = moment();

	// Obtener el año actual
	const currentYear = currentDate.year();

	// Crear la fecha del 1 de enero o 31 de diciembre del año anterior según el tipo
	const date =
		tipo === "start_date"
			? moment([currentYear - 1, 0, 1])
			: moment([currentYear - 1, 11, 31]);

	// Formatear la fecha según el formato especificado
	return date.format(DEFAULT_MOMENT_SQL_FORMAT);
}

export function getDateByYear(
	type: "start_date" | "end_date",
	year: number
): string {
	// Obtener la fecha actual
	const currentDate = moment();

	if (year === currentDate.year() - 1) {
		// Si es el año anterior, retornar desde el 1 de enero hasta el 31 de diciembre
		const startDate = moment([year, 0, 1]);
		const endDate = moment([year, 11, 31]);

		return type === "start_date"
			? startDate.format(DEFAULT_MOMENT_SQL_FORMAT)
			: endDate.format(DEFAULT_MOMENT_SQL_FORMAT);
	} else if (year === currentDate.year()) {
		// Si es el año actual, retornar desde el 1 de enero hasta el día de hoy
		const startDate = moment([year, 0, 1]);
		const endDate = currentDate;

		return type === "start_date"
			? startDate.format(DEFAULT_MOMENT_SQL_FORMAT)
			: endDate.format(DEFAULT_MOMENT_SQL_FORMAT);
	} else {
		return "";
	}
}

// ### DATE FNS

export function dateaddDays(dateString: string) {
	return new Date(dateString);
}

export function parseStringToDate(dateString: string) {
	return new Date(dateString);
}

export function fnsFormat(date: Date) {
	if (!date) return "Date is empty";
	if (!isValid(date)) return "Date not valid";
	return format(date, DEFAULT_FNS_SHORT_FORMAT, localeOption);
}

export function fnsDateFormat(date: Date) {
	if (!date) return "Date is empty";
	if (!isValid(date)) return "Date not valid";
	return format(date, DEFAULT_FNS_SQL_DATE_FORMAT, localeOption);
}

export function fnsDateTimeFormat(date: Date) {
	if (!date) return "Date is empty";
	if (!isValid(date)) return "Date not valid";
	return format(date, DEFAULT_FNS_SHORT_YEAR_FORMAT, localeOption);
}

export function fnsTimeFormat(date: Date) {
	if (!date) return "Date is empty";
	if (!isValid(date)) return "Date not valid";
	return format(date, DEFAULT_FNS_SHORT_TIME_FORMAT, localeOption);
}

export function fnsDateTimeSQLFormat(date: Date) {
	if (!date) return "Date is empty";
	if (!isValid(date)) return "Date not valid";
	return format(date, DEFAULT_FNS_SQL_FORMAT, localeOption);
}

export function fnsYearFormat(date: Date) {
	if (!date) return "Date is empty";
	if (!isValid(date)) return "Date not valid";
	return format(date, DEFAULT_FNS_YEAR_FORMAT, localeOption);
}

export function fnsIsValid(date: Date) {
	return isValid(date);
}

export function fnsIsToday(date: Date) {
	return isToday(date);
}

export function fnsRangeShortFormat(start: Date, end: Date) {
	if (!fnsIsValid(start) || !fnsIsValid(end)) return "Date not valid";
	return `${fnsDateTimeFormat(start)} ~ ${fnsDateTimeFormat(end)}`;
}

export function fnsRangeShortTimeFormat(start: Date, end: Date) {
	if (!fnsIsValid(start) || !fnsIsValid(end)) return "Date not valid";
	return `${fnsTimeFormat(start)} ~ ${fnsTimeFormat(end)}`;
}
