import { isEmpty } from "lodash";
import {
	hasRole,
	hasRoleExcluded,
	MATCHED_ROLE_ROUTE,
	ROLE_REPORT,
	ROLE_PERSONS,
	ROUTE_DEFAULT,
	canAccess
} from "./utils";

import {  getPermisionForUrl } from "@/utils/permissionResolve";
import store from "@/store";

/**
 * Meta:
 * excludes: Estos roles no podran acceder a la ruta.
 */
export default async function ({ from, next, router, to }) {

	const excludes: Array<string> = to?.meta?.excludes ?? [];

	const hasExcluded: Boolean =
		!isEmpty(excludes) && (await hasRoleExcluded(excludes));

	//let permission = store.getters['profile/getPermission'];
	//@ts-ignore
	let permission = store.state?.profile?.permission;
	const hasPermission: Boolean = Object.keys(permission).length > 0;

	if(!hasPermission){
		try{
			await store.dispatch('profile/fetchProfile', {root: true});
			//@ts-ignore
			permission = store.state?.profile?.permission;
		}catch(err){}
	}

	if(getPermisionForUrl().hasOwnProperty(to.name)){
		const access = getPermisionForUrl()[to.name];

		let res = false;
		switch(to.name){
			case "ModifiersCreate":
				res = ((await canAccess("create_bid_modifier", "Modifier")) || 
					(await canAccess("create_delivery_modifier", "Modifier")))
				break;
			case "ModifiersEdit":
				res = ((await canAccess("update_bid_modifier", "Modifier")) || 
					(await canAccess("update_delivery_modifier", "Modifier")))
				break;
			default:
				res = await canAccess(access.action, access.subject);
				break;
		}

		if(res){

			if (hasExcluded) {
				redirectTo(router);
			}
	
			return next();
		}else{
			return router.push({name: "Denied"}).catch((failure: any) => {});
		}
	}else{
		return next();
	}
}

async function redirectTo({router}){
	let routerName = MATCHED_ROLE_ROUTE[ROUTE_DEFAULT];

	if(await hasRole(ROLE_PERSONS)){
		routerName = MATCHED_ROLE_ROUTE[ROLE_PERSONS];
	} else if(await hasRole(ROLE_REPORT)){
		routerName = MATCHED_ROLE_ROUTE[ROLE_REPORT];
	}
	return router.push({name: routerName}).catch((failure: any) => {});
}
