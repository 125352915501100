import {
	ScheduleCreate,
	ScheduleUpdate,
} from "@/interfaces/reports/v2/scheduler";
import {
	AxiosGet,
	AxiosPatch,
	AxiosPost,
	GetData,
} from "@/services/axios-service";
import { catchServiceErrors } from "@/utils/services-global";

const ROUTE = require("@/api/routes").REPORT;

class ReportV2Service {
	async show(id: number) {
		try {
			const response = await AxiosGet(
				`${matchedRoutes()["scheduler"]}/${id}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async createSchedule(params: ScheduleCreate) {
		try {
			const response = await AxiosPost(
				matchedRoutes()["scheduler"],
				params
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async updateSchedule(params: ScheduleUpdate, id: number) {
		try {
			const response = await AxiosPatch(
				`${matchedRoutes()["scheduler"]}/${id}`,
				params
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
}

function matchedRoutes() {
	return {
		scheduler: ROUTE.REPORT_SCHEDULER_ROUTE,
	};
}

export default new ReportV2Service();
