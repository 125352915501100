import { ResultPaginate } from "@/interfaces/paginated";

export class PaginatedEntity implements ResultPaginate {
	current_page: number = 0;
	first_page_url?: string | undefined;
	from?: number | undefined | null;
	last_page?: number | undefined;
	last_page_url?: string | undefined;
	next_page_url?: string | undefined;
	path?: string | undefined;
	per_page?: number | undefined;
	prev_page_url?: string | undefined;
	to?: number | undefined;
	total: number = 0;
	data: any[] = [];
	object?: Record<string, any> = {};

	constructor(paginated?: ResultPaginate) {
		this.current_page = paginated?.current_page || 0;
		this.first_page_url = paginated?.first_page_url || "";
		this.from = paginated?.from || null;
		this.last_page = paginated?.last_page;
		this.last_page_url = paginated?.last_page_url;
		this.next_page_url = paginated?.next_page_url;
		this.path = paginated?.path;
		this.per_page = paginated?.per_page;
		this.prev_page_url = paginated?.prev_page_url;
		this.to = paginated?.to;
		this.total = paginated?.total || 0;
		this.data = paginated?.data || [];
		this.object = paginated?.object || {};
	}
}
