import AudienceGraphicsService from "@/services/graphics-audience-service";
import AudienceService from "@/services/audience-service";
import { FilterAudience, FilterMode, NameUnique } from "@/interfaces/audience";
import {
	initSource,
	isWillBeRetried,
	setSource,
} from "@/utils/persons/audience";
import { catchError } from "../Util";
import AmchartGlobal from "@/utils/amcharts/global";
import { AudienceLoading, PanelFilterEntity } from "@/models/Audience";

export const GraphicsAudienceModule = {
	namespaced: true,
	state: () => ({
		changedFilters: false,
		filteredGraphics: false,
		source: structuredClone(initSource),
		loading: new AudienceLoading(),
		week_days: [] as Array<string>,
		display_hours: [] as Array<string>,
		filters: {} as any,
		filterAudience: {} as FilterAudience,
		panelFilter: new PanelFilterEntity(),
	}),
	mutations: {
		SET_DATA(state: any, params: { key: string; value: Array<string> }) {
			state[params.key] = params.value;
		},
		SET_SOURCE(
			state: any,
			params: { type: string; key: string; value: Array<string> }
		) {
			state.source[params.type][params.key] = params.value;
		},
		SET_LOADING(
			state: any,
			params: { type: string; key: string; value: Boolean }
		) {
			state.loading[params.type][params.key] = params.value;
		},
		SET_FILTERED_GRAPHICS(state: any, isFiltered: boolean) {
			state.filteredGraphics = isFiltered;
		},
		RESET_LOADING(state: any) {
			const newLoading = new AudienceLoading();
			state.loading = newLoading;
		},
		INIT_PANEL_FILTER(state: any) {
			state.panelFilter = new PanelFilterEntity();
		}
	},
	getters: {
		getData: (state: any) => (key: string) => {
			return state[key];
		},
		getSource(state: any): any {
			return state.source;
		},
		getUserCount(state: any): any {
			return state.source.user_count;
		},
		getLoading(state: any): Boolean {
			return state.loading;
		},
		getWeeDays(state: any): string[] {
			return state.week_days;
		},
		filters(state: any): string[] {
			return state.filters;
		},
		isFilteredGraphics(state: any): Boolean {
			return state.filteredGraphics;
		},
		hasChangedFilters(state: any): Boolean {
			return state.changedFilters;
		},
		getAudienceFilters(state: any) {
			return state.filterAudience;
		},
		getPanelFilter(state: any) {
			return state.panelFilter;
		}
	},
	actions: {
		async setData({ commit }: any, params: { key: any; value: any }) {
			await commit("SET_DATA", {
				key: params.key,
				value: params.value,
			});
		},

		setChangeFilters({ commit }, isChanged: Boolean = false) {
			commit("SET_DATA", {
				key: "changedFilters",
				value: isChanged,
			});
		},

		async initPanelFilter({ commit }: any) {
			await commit("INIT_PANEL_FILTER");
		},

		async disposeBeforeAudienceGraphics({ commit }: any) {
			commit("RESET_LOADING");

			const newSource = structuredClone(initSource);

			commit("SET_DATA", {
				key: "source",
				value: newSource,
			});

			await AmchartGlobal.dispose();
			await AmchartGlobal.resetExporting();
		},

		fetchAudienceGraphics({ commit, getters }: any) {
			const fetchMode: string = getters.isFilteredGraphics
				? FilterMode.FILTERED
				: FilterMode.ALL;

			// pois::users_by_date
			attemptByKey(commit, {
				key: "dates",
				mode: fetchMode,
				sourceKey: "users_by_date",
				sourceType: "pois",
			});

			// pois::users_by_distance
			attemptByKey(commit, {
				key: "distances",
				mode: fetchMode,
				sourceKey: "users_by_distance",
				sourceType: "pois",
			});

			// pois::users_by_week_day
			attemptByKey(commit, {
				key: "weekDays",
				mode: fetchMode,
				sourceKey: "users_by_week_day",
				sourceType: "pois",
			});

			// pois::users_by_hour_day
			attemptByKey(commit, {
				key: "hourDays",
				mode: fetchMode,
				sourceKey: "users_by_hour_day",
				sourceType: "pois",
			});

			// pois::users_by_category
			attemptByKey(commit, {
				key: "categories",
				mode: fetchMode,
				sourceKey: "users_by_category",
				sourceType: "pois",
			});

			// pois::users_by_subcategory
			attemptByKey(commit, {
				key: "subcategories",
				mode: fetchMode,
				sourceKey: "users_by_subcategory",
				sourceType: "pois",
			});

			// pois::users_by_poi_city
			attemptByKey(commit, {
				key: "cities",
				mode: fetchMode,
				sourceKey: "users_by_poi_city",
				sourceType: "pois",
			});

			// pois::users_by_dpto
			attemptByKey(commit, {
				key: "departments",
				mode: fetchMode,
				sourceKey: "users_by_dpto",
				sourceType: "pois",
			});

			// pois::users_by_poi_neighborhood
			attemptByKey(commit, {
				key: "neighborhood",
				mode: fetchMode,
				sourceKey: "users_by_poi_neighborhood",
				sourceType: "pois",
			});

			// demographic::users_by_ciudad_residencia
			attemptByKey(commit, {
				key: "residence_cities",
				mode: fetchMode,
				sourceKey: "users_by_ciudad_residencia",
				sourceType: "demographic",
			});

			// demographic::users_by_niv_socio
			attemptByKey(commit, {
				key: "socioeconomics",
				mode: fetchMode,
				sourceKey: "users_by_niv_socio",
				sourceType: "demographic",
			});

			// demographic::users_by_age
			attemptByKey(commit, {
				key: "ages",
				mode: fetchMode,
				sourceKey: "users_by_age",
				sourceType: "demographic",
			});

			// demographic::users_by_gender
			attemptByKey(commit, {
				key: "genders",
				mode: fetchMode,
				sourceKey: "users_by_gender",
				sourceType: "demographic",
			});

			// demographic::users_by_barrio_residencia
			attemptByKey(commit, {
				key: "neighborhood_residence",
				mode: fetchMode,
				sourceKey: "users_by_barrio_residencia",
				sourceType: "demographic",
			});

			// online_behaviour::users_by_type
			attemptByKey(commit, {
				key: "identifier_type",
				mode: getters.isFilteredGraphics
					? FilterMode.FILTERED
					: FilterMode.ALL,
				sourceKey: "users_by_type",
				sourceType: "online_behaviour",
			});

			// online_behaviour::users_by_iab
			attemptByKey(commit, {
				key: "navigation_behaviour",
				mode: fetchMode,
				sourceKey: "users_by_iab",
				sourceType: "online_behaviour",
			});

			// online_behaviour::users_by_intereses
			attemptByKey(commit, {
				key: "campaign_interests",
				mode: fetchMode,
				sourceKey: "users_by_intereses",
				sourceType: "online_behaviour",
			});

			// online_behaviour::users_by_app_name
			attemptByKey(commit, {
				key: "app_visited",
				mode: fetchMode,
				sourceKey: "users_by_app_name",
				sourceType: "online_behaviour",
			});

			// online_behaviour::users_by_domain
			attemptByKey(commit, {
				key: "sites_visited",
				mode: fetchMode,
				sourceKey: "users_by_domain",
				sourceType: "online_behaviour",
			});

			// online_behaviour::users_by_city_seen
			attemptByKey(commit, {
				key: "city_seen",
				mode: fetchMode,
				sourceKey: "users_by_city_seen",
				sourceType: "online_behaviour",
			});

			// device::users_by_carrier
			attemptByKey(commit, {
				key: "operator",
				mode: fetchMode,
				sourceKey: "users_by_carrier",
				sourceType: "device",
			});

			// device::users_by_device_make
			attemptByKey(commit, {
				key: "make",
				mode: fetchMode,
				sourceKey: "users_by_device_make",
				sourceType: "device",
			});

			// device::users_by_platform_device_type
			attemptByKey(commit, {
				key: "device_type",
				mode: fetchMode,
				sourceKey: "users_by_platform_device_type",
				sourceType: "device",
			});

			// device::users_by_platform_browser
			attemptByKey(commit, {
				key: "browser",
				mode: fetchMode,
				sourceKey: "users_by_platform_browser",
				sourceType: "device",
			});

			// device::users_by_device_language
			attemptByKey(commit, {
				key: "language",
				mode: fetchMode,
				sourceKey: "users_by_device_language",
				sourceType: "device",
			});

			// device::users_by_plataform_os
			attemptByKey(commit, {
				key: "operating_system",
				mode: fetchMode,
				sourceKey: "users_by_plataform_os",
				sourceType: "device",
			});

			// table::users_by_pois_gender_age
			attemptByKey(commit, {
				key: "gender_age",
				mode: fetchMode,
				sourceKey: "users_by_pois_gender_age",
				sourceType: "table",
			});

			// head_map::users_by_hour_day_week
			attemptByKey(commit, {
				key: "day_week",
				mode: fetchMode,
				sourceKey: "users_by_hour_day_week",
				sourceType: "head_map",
			});

			// maps.map
			attemptByKey(commit, {
				key: "map",
				mode: fetchMode,
				sourceKey: "map",
				sourceType: "maps",
			});
		},

		async prepareFilters({}, params: { filters: any; models: any }) {
			try {
				const response: FilterAudience =
					await AudienceGraphicsService.prepareFiltersAudience(
						params.filters,
						params.models
					);

				return await Promise.resolve(response);
			} catch (error) {
				console.error("GraphicsAudienceModule::prepareFilters", {
					error,
				});
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async fetchFilterAudience(
			{ commit, rootState },
			filters: FilterAudience
		) {
			try {
				commit("SET_FILTERED_GRAPHICS", false);

				const response = await AudienceGraphicsService.filterAudience(
					filters
				);

				rootState.audience.optionFilters.total_users =
					response.total_users as NameUnique[];

				commit(
					"SET_FILTERED_GRAPHICS",
					Object.keys(response).length > 0
				);

				return await Promise.resolve(response);
			} catch (error) {
				console.error("GraphicsAudienceModule::fetchFilterAudience", {
					error,
				});
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};

export async function attemptByKey(
	commit: any,
	params: { key: any; mode: any; sourceType: any; sourceKey: any }
) {
	let willBeRetried = true;

	while (willBeRetried) {
		try {
			// Obtener datos desde la API
			const result: any = await AudienceService.fetchResourceBykey(
				{
					key: params.key,
					mode: params.mode,
				},
				true
			);

			// Si la respuesta es un strig (PENDING | PROCESSING) se volvera a realizar el llamado a la API
			willBeRetried = await isWillBeRetried(result);

			// Setear loading para el grafico
			commit("SET_LOADING", {
				type: params.sourceType,
				key: params.sourceKey,
				value: willBeRetried,
			});

			if (!willBeRetried) {
				/**
				 * Parsear y Setear datos {source}
				 */

				let source: any = await setSource(params, result);

				commit("SET_SOURCE", {
					type: params.sourceType,
					key: params.sourceKey,
					value: source,
				});
			} else {
				/**
				 * if {willBeRetried} reintentar en 4 segundos
				 */
				await sleep(1000);
			}
		} catch (error) {
			console.error("while::fetchResourceBykey", { error });
			willBeRetried = false;
			await commit("SET_LOADING", {
				type: params.sourceType,
				key: params.sourceKey,
				value: false,
			});
		}
	}
}

// sleep time expects milliseconds
export async function sleep(time: number) {
	return new Promise((resolve) => setTimeout(resolve, time));
}
