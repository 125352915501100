import { Term } from "@/interfaces/targeting";
import { InitTargeting } from "@/models/tabs/Targeting";
import {
	groupBy,
	isArray,
	isEmpty,
	isUndefined,
	orderBy,
	trim,
	isNull,
	sortBy,
} from "lodash";
import { convLocaleString } from "./convert";
import TargetingResolve from "./TargetingResolve";

export interface ComboListOptions {
	id?: any;
	value?: string;
	header?: string | undefined;
	group?: string | undefined;
	creative_type_id?: Number | undefined;
	thumbnail_url?: string | undefined;
	devices?: Number | undefined;
}

export interface ComboListOptionsCampaign {
	id?: any;
	external_id?: any;
	name?: string;
	value?: string;
	start_date?: string;
	end_date?: string;
	clicks?: any;
	impression?: any;
	ctr?: any;
	active?: Boolean;
	line_items_count?: number;
	creative_associations_count?: number;
}

export interface ComboAdvertiserListOptions {
	id?: any;
	value?: String;
	domain?: string;
	app_bundle?: string;
	category_id?: number;
	line_items_count?: number;
}

export interface DetectAttributes {
	bitrates?: [];
	durations?: [];
	mime_types?: [];
	video_apis?: [];
}

export function resolveList(
	response: any,
	isnumeric_id: Boolean = true
): Array<ComboListOptions> {
	const list: Array<ComboListOptions> = [];

	Object.fromEntries(
		Object.entries(response).map(([key, value]) => {
			const obj = {
				id: isnumeric_id ? Number(key) : String(key),
				value: String(value),
			};
			list.push(obj);
			return [key, value];
		})
	);

	return list;
}

export function resolveListAdvertiserCreativo(
	response: any
): Array<ComboAdvertiserListOptions> {
	const list: Array<ComboAdvertiserListOptions> = [];
	response.map(function (obj) {
		const asse = {
			id: Number(obj.id),
			value: String(obj.name),
			domain: isNull(obj.domain) ? undefined : String(obj.domain),
			app_bundle: isNull(obj.app_bundle)
				? undefined
				: String(obj.app_bundle),
			category_id: isNull(obj.category_id)
				? undefined
				: Number(obj.category_id),
		};
		list.push(asse);
		return asse;
	});
	return list;
}

export function resolveDetectAttributes(response: any) {
	const detect = {
		bitrates: response.bitrates,
		durations: response.durations,
		mime_types: response.mime_types,
		video_apis: response.video_apis,
	} as DetectAttributes;

	return detect;
}

export function resolveListAsset(response: any): Array<ComboListOptions> {
	const list: Array<ComboListOptions> = [];
	response.map(function (obj) {
		const asse = {
			id: Number(obj.id),
			value: String(obj.name),
			thumbnail_url: String(obj.thumbnail_url),
			asset_url: String(obj.asset_url),
		};
		list.push(asse);
		return asse;
	});
	return list;
}

export function resolveListMIMEType(response: any): Array<ComboListOptions> {
	const list: Array<ComboListOptions> = [];

	response.map(function (obj) {
		const mime = {
			id: Number(obj.id),
			value: String(obj.description),
			extra: String(obj.extra),
		};
		list.push(mime);
		return mime;
	});

	return list;
}

export function resolveListParams(
	response: any,
	customKey: any = "id",
	customValue: any = "name",
	castToNumber: boolean = false,
	extra: boolean = false,
	showID: boolean = false
): Array<ComboListOptions> {
	const list: Array<ComboListOptions> = [];

	response.map((item: any) => {
		let obj = {
			id: castToNumber
				? Number(item[customKey])
				: String(item[customKey]),
			value: showID
				? item[customValue] + " - (" + String(item[customKey]) + ")"
				: item[customValue],
		};

		if (item.width) {
			Object.assign(obj, { with: item.width });
		}

		if (item.height) {
			Object.assign(obj, { height: item.height });
		}

		if (extra) {
			Object.assign(obj, { extra: item.id });
		}

		list.push(obj);
	});

	return list;
}

export function resolveListCampaignData(
	response: any,
	getID: Boolean = false
): Array<ComboListOptionsCampaign> {
	const list: Array<ComboListOptionsCampaign> = [];

	response.map((item: any) => {
		list.push({
			id: getID ? item.id : item.external_id,
			external_id: item.external_id,
			name: item.name,
			value: item.name,
			start_date: item.start_date,
			end_date: item.end_date,
			clicks: convLocaleString(item.clicks),
			impression: convLocaleString(item.impression),
			ctr: `${item.ctr || 0}%`,
			active: item.active,
			line_items_count: item.line_items_count,
		});
	});

	return list;
}

export function resolveListItemData(
	response: any,
	getID: Boolean = false
): Array<ComboListOptionsCampaign> {
	const list: Array<ComboListOptionsCampaign> = [];

	response.map((item: any) => {
		list.push({
			id: getID ? item.id : item.external_id,
			external_id: item.external_id,
			name: item.name,
			value: item.name,
			start_date: item.start_date,
			end_date: item.end_date,
			ctr: `${item.ctr || 0}%`,
			active: item.active,
			creative_associations_count: item.creative_associations_count,
		});
	});

	return list;
}

export function resolveTemplates(
	response: any,
	creativeTypes: Array<any>
): Array<ComboListOptions> {
	var list: Array<ComboListOptions> = [];
	var ordered: Array<ComboListOptions> = [];
	var header: string | undefined = "";

	response.map((item: any) => {
		const creativeType =
			getCreativeTypeByTemplateId(
				creativeTypes,
				item["creative_type_id"]
			) || undefined;
		const obj = {
			id: Number(item["id"]),
			value: String(item["name"]),
			group: isUndefined(creativeType) ? "" : String(creativeType.value),
			creative_type_id: Number(item["creative_type_id"]),
		};
		list.push(obj);
	});

	list = orderBy(list, "group", "asc");

	list.map((l) => {
		if (l.group !== header) {
			header = l.group;
			ordered.push({ header: header });
		}
		ordered.push(l);
	});

	return ordered;
}

export function getCreativeTypeByTemplateId(
	creativeTypes: Array<any> = [],
	creative_template_id: Number
): any {
	return creativeTypes.find((type) => type.id == creative_template_id);
}

export function getError(errors: Object, index: string) {
	try {
		if (!errors.hasOwnProperty(index)) return undefined;
		return errors[index];
	} catch (error) {
		console.error("getError", { error });
	}
}

export function prepareTargetingDataCreate(targeting: any) {
	if (!targeting) return;

	var targeting_terms: Array<Term> = [];
	const keys = Object.keys(targeting);

	keys.forEach((key) => {
		var t = targeting[key];
		if (!t) return;

		Object.keys(t).forEach((tab) => {
			if (!tab) return;

			const term = t[tab];

			const booleanText = ["true", "false"];

			if (!isEmpty(term.targeting_terms)) {
				if (isArray(term.targeting_terms[0].value)) {
					term.targeting_terms[0].value.forEach((v: any) => {
						targeting_terms.push({
							value: booleanText.includes(v)
								? Boolean(v === "true")
								: String(v),
							targeting_key_id:
								term.targeting_terms[0].targeting_key_id,
							targeting_predicate_id:
								term.targeting_terms[0].targeting_predicate_id,
						} as Term);
					});
				} else {
					term.targeting_terms.forEach((targeting_term: Term) => {
						targeting_terms.push(targeting_term);
					});
				}
			}
		});
	});

	return targeting_terms;
}

export function mappingTargetingKeys(response: Array<any>): Array<any> {
	const res = response.map((t) => {
		return {
			id: t.id,
			name: t.name,
			unique_predicate: t.unique_predicate,
			targeting_module: t.targeting_module,
			targeting_module_id: t.targeting_module.id,
		};
	});

	const g = groupBy(res, "targeting_module.extra");

	return res;
}

export function findByParam(
	_array: Array<any>,
	attribute: string | number,
	term: any
): any {
	return _array.find((type) => type[attribute] === term);
}

export async function getAssetTypeMatchedByKey() {
	return {
		primary_asset_id: {
			"Banner Image": "creative_asset",
			"HTML5 Creative": "html_asset",
			"VAST InLine": "video_asset",
		},
		secondary_asset_id: {
			"VAST InLine": "creative_asset, html_asset",
		},
		thumbnail_id: {
			"HTML5 Creative": "creative_asset",
		},
	};
}

export async function getAssetTypeID(params: {
	creative_templates: any;
	creative_template_id: any;
	key: any;
	asset_types: any;
}) {
	const creative_type = findByParam(
		params.creative_templates,
		"id",
		params.creative_template_id
	);

	const assetTypeMatched = await getAssetTypeMatchedByKey();
	if (!assetTypeMatched) return;

	const configTemplate = assetTypeMatched[params.key];
	if (!configTemplate) return;

	const assetTypeValue = configTemplate[creative_type.value];
	if (!assetTypeValue) return;

	return findByParam(params.asset_types, "extra", assetTypeValue)?.id;
}

export async function getAssetFilters(params: {
	advertiser_id?: any;
	_advertiser_id?: any;
	creative_templates: any[];
	creative_template_id: any;
	key: string | number;
	asset_types: any[];
}) {
	return {
		asset_type_id: await getAssetTypeID(params),
		advertiser_id: params?.advertiser_id,
		_advertiser_id: params?._advertiser_id, // assets
	};
}

// START Resolver listas de parejas sin IDs
export function resolveListWithoutIDs(response: any): Array<ComboListOptions> {
	const list: Array<ComboListOptions> = [];
	let index: number = 0;
	Object.fromEntries(
		Object.entries(response).map(([key, value]) => {
			const obj = { id: String(index), value: String(value) };
			list.push(obj);
			index = index + 1;
			return [key, value];
		})
	);

	return list;
}
// END Resolver listas de parejas sin IDs

export function resolveLisWithKeyApi(response: any): Array<ComboListOptions> {
	const list: Array<ComboListOptions> = [];
	Object.fromEntries(
		Object.entries(response).map(([key, value]) => {
			const obj = { key: String(key), value: String(value) };
			list.push(obj);
			return [key, value];
		})
	);

	return list;
}

export function getExcludedKeys() {
	return [
		"app_name",
		"site",
		"publisher_id",
		"placement_id",
		"deal_id",
		"environment_type",
		"video_api",
		"country",
		"city",
		"region",
	];
}

export function getBooleanKeys() {
	return ["topframe"];
}

export function isValueNumeric(value: any) {
	return !isNaN(parseInt(value));
}

/**
 * Resolve Targeting Expressions
 *
 * @param targeting_expressions
 * @returns InitTargeting
 */
export function resolveTargetingExpressions(
	targeting_expressions: Array<any>
): InitTargeting | null {
	try {
		let targeting = new InitTargeting();

		let resolve: TargetingResolve = new TargetingResolve(
			targeting_expressions,
			targeting
		);

		const resolveTargeting = resolve.getTargeting;

		return resolveTargeting;
	} catch (error) {
		console.error("resolveTargetingExpressions", error);
		return null;
	}
}

export function resolveListSegments(
	response: any
): Array<ComboAdvertiserListOptions> {
	const list: Array<ComboAdvertiserListOptions> = [];
	response.map(function (obj) {
		const asse = {
			id: Number(obj.id),
			value: String(obj.name),
			key: String(obj.key),
		};
		list.push(asse);
		return asse;
	});
	return list;
}

export function findRecursive(items: any[], identifier: string) {
	let result: any = null;

	items.forEach((item) => {
		const it: any = determineParent(item, identifier);
		if (!isNull(it)) {
			result = it;
			return false;
		}
	});

	return result;
}

/**
 * determineParent
 * @param item
 * @param identifier
 * @returns
 */
export function determineParent(item: any, identifier: string) {
	let result: any = null;

	if (item.key === identifier && !item.is_category) {
		result = item;
	}

	if (result) return result;

	if (isArray(item.children) && !isEmpty(item.children)) {
		for (const node of item.children) {
			const _result = determineParent(node, identifier);
			if (_result) {
				result = _result;
				break;
			}
		}
	}

	return result;
}

/**
 * ProcessSegmentSelection
 * @param current // current value of selection
 * @param older // previous value of selection
 * @param treeview // tree view value
 * @returns
 */
export async function processSegmentSelection(
	current: Array<string>,
	older: Array<string>,
	treeview: Array<any>
) {
	const newValue: Array<any> = current
		.map((v) => {
			const resursive: any = findRecursive(treeview, v);
			if (resursive) {
				return {
					key: resursive.key,
					verb: resursive.verb_usage_id,
					has_categories: resursive.has_categories,
					is_category: resursive.is_category,
					segment_name: resursive.name,
					verb_usage_name: resursive.verb_usage_name,
				};
			}
		})
		.filter((x) => x !== undefined);

	const oldValue: Array<any> = older.map((v: string) => {
		const resursive: any = findRecursive(treeview, v);
		if (resursive) {
			return {
				key: resursive.key,
				verb: resursive.verb_usage_id,
				has_categories: resursive.has_categories,
				is_category: resursive.is_category,
				segment_name: resursive.name,
				verb_usage_name: resursive.verb_usage_name,
			};
		}
	});

	return {
		newValue: newValue.filter((x) => typeof x !== typeof undefined),
		oldValue: oldValue.filter((x) => typeof x !== typeof undefined),
	};
}

export async function resolveListPrivatePOIs(
	response: any
): Promise<Array<ComboAdvertiserListOptions>> {
	const list: Array<ComboAdvertiserListOptions> = [];
	response.map(function (obj) {
		const asse = {
			id: Number(obj.id),
			value: String(obj.layer_name),
			//value: String(obj.layer_name) + " - (" + String(obj.status_name) + ")",
			status: String(obj.status_name),
			totalPois: obj.total_pois,
		};
		list.push(asse);
		return asse;
	});
	return list;
}

export async function resolveListParamsPOIs(
	response: any
): Promise<Array<ComboListOptions>> {
	const list: Array<ComboListOptions> = [];

	response.map((item: any) => {
		const asse = {
			id: trim(item.id),
			value: trim(item.text),
			totalPois: item.pois,
			devices: item.devices,
		};

		list.push(asse);
	});

	return list;
}

export function resolveListByAttr(
	response: any,
	customKey: any = "id",
	customValue: any = "name",
	keyString: Boolean = true,
	fieldExtra: Boolean = false
): Array<ComboListOptions> {
	const list: Array<ComboListOptions> = [];

	response.map((item: any) => {
		let obj = {
			id: keyString ? String(item[customKey]) : item[customKey],
			value: item[customValue],
		};
		if (fieldExtra) {
			obj["extra"] = item.extra;
		}
		list.push(obj);
	});

	return list;
}

export async function resolveUsersBy(
	items: any[]
): Promise<Array<ComboListOptions>> {
	return items.map((item: any) => {
		return {
			id: item.name,
			value: item.name,
			devices: item.uniques,
		};
	}) as Array<ComboListOptions>;
}

export async function resolveListCountry(response: any) {
	let values = new Array();
	const dataArr = new Set(response.map((v) => v.continent));
	let result = [...dataArr];
	result.forEach((v) => {
		let obj = {
			title: v,
			items: sortBy(
				response.filter((d) => d.continent == v),
				["name"]
			),
		};
		values.push(obj);
	});
	return values;
}

export function resolveListSegmentUpload(response: any) {
	const list = new Array();
	Object.entries(response).forEach(([key, value]) => {
		const obj = { id: String(value), value: String(key) };
		list.push(obj);
	});
	return list;
}

export function resolveCountryPrivatePois(
	response: any
): Array<ComboListOptions> {
	var list: Array<ComboListOptions> = [];
	var ordered: Array<ComboListOptions> = [];
	var header: string | undefined = "";

	response.map((item: any) => {
		const obj = {
			id: Number(item["country_code"]),
			value: String(item["name"]),
			group: String(item["continent"]),
		};
		list.push(obj);
	});

	list = orderBy(list, "group", "asc");

	list.map((l) => {
		if (l.group !== header) {
			header = l.group;
			ordered.push({ header: header });
		}
		ordered.push(l);
	});

	return ordered;
}

export function resolveLanguages(response: Record<string, string>) {
	const langs: Array<any> = [];

	for (const [key, name] of Object.entries(response)) {
		const lang: ComboListOptions = {
			id: key,
			value: name,
		};

		langs.push(lang);
	}

	return langs;
}
