import {
	CardAction,
	CardActionColor,
	CardActionEmit,
	CardActionType,
} from "@/interfaces/action";

export class CardActionEntity implements CardAction {
	type: CardActionType = CardActionType.SAVE;
	text: CardActionType = CardActionType.SAVE;
	disabled: Boolean = true;
	outlined: Boolean = false;
	color: CardActionColor = CardActionColor.SECONDARY;
	emit: CardActionEmit = CardActionEmit.SAVE;
	icon?: string;

	constructor(params: {
		type?: CardActionType;
		properties?: Partial<CardAction>;
	}) {
		if (params.type) {
			this.type = params.type;
		}

		if (params.properties) {
			this.type = params.properties.type || CardActionType.SAVE;
			this.text = params.properties.type || CardActionType.SAVE;
			this.disabled = params.properties.disabled || false;
			this.outlined = params.properties.outlined || false;
			this.color = params.properties.color || CardActionColor.SECONDARY;
			this.emit = params.properties.emit || CardActionEmit.SAVE;
			this.icon = params.properties.icon;
		}
	}

	setDisabled(disabled: Boolean) {
		this.disabled = disabled;
	}

	setText(text: CardActionType) {
		this.text = text;
	}

	getBtnType() {
		return this.type === CardActionType.SUBMIT
			? CardActionType.SUBMIT
			: CardActionType.BUTTON;
	}

	getBtnDataCy(reference?: string) {
		return `${this.type}${reference}`;
	}

	getText(): CardActionType {
		return this.text;
	}
}

export const getCardActions: CardActionEntity[] = [
	new CardActionEntity({
		properties: {
			type: CardActionType.VIEW_LINE_ITEM,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.VIEW_LINE_ITEM,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.CANCEL,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.CANCEL,
			outlined: true,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.CLOSE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.CLOSE,
			icon: "mdi-close",
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.DOWNLOAD,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.DOWNLOAD,
			icon: "mdi-download",
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.RUN,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.RUN,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SAVE_CONTINUE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SAVE_CONTINUE,
			icon: "mdi-content-save-move-outline",
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.CREATE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.CREATE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SAVE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SAVE,
			icon: "mdi-content-save-outline",
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.UPDATE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.UPDATE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SCHEDULER,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SCHEDULER,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SCHEDULER_CREATE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SCHEDULER_CREATE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SCHEDULER_UPDATE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SCHEDULER_UPDATE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SUBMIT,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SUBMIT,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.DELETE_ALL,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.DELETE_ALL,
			icon: "mdi-delete-circle",
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.UPLOAD,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.UPLOAD,
			icon: "mdi-upload-circle",
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.BACK,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.BACK,
			icon: "mdi-arrow-left",
		},
	}),
];

export function getEnabledActions(actions: CardActionType[]) {
	return getCardActions.filter((a) => actions.includes(a.type));
}

export const orderActionTypes: Record<CardActionType, number> = {
	[CardActionType.VIEW_LINE_ITEM]: 1,
	[CardActionType.SUBMIT]: 2,
	[CardActionType.SAVE]: 3,
	[CardActionType.SAVE_CONTINUE]: 4,
	[CardActionType.DELETE_ALL]: 5,
	[CardActionType.CREATE]: 6,
	[CardActionType.UPDATE]: 7,
	[CardActionType.BUTTON]: 8,
	[CardActionType.CLOSE]: 9,
	[CardActionType.DOWNLOAD]: 10,
	[CardActionType.GENERATE]: 11,
	[CardActionType.RUN]: 12,
	[CardActionType.SCHEDULER]: 13,
	[CardActionType.SCHEDULER_CREATE]: 14,
	[CardActionType.SCHEDULER_UPDATE]: 15,
	[CardActionType.SAVE_ASSOCIATION]: 16,
	[CardActionType.UPLOAD]: 17,
	[CardActionType.CANCEL]: 18,
	[CardActionType.BACK]: 19,
};

export function getOrderedActions(actions: CardActionEntity[]) {
	const orderActions: Record<CardActionType, number> = orderActionTypes;

	return actions
		.filter((action) => orderActions.hasOwnProperty(action.type))
		.sort((a, b) => orderActions[a.type] - orderActions[b.type]);
}
