import { AxiosGet, GetData } from '@/services/axios-service'
import { catchServiceErrors } from '@/utils/services-global';

export const ACCOUNT_ROUTE = '/api/account'

class AccountService {
    async account() {
        try {
            const response = await AxiosGet(ACCOUNT_ROUTE)
            return Promise.resolve(GetData(response));
        } catch (error) {
            return await catchServiceErrors(error);
        }
    }
}

export default new AccountService()
