import { List } from "@/interfaces/custom_list";
import ModifierOptionsService from "@/services/modifier-options-service";
import SegmentService from "@/services/segment-service";
import { resolveList, resolveListWithoutIDs, resolveListSegments, resolveLisWithKeyApi } from "@/utils/resolveObjectArray";
import { catchError } from "@/store/Util";

export const ModifierOptionModule = {
			namespaced: true,
			state: () => ({
				// Global parameters
				matching_types: [] as List[],
				// Module GEO
				countries: [] as List[],
				regions: [] as List[],
				cities: [] as List[],
				locations: [] as List[],
				lat_long_list: [] as List[],
				// Module Ad Sizes
				sizes: [] as List[],
				// Module Inventory
				positions: [] as List[],
				auction_types: [] as List[],
				deal_ids: [] as List[],
				environment_types: [] as List[],
				interstitial_flags: [] as List[],
				interstitial_types: [] as List[],
				inventory_sources: [] as List[],
				placement_ids: [] as List[],
				publisher_ids: [] as List[],
				site_ids: [] as List[],
				api_frameworks: [] as List[],
				// Module Content
				content_categories: [] as List[],
				content_ratings: [] as List[],
				languages: [] as List[],
				// Module Video
				companion_support: [] as List[],
				video_playback_methods: [] as List[],
				player_sizes: [] as List[],
				start_delays: [] as List[],
				video_placements: [] as List[],
				// Module Mobile App
				app_bundles: [] as List[],
				app_ids: [] as List[],
				// Module Native
				native_layouts: [] as List[],
				// Module Platform
				bandwidths: [] as List[],
				browsers: [] as List[],
				browser_versions: [] as List[],
				carriers: [] as List[],
				device_makes: [] as List[],
				device_models: [] as List[],
				device_screen_sizes: [] as List[],
				device_types: [] as List[],
				operating_systems: [] as List[],
				operating_system_versions: [] as List[],
				// Module Domain
				domain_list: [] as List[],
				// Module IP Address List
				ip_address_list: [] as List[],
				// Module
				video_apis: [] as List[],
				segment_providers: [] as List[],
				segment_categories: [] as List[],
				top_frame: [] as List[],
			}),
			mutations: {
				// Global parameters
				SET_MATCHING_TYPES(state, _list: List[]) {
					state.matching_types = _list;
				},
				// Module GEO
				SET_COUNTRIES(state, _list: List[]) {
					state.countries = _list;
				},
				SET_REGIONS(state, _list: List[]) {
					state.regions = _list;
				},
				SET_CITIES(state, _list: List[]) {
					state.cities = _list;
				},
				SET_LOCATIONS(state, _list: List[]) {
					state.locations = _list;
				},
				SET_LAT_LONG(state, _list: List[]) {
					state.lat_long_list = _list;
				},
				// Module Ad Sizes
				SET_SIZES(state, _list: List[]) {
					state.sizes = _list;
				},
				// Module Inventory
				SET_POSITIONS(state, _list: List[]) {
					state.positions = _list;
				},
				SET_AUCTION_TYPES(state, _list: List[]) {
					state.auction_types = _list;
				},
				SET_DEAL_IDS(state, _list: List[]) {
					state.deal_ids = _list;
				},
				SET_ENVIRONMENT_TYPES(state, _list: List[]) {
					state.environment_types = _list;
				},
				SET_INTERSTITIAL_FLAGS(state, _list: List[]) {
					state.interstitial_flags = _list;
				},
				SET_INTERSTITIAL_TYPES(state, _list: List[]) {
					state.interstitial_types = _list;
				},
				SET_INVENTORY_SOURCES(state, _list: List[]) {
					state.inventory_sources = _list;
				},
				SET_PLACEMENT_IDS(state, _list: List[]) {
					state.placement_ids = _list;
				},
				SET_PUBLISHER_IDS(state, _list: List[]) {
					state.publisher_ids = _list;
				},
				SET_SITE_IDS(state, _list: List[]) {
					state.site_ids = _list;
				},
				SET_API_FRAMEWORKS(state, _list: List[]) {
					state.api_frameworks = _list;
				},
				// Module Content
				SET_CONTENT_CATEGORIES(state, _list: List[]) {
					state.content_categories = _list;
				},
				SET_CONTENT_RATINGS(state, _list: List[]) {
					state.content_ratings = _list;
				},
				SET_LANGUAGES(state, _list: List[]) {
					state.languages = _list;
				},
				// Module Video
				SET_COMPANION_SUPPORT(state, _list: List[]) {
					state.companion_support = _list;
				},
				SET_VIDEO_PLAYBACK_METHODS(state, _list: List[]) {
					state.video_playback_methods = _list;
				},
				SET_PLAYER_SIZES(state, _list: List[]) {
					state.player_sizes = _list;
				},
				SET_START_DELAYS(state, _list: List[]) {
					state.start_delays = _list;
				},
				SET_VIDEO_PLACEMENTS(state, _list: List[]) {
					state.video_placements = _list;
				},
				// Module Mobile App
				SET_APP_BUNDLES(state, _list: List[]) {
					state.app_bundles = _list;
				},
				SET_APP_IDS(state, _list: List[]) {
					state.app_ids = _list;
				},
				// Module Native
				SET_NATIVE_LAYOUTS(state, _list: List[]) {
					state.native_layouts = _list;
				},
				// MODULE Platform
				SET_BANDWIDTHS(state, _list: List[]) {
					state.bandwidths = _list;
				},
				SET_BROWSERS(state, _list: List[]) {
					state.browsers = _list;
				},
				SET_BROWSER_VERSIONS(state, _list: List[]) {
					state.browser_versions = _list;
				},
				SET_CARRIERS(state, _list: List[]) {
					state.carriers = _list;
				},
				SET_DEVICE_MAKES(state, _list: List[]) {
					state.device_makes = _list;
				},
				SET_DEVICE_MODELS(state, _list: List[]) {
					state.device_models = _list;
				},
				SET_DEVICE_SCREEN_SIZES(state, _list: List[]) {
					state.device_screen_sizes = _list;
				},
				SET_DEVICE_TYPES(state, _list: List[]) {
					state.device_types = _list;
				},
				SET_OPERATING_SYSTEMS(state, _list: List[]) {
					state.operating_systems = _list;
				},
				SET_OPERATING_SYSTEM_VERSIONS(state, _list: List[]) {
					state.operating_system_versions = _list;
				},
				// Module Domain
				SET_DOMAIN_LIST(state, _list: List[]) {
					state.domain_list = _list;
				},
				// Module IP Address List
				SET_IP_ADDRESS_LIST(state, _list: List[]) {
					state.ip_address_list = _list;
				},
				// Module
				SET_VIDEO_APIS(state, _list: List[]) {
					state.video_apis = _list;
				},
				SET_SEGMENT_PROVIDERS(state, _list: List[]) {
					state.segment_providers = _list;
				},
				SET_SEGMENT_CATEGORIES(state, _list: List[]) {
					state.segment_categories = _list;
				},
				SET_TOP_FRAME(state, _list: List[]) {
					state.top_frame = _list;
				},
			},
			getters: {
				getModifierOption(state) {
					return {
						ad_size: state.sizes,
						app_bundle_list: state.app_bundles,
						ad_position: state.positions,
						environment_type: state.environment_types,
						interstitial_flags: state.interstitial_flags,
						interstitial_type: state.interstitial_types,
						api_frameworks: state.api_frameworks,
						topframe: state.top_frame,
						video_api: state.video_apis,
						content_rating: state.content_ratings,
						language: state.languages,
						auction_type: state.auction_types,
						companion_required: state.companion_support,
						inventory_source: state.inventory_sources,
						playback_method: state.video_playback_methods,
						player_size: state.player_sizes,
						start_delay: state.start_delays,
						video_placement_type: state.video_placements
					};
				},
			},
			actions: {
				// GLOBAL PARAMETERS
				async getMatchingTypes({ commit }, params) {
					try {
						const response = await ModifierOptionsService.matchingTypeList(
							params
						);
						commit("SET_MATCHING_TYPES", resolveList(response));
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE GEO REQUEST
				async getCountries({ commit }, params) {
					try {
						const response = await ModifierOptionsService.countriesList(params);
						commit("SET_COUNTRIES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getRegions({ commit }, params) {
					try {
						const response = await ModifierOptionsService.regionsList(params);
						commit("SET_REGIONS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getCities({ commit }, params) {
					try {
						const response = await ModifierOptionsService.citiesList(params);
						commit("SET_CITIES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getLocations({ commit }, params) {
					try {
						const response = await ModifierOptionsService.locationsList();
						commit("SET_LOCATIONS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getLatLong({ commit }, params) {
					try {
						const response = await ModifierOptionsService.latLongList();
						commit("SET_LAT_LONG", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "AD SIZE" REQUEST
				async getSizes({ commit }, params) {
					try {
						const response = await ModifierOptionsService.sizesList();
						commit("SET_SIZES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "INVENTORY" REQUEST
				async getPositions({ commit }, params) {
					try {
						const response = await ModifierOptionsService.positionsList();
						commit("SET_POSITIONS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getAuctionTypes({ commit }, params) {
					try {
						const response = await ModifierOptionsService.auctionTypesList();
						commit("SET_AUCTION_TYPES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getDealIdList({ commit }, params) {
					try {
						const response = await ModifierOptionsService.dealIdList();
						commit("SET_DEAL_IDS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getEnvironmentTypes({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.environmentTypesList();
						commit("SET_ENVIRONMENT_TYPES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getInterstitialFlags({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.interstitialFlagsList();
						commit("SET_INTERSTITIAL_FLAGS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getInterstitialTypes({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.interstitialTypesList();
						commit("SET_INTERSTITIAL_TYPES", resolveLisWithKeyApi(response, ));
						return await Promise.resolve(resolveListWithoutIDs(response));
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getInventorySources({ commit }, params) {
					try {
						const response = await ModifierOptionsService.inventorySourceList();
						commit("SET_INVENTORY_SOURCES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getPlacementIds({ commit }, params) {
					try {
						const response = await ModifierOptionsService.placementIdList();
						commit("SET_PLACEMENT_IDS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getPublisherIds({ commit }, params) {
					try {
						const response = await ModifierOptionsService.publisherIdList();
						commit("SET_PUBLISHER_IDS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getSiteIds({ commit }, params) {
					try {
						const response = await ModifierOptionsService.siteIdList();
						commit("SET_SITE_IDS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getAPIFrameworks({ commit }, params) {
					try {
						const response = await ModifierOptionsService.APIFrameworksList();
						commit("SET_API_FRAMEWORKS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "CONTENT" REQUEST
				async getContentCategories({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.contentCategoriesList();
						commit("SET_CONTENT_CATEGORIES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getContentRatings({ commit }, params) {
					try {
						const response = await ModifierOptionsService.contentRatingsList();
						commit("SET_CONTENT_RATINGS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getLanguages({ commit }, params) {
					try {
						const response = await ModifierOptionsService.languagesList();
						commit("SET_LANGUAGES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "VIDEO" REQUEST
				async getCompanionSupports({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.companionSupportsList();
						commit("SET_COMPANION_SUPPORT", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getVideoPlaybackMethods({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.videoPlaybackMethodsList();
						commit("SET_VIDEO_PLAYBACK_METHODS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getPlayerSizes({ commit }, params) {
					try {
						const response = await ModifierOptionsService.playerSizesList();
						commit("SET_PLAYER_SIZES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getStartDelays({ commit }, params) {
					try {
						const response = await ModifierOptionsService.startDelaysList();
						commit("SET_START_DELAYS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getVideoPlacements({ commit }, params) {
					try {
						const response = await ModifierOptionsService.videoPlacementsList();
						commit("SET_VIDEO_PLACEMENTS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "MOBILE APP" REQUEST
				async getAppBundles({ commit }, params) {
					try {
						const response = await ModifierOptionsService.appBundlesList();
						commit("SET_APP_BUNDLES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getAppIds({ commit }, params) {
					try {
						const response = await ModifierOptionsService.appIdsList();
						commit("SET_APP_IDS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "NATIVE" REQUEST
				async getNativeLayouts({ commit }, params) {
					try {
						const response = await ModifierOptionsService.nativeLayoutsList();
						commit("SET_NATIVE_LAYOUTS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "PLATFORM" REQUEST
				async getBandwidths({ commit }, params) {
					try {
						const response = await ModifierOptionsService.bandwidthList();
						commit("SET_BANDWIDTHS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getBrowsers({ commit }, params) {
					try {
						const response = await ModifierOptionsService.browserList();
						commit("SET_BROWSERS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getBrowserVersions({ commit }, params) {
					try {
						const response = await ModifierOptionsService.browserVersionList();
						commit("SET_BROWSER_VERSIONS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getCarriers({ commit }, params) {
					try {
						const response = await ModifierOptionsService.carrierList();
						commit("SET_CARRIERS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getDeviceMakes({ commit }, params) {
					try {
						const response = await ModifierOptionsService.deviceMakeList();
						commit("SET_DEVICE_MAKES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getDeviceModels({ commit }, params) {
					try {
						const response = await ModifierOptionsService.deviceModelList();
						commit("SET_DEVICE_MODELS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getDeviceScreenSizes({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.deviceScreenSizeList();
						commit("SET_DEVICE_SCREEN_SIZES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getDeviceTypes({ commit }, params) {
					try {
						const response = await ModifierOptionsService.deviceTypeList();
						commit("SET_DEVICE_TYPES", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getOperatingSystems({ commit }, params) {
					try {
						const response = await ModifierOptionsService.operatingSystemList();
						commit("SET_OPERATING_SYSTEMS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getOperatingSystemVersions({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.OperatingSystemVersionList();
						commit("SET_OPERATING_SYSTEM_VERSIONS", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "DOMAIN" REQUEST
				async getDomains({ commit }, params) {
					try {
						const response = await ModifierOptionsService.domainList();
						commit("SET_DOMAIN_LIST", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "IP ADDRESS LIST" REQUEST CHANGE TO USER
				async getIPAddressList({ commit }, params) {
					try {
						const response = await ModifierOptionsService.ipAddressList();
						commit("SET_IP_ADDRESS_LIST", response);
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getSegmentsList({ commit }, params) {
					try {
						const response = await SegmentService.all(params);
						return await Promise.resolve(resolveListSegments(response));
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				// MODULE "" REQUEST
				async getVideoApis({ commit }, params) {
					try {
						const response = await ModifierOptionsService.videoAPIsList();
						commit("SET_VIDEO_APIS", resolveList(response));
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getSegmentProviders({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.segmentProvidersList();
						commit("SET_SEGMENT_PROVIDERS", resolveListSegments(response));
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getSegmentCategories({ commit }, params) {
					try {
						const response =
							await ModifierOptionsService.segmentCategoriesList();
						commit("SET_SEGMENT_CATEGORIES", resolveList(response));
						commit("SET_MODIFIER_MODULES_LIST", resolveList(response));
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				async getTopFrame({ commit }, params) {
					try {
						const response = await ModifierOptionsService.APITopFrame();
						commit("SET_TOP_FRAME", resolveList(response));
						return await Promise.resolve(response);
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				},

				//MODULE APP-SITE
				async getAppName({commit}, params) {
					try {	
						return await Promise.resolve(resolveList(ModifierOptionsService.getAppName()));
					} catch (error) {
						catchError(this, error);
						return await Promise.reject(error);
					}
				}
			},
		};
