import { Account, TwoFactorData } from "@/interfaces/account";
import { Category } from "@/interfaces/advertiser";
import { ResultPaginate } from "@/interfaces/paginated";
import AccountService from "@/services/account-service";
import { catchError } from "@/store/Util";

export const AccountModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		account: {} as Category,
		twoFactor: {} as TwoFactorData
	}),
	mutations: {
		SET_RESULT_PAGINATED(state, _result_paginate: ResultPaginate) {
			state.result_paginate = _result_paginate;
		},
		SET_ACCOUNT(state, _account: Account = {} as Account) {
			state.account = _account;
		},
		SET_TWO_FACTOR_DATA(state, _twoFactorData: TwoFactorData = {} as TwoFactorData) {
			state.twoFactor = _twoFactorData;
		},
	},
	getters: {
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
		account(state): any {
			return state.account;
		},
		isAccountMaster(state): any {
			return state.account.master_account;
		},
		getTwoFactorData(state): TwoFactorData{
			return state.twoFactor;
		}
	},
	actions: {
		async getAccount({ commit }) {
			try {
				const response = await AccountService.account();
				commit("SET_ACCOUNT", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "Dashboard" });
				return await Promise.reject(error);
			}
		},
	},
};
