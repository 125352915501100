import CustomListExchangeService from "@/services/custom-list-exchange-service";

export const CustomListExchangeModule = {
	namespaced: true,
	state: () => ({
		entities: [] as any[],
	}),
	mutations: {
		SET_ENTITIES(state, _entities: [] = []) {
			state.entities = _entities;
		},
	},
	getters: {},
	actions: {
		async all({ commit }) {
			try {
				const response = await CustomListExchangeService.all();
				if (response?.success) {
					let list: any[] = [];
					response?.data.map((e) => {
						list.push({
							id: e.id,
							abbreviation: e.abbreviation,
							description: e.description,
						});
					});
					commit("SET_ENTITIES", list);
				} else {
					commit("SET_ENTITIES");
				}
				return await Promise.resolve(response?.data);
			} catch (error) {
				commit("SET_ENTITIES");
				return await Promise.reject(error);
			}
		},
	},
};
