
import Vue from "vue";

// @ts-ignore
import IconAppSite from "@/components/Commons/Icons/IconAppSite.vue";
// @ts-ignore
import IconBidModel from "@/components/Commons/Icons/IconBidModel.vue";
// @ts-ignore
import IconBidModifier from "@/components/Commons/Icons/IconBidModifier.vue";
// @ts-ignore
import IconCalendar from "@/components/Commons/Icons/IconCalendar.vue";
// @ts-ignore
import IconChevrondown from "@/components/Commons/Icons/IconChevrondown.vue";
// @ts-ignore
import IconContent from "@/components/Commons/Icons/IconContent.vue";
// @ts-ignore
import IconDeliveryModel from "@/components/Commons/Icons/IconDeliveryModel.vue";
// @ts-ignore
import IconDeliveryModifier from "@/components/Commons/Icons/IconDeliveryModifier.vue";
// @ts-ignore
import IconDownload from "@/components/Commons/Icons/IconDownload.vue";
// @ts-ignore
import IconEdit from "@/components/Commons/Icons/IconEdit.vue";
// @ts-ignore
import IconEnvironment from "@/components/Commons/Icons/IconEnvironment.vue";
// @ts-ignore
import IconExchange from "@/components/Commons/Icons/IconExchange.vue";
// @ts-ignore
import IconEyes from "@/components/Commons/Icons/IconEyes.vue";
// @ts-ignore
import IconGeo from "@/components/Commons/Icons/IconGeo.vue";
// @ts-ignore
import IconGroup from "@/components/Commons/Icons/IconGroup.vue";
// @ts-ignore
import IconLink from "@/components/Commons/Icons/IconLink.vue";
// @ts-ignore
import IconPlatform from "@/components/Commons/Icons/IconPlatform.vue";
// @ts-ignore
import IconPlus from "@/components/Commons/Icons/IconPlus.vue";
// @ts-ignore
import IconSearch from "@/components/Commons/Icons/IconSearch.vue";
// @ts-ignore
import IconSetting from "@/components/Commons/Icons/IconSetting.vue";
// @ts-ignore
import IconTime from "@/components/Commons/Icons/IconTime.vue";
// @ts-ignore
import IconTrash from "@/components/Commons/Icons/IconTrash.vue";
// @ts-ignore
import IconUpload from "@/components/Commons/Icons/IconUpload.vue";
// @ts-ignore
import IconUser from "@/components/Commons/Icons/IconUser.vue";
// @ts-ignore
import IconWorld from "@/components/Commons/Icons/IconWorld.vue";
// @ts-ignore
import IconHelp from "@/components/Commons/Icons/IconHelp.vue";
// @ts-ignore
import IconEmail from "@/components/Commons/Icons/IconEmail.vue";

import IconCheck from "@/components/Commons/Icons/IconCheck.vue";
// @ts-ignore
import IconCircle from "@/components/Commons/Icons/IconCircle.vue";
// @ts-ignore
import IconInfo from "@/components/Commons/Icons/IconInfo.vue";
export default Vue.extend({
	props: {
		colorFill: {
			type: String,
			default: "none",
		},
		colorStroke: {
			type: String,
			default: "#AD0075",
		},
		icon: {
			type: String,
			default: "",
		},
	},
	components: {
		IconAppSite,
		IconBidModel,
		IconBidModifier,
		IconCalendar,
		IconChevrondown,
		IconContent,
		IconDeliveryModel,
		IconDeliveryModifier,
		IconDownload,
		IconEdit,
		IconEmail,
		IconEnvironment,
		IconExchange,
		IconEyes,
		IconGeo,
		IconGroup,
		IconLink,
		IconPlatform,
		IconPlus,
		IconSearch,
		IconSetting,
		IconTime,
		IconTrash,
		IconUpload,
		IconUser,
		IconWorld,
		IconHelp,
		IconCheck,
		IconCircle,
		IconInfo
	},
});
