const detect_prod_key: string = "prod";

export function getLocation() {
  return window.location;
}
export function getOrigin() {
  return getLocation().origin;
}
export function isProd() {
  return getOrigin().includes(detect_prod_key);
}
