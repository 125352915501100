import { AccountColor } from "@/interfaces/audience";
import {
	CellColor,
	Content,
	StyleDictionary,
	TDocumentInformation,
} from "@/interfaces/pdfmake";
import PdfMakeDefinitions from "@/models/PdfMakeDefinitions";
import store from "@/store";
import { head } from "lodash";
import {
	getStoreAttributionTableHeader,
} from "./persons/audience";

/**
 * Get order graphics for export
 * @returns
 */
export function getOrderGraphicExport() {
	return [
		// pois
		"users_by_date_line_date",
		"users_by_distance_grouped",
		"users_by_week_day_line_graph",
		"users_by_hour_day_line_graph",
		"users_by_category_grouped",
		"users_by_subcategory_grouped",
		"users_by_poi_city_grouped",
		"users_by_dpto_grouped",
		"users_by_poi_neighborhood_grouped",

		// demographic
		"users_by_ciudad_residencia_grouped",
		"users_by_niv_socio_grouped",
		"users_by_age_grouped",
		"users_by_gender_grouped",
		"users_by_barrio_residencia_grouped",

		// online_behaviour
		"users_by_type_grouped",
		"users_by_iab_grouped",
		"users_by_intereses_grouped",
		"users_by_app_name_grouped",
		"users_by_domain_grouped",
		"users_by_city_seen_grouped",

		// device
		"users_by_carrier_grouped",
		"users_by_device_make_grouped",
		"users_by_platform_device_type_grouped",
		"users_by_device_language_grouped",
		"users_by_platform_browser_grouped",
		"users_by_plataform_os_grouped",
		
		// table
		"users_by_hour_day_week_heat_map",
	];
}

export async function makePdfMakeDefinitions(cellColor?: CellColor) {
	return new PdfMakeDefinitions(cellColor);
}

export function getDocInit(config: {
	defaultWidth: number;
	marginDefault: number;
	headerData: any;
	cellColors: any;
}) {
	return {
		compress: true,
		unbreakable: true,
		pageSize: {
			width: config.defaultWidth,
			height: "auto",
		},
		pageOrientation: "portrait",
		pageMargins: config.marginDefault,
		content: [] as any[],
		header: config.headerData,
		styles: {
			gotham: {
				font: "Gotham",
			},
			colorDinamic: {
				color: config.cellColors.color,
			},
			title: {
				bold: true,
				fontSize: 12,
				margin: [0, 10],
			},
			header: {
				bold: true,
				fontSize: 10,
			},
			infoRowTitle: {
				color: "#606060",
				bold: false,
				fontSize: 8,
			},
			infoRowValue: {
				color: "#000000",
				bold: true,
				fontSize: 9,
			},
			helpField: {
				color: "#606060",
				bold: false,
				margin: [0, 20, 0, 10],
				fontSize: 6,
			},
			tableSummary: {
				margin: [0, 0, 0, 10],
			},
			tableFilter: {
				margin: [0, 0, 0, 10],
				color: config.cellColors.color,
			},
			categoryFilter: {
				fontSize: 10,
				bold: true,
				color: "808080",
			},
			colorTableCell: {
				fillColor: config.cellColors.fillColor,
				color: config.cellColors.color,
			},
		},
		defaultStyle: {
			columnGap: 10,
		},
		info: {
			title: "Export Charts - Audiences",
			author: "DSP Adsmovil",
			subject: "DSP Adsmovil",
			keywords: "audience",
		},
	};
}

export function defaultInfoDoc(): TDocumentInformation {
	return {
		title: "Export Charts - Audiences",
		author: "DSP Adsmovil",
		subject: "DSP Adsmovil",
		keywords: "audience",
	};
}

export function defaultStyleDoc() {
	return {
		columnGap: 10,
	};
}

export function defaultStylesDoc(cellColor?: CellColor): StyleDictionary {
	return {
		gotham: {
			font: "Gotham",
		},
		colorDinamic: {
			color: cellColor?.color,
		},
		title: {
			bold: true,
			fontSize: 12,
			margin: [0, 10],
			noWrap: false
		},
		header: {
			bold: true,
			fontSize: 10,
			noWrap: false
		},
		infoRowTitle: {
			color: "#606060",
			bold: false,
			fontSize: 8,
			noWrap: false
		},
		infoRowValue: {
			color: "#000000",
			bold: true,
			fontSize: 9,
			noWrap: false
		},
		helpField: {
			color: "#777777",
			bold: false,
			margin: [0, 20, 0, 10],
			fontSize: 7,
		},
		tableSummary: {
			margin: [0, 0, 0, 10],
		},
		tableFilter: {
			margin: [0, 0, 0, 10],
			color: cellColor?.color,
		},
		categoryFilter: {
			fontSize: 10,
			bold: true,
			color: "808080",
			noWrap: false
		},
		colorTableCell: {
			fillColor: cellColor?.fillColor,
			color: cellColor?.color,
			noWrap: false
		},
	};
}

export function getFonts() {
	return {
		Gotham: {
			normal: `${process.env.VUE_APP_FRONT_URL}/fonts/GOTHAM/Gotham-Medium.ttf`,
			bold: `${process.env.VUE_APP_FRONT_URL}/fonts/GOTHAM/Gotham-Bold.ttf`,
			italics: `${process.env.VUE_APP_FRONT_URL}/fonts/GOTHAM/Gotham-BlackItalic.ttf`,
			bolditalics: `${process.env.VUE_APP_FRONT_URL}/fonts/GOTHAM/Gotham-BoldItalic.ttf`,
		},
		Roboto: {
			normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
			bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
			italics:
				"https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
			bolditalics:
				"https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
		},
	};
}

/**
 * matched lang => key
 */
export const matchedLangByKey = {
	filter: "persons.labels.audience.filter",
	audience: "persons.labels.audience.audience",
	store_attribution: "reportOnDemand.separators.store_attribution",
	country: "persons.filters.country.label",
	pois: "persons.tabs.pois",
	demographic: "persons.tabs.demographic",
	online_behaviour: "persons.tabs.online_behaviour",
	device: "persons.tabs.device",
	countUser: "persons.labels.audience.count-user",
	countUserRequired: "persons.labels.audience.count-user-required-info",
	radio: "persons.labels.audience.radio",
	totals: "persons.labels.pois.totals",
	totalPrivate: "persons.labels.pois.totals-private",
	totalPublic: "persons.labels.pois.totals-public",
	totalOOH: "persons.v10.poisCount.ooh",
};

/**
 * Internalization
 * matched langs
 */
export const matchedLangIntl = {
	es: "es-ES",
	en: "en-US",
	pt: "pt-Br",
};

/**
 * Add Content: title
 * @param headerName
 * @returns
 */
export async function makeDocHeaderTitle(headerName: string): Promise<Content> {
	return {
		style: "header",
		margin: [0, 0, 0, 11],
		text: headerName?.toUpperCase(),
	};
}

/**
 * Get color from account colors
 * @returns
 */
export async function getAccountColorFromStore(): Promise<CellColor> {
	const accountColor: AccountColor = store.getters["profile/account"]?.colors;

	return {
		color: accountColor.account_name_color,
		fillColor: accountColor.header_color_1,
		//fillColor: getColorByBgColor(accountColor.header_color_1),
	};
}

/**
 * Has store attribution campaigns
 * @returns
 */
export async function hasStoreAttributionCampaigns(): Promise<Boolean> {
	return store.getters["audience/hasStoreAttributionCampaigns"];
}

/**
 * If graphics have filters
 * @returns
 */
export async function isFilteredGraphics(): Promise<Boolean> {
	return store.getters["audience_graphics/isFilteredGraphics"];
}

/**
 * Get store attribution campaigns
 * @returns
 */
export async function getStoreAttributionCampaigns(): Promise<any[]> {
	return store.getters["audience/getStoreAttributionCampaigns"];
}

/**
 * Get headerName from store attribution
 * @returns
 */
export async function getHeaderNameFromStoreAttribution() {
	const items = await getStoreAttributionTableHeader();
	const headers: any[] = head(items)?.children || [];
	return {
		headerName: head(items)?.headerName.toString() || "",
		headers,
	};
}
