import FieldEntity from "@/models/FieldEntity";
import SelectedEntity from "@/models/SelectedEntity";
import {
	prepareSelectedData,
} from "@/utils/CustomizeViewData";

export const CustomizeViewModule = {
	namespaced: true,
	state: () => ({
		AdvertisersIndex: new FieldEntity(),
		CampaignsIndex: new FieldEntity(),
		LineItemList: new FieldEntity(),
		CreativesIndex: new FieldEntity(),
		SegmentsIndex: new FieldEntity(),
		ModifiersIndex: new FieldEntity(),
		UsersIndex: new FieldEntity(),
		RequestCampaignIndex: new FieldEntity(),
		PrivatePoisCreate: new FieldEntity(),
		EventIndex: new FieldEntity(),
		selected: {
			AdvertisersIndex: new SelectedEntity(),
			CampaignsIndex: new SelectedEntity(),
			LineItemList: new SelectedEntity(),
			CreativesIndex: new SelectedEntity(),
			SegmentsIndex: new SelectedEntity(),
			CustomListIndex: new SelectedEntity(),
			ModifiersIndex: new SelectedEntity(),
			UsersIndex: new SelectedEntity(),
			RequestCampaignIndex: new SelectedEntity(),
			PrivatePoisCreate: new SelectedEntity(),
			EventIndex: new SelectedEntity(),
		},
	}),
	mutations: {
		SET_FIELDS(state, params) {
			state[params.view] = params.fields;
		},
		SET_SELECTED(state, params) {
			const { view, tab, selected } = params;
			state.selected[view][tab] = selected;
		},
		SET_COLUMNS(state, params) {
			const { event, columns } = params;
			if (!event.view) return;
			state.selected[event.view]["columns"] = columns;
		},
		RESET_SELECTED_COLUMNS(state) {
			Object.keys(state.selected).map((key) => {
				state.selected[key].columns = [];
			});
		},
	},
	getters: {
		getFieldByView:
			(state) =>
			(view: string): FieldEntity => {
				return state[view];
			},
		getSelectedByView:
			(state) =>
			(view: string): SelectedEntity => {
				return state.selected[view];
			},
	},
	actions: {
		setFields({ commit }, params) {
			return new Promise((resolve) => {
				commit("SET_FIELDS", params);
				resolve(params);
			});
		},

		saveSelected({ commit }, params) {
			return new Promise((resolve) => {
				commit("SET_SELECTED", params);
				resolve(params);
			});
		},

		resetSelectedColumns({ commit }) {
			commit("RESET_SELECTED_COLUMNS");
		},

		saveColumns({ commit }, params) {
			return new Promise((resolve) => {
				commit("SET_COLUMNS", params);
				resolve(params);
			});
		},

		async getTableDataSelected(
			{ commit, getters },
			params: { route: string; entity: any[] }
		) {
			const selected: SelectedEntity = await getters.getSelectedByView(
				params.route
			);

			const fields = await prepareSelectedData(selected);

			return {
				selected,
				fields,
			};
		},
	},
};
