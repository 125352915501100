export interface SnackNotification {
	type: SnactType;
	message: string;
}

export enum SnactType {
	DEFAULT = "",
	SUCCESS = "green",
	ERROR = "red",
	INFO = "indigo",
	PRIMARY = "primary",
	SECONDARY = "secondary",
}
