import { defineAbility } from '@casl/ability';
import { isEmpty } from "lodash";
import store from "@/store";

export default () => defineAbility((can, cannot) => {
    const permission = store.getters['profile/getPermission'];
    if(!isEmpty(permission)){
        for (let clave in permission){
            permission[clave].forEach((v) => {
                can(v, clave);
            });
        }
    }
});