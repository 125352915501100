
import { sleep } from "@/utils/convert";
import { Notification, MessageTypes } from "../interfaces/proccess";
import Vue from "vue";
export default Vue.extend({
	name: "Alertize",
	props: {},
	data: () => ({
		images: {
			success: `@/assets/icons/success.svg`,
			error: `@/assets/icons/error.svg`,
		},
		showDownloadFileBtn: false,
	}),
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		notification(): Notification {
			return this.$store.state.proccess.notification;
		},
		showNotification() {
			return Boolean(this.notification?.show);
		},
		getMessage() {
			return this.notification?.message;
		},
		isStringMessage() {
			return typeof this.notification?.message === "string";
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		getType() {
			return this.notification?.type;
		},
		getRedirectTo() {
			return this.notification?.to;
		},
		getTitle() {
			return this.notification?.title;
		},
		getBtnText() {
			return this.notification?.btn_text;
		},
		getBtnTextRedirect() {
			return this.notification?.btn_text_redirect;
		},
		getRedirect() {
			return this.notification?.to_redirect;
		},
		getQuery() {
			return this.notification?.query;
		},
		getParams() {
			return this.notification?.params;
		},
		isSuccess() {
			return this.notification?.type === MessageTypes.SUCCESS;
		},

		isError() {
			return this.notification?.type === MessageTypes.ERROR;
		},

		isInfo() {
			return this.notification?.type === MessageTypes.INFO;
		},

		showIcon() {
			return [
				MessageTypes.SUCCESS,
				MessageTypes.ERROR,
				MessageTypes.INFO,
			].includes(this.notification?.type);
		},

		showRedirect() {
			return this.notification?.show_redirect;
		},

		// matched

		matchedTitle() {
			return {
				success: this.$t("title-success"),
				error: this.$t("title-failed"),
			};
		},

		matchedType() {
			return {
				success: this.$t("continue"),
				error: this.$t("try-again"),
			};
		},

		isAlertize: {
			set(val: Boolean) {
				this.setAlertize(val);
			},
			get() {
				return this.showNotification;
			},
		},
	},
	methods: {
		setAlertize(val: Boolean) {
			this.$store.state.proccess.notification.show = val;
		},
		async resetNotification() {
			return this.$store.dispatch(
				"proccess/setNotification",
				{
					show: false,
					message: "",
					type: "",
					title: "",
					btn_text: "",
				} as Notification,
				{ root: true }
			);
		},
		async resetErrors() {
			return this.$store.dispatch(
				"proccess/setErrors",
				{},
				{ root: true }
			);
		},
		async actionBtn() {
			try {

				/**
				 * Accion para redireccion de rutas
				 */
				const to = this.getRedirectTo;

				await this.actionClose();

				if (to) {
					return this.$router.push(
						typeof to == "object" ? to : { name: to }
					);
				}
			} catch (error) {
				console.error("actionBtn", { error });
			}
		},
		async actionRedirectBtn() {
			const to = this.getRedirect;
			const query = this.getQuery;
			const params = this.getParams;
			this.actionClose();
			if (to) {
				return this.$router.push(
					typeof to == "object"
						? to
						: { name: to, query: query, params: params }
				);
			}
			return;
		},

		async actionClose() {
			await this.resetNotification();
			await this.resetErrors();
		},

		/**
		 * Simular un clic en el boton ref:btnDownload para descargar el archivo
		 */
		async clickDownloadFile() {
			const domRefs = this.$refs;
			const url = this.notification.url_download;
			if (!url || !Object.keys(domRefs).length || !domRefs["btnDownload"])
				return;
			domRefs["btnDownload"]?.$el?.click();
		},

		/**
		 * Descargar archivo via url
		 */
		async downloadFile() {
			// URL del archivo que deseas descargar
			const url = this.notification.url_download;

			if (!url) return;

			// Crear un enlace temporal
			const link = document.createElement("a");
			link.href = url;
			//link.target = "_blank";
			link.target = "_top";
			//link.download = 'nombre_del_archivo';

			// Simular un clic en el enlace para descargar el archivo
			document.body.appendChild(link);
			link.click();

			// Eliminar el enlace del DOM
			document.body.removeChild(link);

			this.resetUrlDownload();
		},

		resetUrlDownload() {
			this.notification.url_download = "";
		},
	},
	watch: {
		async "notification.url_download"(val: string | undefined) {
			if (val) {
				await sleep(2000);
				//await this.clickDownloadFile();
			}
		},
	},
});
