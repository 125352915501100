import Vue from "vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
	data: () => ({}),
	components: {
		CardSwitch,
	},
	created() {},
	mounted() {},
	computed: {
		...mapGetters("profile", ["getAbility"]),
		getArchived: {
			get() {
				return Boolean(this.params.data.archived);
			},
			set(value) {},
		},

		showSwitch(): boolean {
			return this.params.showSwitch;
		},

		isReadOnly(): boolean {
			return this.params.readonly;
		},

		getLabelColor(): string {
			return this.getArchived
				? "text-bold red--text"
				: "text-bold green--text";
		},

		getColor(): string {
			return this.getArchived ? "red" : "green";
		},

		getText() {
			return this.getArchived
				? "events.fields.archived"
				: "events.fields.unarchived"; //events.fields.unarchived
		},

		getClassCard() {
			return {
				archived: Boolean(this.getArchived),
				"not-archived": !this.getArchived,
			};
		},

		getPermissions(){
            return this.params.permission;
        },

		canActive(){
			return !this.getAbility.can(this.getPermissions.actions.active, this.getPermissions.subject);
        },
	},
	methods: {
		invokeParentMethod(): void {
			if (this.isReadOnly) return;
			const param = {
				id: this.params.data.id,
				archived: this.getArchived,
				rowIndex: this.params.rowIndex,
			};
			this.params.contextT.componentParent.handleArchived(param);
		},
	},
});
