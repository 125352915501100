import { PaginatedEntity } from "@/models/Paginated";
import EventService from "@/services/event-service";
import { resolveList } from "@/utils/resolveObjectArray";
import { catchError } from "../Util";
import { EventEntity } from "@/models/events/Event";
import {
	ASSOCIATION_ROUTE_TYPE,
	AssociatedItem,
	EVENT_ASSOCIATION_TYPE,
	EventCreate,
	EventFilters,
	EventUpdate,
} from "@/interfaces/event";
import { SortingOption } from "@/interfaces/paginated";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { TableAssociatedDataEntity } from "@/models/events/Table";
import { tableHeaderCampaign, tableHeaderLineItem } from "@/models/events/Data";
import { CardActionType } from "@/interfaces/action";
import { DataFieldEntity } from "@/models/events/Field";

export const EventModule = {
	namespaced: true,
	state: () => ({
		paginated: new PaginatedEntity(),
		dataFields: new DataFieldEntity(),
		event: new EventEntity(),
		tables: {
			[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: new TableAssociatedDataEntity(
				tableHeaderCampaign
			),
			[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]:
				new TableAssociatedDataEntity(tableHeaderLineItem),
		},
		forms: {
			[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: [] as AssociatedItem[],
			[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: [] as AssociatedItem[],
		},
		messages: {
			[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: [] as any[],
			[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: [] as any[],
		},
		actions: {
			[CardActionType.SAVE]: true,
			[CardActionType.CANCEL]: true,
		},
	}),
	mutations: {
		SET_PAGINATED(
			state: { paginated: PaginatedEntity },
			_paginated: PaginatedEntity = new PaginatedEntity()
		) {
			state.paginated = {
				..._paginated,
				data: _paginated.data.map((d) => new EventEntity(d)),
			};
		},
		SET_EVENT(
			state: { event: EventEntity },
			event: EventEntity = new EventEntity()
		) {
			state.event = event;
		},
		RESET_TABLE_ITEMS(state: {
			event: EventEntity;
			tables: {
				[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: TableAssociatedDataEntity;
				[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: TableAssociatedDataEntity;
			};
		}) {
			state.tables[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID].setItems(
				state.event.getCurrentCampaigns()
			);
			state.tables[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID].setItems(
				state.event.getCurrentLineItems()
			);
		},
		SET_TABLE_ITEMS(
			state: {
				tables: {
					[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: TableAssociatedDataEntity;
					[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: TableAssociatedDataEntity;
				};
			},
			payload: { key: EVENT_ASSOCIATION_TYPE; items: AssociatedItem[] }
		) {
			try {
				state.tables[payload.key].setItems(payload.items);
			} catch (error) {
				console.error("SET_TABLE_ITEMS", error);
			}
		},
		ADD_MESSAGE(
			state: {
				messages: {
					[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: any[];
					[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: any[];
				};
			},
			payload: { key: EVENT_ASSOCIATION_TYPE; message: string }
		) {
			state.messages[payload.key].push(payload.message);
		},
		SET_MESSAGES(
			state: {
				messages: {
					[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: any[];
					[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: any[];
				};
			},
			payload: { key: EVENT_ASSOCIATION_TYPE; messages: any[] }
		) {
			state.messages[payload.key] = payload.messages || [];
		},
		SET_FORM(
			state: { forms: { [x: string]: AssociatedItem[] } },
			payload: { key: EVENT_ASSOCIATION_TYPE; items: AssociatedItem[] }
		) {
			state.forms[payload.key] = payload.items;
		},
		SET_ACTION(
			state: { actions: { save: Boolean } },
			payload: { key: CardActionType; disabled: Boolean }
		) {
			state.actions[payload.key] = payload.disabled;
		},
	},
	getters: {
		storeDataFields(state: {
			dataFields: DataFieldEntity;
		}): DataFieldEntity {
			return state.dataFields;
		},
		storePaginated(state: { paginated: PaginatedEntity }): PaginatedEntity {
			return state.paginated;
		},
		storeEvent(state: { event: EventEntity }): EventEntity {
			return state.event;
		},
		getTables(state: {
			tables: {
				[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: TableAssociatedDataEntity;
				[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: TableAssociatedDataEntity;
			};
		}) {
			return state.tables;
		},
		getMessages(state: {
			messages: {
				[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: string[];
				[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: string[];
			};
		}) {
			return state.messages;
		},
		getForms(state: { forms: { [x: string]: AssociatedItem[] } }) {
			return state.forms;
		},
		getAction(state: { actions: { save: Boolean } }) {
			return state.actions;
		},
	},
	actions: {
		/**
		 * Paginacion
		 * endpoint: {{url}}/api/events?mode=paginated&s...
		 * @param params
		 * @returns
		 */
		async paginated({ commit }, params) {
			try {
				const response: PaginatedEntity = await EventService.paginated(
					params
				);
				commit("SET_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * Fetch Resources
		 * @param params
		 * @returns
		 */
		async fetResources(
			{}: any,
			params: {
				route_type: string;
				filters?: EventFilters | undefined;
				options?: SortingOption | undefined;
			}
		) {
			try {
				const response = await EventService.resource(params);
				const values = resolveList(response);
				return await Promise.resolve(values);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * Create event
		 * @param params
		 * @returns
		 */
		async create(
			{ commit },
			params: { data: EventCreate; continue: Boolean; redirect: Boolean }
		) {
			try {
				const response = await EventService.create(params.data);

				const routeName: string = params.continue
					? params.redirect
						? "EventEdit"
						: ""
					: "EventIndex";

				const to = {
					name: routeName,
					params: { id: String(response.id) },
					query: { tab: "1" },
				};

				const notifConfig = params.redirect ? to : routeName;

				notificationService.notifySuccess(this, {
					to: notifConfig,
				} as Notification);

				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * Update event
		 * @param params
		 * @returns
		 */
		async update(
			{ commit },
			params: { data: EventUpdate; continue: Boolean; redirect: Boolean }
		) {
			try {
				const response = await EventService.update(
					params.data,
					Number(params.data.id)
				);
				const routeName: string = params.continue
					? params.redirect
						? "EventEdit"
						: ""
					: "EventIndex";

				const to = {
					name: routeName,
					params: { id: String(response.id) },
					query: { tab: "1" },
				};

				const notifConfig = params.redirect ? to : routeName;

				notificationService.notifySuccess(this, {
					to: notifConfig,
				} as Notification);

				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * Get event by id
		 * /admin/events/1
		 * @param id
		 * @returns
		 */
		async show({ commit }, id: number) {
			try {
				const response = await EventService.show(id);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "EventIndex" });
				return await Promise.reject(error);
			}
		},

		/**
		 * Delete event by event
		 * @param id
		 * @returns
		 */
		async delete({ commit }, id: number) {
			try {
				const response = await EventService.delete(id);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		/**
		 * Archive event
		 * @param payload
		 * @returns
		 */
		async archive({ commit }, payload: { id: number; archived: Boolean }) {
			try {
				const response = await EventService.archive(
					payload.id,
					payload.archived
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		/**
		 * Generate tag
		 * @param params
		 * @returns
		 */
		async generateTag(
			{ commit },
			params: { event_id: number; event_tag_type_id: number }
		) {
			try {
				const response = await EventService.generateTag(
					params.event_id,
					params.event_tag_type_id
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		/**
		 * Associate event: campaigns | line items
		 * @param payload
		 * @returns
		 */
		async associate(
			{ commit }: any,
			payload: { route_type: ASSOCIATION_ROUTE_TYPE; payload: any }
		) {
			try {
				const response = await EventService.associate(
					payload.route_type,
					payload.payload
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async list({ commit }, params) {
			try {
				const response = await EventService.list(params);
				const values = resolveList(response);
				return await Promise.resolve(values);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async all({ commit }, params) {
			try {
				const response = await EventService.all(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
