import {
	AssociatedItem,
	TableAssociatedData,
	TableFilter,
	TableHeader,
} from "@/interfaces/event";
import { isNumber } from "lodash";

export class TableAssociatedDataEntity implements TableAssociatedData {
	headers: TableHeader[] = [];
	items: AssociatedItem[] = [];

	constructor(headers?: TableHeader[]) {
		if (headers) {
			this.setHeaders(headers);
		}
	}

	setHeaders(headers: TableHeader[]) {
		this.headers = headers;
	}

	setItems(items: AssociatedItem[] = []) {
		this.items = items;
	}
}

export class TableFilterEntity implements TableFilter {
	id: number | null = null;
	external_id: number | null = null;
	name: string | null = null;
	creative_associations_count: number | null = null;
	line_items_count: number | null = null;
	active?: boolean | null = null;

	setFilter(key: string, value: any | null = null) {
		this[key] = value;
	}

	setFilters(filters?: any) {
		this.setFilter("id", filters?.id);
		this.setFilter("external_id", filters?.external_id);
		this.setFilter(
			"creative_associations_count",
			filters?.creative_associations_count
		);
		this.setFilter("line_items_count", filters?.line_items_count);
		this.setFilter("active", filters?.active);
	}
}
