import { ResultPaginate } from "@/interfaces/paginated";
import { SegmentUpload } from "@/interfaces/segment_upload";
import SegmentUploadService from "@/services/segment-upload-service";
import { catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { resolveListSegmentUpload } from "@/utils/resolveObjectArray";

export const SegmentUploadModule = {
	namespaced: true,
	state: () => ({
		segments_upload: [] as SegmentUpload[],
		result_paginate: {} as ResultPaginate,
	}),
	mutations: {
		SET_SEGMENTS_UPLOAD(
			state,
			_segments_upload: SegmentUpload[] = [] as SegmentUpload[]
		) {
			state.segments_upload = _segments_upload;
		},
		SET_SEGMENTS_UPLOAD_PAGINATED(
			state,
			_result_paginated: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginated;
		},
	},
	getters: {
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
	},
	actions: {
		async createSegmentsUpload({ commit }, params) {
			try {
				const response = await SegmentUploadService.create(params);
				notificationService.notifySuccess(this, {message: response.message} as Notification);
				return await Promise.resolve(response.data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async paginated({ commit }, params) {
			try {
				const response = await SegmentUploadService.paginated(params);
				commit("SET_SEGMENTS_UPLOAD_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				//catchError(this, error);
				this.dispatch("proccess/setLoading", false, {
					root: true,
				});
				return await Promise.reject(error);
			}
		},
		async continentsList({ commit }, params) {
			try {
				const response = await SegmentUploadService.getContinentList();
				return await Promise.resolve(resolveListSegmentUpload(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
