import {
	RequestCampaign,
	RequestCampaignCreate
} from "@/interfaces/request_campaign";
import { ResultPaginate } from "@/interfaces/paginated";
import RequestCampaignService from "@/services/request-campaign-service";
import { catchError } from "@/store/Util";
import { resolveListByAttr } from "@/utils/resolveObjectArray";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { isUndefined } from "lodash";

export const RequestCampaignModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		request_campaign: {} as RequestCampaign,
		request_campaigns: [] as RequestCampaign[],
	}),
	mutations: {
		SET_REQUEST_CAMPAIGN(state, _request_campaign: RequestCampaign = {} as RequestCampaign) {
			state.request_campaign = _request_campaign;
		},
		SET_RESULT_PAGINATED(state, _result_paginate: ResultPaginate = {} as ResultPaginate) {
			state.result_paginate = _result_paginate;
		},
	},
	getters: {},
	actions: {

		async create({ commit }, params: { data: RequestCampaignCreate, redirect: String }) {
			try {
				const response = await RequestCampaignService.createRequestCampaign(params.data);
				if(!isUndefined(params?.redirect)){
					notificationService.notifySuccess(this, {to: params?.redirect,} as Notification);
				}
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "RequestCampaignIndex" });
				return await Promise.reject(error);
			}
		},

		async update({}, params: { data: RequestCampaign, id: number, redirect: String }) {
			try {
				const response = await RequestCampaignService.updateRequestCampaign(params.data, params.id);
				if(!isUndefined(params?.redirect)){
					notificationService.notifySuccess(this, {to: params?.redirect,} as Notification);
				}
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "RequestCampaignIndex" });
				return await Promise.reject(error);
			}
		},

		async show({ commit }, id: number) {
			try {
				const response = await RequestCampaignService.showRequestCampaign(id);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "RequestCampaignIndex" });
				return await Promise.reject(error);
			}
		},

		async paginated({ commit }, params) {
			try {
				const response = await RequestCampaignService.paginatedRequestCampaign(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getListData({}, params){
			try {
				const response = await RequestCampaignService.getListDataForms(params.key);
				return await Promise.resolve(resolveListByAttr(response, params.keyAttr, params.attr, false, params.extra));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async createNote({}, params){
			try {
				const response = await RequestCampaignService.createNoteRequestCampaign(params);
				notificationService.notifySuccess(this, {} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async sendEmail({}, params: { id: number}){
			try {
				const response = await RequestCampaignService.sendEmailCPI(params.id);
				notificationService.notifySuccess(this, {} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginatedNotes({ commit }, params) {
			try {
				const response = await RequestCampaignService.paginatedNotesRequestCampaign(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async createCreative({ commit }, params: any) {
			try {
				const response = await RequestCampaignService.createCreativeRequestCampaign(params);
				notificationService.notifySuccess(this, {} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, {to: ""});
				return await Promise.reject(error);
			}
		},

		async updateCreative({ commit }, params: any) {
			try {
				const response = await RequestCampaignService.UpdateCreativeRequestCampaign(params);
				notificationService.notifySuccess(this, {} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, {to: ""});
				return await Promise.reject(error);
			}
		},

		async paginatedCreative({ commit }, params) {
			try {
				const response = await RequestCampaignService.paginatedCreativeRequestCampaign(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
