import { Permissions } from "@/interfaces/permission";

export function getPermisionLineItem(){
    return {
        subject: "LineItem",
        actions: {
            index: "index_line_item",
            create: "create_line_item",
            show: "show_line_item",
            update: "update_line_item",
            delete: "delete_line_item",
            active: "set_active_line_item",
            clone: "clone_line_item",
            audit: "audit_line_item",
            event_asso: "create_event_association_line_item"
        },
        complement:{
            subject: "Creative",
            actions: {
                index: "index_creative",
                index_assoc: "index_creative_line_association",
                create_assoc: "create_creative_line_association",
                update_assoc: "update_creative_line_association",
                delete_assoc: "delete_creative_line_association",
                update_creative: "update_creative",
            }
        }
    } as Permissions;
}

export function getPermisionCampaign(){
    return {
        subject: "Campaign",
        actions: {
            index: "index_campaign",
            create: "create_campaign",
            show: "show_campaign",
            update: "update_campaign",
            delete: "delete_campaign",
            active: "set_active_campaign",
            clone: "clone_campaign",
            audit: "audit_campaign",
            event_asso: "create_event_association_campaign"
        },
        complement:{
            subject: "LineItem",
            actions: {
                index: "index_line_item"
            }
        }
    } as Permissions;
}

export function getPermisionCreatives(){
    return {
        subject: "Creative",
        actions: {
            index: "index_creative",
            create: "create_creative",
            show: "show_creative",
            update: "update_creative",
            delete: "delete_creative",
            active: "set_active_creative",
            clone: "clone_creative",
            audit: "audit_creative",
            index_addon: "index_creative_addon",
            create_addon: "create_creative_addon",
            show_addon: "show_creative_addon",
            update_addon: "update_creative_addon",
            index_asset: "index_creative_asset",
            show_asset: "show_creative_asset",
            create_asset: "create_creative_asset",
            index_assoc: "index_creative_line_association",
            create_assoc: "create_creative_line_association",
            update_assoc: "update_creative_line_association",
            delete_assoc: "delete_creative_line_association",
            create_bulk: "create_creative_bulk"
        },
        complement:{
            subject: "LineItem",
            actions: {
                index: "index_line_item"
            }
        }
    } as Permissions;
}

export function getPermisionCampaignCpi(){
    return {
        subject: "CpiCampaign",
        actions: {
            index: "index_cpi_campaign",
            create: "create_cpi_campaign",
            show: "show_cpi_campaign",
            update: "update_cpi_campaign",
            index_note: "index_cpi_campaign_note",
            create_note: "create_cpi_campaign_note",
            show_note: "show_cpi_campaign_note",
            index_create: "index_cpi_creative",
            show_creative: "show_cpi_creative",
            create_creative: "create_cpi_creative",
            update_creative: "update_cpi_creative",
            send_mail:"send_cpi_campaign_mail" 
        }
    } as Permissions;
}

export function getPermisionAdvertisers(){
    return {
        subject: "Advertiser",
        actions: {
            index: "index_advertiser",
            create: "create_advertiser",
            show: "show_advertiser",
            update: "update_advertiser",
            active: "set_active_advertiser",
            audit: "audit_advertiser"
        },
        complement:{
            subject: "Campaign",
            actions: {
                index: "index_campaign"
            }
        }
    } as Permissions;
}

export function getPermisionMenu(){
    return {
        subject: "Menu",
        actions: {
            planning: "menu_planning",
            privatePois: "menu_private_pois",
            advertiser: "menu_advertiser",
            campaign: "menu_campaign",
            lineItem: "menu_line_item",
            creative: "menu_creative",
            reporting: "menu_reporting",
            directReport: "menu_direct_reports",
            reportOnDemand: "menu_reports_on_demand",
            reportAllBeta: "menu_reports_sp",
            dashboard: "menu_dashboard",
            campaignCpi: "menu_cpi_campaign",
            tools: "menu_tools",
            list: "menu_list",
            modifier: "menu_modifier",
            segmento: "menu_segment",
            segmentoCategory: "menu_segment_category",
            segmentoUpload: "menu_segment_upload",
            oneLink: "menu_one_link_test",
            user: "menu_users",
            event: "menu_event"
        }
    } as Permissions;
}

export function getPermisionUser(){
    return {
        subject: "User",
        actions: {
            index: "index_user",
            create: "create_user",
            show: "show_user",
            update: "update_user",
            active: "set_active_user",
            delete: "delete_user"
        }
    } as Permissions;
}

export function getPermisionNotification(){
    return {
        subject: "Notification",
        actions: {
            index: "index_notification",
            create: "create_notification",
            update: "update_notification"
        }
    } as Permissions;
}

export function getPermisionSegmento(){
    return {
        subject: "Segment",
        actions: {
            index: "index_segment",
            create: "create_segment",
            show: "show_segment",
            update: "update_segment",
            delete: "delete_segment",
            create_upload: "create_segment_upload",
            index_upload:"index_segment_upload",
            index_third: "index_segment_third"
        }
    } as Permissions;
}

export function getPermisionModifiers(){
    return {
        subject: "Modifier",
        actions: {
            index: "index_modifier",
            show: "show_modifier",
        },
        subComponent:{
            bid_modifier:{
                create_bid: "create_bid_modifier",
                update_bid: "update_bid_modifier",
            },
            delivery_modifier:{
                create_delivery: "create_delivery_modifier",
                update_delivery: "update_delivery_modifier",
            }
        }
    } as Permissions;
}

export function getPermisionCustomList(){
    return {
        subject: "CustomList",
        actions: {
            index: "index_custom_list",
            show: "show_custom_list",
            create: "create_custom_list",
            update: "update_custom_list",
            active: "set_active_custom_list",
            clone: "clone_custom_list",
            create_item: "create_custom_list_item",
            index_item: "index_custom_list_item",
            update_item: "update_custom_list_item",
            delete_item: "delete_custom_list_item",
            active_item: "set_active_custom_list_item"
        },
        complement:{
            subject: "Planning",
            actions: {
                index: "index_planning"
            }
        }
    } as Permissions;
}

export function getPermisionReport(){
    return {
        subject: "Report",
        actions: {
            create: "create_report",
            create_scheduler: "create_report_schedule",
            update_scheduler: "update_report_schedule",
            delete_schedule:"delete_report_schedule",
            index_scheduler: "index_report_schedule",
            show_scheduler: "show_report_schedule",
            active_scheduler: "set_active_report_schedule",
            index_on_demand: "index_report_on_demand",
            report_store_attribution: "create_report_on_demand_store_attribution",
            report_geo: "create_report_on_demand_geo",
            report_campaign_insight: "create_report_on_demand_campaign_insight",
            report_unique_devices: "create_report_on_demand_unique_devices",
            download: "download_report_on_demand",
            create_sp: "create_report_sp",
            index_report_sp: "index_report_sp",
            index_account_sp: "index_accounts_sp",
            index_data_sp: "index_data_range_sp",
            show_filter_sp: "show_filter_sp",
            show_metrics_dim_sp: "show_metrics_and_dimensions_sp"
        }
    } as Permissions;
}

export function getPermisionPlanning(){
    return {
        subject: "Planning",
        actions: {
            index: "index_planning",
            update: "index_planning",
            create_ooh_notification: "create_ooh_notification",
        }
    } as Permissions;
}

export function getPermisionBidModel(){
    return {
        subject: "BidModel",
        actions: {
            index: "index_bid_model",
            show: "show_bid_model",
            create: "create_bid_model",
            update: "update_bid_model"
        }
    } as Permissions;
}

export function getPermisionTargeting(){
    return {
        subject: "Targeting",
        actions: {
            index: "index_targeting",
            show: "show_targeting",
            create: "create_targeting",
            update: "update_targeting"
        }
    } as Permissions;
}

export function getPermisionEvent(){
    return {
        subject: "Event",
        actions: {
            index: "index_event",
            show: "show_event",
            create: "create_event",
            update: "update_event",
            delete: "delete_event",
            active: "set_active_event",
            show_tag: "show_event_tag",
            create_campaign_association: "create_campaign_association",
            create_line_item_association: "create_line_item_association"
        }
    } as Permissions;
}

export function getPermisionForUrl(){
    return{
        'PersonsIndex': {action: "menu_planning", subject: "Menu"},
        'PrivatePoisCreate': {action: "menu_private_pois", subject: "Menu"},

        'AdvertisersIndex': {action: "menu_advertiser", subject: "Menu"},
        'AdvertiserCreate': {action: "create_advertiser", subject: "Advertiser"},
        'AdvertiserEdit': {action: "update_advertiser", subject: "Advertiser"},
        
        'CampaignsIndex': {action: "menu_campaign", subject: "Menu"},
        'CampaignCreate': {action: "create_campaign", subject: "Campaign"},
        'CampaignEdit': {action: "update_campaign", subject: "Campaign"},

        'LineItemList': {action: "menu_line_item", subject: "Menu"},
        'LineItemCreate': {action: "create_line_item", subject: "LineItem"},
        'LineItemEdit': {action: "update_line_item", subject: "LineItem"},

        'CreativesIndex': {action: "menu_creative", subject: "Menu"},
        'CreativesCreate': {action: "create_creative", subject: "Creative"},
        'CreativeEdit': {action: "update_creative", subject: "Creative"},

        'Report': {action: "menu_direct_reports", subject: "Menu"},
        'ReportAll_Beta': {action: "menu_reports_sp", subject: "Menu"},
        'ReportOnDemandIndex': {action: "menu_reports_on_demand", subject: "Menu"},
        
        'RequestCampaignIndex': {action: "menu_cpi_campaign", subject: "Menu"},
        'RequestCampaignCreate': {action: "create_cpi_campaign", subject: "CpiCampaign"},
        'RequestCampaignEdit': {action: "update_cpi_campaign", subject: "CpiCampaign"},

        'Dashboard': {action: "menu_dashboard", subject: "Menu"},
        
        'UsersIndex': {action: "menu_users", subject: "Menu"},
        'UserCreate': {action: "create_user", subject: "User"},
        'UserEdit': {action: "update_user", subject: "User"},

        'EventIndex': {action: "menu_event", subject: "Menu"},
        'EventCreate': {action: "create_event", subject: "Event"},
        'EventEdit': {action: "update_event", subject: "Event"},

        'CustomListIndex': {action: "menu_list", subject: "Menu"},
        'CustomListCreate': {action: "create_custom_list", subject: "CustomList"},
        'CustomListEdit': {action: "update_custom_list", subject: "CustomList"},

        'ModifiersIndex': {action: "menu_modifier", subject: "Menu"},
        'ModifiersCreate': {action: "", subject: "Modifier"},
        'ModifiersEdit': {action: "", subject: "Modifier"},

        'SegmentsIndex': {action: "menu_segment", subject: "Menu"},
        'SegmentCategoryCreate': {action: "menu_segment_category", subject: "Menu"},
        'SegmentUploadCreate': {action: "menu_segment_upload", subject: "Menu"},
        'SegmentsCreate': {action: "create_segment", subject: "Segment"},
        'SegmentsEdit': {action: "update_segment", subject: "Segment"},

        'ToolRappi': {action: "menu_one_link_test", subject: "Menu"},
        
        'ActivityLogCampaing': {action: "audit_campaign", subject: "Campaign"},
        'ActivityLogLineItem': {action: "audit_line_item", subject: "LineItem"},
        'ActivityLogCreative': {action: "audit_creative", subject: "Creative"},

        'NotificationsIndex': {action: "index_notification", subject: "Notification"},

        'settingsEdit':{action: "update_user", subject: "User"},
    }
}