
import Vue from "vue";

export default Vue.extend({
	name: "CardActions",
	props: {
		showSubmit: {
			type: Boolean,
			default: false,
		},
		showSave: {
			type: Boolean,
			default: true,
		},
		showSaveContinue: {
			type: Boolean,
			default: true,
		},
		showCancel: {
			type: Boolean,
			default: true,
		},
		showEdit: {
			type: Boolean,
			default: false,
		},
		showClose: {
			type: Boolean,
			default: false,
		},
		showRun: {
			type: Boolean,
			default: false,
		},
		showDownload: {
			type: Boolean,
			default: false,
		},
		showViewLine: {
			type: Boolean,
			default: false,
		},
		disabledSaveContinue: {
			type: Boolean,
			default: false,
		},
		showCreateScheduler: {
			type: Boolean,
			default: false,
		},
		showUpdateScheduler: {
			type: Boolean,
			default: false,
		},
		showDeleteAll: {
			type: Boolean,
			default: false,
		},
		showSchedulerReport: {
			type: Boolean,
			default: false,
		},
		disabledShcedulerReport:{
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Object,
			default: function() {
				return {
					saveContinue: false,
					cancel: false,
					run: false,
					download: false,
					submit: false,
				}
			}
		}
	},
	components: {},
	data: () => ({}),
	async created() {},
	async mounted() {},
	computed: {},
	methods: {
		handleAction(type: any) {
			this.$emit("action", { type: type });
		},
	},
});
