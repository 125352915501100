import { ResultPaginate } from "@/interfaces/paginated";
import ListItemService from "@/services/list-item-service";
import { catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { ListItemFilters, ListItemOptions } from "@/interfaces/list_items";
import { resolveList } from "@/utils/resolveObjectArray";
import CustomListService from "@/services/custom-list-service";
import CustomListExchangeService from "@/services/custom-list-exchange-service";

export const ListItemModule = {
	namespaced: true,
	state: () => ({
		item: {} as any,
		items: [] as any[],
		entities: [] as any[],
		result_paginate: {} as ResultPaginate,
	}),
	mutations: {
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		},
		SET_ITEM(state, _item: any = {} as any) {
			state.item = _item;
		},
		SET_ITEM_LIST(state, _items_list: [] = []) {
			state.items = _items_list;
		},
		SET_ALL_ENTITIES(state, _entities: [] = []) {
			state.entities = _entities;
		},
	},
	getters: {},
	actions: {
		async paginated({ commit }, params) {
			try {
				const response = await ListItemService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async delete({}, id) {
			try {
				const response = await ListItemService.delete(id);
				return await Promise.resolve(true);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async deleteAll({}, id) {
			try {
				const response = await ListItemService.deleteAll(id);
				return await Promise.resolve(true);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async uploadFile({}, upload: any) {
			try {
				const response = await ListItemService.upload(upload);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},
		async create(
			{ commit },
			params: { listItem: any; customListType: string }
		) {
			try {
				const response = await ListItemService.create(
					params?.listItem,
					params?.customListType
				);
				commit("SET_ITEM", response);
				return Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return Promise.reject(error);
			}
		},
		async update(
			{ commit },
			params: { listItem: any; customListType: string }
		) {
			try {
				const response = await ListItemService.update(
					params?.listItem,
					params?.customListType
				);
				commit("SET_ITEM", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},
		async show({ commit }, id: number) {
			try {
				const response = await ListItemService.show(id);
				commit("SET_ITEM", response);
				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				commit("SET_ITEM");
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},
		async list(
			{ commit },
			payload: {
				filters: ListItemFilters;
				options: ListItemOptions;
			}
		) {
			try {
				const response = await ListItemService.list(
					payload.filters,
					payload.options
				);
				commit("SET_ITEM_LIST", resolveList(response));
				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				commit("SET_ITEM_LIST");
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},
		async getAllByCustomId({ commit }, customList: any) {
			try {
				const entities = await ListItemService.getAllByCustomId(customList?.id);

				let response: any[] = [];
				const modelView = await CustomListService.getViewByTypeSelectedById(
					customList?.custom_list_type_id
				);
				switch (modelView) {
					case "ModelOne":
						entities.forEach((item) => {
							let r = {
								id: item?.id,
								list_item: Array.isArray(item.list_item)
									? item?.list_item[0].toString()
									: item?.list_item,
								value: item?.value,
								name: item?.name,
								actions: item?.actions ? item?.actions : [],
								active: item?.active,
							};
							response.push(r);
						});
						break;
					case "ModelTwo":
						entities.forEach((item) => {
							let r = {
								id: item?.id,
								list_item: {
									lat: item?.list_item?.lat,
									long: item?.list_item?.long,
									radius_km: item?.list_item?.radius_km,
								},
								value: item?.value,
								name: item?.name,
								actions: item?.actions ? item?.actions : [],
								active: item?.active,
							};
							response.push(r);
						});
						break;
					case "ModelTree":
						const customListExchange = await CustomListExchangeService.all();
						entities.forEach((item) => {
							let parameters: String[] = [];
							if (Array.isArray(item.list_item)) {
								parameters = item?.list_item[0].toString().split("/");
							} else {
								parameters = item?.list_item?.split("/");
							}

							let type = customListExchange?.data.find(
								(t) => t.abbreviation == parameters[0]
							);
							if (parameters?.length == 2 && type) {
								let r = {
									id: item?.id,
									list_item_prefix: type.id,
									list_item_prefix_text: parameters[0],
									list_item_value: parameters[1],
									value: item?.value,
									name: item?.name,
									actions: item?.actions ? item?.actions : [],
									active: item?.active,
								};
								response.push(r);
							}
						});
						break;
				}
				commit("SET_ALL_ENTITIES", response);
				return await Promise.resolve(response);
			} catch (error) {
				commit("SET_ALL_ENTITIES");
				return await Promise.reject(error);
			}
		},
	},
};
