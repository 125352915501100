import { LoadingI18Message, TypeLoading } from "@/interfaces/loading";
import LoadingEntity from "@/models/LoadingEntity";
import i18n from "@/plugins/i18n";

export const LoadingModule = {
	namespaced: true,
	state: () => ({
		loadingData: new LoadingEntity(),
	}),
	mutations: {
		SET_LOADING_DATA(state: any, type: string = "") {
			state.loadingData = new LoadingEntity({ type });
		},
		SET_LOADING_LONG(state: any) {
			state.loadingData.setLoading(TypeLoading.long_time);
		},
	},
	getters: {
		getLoadingData(state: any) {
			return state.loadingData;
		},
		isLoading(state: any) {
			return state.loadingData.loading;
		},
		getLoadingMessage(state: any) {
			return i18n.t(LoadingI18Message[state.loadingData.type]);
		},
	},
	actions: {
		async setLoadingData({ commit }, type: string = "") {
			try {
				await commit("SET_LOADING_DATA", type);
				return await Promise.resolve();
			} catch (error) {
				commit("SET_LOADING_DATA");
				return await Promise.reject();
			}
		},
		async setLoadingLong({ commit }) {
			try {
				await commit("SET_LOADING_LONG");
				return await Promise.resolve();
			} catch (error) {
				commit("SET_LOADING_DATA");
				return await Promise.reject();
			}
		},
	},
};
