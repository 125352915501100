import { EnumReportType, EnumStatus } from "@/interfaces/report";

export const PATCH_REPORT_MESSAGE = "report.messages";

export interface TableReport {
	items: TableItem[];
}

export interface TableItem {
	id: number;
	account_id: number;
	dimensions: string;
	metrics: string;
	filters: string;
	start_date: string;
	end_date: string;
	status: string;
	email_subject: string;
	emails: string;
	account_email: number;
	file_type: string;
	scheduler_type_code: string;
	scheduler_type: string;
	report_type_code: string;
	report_type: string;
}

export enum GenerateReportTypeEnum {
	CAMPAIGN_REPORT_SP = "campaign_report_sp",
	REACH_REPORT_SP = "reach_report_sp",
	GEOGRAPHY_REPORT_SP = "geography_report_sp",
	BILLING_REPORT_SP = "billing_report_sp",
}

export type GenerateReportType =
	| GenerateReportTypeEnum.CAMPAIGN_REPORT_SP
	| GenerateReportTypeEnum.REACH_REPORT_SP
	| GenerateReportTypeEnum.BILLING_REPORT_SP
	| GenerateReportTypeEnum.GEOGRAPHY_REPORT_SP;

export interface GenerateReportReponse {
	type: EnumReportType;
	success: boolean;
	status: EnumStatus;
	message: string;
	errors: any[];
	data: Record<any, any>;
}

export const EnumMessageType = {
	EMPTY: `${PATCH_REPORT_MESSAGE}.empty`,
	ERROR: `${PATCH_REPORT_MESSAGE}.error`,
	PENDING: `${PATCH_REPORT_MESSAGE}.pending`,
	SUCCESS: `${PATCH_REPORT_MESSAGE}.success`,
	DATA_RANGE: `${PATCH_REPORT_MESSAGE}.data_range`,
	ACCOUNT: `${PATCH_REPORT_MESSAGE}.account`,
	CAMPAIGN: `${PATCH_REPORT_MESSAGE}.campaign`,
	METRICS: `${PATCH_REPORT_MESSAGE}.metrics`,
	DIMENSIONS: `${PATCH_REPORT_MESSAGE}.dimensions`,
	DISCLAIMER: `${PATCH_REPORT_MESSAGE}.disclaimer`,
	REPORT_GENERATED: `${PATCH_REPORT_MESSAGE}.report_generated`,
	REPORT_TYPE_INVALID: `${PATCH_REPORT_MESSAGE}.report_type_invalid`,
	REPORT_JOB_ID: `${PATCH_REPORT_MESSAGE}.report_job_id`,
};
