import { isProd } from "@/services/process-service";
import { LIMIT_EXCEDED_ROWS } from "@/services/report-service";
import { AxiosResponse } from "axios";
import { isString } from "lodash";
import { EnumMessageType } from "@/interfaces/reports/v2/report";
import store from "@/store";
import { sleep } from "@/utils/convert";
import { getValidityLimitSeg } from "@/utils/env";
import { SnactType } from "@/interfaces/snack";

export interface ResponseReportUrl {
	report_url: string;
}

export enum ResponseStatus {
	NON = "",
	IN_PROGRESS = "in_progress",
	EMPTY = "empty",
	ERROR = "error",
}

export interface ResultReport<T = ResponseReportUrl> {
	success: Boolean;
	response?: T;
	message: ResponseStatus;
}

export interface ResponseReport {
	response?: AxiosResponse<any, any>;
}

export class ResultReportEntity implements ResultReport {
	success: Boolean = false;
	response?: ResponseReportUrl;
	message: ResponseStatus = ResponseStatus.NON;

	constructor(params?: ResultReport) {
		if (params) {
			this.success = params.success;
			this.response = params.response;
			this.message = params.message;
		}
	}
}

export class ResponseReportEntity implements ResponseReport {
	response?: AxiosResponse<any, any>;
	success: boolean = false;

	constructor(response?: AxiosResponse<any, any> | undefined) {
		this.setData(response);
		this.setSuccess(typeof response !== "undefined");

		//this.getConsoleDebug();
	}

	hasResponse() {
		return typeof this.response !== "undefined";
	}

	_getValidityLimitSeg() {
		return getValidityLimitSeg;
	}

	async reset() {
		// reset response
		this.setData();

		await sleep(5000);
		//reset snacktify
		store.dispatch("snacktify/setSnacktifyData");
	}

	getContentType() {
		return this.response?.headers["content-type"];
	}

	isJson() {
		return this.getContentType()?.includes("application/json") || false;
	}

	isTextPlain() {
		return this.getContentType()?.includes("text/plain") || false;
	}

	isXls() {
		return (
			this.getContentType()?.includes(
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			) || false
		);
	}

	setData(response?: AxiosResponse<any, any> | undefined) {
		this.response = response;
	}

	setSuccess(success: boolean = false) {
		this.success = success;
	}

	async setStoreJobId(report_job_id?: number) {
		await store.dispatch("report_v2/setJobId", report_job_id);
	}

	getData() {
		return this.response?.data;
	}

	getMessage() {
		return this.getData()?.message;
	}

	hasMessage() {
		return Boolean(this.getMessage());
	}

	isSuccess() {
		return this.success;
	}

	hasReportUrl() {
		return Boolean(this.getReportUrl()) || false;
	}

	getReportUrl(): string {
		return this.getData()?.response?.report_url || "";
	}

	canParseData() {
		return !this.isWillBeRetried() && isString(this.getMessage());
	}

	getJsonParse() {
		if (!this.canParseData()) return "";
		return JSON.parse(new TextDecoder().decode(this.getData()));
	}

	isWillBeRetried() {
		if (!this.hasMessage()) return false;
		return [ResponseStatus.IN_PROGRESS].includes(this.getMessage());
	}

	isLimitExceeded() {
		return this.response?.status === LIMIT_EXCEDED_ROWS;
	}

	async downloadFile() {
		// URL del archivo que deseas descargar

		if (!this.hasReportUrl()) return;

		// Crear un enlace temporal
		const link = document.createElement("a");
		link.href = this.getReportUrl();
		//link.target = "_blank";
		link.target = "_top";
		//link.download = 'nombre_del_archivo';

		// Simular un clic en el enlace para descargar el archivo
		document.body.appendChild(link);
		link.click();

		// Eliminar el enlace del DOM
		document.body.removeChild(link);

		await sleep(100);

		await this.notifyDownloaded();

		await this.reset();
	}

	async notifyDownloaded() {
		const configSnacktify = {
			type: SnactType.SECONDARY,
			message: EnumMessageType.SUCCESS,
			messageParams: {},
		};
		await store.dispatch("generate_report/sendNotify", configSnacktify);
	}

	getConsoleDebug() {
		try {
			if (isProd()) return;
			const contentType = this.getContentType();
			const isJson = this.isJson();
			const isTextPlain = this.isTextPlain();
			const isXls = this.isXls();
			const hasResponse = this.hasResponse();
			const getData = this.getData();
			const getMessage = this.getMessage();
			const hasMessage = this.hasMessage();
			const isSuccess = this.isSuccess();
			const canParseData = this.canParseData();
			const getJsonParse = this.getJsonParse();
			const isWillBeRetried = this.isWillBeRetried();
			const hasReportUrl = this.hasReportUrl();
			const isLimitExceeded = this.isLimitExceeded();

			console.debug(`DEBUG::console`, {
				contentType,
				isJson,
				isTextPlain,
				isXls,
				hasResponse,
				getData,
				getMessage,
				isSuccess,
				hasMessage,
				canParseData,
				getJsonParse,
				isWillBeRetried,
				hasReportUrl,
				isLimitExceeded,
			});
		} catch (error) {
			console.error(`DEBUG::error`, { error });
		}
	}
}
