import ListService from "@/services/list-service";
import { catchError } from "@/store/Util";

export const ListModule = {
	namespaced: true,
	state: () => ({
		custom_list_exchanges: [],
		budget_types: [],
	}),
	mutations: {
		SET_CUSTOM_LIST_EXCHANGES(state, custom_list_exchanges: [] = []) {
			state.custom_list_exchanges = custom_list_exchanges;
		},
		SET_BUDGET_TYPES(state, budget_types: [] = []) {
			state.budget_types = budget_types;
		},
	},
	getters: {},
	actions: {
		async getList({ commit }, payload) {
			try {
				const response = await ListService.getList(payload.list_name);
				if (payload.action) {
					commit(payload.action, response);
				}
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
