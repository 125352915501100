import ActivityLogService from "@/services/activity-log-service";
import { catchError } from "../Util";

export const ActivityLogModule = {
	namespaced: true,
	state: () => ({
		id: undefined,
		entity: undefined
	}),
	mutations: {
		SET_DATA_ACTIVITY_LOG(state, params: any){
			state.id = params.id,
			state.entity = params.entity
		}
	},
	getters: {
		getEntityActivityLog(state){
			return {
				id: state.id,
				entity: state.entity
			}
		}
	},
	actions: {

		async setDataActivityLog({commit}, params: any){
			commit("SET_DATA_ACTIVITY_LOG", params);
		},

		async getActivityLog({}){ 
			try {
				const response = await ActivityLogService.getActivityLog();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async all({}, params: any){
			try {
				const response = await ActivityLogService.all(params.filter, params.entity);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		}
	},
};
