import { ResultPaginate } from "@/interfaces/paginated";
import {
	SegmentCategory,
	SegmentCategoryList,
} from "@/interfaces/segment_category";
import SegmentCategoryService from "@/services/segment-category-service";
import { catchError } from "@/store/Util";

export const SegmentCategoryModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		segments_categories: [] as SegmentCategory[],
		segment_category: {} as SegmentCategory,
		segment_category_list: [] as SegmentCategory[],
	}),
	mutations: {
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		},
		SET_SEGMENT_CATEGORY(
			state,
			_segment_category: SegmentCategory = {} as SegmentCategory
		) {
			state.segment_category = _segment_category;
		},
		SET_SEGMENTS_CATEGORIES(
			state,
			_segments_categories: SegmentCategory[] = [] as SegmentCategory[]
		) {
			state.segments_categories = _segments_categories;
		},
		SET_SEGMENT_CATEGORY_LIST(
			state,
			_segment_category_list: SegmentCategoryList[] = [] as SegmentCategoryList[]
		) {
			state.segment_category_list = _segment_category_list;
		},
	},
	getters: {
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
	},
	actions: {
		async paginated({ commit }, params) {
			try {
				const response = await SegmentCategoryService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async all({ commit }, params) {
			try {
				const response = await SegmentCategoryService.all(params);
				commit("SET_SEGMENTS_CATEGORIES", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async show({ commit }, id: number) {
			try {
				const response = await SegmentCategoryService.show(id);
				commit("SET_SEGMENT_CATEGORY", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "SegmentsIndex" });
				return await Promise.reject(error);
			}
		},
		async create({ commit }, params) {
			try {
				const response = await SegmentCategoryService.create(params.seg);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async delete({ commit }, id: number) {
			try {
				const response = await SegmentCategoryService.delete(id);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "SegmentsIndex" });
				return await Promise.reject(error);
			}
		},
	},
};
