
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default Vue.extend({
	name: "CustomizeRowGrid",
	props: {},
	components: {},

	data: () => ({}),

	created() {},
	mounted() {},

	computed: {
		
		...mapGetters("breadcrumbsM", ["getHistoryData"]),
		...mapGetters("profile", ["getAbility"]),

		isLink() {
			return this.params.link;
		},

		isSeccion() {
			return this.params.redirecCustom.hasOwnProperty("section");
		},

		getID() {
			return this.params.data.id;
		},

		getValueField() {
			return this.params.fieldText;
		},

		getText() {
			return (
				this.getPrefix +
				this.params.data[this.getValueField] +
				this.getSuffix
			);
		},

		getPrefix() {
			return this.params.prefix;
		},

		getSuffix() {
			return this.params.suffix;
		},

		getToRedirectCustom() {
			return this.params.redirecCustom.to;
		},

		getEntityRedirectCustom() {
			return this.params.redirecCustom.entity;
		},

		getSeccionRedirectCustom() {
			return this.params.redirecCustom.section;
		},

        getPermissions(){
            return this.params.permission;
        },

        canRedirectTo(){
            return this.getAbility.can(this.getPermissions.complement?.actions?.index, this.getPermissions.complement?.subject);
        },

		getRedirectFromColumn() {
			return this.params.redirecCustom.isColumn;
		},

		isInvoke() {
			return this.params.redirecCustom.isInvoke;
		},
	},

	methods: {
		...mapActions("breadcrumbsM", ["addHistory"]),

		getHistoryEntity() {
			return {
				id: this.params.data.id,
				name: this.params.data.name,
				external_id: this.params.data.external_id,
				advertiser: null,
			};
		},

		async redirectTo(event: MouseEvent) {
			const newWindow = event.ctrlKey;
			if (this.isInvoke) {
				this.invokeRedirectMethod(newWindow);
				return;
			}
			if (this.getToRedirectCustom == "CampaignsIndex") {
				await this.saveHistoryData(
					"advertisers",
					this.getHistoryEntity()
				);
			}
			if (this.getToRedirectCustom == "LineItemList") {
				await this.saveHistoryData("campaign", this.getHistoryEntity());
			}
			if (newWindow) {
				const routeData = this.$router.resolve(this.getParams());
				window.open(routeData.href, "_blank");
			} else {
				this.$router.push(this.getParams());
			}
		},

		getParams() {
			let obj = {
				name: this.getToRedirectCustom,
			};
			if (this.getRedirectFromColumn) {
				obj["params"] = { id: this.getID };
				return obj;
			}
			if (this.isSeccion) {
				obj["params"] = { [this.getEntityRedirectCustom]: this.getID };
				obj["query"] = { section: this.getSeccionRedirectCustom };
			} else {
				obj["query"] = {
					[this.getEntityRedirectCustom]: String(this.getID),
				};
			}
			return obj;
		},

		invokeRedirectMethod(newWindow: boolean = true) {
			let param = {
				id: this.params.data.id,
				column: this.getValueField,
			};
			this.params.contextT.componentParent.handleRedirectMethod(
				param,
				newWindow
			);
		},

		async saveHistoryData(_view: string, _data: any) {
			let valuesHistory = await this.getHistoryData(_view);
			if (isEmpty(valuesHistory)) {
				await this.addHistory({ view: _view, data: _data });
			} else {
				if (!valuesHistory.some((item: any) => item.id == _data.id)) {
					await this.addHistory({ view: _view, data: _data });
				}
			}
		},
	},

	watch: {},
});
