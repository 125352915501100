import { ElementData } from "@/interfaces/persons/v10/person";
import { PersonGeoKey, PersonOohKey, PersonPoisKey, PersonPrivateKey } from "@/interfaces/persons/v10/types";

export const ELEMENT_KEY_DEFAULT: number = NaN;

export const ENDPOINT_KEYS = {
	BRAZIL: "brasil",
	COLOMBIA: "colombia",
	PORTUGAL: "portugal",
	SPAIN: "spain",
	CHILE: "chile",
	TURKEY: "turkey",
	WORLD: "world",
};

export const COUNTRY_CODE_NAMES = {
	76: "BRAZIL",
	170: "COLOMBIA",
	620: "PORTUGAL",
	724: "SPAIN",
	152: "CHILE",
	792: "TURKEY",
};

export const COUNTRY_DEFAULT: ElementData = {
	id: 170,
	value: "Colombia",
};

export const COUNTRY_PORTUGAL: ElementData = {
	id: 620,
	value: "Portugal",
};

export const AUDIENCE_TYPE_POIS: number[] = [2, 3];
export const AUDIENCE_TYPE_GEO: number[] = [1, 4];

export const SHOW_MAX_SELECTION: number = 100;

export const HIDE_VIEW_CLEAR_FILTERS: string[] = [
	PersonGeoKey.NEIGHBORHOODS,
	PersonPoisKey.NAMES,
	PersonOohKey.OOH_NAMES,
	PersonPrivateKey.PRIVATES,
];

export const DELAY_FETCH: number = 50;