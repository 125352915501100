import { ItemGraphic } from "@/interfaces/graphic";
import { uniq } from "lodash";
import { capitalizeWords, hyphenize } from "../filter-global";

export const filterByCat: string = "pois by cat/subcat";

export const filterByCity: string = "pois by city";

export const filterByBrands: string = "top 20 brands";

export const filterByNames: string = "top 20 names";

export const matchetFilterBy = () => {
	return {
		categories: filterByCat,
		cities: filterByCity,
		brands: filterByBrands,
		names: filterByNames,
	};
};

export async function prepareGraphics(data: any) {
	const { graphics, pois_reach } = data;

	const filteredCategories: Array<any> = await filterByKey(
		graphics,
		"categories"
	);

	const filteredCities: Array<any> = await filterByKey(graphics, "cities");

	const filteredBrands: Array<any> = await filterByKey(graphics, "brands");

	const filteredNames: Array<any> = await filterByKey(graphics, "names");
	
	/**
	 * stacked-bar
	 */
	const categories: Array<any> = await prepareGraphicData(filteredCategories);
	
	/**
	 * grouped
	 */
	const cities: Array<ItemGraphic> = await prepareItemGraphics(
		filteredCities
	);
	
	/**
	 * grouped
	 */
	const brands: Array<ItemGraphic> = await prepareItemGraphics(
		filteredBrands
	);
	
	/**
	 * grouped
	 */
	const names: Array<ItemGraphic> = await prepareItemGraphics(filteredNames);

	return {
		pois_reach,
		categories,
		cities,
		brands,
		names,
	};
}

export async function filterByKey(graphics: Array<any>, key: string) {
	return graphics.filter((g: any) => g.name == matchetFilterBy()[key]);
}

export async function prepareGraphicData(data: Array<any>) {
	let items: Array<any> = [];
	const keys: Array<string> = data.map((d) => capitalizeWords(d.level_1));
	const keysUniques: Array<any> = uniq(keys).sort();

	keysUniques.map((key) => {
		const attrs: Array<any> = data.filter((d) => capitalizeWords(d.level_1) == capitalizeWords(key));

		const name: string = capitalizeWords(key);
		let item: any = { value: name };

		attrs.map((a) => {
			item[hyphenize(a.level_2)] = a.pois;
		});

		items.push(item);
	});

	return items;
}

export async function prepareItemGraphics(data: Array<any>) {
	let items: Array<ItemGraphic> = [];
	data.map((d) => {
		const item: ItemGraphic = {
			type: "",
			name: capitalizeWords(d.level_2),
			uniques: d.pois,
		};
		items.push(item);
	});
	return items;
}
