import { ResultPaginate } from "@/interfaces/paginated";
import { Segment, SegmentList, SegmentTag } from "@/interfaces/segment";
import SegmentService from "@/services/segment-service";
import { catchError } from "@/store/Util";
import { resolveList } from "@/utils/resolveObjectArray";

export const SegmentModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		segments: [] as Segment[],
		segment: {} as Segment,
		segment_list: [] as Segment[],
	}),
	mutations: {
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		},
		SET_ENTITY(state, _entity: Segment = {} as Segment) {
			state.segment = _entity;
		},
		SET_ENTITIES(state, _entities: Segment[] = [] as Segment[]) {
			state.segments = _entities;
		},
		SET_ENTITY_LIST(state, _entity_list: SegmentList[] = [] as SegmentList[]) {
			state.segment_list = _entity_list;
		},
	},
	getters: {
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
	},
	actions: {
		async paginated({ commit }, params) {
			try {
				const response = await SegmentService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async all({ commit }, params) {
			try {
				const response = await SegmentService.all(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async create({ commit }, params) {
			try {
				const response = await SegmentService.create(params.seg);
				commit("SET_ENTITY", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async show({ commit }, id: number) {
			try {
				const response = await SegmentService.show(id);
				commit("SET_ENTITY", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "SegmentsIndex" });
				return await Promise.reject(error);
			}
		},

		async update({ commit }, params) {
			try {
				const response = await SegmentService.update(params.seg);
				commit("SET_ENTITY", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "SegmentsIndex" });
				return await Promise.reject(error);
			}
		},

		async list({ commit }, params) {
			try {
				const response = await SegmentService.list(params);
				commit("SET_ENTITY_LIST", resolveList(response));
				return await Promise.resolve(resolveList(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async download({}, params) {
			try {
				await SegmentService.download(params);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getGeneratedTag({ commit }, params: SegmentTag) {
			try {
				const response = await SegmentService.generatedTag(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getTreeViewSegments({}, params) {
			try {
				const response = await SegmentService.getViewTree(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getTreeViewSegmentsThird({}, params) {
			try {
				const response = await SegmentService.getViewTreeSegmentThird(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getSegmentsProviders({}) {
			try {
				const response = await SegmentService.getSegmentProviders();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getSegmentSearch({}, params: any){
			try{
				const response = await SegmentService.getSegmentSearch(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		}
	},
};
