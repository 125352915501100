

import { isEmpty, isNull } from "lodash";

export default {
	name: "CardTList",
	model: {
		prop: "model_data",
		event: "input"
	},
	props: {
		model_data: {},
		cardStyles: {
			type: String,
			default: "px-2 mb-2"
		},
        item: {
            type: Object,
            default: () => {return {}}
        }
	},
	data: () => ({}),
	mounted() {},
	computed: {
		isLoading() {
			return this.$store.state.proccess.loading_field;
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			}
		},

        getItems(){
            if(isEmpty(this.item) || isNull(this.item)) return [];
            let keys = new Array<String>();
            for (var k in this.item) keys.push(k);
            return keys;
        },

	},
	methods: {

        getChilds(item: any): Array<any> {
            if(isEmpty(this.item) || isNull(this.item)) return [];
            let values = new Array<String>();
            Object.keys(this.item).forEach(e => {
                if(e == item) values = this.item[e];
            });
            return values;
        },

        addItem(event, data){
            if(event){
                let obj = {id: data.id, value: data.name}
                this.modelData.push(obj);
            }else{
                this.removeItem(data);
            }
        },

        removeItem(item){
            if(isEmpty(this.modelData)) return;
            let idx = -1;
            this.modelData.forEach((i, index) => {
                if(i.id == item.id) idx = index;
            });
            if(idx >= 0){
                this.modelData.splice(idx, 1);
            }
        },

        constrainKey(item){
            if(isEmpty(this.modelData)) return false;
            let idx = -1;
            this.modelData.forEach((i, index) => {
                if(i.id == item.id) idx = index;
            });
            if(idx >= 0){
                return true;
            }
            return false;
        }

    },
	watch: {}
};
