import {
	CellColor,
	Content,
	CustomPageSize,
	DynamicContent,
	Margins,
	PageOrientation,
	Style,
	StyleDictionary,
	TDocumentDefinitions,
	TDocumentInformation,
} from "@/interfaces/pdfmake";
import {
	defaultInfoDoc,
	defaultStyleDoc,
	defaultStylesDoc,
} from "@/utils/exports";
import path from "path";

export default class PdfMakeDefinitions implements TDocumentDefinitions {
	cellColor?: CellColor | undefined;
	compress?: boolean | undefined = true;
	unbreakable?: boolean | undefined = true;
	pageSize: CustomPageSize = {
		width: 595.28,
		height: "auto",
	};
	pageOrientation: PageOrientation = "portrait";
	pageMargins?: Margins | undefined = 80;
	content: Content[] = [] as Content[];
	header?: DynamicContent | Content | undefined;
	styles?: StyleDictionary | undefined;
	defaultStyle?: Style | undefined;
	info?: TDocumentInformation | undefined;

	constructor(cellColor?: CellColor) {
		/**
		 * Set default values
		 */
		this.setAttribute("cellColor", cellColor);
	}

	async setup() {
		this.setAttribute("styles", defaultStylesDoc(this.cellColor));
		this.setAttribute("defaultStyle", defaultStyleDoc());
		this.setAttribute("info", defaultInfoDoc());
	}

	/**
	 * Set attribute
	 * @param key
	 * @param value
	 */
	setAttribute(key: string, value: any) {
		this[key] = value;
	}

	/**
	 * Add content
	 * @param content
	 */
	addContent(content: Content) {
		this.content.push(content);
	}
}
