import { FilterAudience, Status } from "@/interfaces/audience";
import { ItemGraphic, ItemHeatMap } from "@/interfaces/graphic";
import { AudiencePoint } from "@/interfaces/person";
import { AudiencePointEntity } from "@/models/Audience";
import { FilterAudienceEntity } from "@/models/FilterAudienceEntity";
import store from "@/store";
import i18n from "@/plugins/i18n";
import { isEmpty, isUndefined, uniq } from "lodash";
import { matchedHourText } from "../amcharts/utils";

export async function getInitFilterGraphic() {
	return new FilterAudienceEntity();
}

export async function prepareFilterGraphic(
	entity: FilterAudience,
	filters: any,
	models: any
) {
	if (isEmpty(filters.intervalTime)) return entity;

	const isBr: Boolean = store.getters["audience/isBR"];

	entity.start_date = filters.intervalFecha.start;
	entity.end_date = filters.intervalFecha.end;
	entity.radio = filters.radio;

	let days: number[] = filters.intervalTime.map(function (el: {
		key: number;
	}) {
		return el.key;
	});

	let keys = uniq(days);

	let times: any[] = [];

	keys.forEach((day) => {
		const horas = filters.intervalTime
			.filter((time: { key: number }) => time.key == day)
			.map((e: any) => {
				return e.value;
			});

		times.push(Object.values({ day, horas }));
	});

	// frecuency
	entity.frecuency = Object.fromEntries(Object.values(times));

	// pois
	entity.pois = {
		city_poi: models.cities,
		neighborhood_poi: models.neighborhood,
		dpto: models.departments,
		category: models.categories,
		subcategory: models.subcategories,
		marca: models.brands,
		nombre_poi: models.names,
	};

	// demo
	entity.demo = {
		age: models.ages,
		gender: models.genders,
	};

	// digital_behaviour
	entity.digital_behaviour = {
		user_type: models.identifier_type,
		iab: models.navigation_behaviour,
		interest: models.campaign_interests,
		app_name: models.app_visited,
		domain: models.sites_visited,
		city_seen: models.city_seen,
	};

	// device
	entity.device = {
		platform_browser: models.browser,
		platform_device_language: models.language,
		platform_device_make: models.make,
		platform_device_type: models.device_type,
		platform_os: models.operating_system,
		carrier: models.operator,
	};

	// home
	entity.home = {
		city_residence: models.residence_cities,
		neighborhood_residence: models.neighborhood_residence,
		nivsocio: isBr ? undefined : models.socioeconomics,
		nivsoc_brasil: !isBr ? undefined : models.socioeconomics,
	};

	return entity;
}

export async function prepareItemGraphic(
	data: Array<any>,
	isHour: Boolean = false
) {
	let items: Array<ItemGraphic> = [];
	data.map((d) => {
		const name: string = !isUndefined(d.dia_semana)
			? String(d.dia_semana)
			: String(d.name);
		const item: ItemGraphic = {
			name: isHour ? matchedHourText[name] : name,
			uniques: d.uniques,
		};
		items.push(item);
	});
	return items;
}

export const initLoading = {
	pois: {
		users_by_date: true,
		users_by_distance: true,
		users_by_week_day: true,
		users_by_hour_day: true,
		users_by_category: true,
		users_by_subcategory: true,
		users_by_poi_city: true,
		users_by_dpto: true,
		users_by_poi_neighborhood: true,
	},
	demographic: {
		users_by_ciudad_residencia: true,
		users_by_niv_socio: true,
		users_by_age: true,
		users_by_gender: true,
		users_by_barrio_residencia: true,
	},
	online_behaviour: {
		users_by_type: true, // default
		users_by_iab: true,
		users_by_intereses: true,
		users_by_app_name: true,
		users_by_domain: true,
		users_by_city_seen: true,
	},
	device: {
		users_by_carrier: true,
		users_by_device_make: true,
		users_by_platform_device_type: true,
		users_by_platform_browser: true,
		users_by_device_language: true,
		users_by_plataform_os: true,
	},
	table: {
		users_by_pois_gender_age: false,
	},
	head_map: {
		users_by_hour_day_week: true,
	},
	maps: {
		map: false,
	},
};

export const initSource = {
	pois: {
		// Usuarios por fecha
		users_by_date: [] as ItemGraphic[],

		// Cercania al punto
		users_by_distance: [] as ItemGraphic[],

		// Dia de la semana
		users_by_week_day: [] as ItemGraphic[],

		// Hora de la semana
		users_by_hour_day: [] as ItemGraphic[],

		// Categoria
		users_by_category: [] as ItemGraphic[],

		// Subcategoria
		users_by_subcategory: [] as ItemGraphic[],

		// Ciudad
		users_by_poi_city: [] as ItemGraphic[],

		// Depto
		users_by_dpto: [] as ItemGraphic[],

		// Barrio
		users_by_poi_neighborhood: [] as ItemGraphic[],
	},

	demographic: {
		// Ciudad de residencia
		users_by_ciudad_residencia: [] as ItemGraphic[],

		// Nivel Socioeconomico (Solo Brasil/Colombia)
		users_by_niv_socio: [] as ItemGraphic[],

		// Edad
		users_by_age: [] as ItemGraphic[],

		// Genero
		users_by_gender: [] as ItemGraphic[],

		// Neighborhood of residence
		users_by_barrio_residencia: [] as ItemGraphic[],
	},

	online_behaviour: {
		// Identifier Type
		users_by_type: [] as ItemGraphic[],

		// IAB
		users_by_iab: [] as ItemGraphic[],

		// Interest
		users_by_intereses: [] as ItemGraphic[],

		// App Visited
		users_by_app_name: [] as ItemGraphic[],

		// Sites Visited
		users_by_domain: [] as ItemGraphic[],

		// City Where are connecting
		users_by_city_seen: [] as ItemGraphic[],
	},

	device: {
		// Carrier
		users_by_carrier: [] as ItemGraphic[],

		// Make
		users_by_device_make: [] as ItemGraphic[],

		// Platform
		users_by_platform_device_type: [] as ItemGraphic[],

		// Browser
		users_by_platform_browser: [] as ItemGraphic[],

		// Language Set Up
		users_by_device_language: [] as ItemGraphic[],

		// Operating System
		users_by_plataform_os: [] as ItemGraphic[],
	},

	table: {
		// Gender Age
		users_by_pois_gender_age: {
			header: [] as any[],
			items: [] as any[],
		},
	},

	head_map: {
		// Gender Age
		users_by_hour_day_week: [] as ItemHeatMap[],
	},

	maps: {
		map: new AudiencePointEntity(),
	},
};

/**
 * Si la respuesta es un strig (PENDING | PROCESSING) se volvera a realizar el llamado a la API
 * @param result
 * @returns
 */
export async function isWillBeRetried(result: any) {
	return result === Status.PENDING || result === Status.PROCESSING;
}

export async function setSource(params: any, result: any) {
	let source: any = null;

	if (["gender_age", "day_week"].includes(params.key)) {
		// No parsear datos
		source = result;
	} else if (["map"].includes(params.key)) {
		// parsear AudiencePoint
		source = result as AudiencePoint;
	} else {
		// parsear itemGraphs[]
		const itemGraphs: ItemGraphic[] = await prepareItemGraphic(
			result,
			params.key === "hourDays"
		);

		source = itemGraphs;
	}

	return source;
}

export async function getStoreAttributionTableHeader() {
	return [
		{
			headerName: i18n.t("persons.labels.store-attribution.summary"),
			children: [
				{
					headerName: i18n.t(
						"persons.labels.store-attribution.fields.campaign_id"
					),
					field: "id",
					resizable: true,
				},
				{
					headerName: i18n.t(
						"persons.labels.store-attribution.fields.name"
					),
					field: "value",
					resizable: true,
				},
				{
					headerName: i18n.t(
						"persons.labels.store-attribution.fields.start_date"
					),
					field: "start_date",
					resizable: true,
				},
				{
					headerName: i18n.t(
						"persons.labels.store-attribution.fields.end_date"
					),
					field: "end_date",
					resizable: true,
					type: ["dateColumn", "nonEditableColumn"],
				},
				{
					headerName: i18n.t(
						"persons.labels.store-attribution.fields.clicks"
					),
					field: "clicks",
					resizable: true,
					width: 80,
				},
				{
					headerName: i18n.t(
						"persons.labels.store-attribution.fields.impressions"
					),
					field: "impressions",
					resizable: true,
					width: 80,
				},
				{
					headerName: i18n.t(
						"persons.labels.store-attribution.fields.ctr"
					),
					field: "ctr",
					resizable: true,
					width: 80,
				},
			],
		},
	];
}

export async function getHardCodeCampaigns() {
	return [
		{
			id: 686,
			value: "411139|2022|Jul|CO|Stayfree|Stayfree Other|Siempre Libres|CPC|Display|DPECOM|BUYE|Rappi|Rappi Amplify|3P|PR|Mid Interrupters|N/A|PMP|Rappi Amplify",
			start_date: "2022-04-22 00:00:00",
			end_date: "2022-08-05 23:59:59",
			clicks: "0",
			impressions: "0",
			ctr: "0%",
		},
		{
			id: 687,
			value: "416039|2022|Aug|CO|Lubriderm|Lubriderm|FPS|CPC|Display|PGDP|BUYP|Google|N/A|2P|PR|No fuss Functional Mass|N/A|PMP|Rappi Amplify",
			start_date: "2022-04-22 00:00:00",
			end_date: "2022-08-04 23:59:59",
			clicks: "0",
			impressions: "0",
			ctr: "0%",
		},
		{
			id: 821,
			value: "C 27",
			start_date: "2022-10-27 18:10:00",
			end_date: "2022-11-30 23:59:59",
			clicks: "0",
			impressions: "0",
			ctr: "0%",
		},
		{
			id: 515,
			value: "Campaign Impr",
			start_date: "2022-04-22 00:00:00",
			end_date: "2022-05-31 23:59:59",
			clicks: "0",
			impressions: "0",
			ctr: "0%",
		},
	];
}
