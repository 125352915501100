import {
	ConfigDirectiveField,
	DataField,
	ResourceConfig,
	ResourceConfigOption,
	ResourceItem,
	TagData,
} from "@/interfaces/event";
import { AttributionType, isAttributionType } from "./Tab";

export class ConfigDirectiveFieldEntity implements ConfigDirectiveField {
	ref: string = "";
	label: string = "";
	hint: string = "";
	placeholder: string = "";

	constructor(config?: Partial<ConfigDirectiveField>) {
		this.ref = config?.ref || "";
		this.label = config?.label || "";
		this.hint = config?.hint || "";
		this.placeholder = config?.placeholder || "";
	}
}

export class TagDataEntity implements TagData {
	loading: Boolean = false;
	generated: string = "";

	constructor() {}

	setData<T extends keyof TagData>(key: T, value: this[T]): void {
		this[key] = value;
	}

	getData<T extends keyof TagData>(key: T) {
		return this[key];
	}
}

export class DataFieldEntity implements DataField {
	tag = new TagDataEntity();
	resources = {
		advertiser_id: [] as ResourceItem[],
		event_attribution_id: [] as ResourceItem[],
		event_type_id: [] as ResourceItem[],
		segment_id: [] as ResourceItem[],
		event_tag_type_id: [] as ResourceItem[],
	};
	loading = {
		advertiser_id: false,
		event_attribution_id: false,
		event_type_id: false,
		segment_id: false,
		event_tag_type_id: false,
	};

	seTagData<T>(key: string, value: T) {
		this.tag[key] = value;
	}

	setLoading<T>(key: string, value: Boolean) {
		this.loading[key] = value;
	}

	isTag(key: string) {
		return !!this.tag[key];
	}

	isOnlyOnjects(event_attribution_id?: number | null): Boolean {
		if (!event_attribution_id) return false;
		const elements = this.resources.event_attribution_id;
		const element = elements.find((e) => e.id === event_attribution_id);
		if (!element) return false;
		const isWhitelist = isAttributionType(
			AttributionType.WHITELIST_TEXT,
			element.value
		);
		return Boolean(isWhitelist);
	}
}

export class ResourceConfigOptionEntity implements ResourceConfigOption {
	route_type?: string;
	filters: Record<string, any> = {};
	options: Record<string, any> = {};
}

export class ResourceConfigEntity implements ResourceConfig {
	dispatch: string = "";
	params: ResourceConfigOption = new ResourceConfigOptionEntity();

	constructor(dispatch?: string, params?: ResourceConfigOption) {
		this.dispatch = dispatch || "";
		this.params = params || new ResourceConfigOptionEntity();
	}

	addFilter<T>(key: string, value: T) {
		Object.assign(this.params.filters, { [key]: value });
	}
}

export const resourceConfigData = {
	advertiser_id: new ResourceConfigEntity("advertiser/list", {
		filters: { active: true },
		options: {},
	}),
	event_attribution_id: new ResourceConfigEntity("events/fetResources", {
		route_type: "event_attribution_id",
		filters: { active: true },
		options: {},
	}),
	event_type_id: new ResourceConfigEntity("events/fetResources", {
		route_type: "event_type_id",
		filters: { active: true },
		options: {},
	}),
	segment_id: new ResourceConfigEntity("segment/all", {
		filters: { active: true },
		options: {},
	}),
	event_tag_type_id: new ResourceConfigEntity("events/fetResources", {
		route_type: "event_tag_type_id",
		filters: { active: true },
		options: {},
	}),
};
