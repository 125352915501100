import { V10 } from "@/interfaces/persons/v10/route";
import { PaginatedEntity } from "@/models/Paginated";
import {
	AxiosPost,
	AxiosGet,
	GetData,
	GetErrors,
	GetMessage,
	GetDataResponse,
	AxiosDelete,
} from "@/services/axios-service";
import {
	catchServiceErrors,
	modes,
	prepareParams,
} from "@/utils/services-global";
import { isEmpty, isUndefined } from "lodash";

const FILTRO_PRIVATE_POIS = [
	"id",
	"country_name",
	"layer_name",
	"file",
	"total_pois",
	"status_name",
	"error_pois",
	"created_at",
];
const T_FILTRO = {
	id: "id",
	layer_name: "layer_name",
	file_name: "file_name",
	total_pois: "total_pois",
	error_pois: "total_errors",
	status_name: "status.description",
	country_name: "country_name",
	created_at: "private_pois_persons.created_at",
};

const ROUTE_PERSON = require("@/api/routes").PERSON;
const ROUTEV10: V10 = require("@/api/routes").V10;
const ENTITY: string = "persons";

class PrivatePoisService {
	async fetchData(params: { type: string }) {
		try {
			const url = `${matchedRoutes()[params.type]}`;
			const response = await AxiosGet(url);
			return Promise.resolve(GetDataResponse(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async createPrivatePOis(params: any) {
		try {
			const response = await AxiosPost(
				`${ROUTE_PERSON.PERSON_PRIVATE_POIS_ROUTE}`,
				params,
				true
			);
			return Promise.resolve({
				data: response?.data?.response,
				message: response?.data?.message,
			});
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async paginatedPrivatePOIs(params: any) {
		try {
			let filter = "";
			if (!isUndefined(params.filters)) {
				filter = getFiltersPrivatePOIS(
					FILTRO_PRIVATE_POIS,
					params.filters
				);
			}

			const url = await prepareParams({
				route: ROUTEV10.PERSONS.TAB_FILTERS.PRIVATES,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	/**
	 * Obtener los items (name, lat, lon) de private pois por id
	 * @param params
	 * @param id
	 * @returns
	 */
	async paginatedItemsPrivatePOIs(
		params: any,
		id: number
	): Promise<PaginatedEntity> {
		try {
			let filter = "";

			const url = await prepareParams({
				route: `${ROUTEV10.PERSONS.TAB_FILTERS.PRIVATES}/${id}/items`,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async postData(type: string, postData: FormData, has_file: boolean = false) {
		try {
			const url = `${matchedRoutes()[type]}`;
			const response = await AxiosPost(url, postData, has_file);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async deleteData(id: number, payload: any) {
		try {
			const url = `${ROUTE_PERSON.PERSON_PRIVATE_POIS_ROUTE}/${id}/delete`;
			const response = await AxiosDelete(url, payload);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
}

function matchedRoutes() {
	return {
		countries: ROUTE_PERSON.PERSON_GET_COUNTRIES_PRIVATE_POIS_ROUTE,
		privatepois: ROUTE_PERSON.PERSON_PRIVATE_POIS_ROUTE,
		replace_privatepois: `${ROUTE_PERSON.PERSON_PRIVATE_POIS_ROUTE}/replace`,
	};
}

function getFiltersPrivatePOIS(FILTROS: string[], filters: any): string {
	let filter = "";
	FILTROS.forEach((element) => {
		if (!isEmpty(filters[element])) {
			filter +=
				"filters[" + T_FILTRO[element] + "]=" + filters[element] + "&";
		}
	});
	filter = filter.substring(0, filter.length - 1);
	return filter;
}

export default new PrivatePoisService();
