import notificationService from "@/services/notification-service";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import i18n from "@/plugins/i18n";
import { isNull, isUndefined } from "lodash";
import { Dispatch } from "vuex";
import { ResultErrorData } from "@/interfaces/persons/v10/response";
import { GetErrors } from "@/services/axios-service";

export function catchError(store: any, error: any, params?: { to?: string }) {
	store.dispatch("proccess/setLoading", false, { root: true });

	const isCancel: Boolean = Boolean(error.isCancel);

	if (isCancel) return;

	notificationService.notifyError(store, {
		type: MessageTypes.ERROR,
		message: error.message,
		details: error.errors,
		to: params?.to,
		btn_text: isUndefined(params?.to)
			? i18n.t(MessageTypes.TRYAGAIN)
			: i18n.t(MessageTypes.CONTINUE),
	} as Notification);
}

/**
 * CatcherError
 * @param dispatch
 * @param error AxiosError
 * @param params { to?: string }
 */
export function CatcherError(
	dispatch: Dispatch,
	error: any,
	params?: { to?: string }
) {
	dispatch("proccess/setLoading", false, { root: true });

	Errors(dispatch, error);

	CreateNotification(dispatch, {
		type: MessageTypes.ERROR,
		title: i18n.t("title-failed"),
		message: error.message,
		details: error.errors,
		to: params?.to,
		btn_text: isUndefined(params?.to)
			? i18n.t(MessageTypes.TRYAGAIN)
			: i18n.t(MessageTypes.CONTINUE),
	} as Notification);
}

/**
 * Validation Errors
 * @param dispatch
 * @param error
 */
export function Errors(dispatch: Dispatch, error: any) {
	if (
		!isUndefined(error.errors) &&
		!isNull(error.errors) &&
		typeof error.errors === "object" &&
		Object.entries(error.errors).length > 0
	) {
		dispatch("proccess/setErrors", error.errors, { root: true });
	}
}

/**
 * Create Notification
 * @param dispatch
 * @param params
 */
export async function CreateNotification(
	dispatch: Dispatch,
	params: Notification
) {
	dispatch(
		"proccess/setNotification",
		await notificationService.CreateNotification(params),
		{ root: true }
	);
}
