import Vue from "vue";
import VueI18n from "vue-i18n";
import { en, es, pt } from "vuetify/lib/locale";

import _en from "@/plugins/langs/en.json";
import _es from "@/plugins/langs/es.json";
import _pt from "@/plugins/langs/pt.json";

Vue.use(VueI18n);

const messages = {
	en: _en,
	es: _es,
	pt: _pt,
};

function loadVuetifyLocales(messages: any) {
	messages["en"]["$vuetify"] = en;
	messages["es"]["$vuetify"] = es;
	messages["pt"]["$vuetify"] = pt;
	return messages;
}

const i18n = new VueI18n({
	locale: localStorage.getItem("language") || "en", // set locale
	fallbackLocale: "en", // set fallback locale
	messages: loadVuetifyLocales(messages), // set locale messages
});

export default i18n;
