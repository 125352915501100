import PermissionService, {
	PermissionProfile,
} from "@/services/permission-service";
import { catchError } from "@/store/Util";

export const PermissionModule = {
	namespaced: true,
	state: () => ({
		permissions: [],
	}),
	mutations: {
		SET_PERMISSIONS(state, _permissions = []) {
			state.permissions = _permissions;
		},
	},
	getters: {
		permissions(state) {
			return state.permissions;
		},
	},
	actions: {
		async fetchPermissions({ commit }) {
			try {
				const response = await PermissionService.permissions();
				commit("SET_PERMISSIONS", PermissionProfile(response));
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "Root" });
				return await Promise.reject(error);
			}
		},
	},
};
