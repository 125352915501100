import CloneEntityService from "@/services/clone-entity-service";
import { CatcherError, catchError } from "@/store/Util";

export const CloneEntityModule = {
	namespaced: true,
	state: () => ({}),
	mutations: {},
	getters: {},
	actions: {
		async fetchCloneEntity({ commit }: any, params: any) {
			try {
				const response = await CloneEntityService.fetchDuplicatedServices(
					params.key,
					params.data
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};