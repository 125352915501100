import { V10 } from "@/interfaces/persons/v10/route";
import {
	GetErrors,
	GetMessage,
	AxiosPostDownload,
	AxiosGetDownload,
	dataForceDownload,
	AxiosGet,
	AxiosDownload,
} from "./axios-service";
import { catchServiceErrors, prepareFileName } from "@/utils/services-global";
import { EnumReportType } from "@/interfaces/report";

const { AUDIENCE: ROUTE_AUDIENCE }: V10 = require("../api/routes").V10;

class DownloadService {
	/**
	 * @param params An object with the following struct
	 * - `filters`: filters to be sended to backend
	 * - `title`: name of file
	 * - `type`: name of route to be fetched
	 * @returns
	 */
	async download(params: {
		filters?: any;
		title: string;
		type: string;
		method?: string;
	}, format = 'csv') {
		try {
			if (params.method == "GET") {
				await AxiosGetDownload(
					matchedRoutes[params.type],
					params.title
				);
			} else {
				await AxiosPostDownload(
					matchedRoutes[params.type],
					params.title,
					params.filters,
					format
				);
			}

			return Promise.resolve({ success: true });
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
}

export const matchedRoutes = {
	demographics_by_poi: ROUTE_AUDIENCE.DOWNLOAD_METRICS,
	matrix_poi: ROUTE_AUDIENCE.DOWNLOAD_ATTRIBUTIONS,
	visits_by_distance_by_poi: ROUTE_AUDIENCE.DOWNLOAD_RICH_ATTRIBUTIONS,
	rich_attributions_daily: ROUTE_AUDIENCE.DOWNLOAD_RICH_ATTRIBUTIONS_DAILY,
};

export default new DownloadService();

/**
 * Descargar archivo
 * @param result
 * @param extension
 */
export async function downloadReport(
	result: string,
	extension: EnumReportType
) {
	dataForceDownload(result, prepareFileName("report", extension), extension);
}

export async function downloadFileFromURL(
	url: string,
	extension: EnumReportType
) {
	const fileName = prepareFileName("report", extension);
	await AxiosDownload(url, fileName);
}
