import { ResourceItem, ResourceSegment } from "@/interfaces/event";
import { TabItemEntity } from "../Core/Tab";

const TAB = {
	TOOLS: {
		EVENT: "menu.tools.event",
		ELEGIBLE_OBJECT: "events.elegible_objects.label",
	},
};

export const NAVIGATION_METHOD_PUSH = "push";
export const NAVIGATION_METHOD_REPLACE = "replace";

export enum AttributionType {
	WHITELIST = "WHITELIST",
	ALL = "ALL",
	WHITELIST_TEXT = "WHITELIST_TEXT",
	ALL_TEXT = "ALL_TEXT",
}

const ATTRIBUTION_TYPES = {
	[AttributionType.WHITELIST]: AttributionType.WHITELIST,
	[AttributionType.ALL]: AttributionType.ALL,
	[AttributionType.WHITELIST_TEXT]:
		"Only selected Campaign and Line Items for this Advertiser are eligible for this Event",
	[AttributionType.ALL_TEXT]:
		"All Line Items for this Advertiser are eligible for this Event",
};

/**
 * Buscar atribucion por tipo
 * @param items
 * @param type
 * @returns
 */
export function findAttributionByType(
	items: ResourceItem[],
	type: AttributionType
) {
	return items.find(
		(i) => i.value.toLowerCase() === ATTRIBUTION_TYPES[type].toLowerCase()
	);
}

/**
 * Verifica si el event type es del tipo WHITELIST*
 * @param eventAttribution
 * @returns
 */
export function isAttributionElegible(eventAttribution: any) {
	if (!eventAttribution) return false;
	const hasExtra = Boolean(eventAttribution?.extra);
	const value = hasExtra ? eventAttribution?.extra : eventAttribution;
	const type = hasExtra
		? AttributionType.WHITELIST
		: AttributionType.WHITELIST_TEXT;
	return isAttributionType(type, value);
}

/**
 * Verifica si un valor dado coincide con el tipo de atribución especificado.
 * @param type Tipo de atribución.
 * @param value Valor a verificar.
 * @returns true si el valor coincide con el tipo de atribución, false de lo contrario.
 */
export function isAttributionType(
	type: AttributionType,
	value?: string
): boolean {
	if (!value) return false;
	return (
		value.toLowerCase() ===
		ATTRIBUTION_TYPES[AttributionType[type]].toLowerCase()
	);
}

export function getTabItems(isEdit: Boolean = false) {
	let items: TabItemEntity[] = [];

	items.push(
		new TabItemEntity({
			key: 0,
			tab: TAB.TOOLS.EVENT,
			disabled: false,
			show: true,
		})
	);

	items.push(
		new TabItemEntity({
			key: 1,
			tab: TAB.TOOLS.ELEGIBLE_OBJECT,
			disabled: !isEdit,
			show: true,
		})
	);

	return items;
}

export async function prepareFilteredSegments(
	items: ResourceSegment[],
	advertiser_id: number | null
) {
	const currentItems = structuredClone(items);
	const itemsByNull = filterItemsBy(currentItems, null);
	const itemsByAdvertiserId = filterItemsBy(currentItems, advertiser_id);
	const uniqueItems = mergeUnique(itemsByNull, itemsByAdvertiserId);
	return uniqueItems;
}

export function mergeUnique(
	array1: ResourceItem[],
	array2: ResourceItem[]
): ResourceItem[] {
	const combined = array1.concat(array2);
	return combined.filter(
		(item, index, self) =>
			index ===
			self.findIndex((t) => t.id === item.id && t.value === item.value)
	);
}

export function filterItemsBy(
	items: ResourceSegment[],
	advertiser_id: number | null
): ResourceItem[] {
	return items
		.filter((i) => i.advertiser_id === advertiser_id)
		.map((s) => {
			return { id: s.id, value: s.name };
		});
}
