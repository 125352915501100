
// @ts-ignore
import Vue from "vue";
// @ts-ignore
import { AgGridVue } from "ag-grid-vue";
// @ts-ignore
import { isEmpty, debounce, isUndefined, isNull } from "lodash";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import CustomNoRowsOverlay from "@/components/Header/Tables/CustomNoRowsOverlay.vue";

// @ts-ignore
import { totalVisiblePagination } from "@/utils/services-global";

const DIMENSIONS: string = "dimensions";

export default Vue.extend({

    name: "GridTableCampaign",

    props: {
        current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
        headers: {
			type: Array,
			default: [],
		},
		items: {
			type: Array,
			default: [],
		},
        selectionRows: {
            type: String,
        },
        bulk: {
			type: Object,
			default: function () {
				return {};
			},
		},
        context: {
			type: Object,
			default: function () {
				return {};
			},
		},
    },

    components: {
        AgGridVue,
        CustomNoRowsOverlay
    },

    data: () => ({
        width: '100%',
        columnDefs: [],
        rowData: [],
        rowSelection: null,
        gridApi: {} || null,
        gridColumnApi: {} || null,
        defaultColDef: {
            editable: false,
            sortable: true,
            resizable: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
			lockPinned: true,
            sortingOrder: ['asc','desc'],
            cellStyle: { textAlign: 'left' },
        },
        selected_saved: {},
        noRowsOverlayComponent: null
    }),

    async created() {
        this.noRowsOverlayComponent = 'CustomNoRowsOverlay';
    },
	async mounted() {
        this.rowSelection = this.selectionRows;
    },

    computed: {
        currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},

        noSortHeaders() {
            return this.headers.map((header: any) => {
                if(!header.headerName) {
                    header.sortable = false
                }
                if(header.headerName) {
                    header.headerName = this.$t(header.headerName);
                }
                return header;
            });
        },

		getLength() {
			return Math.ceil(this.total / this.per_page);
		},

		perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},

		getTotalVisible() {
			return totalVisiblePagination;
		},
    },

    methods: {

        ...mapActions("customize_view",
            [
                "setFields",
                "saveSelected",
            ]),

        onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        },

        getNameColumnsGrid(){
            if(isUndefined(this["gridColumnApi"])){
                return new Array();
            }else{
                const col = (<any>this).gridColumnApi.getAllGridColumns()
                            .filter(c => c.colId != "actions")
                            .map(c => c.colId);
                if(isUndefined(col.find(c => c == "id"))){
                    col.push("id");
                }
                return col;
            }
        },

        getColumnsGrid(){
            if(!isUndefined(this["gridColumnApi"])){
                return (<any>this).gridColumnApi.getAllGridColumns()
                .filter(c => c.colId != "actions" && c.colId != "id" && c.colId != "external_id");
            }
        },

        getSumWidthAllCols(){
            return (<any>this).getColumnsGrid()
                            .reduce((contador,col)=>{
                                    return contador += col.actualWidth
                            },0);
        },

        onLoadResources(){
            this["selected_saved"] = {
				view: this.$route?.name,
				tab: DIMENSIONS,
				selected: {
					all: false,
					data: (<any>this).getNameColumnsGrid(),
				},
			};
        },

        onSelectionChanged(event) {
            let selectedRow = event.api.getSelectedRows();
            this.$emit("update-selected", selectedRow);
        },

        onSortChanged(params){
            if(params.source == "uiColumnSorted"){
                var colState = this["gridColumnApi"].getColumnState();
                var sortState = colState.filter(function (s) {
                    return s.sort != null;
                });
                const param = {
                    options: { sort: sortState[0].colId, order: sortState[0].sort },
                    filter: "",
                };
                this.$emit("selected-option", param);

                let colsArrays = this["gridColumnApi"].getAllGridColumns();
                let totalWidth = colsArrays.reduce((contador,col)=>{
                                                    return contador += col.actualWidth
                                                },0);
                (<any>this).updateResizeColumn(params, totalWidth);
            }

            //console.log({EVENTO: "ORDENAR", SOURCE: params.source, PARAMS: params});
        },

        onColumnResized(params){
            if(params.source == "uiColumnDragged" || params.source == "gridOptionsChanged"){
                let colsArrays = this["gridColumnApi"].getAllGridColumns();
                let totalWidth = colsArrays.reduce((contador,col)=>{
                                                return contador += col.actualWidth
                                            },0);
                (<any>this).updateResizeColumn(params, totalWidth);
            }
            //console.log({EVENTO: "SIZES", SOURCE: params.source, PARAMS: params});
            /*
            let widthGrid = this.$refs.agGRID.$el.offsetWidth;
            if(totalWidth < widthGrid){
                params.api.sizeColumnsToFit();
            }*/
        },

        onColumnMoved(event){
            var cols = (<any>this).getNameColumnsGrid();
            this["selected_saved"].selected.data = cols;
            (<any>this).updateSortColumn();
        },

        onGridReady(params){
            this["gridApi"] = params.api;
            this["gridColumnApi"] = params.columnApi;
            //this["gridApi"].showNoRowsOverlay();
            (<any>this).onLoadResources();
        },

        onGridColumnsChanged(params){
            params.api.sizeColumnsToFit();
        },

        updatePaginate(data){
			this.$emit("update-paginate", data);
		},

        updateRowNodeByIndex(indexRow: any, value: any){
            const rowNode = this["gridApi"].getRowNode(indexRow);
            rowNode.setData(rowNode.data);
        },

        updateResizeColumn: debounce(async function (params, width){
            //this.$emit("save-size-col", params)
            if(isUndefined(this.$refs.agGRID)) return;
            let widthGrid = this.$refs.agGRID.$el.offsetWidth;
            if(width < widthGrid){
                params.api.sizeColumnsToFit();
            }
        }, 500),

        updateSortColumn: debounce(async function (){
            this.$emit("save", this.selected_saved)
        }, 500),

    },

    watch:{}

});

