import { Geo, GeoFilterData, Person, Pois, PoisFilterData } from "./person";

export type PersonKeyof = keyof Person;

export type GeoFilterDataKeyof = keyof GeoFilterData;
export type PoisFilterDataKeyof = keyof PoisFilterData;

export type GeoKeyof = keyof Geo;

export type PoisKeyof = keyof Pois;

export type PersonFilterKey = PersonKey.GEO | PersonKey.POIS | PersonKey.PRIVATE | PersonKey.OOH;

export type PersonFilterType =
	| PersonGeoKey
	| PersonPoisKey
	| PersonOohKey
	| PersonPrivateKey;

export enum PersonKey {
	NONE = "",
	COUNTRY_GLOBAL = "country_global",
	GEO = "geo",
	POIS = "pois",
	PRIVATE = "privates",
	AUDIENTE = "audience",
	STORE_ATTRIBUTION = "store_attribution",
	STRATEGY = "strategy",
	USES_CASES = "uses_cases",
	OOH = "ooh",
}

export enum PersonGeoKey {
	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",
}

export enum PersonPoisKey {
	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
	NAMES = "names",
}

export enum PersonPrivateKey {
	PRIVATES = "privates",
}

export enum PersonOohKey {
	OOH_CATEGORIES = "ooh_categories",
	OOH_SUBCATEGORIES = "ooh_subcategories",
	OOH_BRANDS = "ooh_brands",
	OOH_NAMES = "ooh_names",
}

export enum MatchFilter {
	categories = "categoria",
	subcategories = "subcategoria",
	brands = "marca",
	names = "nombre",
}

export enum TypeFilterKey {
	SELECTED = "selected",
	PRE = "pre",
	AND = "and",
}

export enum GraphicType {
	POIS = "pois",
}

export enum AnalyzeType {
	GEO = "analyze_geo",
	POIS = "analyze_pois",
}

export enum GraphicDataType {
	GEO = "geo",
	POIS = "pois",
	REACH = "reach",
}

export enum AnalyzeAudienceType {
	GEO = "geo",
	POIS = "pois",
}

export enum GraphicDataKey {
	GEO = "geo",
	POIS = "pois",

	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",
	
	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
}

export enum ButtonActionType {
	NONE = "",
	ANALYZE_GEO = "analyze_geo",
	ANALYZE_POIS = "analyze_pois",
	CALCULATE_REACH = "calculate_reach",
	STORE_ATTR = "store_attribution",
	ANALYZE_AUDIENCE = "analyze_audience",
	SAVE_POIS = "save_pois",
	GEO_FENCING = "geo_fencing",
	TARGET_AUDIENCE = "target_audience",
	GEO_REPORT = "geo_report",
	ACTIVATE_OOH = "activate_ooh",
}

export enum PersonTab {
	POIS = 0,
	AUDIENCE = 1,
	STORE_ATTRIBUTION = 2,
}

export enum ActivePanelTab {

	/** Persons */
	NONE = NaN,
	COUNTRY_GLOBAL = 0,
	STRATEGY = 1,
	USES_CASES = 2,
	GEO = 3,
	POIS = 4,
	OOH = 5,
	PRIVATES = 6,

	/** Schedule Report tabs */
	SCHEDULER_REPORT = 0,
	SAVE_SCHEDULE = 1,
	REPORT_TYPE = 2,
	REPORT_FILTERS = 3,
	REPORT_DIMENSIONS = 4,
	REPORT_METRICS = 5,

}

export enum TotalType {
	TOTAL = "total",
	SHOWN = "shown",
}

export enum SelectedType {
	UNSELECTED = "unselected",
	SELECTED = "selected",
	CHECKED = "checked",
}

export enum PersonStorageKey {
	STORED_TIMESTAND = "stored_timestamp",
	STORED_PERSON = "stored_person",
	STORED_RESOURCE = "stored_resource",
	STORED_EXPANSION = "stored_expansion",
	STORED_ACTION = "stored_action",
}

export enum StoreAttributionType {
	COOKIE = "cookie",
	MAID = "maid",
	HASHED = "hashed",
}

/**
 * create_audience: 					Create audience
 * select_shelf_audiences: 				Select off-the-shelf audiences
 * individuals_interact_campaign: 		Individuals that interact with a campaign
 * identify_pois_ooh: 					Identify POIs /OOH
 * generate_geo_report: 				Generate geo report
 * generate_store_attribution_report:	Generate store attribution report
 *
 */
export enum PersonStrategyKey {
	NONE = "",
	CREATE_AUDIENCE = "create_audience",
	SHELF_AUDIENCES = "select_shelf_audiences",
	INDIVIDUALS_INTERACT_CAMPAIGN = "individuals_interact_campaign",
	IDENTIFY_POIS_OOH = "identify_pois_ooh",
	GEO_REPORT = "generate_geo_report",
	STORE_ATTR_REPORT = "generate_store_attribution_report",
}

export enum PersonStrategyID {
	NONE = NaN,
	CREATE_AUDIENCE = 1,
	SHELF_AUDIENCES = 2,
	INDIVIDUALS_INTERACT_CAMPAIGN = 3,
	IDENTIFY_POIS_OOH = 4,
	GEO_REPORT = 5,
	STORE_ATTR_REPORT = 6,
}

/**
 * SEEN_CERTAIN_AREA: Individuals seen in certain area
 * SEEN_CLOSE_TO_POI_OOH: Individuals seen close to a POI /OOH
 * POIS_OOH_CERTAIN_AREA: POIs / OOHs  in certain areas
 * POIS_CLOSE_CERTAIN_OOHS: POIs / close to certain OOHs
 * OOH_LOCATIONS_CLOSE_CERTAIN_POIS: OOH locations close to certain POIs
 * STORE_ATTRIBUTION_REPORT: Store attribution report
 *
 */
export enum PersonUsesCasesKey {
	NONE = "",
	SEEN_CERTAIN_AREA = "individuals_seen_in_certain_area",
	SEEN_CLOSE_TO_POI_OOH = "individuals_seen_close_to_a_poi_ooh",
	POIS_OOH_CERTAIN_AREA = "pois_oohs_in_certain_areas",
	POIS_CLOSE_CERTAIN_OOHS = "pois_close_to_certain_oohs",
	OOH_LOCATIONS_CLOSE_CERTAIN_POIS = "ooh_locations_close_to_certain_pois",
	STORE_ATTRIBUTION_REPORT = "store_attribution_report",
}

export enum PersonUsesCasesTab {
	NONE = NaN,
	SEEN_CERTAIN_AREA = 1,
	SEEN_CLOSE_TO_POI_OOH = 2,
	POIS_OOH_CERTAIN_AREA = 3,
	POIS_CLOSE_CERTAIN_OOHS = 4,
	OOH_LOCATIONS_CLOSE_CERTAIN_POIS = 5,
	STORE_ATTRIBUTION_REPORT = 6,
}

export enum TotalPoisType {
	NONE = "",
	PUBLIC = "public",
	PRIVATE = "private",
	OOH = "ooh",
}

export enum StrategyTitleType {
	NONE = "",
	STRATEGY_AUDIENCES = "strategy.audiences",
	STRATEGY_LOCATIONS = "strategy.locations",
	STRATEGY_REPORTS = "strategy.reports",
}

export enum StrategyTitleID {
	"" = "",
	"create_audience" = "strategy.audiences",
	"identify_pois_ooh" = "strategy.locations",
	"store_attribution_report" = "strategy.reports",
}

export enum MatchedRouteType {
	INDEX = "index",
	KEY = "key",
	VALUE = "value",
	KEY1 = "key_1",
	KEY2 = "key2",
	KEY3 = "key3",
}

export type TaskMap<T> = {
	[key: string]: T;
};
