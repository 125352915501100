
import { mapGetters } from "vuex";
import { format, parse } from "date-fns";
import { range } from "lodash";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import i18n from "@/plugins/i18n";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_TIME_END = "23:59:59";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DIALOG_WIDTH = 360;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "OK";

export default {
	name: "v-datetime-picker",
	model: {
		prop: "datetime",
		event: "input",
	},
	components: {
		IconBase,
	},
	props: {
		datetime: { type: [Date, String], default: null },
		disabled: { type: Boolean, default: false },
		disabledTime: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		label: { type: String, default: "Label" },
		dialogWidth: { type: Number, default: DEFAULT_DIALOG_WIDTH },
		dateFormat: { type: String, default: DEFAULT_DATE_FORMAT },
		timeFormat: { type: String, default: DEFAULT_TIME_FORMAT },
		clearText: { type: String, default: i18n.t('clear') },
		okText: { type: String, default: i18n.t('ok') },
		readonly: { type: Boolean, default: false },
		textFieldProps: {
			type: Object,
			default: () => ({ prependInnerIcon: "mdi-calendar" }),
		},
		datePickerProps: {
			type: Object,
			default: () => ({ headerColor: "secondary" }),
		},
		timePickerProps: {
			type: Object,
			default: () => ({
				useSeconds: true,
				ampmInTitle: true,
				format: "24hr",
				headerColor: "secondary",
			}),
		},
		min_date: { type: String, default: "" },
		max_date: { type: String, default: "" },
		is_end: { type: Boolean, default: false },
		rules: { type: Array, default: () => [] },
		required: { type: Boolean, default: false },
		error_messages: {
			type: [Array, String],
			default: function () {
				return [] || "";
			},
		},
		withoutLabel: { type: Boolean, default: false },
		dataCy: {
			type: String,
			default: ""
		},
	},
	data() {
		return {
			display: false,
			activeTab: 0,
			date: DEFAULT_DATE,
			time: DEFAULT_TIME,
			hours: "00",
			minutes: "00",
			seconds: "00",
			hoursData: range(0, 24),
			minSecData: range(0, 60),
		};
	},
	async mounted() {
		await this.init();
		await this.initTime();
	},
	computed: {
		...mapGetters("internationalization", ["getLanguage"]),

		dateTimeFormat() {
			return this.dateFormat + " " + this.timeFormat;
		},
		defaultDateTimeFormat() {
			return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
		},
		formattedDatetime() {
			return this.selectedDatetime
				? format(this.selectedDatetime, this.dateTimeFormat)
				: "";
		},
		selectedDatetime() {
			if (this.date && this.time) {
				let datetimeString = this.date + " " + this.time;
				if (this.time.length === 5) {
					datetimeString += ":00";
				}
				return parse(datetimeString, this.defaultDateTimeFormat, new Date());
			} else {
				return null;
			}
		},
		dateSelected() {
			return !this.date;
		},
		getCustomClass() {
			return `label-fixed static v-inner ${this.disabled ? "disabled" : ""}`;
		},
	},
	methods: {
		async initTime() {
			this.hoursData = await this.parsedItems(this.hoursData);
			this.minSecData = await this.parsedItems(this.minSecData);
			this.time = this.is_end ? DEFAULT_TIME_END : DEFAULT_TIME;
			this.hours = this.is_end ? "23" : "00";
			this.minutes = this.is_end ? "59" : "00";
			this.seconds = this.is_end ? "59" : "00";
		},
		async parsedItems(data) {
			return data.map((item) => {
				item =
					item.toString().length < 2 ? `0${item.toString()}` : item.toString();
				return item;
			});
		},
		async init() {
			if (!this.datetime) {
				this.date = null;
				this.time = null;
				return;
			}

			let initDateTime;
			if (this.datetime instanceof Date) {
				initDateTime = this.datetime;
			} else if (
				typeof this.datetime === "string" ||
				this.datetime instanceof String
			) {
				// see https://stackoverflow.com/a/9436948
				initDateTime = parse(this.datetime, this.dateTimeFormat, new Date());
			}

			this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
			this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
		},
		okHandler() {
			this.resetPicker();
			this.time = `${this.hours}:${this.minutes}:${this.seconds}`;
			this.$emit("input", this.selectedDatetime);
		},
		cancelHandler() {
			this.resetPicker();
			this.date = DEFAULT_DATE;
			this.time = DEFAULT_TIME;
			this.$emit("input", null);
		},
		resetPicker() {
			this.display = false;
			this.activeTab = 0;
			if (this.$refs.timer) {
				this.$refs.timer.selectingHour = true;
			}
		},
		showTimePicker() {
			if (!this.disabledTime) {
				this.activeTab = 1;
			}
		},
	},
	watch: {
		datetime: function () {
			this.init();
		},
	},
};
