import {
	AssociatedItem,
	EventCreate,
	EventField,
	EventRelation,
	EventTagCreate,
	EventUpdate,
} from "@/interfaces/event";
import { isNull, isObject, isString, isUndefined } from "lodash";
import { AttributionType, isAttributionType } from "./Tab";
export class EventRelationEntity implements EventRelation {
	id: number = 0;
	description?: string;
	extra?: string;

	constructor(properties?: EventRelation | string | null) {
		if (
			isUndefined(properties) ||
			isNull(properties) ||
			isString(properties)
		) {
			this.description = properties || "-";
		}

		if (isObject(properties)) {
			this.id = properties?.id || NaN;
			this.description = properties?.description;
			this.extra = properties?.extra;
		}
	}
}

export class EventEntity implements EventField {
	id: number | null = null;
	name: string = "";
	active: Boolean = true;
	archived: Boolean = false;
	external_id: number | null = null;
	account_id: number | null = null;
	advertiser_id: number | null = null;
	event_attribution_id?: number | null = null;
	event_type_id?: number | null;
	segment?: EventRelation | null = null;
	segment_id: number | null = null;
	segment_name?: string = "";
	value?: number | null = null;
	click_window_days?: number | null = 30;
	view_window_days?: number | null = 30;
	events_fired_today?: any;
	events_fired_yesterday?: any;
	events_fired_past_7_days_avg?: any;
	events_fired_past_30_days_look_back?: any;
	created_by: number | null = null;
	updated_by: number | null = null;
	deleted_by: number | null = null;
	created_at: string | null = null;
	updated_at: string | null = null;
	deleted_at: string | null = null;
	campaigns?: AssociatedItem[] = [];
	line_items?: AssociatedItem[] = [];
	private readonly _campaigns?: AssociatedItem[] = [];
	private readonly _line_items?: AssociatedItem[] = [];
	advertiser_name?: string = "";
	event_type?: EventRelation | string | null = null;
	event_attribution?: EventRelation | string | null = null;
	elegible?: Boolean | null;
	event_tag_type_id?: number | null = null;

	constructor(event?: EventField) {
		this._campaigns = event?.campaigns || [];
		this._line_items = event?.line_items || [];

		if (event) {
			this.setEvent(event);
		}

		//this.log();
	}

	setEvent(event: EventField) {
		this.id = event?.id || null;
		this.name = event?.name || "";
		this.active = event?.active;
		this.archived = !!event?.archived;
		this.external_id = event?.external_id || null;
		this.account_id = event?.account_id || null;
		this.advertiser_id = event?.advertiser_id || null;
		this.event_attribution_id = event?.event_attribution_id;
		this.event_type_id = Number(event?.event_type_id) || null;
		this.segment = event?.segment || null;
		this.segment_id = event?.segment_id || null;
		this.segment_name = event?.segment?.name || event?.segment_name || "";
		this.value = event?.value || null;
		this.click_window_days = event?.click_window_days || 30;
		this.view_window_days = event?.view_window_days || 30;
		this.events_fired_today = event?.events_fired_today || null;
		this.events_fired_yesterday = event?.events_fired_yesterday || null;
		this.events_fired_past_7_days_avg =
			event?.events_fired_past_7_days_avg || null;
		this.events_fired_past_30_days_look_back =
			event?.events_fired_past_30_days_look_back || null;
		this.created_by = event?.created_by || null;
		this.updated_by = event?.updated_by || null;
		this.deleted_by = event?.deleted_by || null;
		this.created_at = event?.created_at || null;
		this.updated_at = event?.updated_at || null;
		this.deleted_at = event?.deleted_at || null;
		this.campaigns = this.setCampaigns(event?.campaigns);
		this.line_items = this.setLineItems(event?.line_items);
		this.advertiser_name = event?.advertiser_name || "";
		this.event_type = new EventRelationEntity(event?.event_type);
		this.event_attribution =
			event?.event_attribution || new EventRelationEntity();
		this.event_tag_type_id = event?.event_tag_type_id || null;
		this.elegible =
			typeof this.event_attribution === "string" &&
			isAttributionType(AttributionType.WHITELIST, this.event_attribution);
	}

	setCampaigns(campaigns?: any[]) {
		if (isUndefined(campaigns)) return [];
		return campaigns.map((c) => {
			return {
				id: c.id,
				name: c.name,
				external_id: c.external_id,
				active: c.active,
				associated: c.line_items_count,
			} as AssociatedItem;
		});
	}

	setLineItems(line_items?: any[]) {
		if (isUndefined(line_items)) return [];
		return line_items.map((c) => {
			return {
				id: c.id,
				name: c.name,
				external_id: c.external_id,
				active: c.active,
				associated: c.creative_associations_count,
			} as AssociatedItem;
		});
	}

	getCurrentCampaigns() {
		return this._campaigns;
	}

	getCurrentLineItems() {
		return this._line_items;
	}

	getCreatePayload(): EventCreate {
		return {
			name: this.name,
			advertiser_id: this.advertiser_id || null,
			event_attribution_id: this.event_attribution_id || null,
			event_type_id: this.event_type_id || null,
			segment_id: this.segment_id || null,
			value: this.value || 0,
			click_window_days: this.click_window_days || 30,
			view_window_days: this.view_window_days || 30,
			event_tag_type_id: this.event_tag_type_id || null,
			active: this.active,
		};
	}

	getUpdatePayload(): EventUpdate {
		return {
			id: this.id,
			name: this.name,
			advertiser_id: this.advertiser_id || null,
			event_attribution_id: this.event_attribution_id || null,
			event_type_id: this.event_type_id || null,
			segment_id: this.segment_id || null,
			value: this.value || 0,
			click_window_days: this.click_window_days || 30,
			view_window_days: this.view_window_days || 30,
			event_tag_type_id: this.event_tag_type_id || null,
			active: this.active,
		};
	}

	geyEventTagPayload(): EventTagCreate {
		return {
			id: this.id,
			event_tag_type_id: this.event_tag_type_id || null,
		};
	}

	log() {
		console.log("DEBUG: fields: ", {
			id: this.id,
			external_id: this.external_id,
			segment: this.segment,
			segment_id: this.segment_id,
			segment_name: this.segment_name,
			name: this.name,
			active: this.active,
			archived: this.archived,
			deleted_at: this.deleted_at,
			campaigns: this.campaigns,
			line_items: this.line_items,
			getCurrentCampaigns: this.getCurrentCampaigns(),
			getCurrentLineItems: this.getCurrentLineItems(),
		});
	}
}
