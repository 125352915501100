import {
	ASSOCIATION_ROUTE_TYPE,
	EventCreate,
	EventFilters,
	EventID,
	EventTagResponse,
	EventUpdate,
	TYPE_ARCHIVED,
} from "@/interfaces/event";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { EventEntity } from "@/models/events/Event";
import {
	AxiosGet,
	AxiosPost,
	AxiosPatch,
	GetData,
	AxiosDelete,
	AxiosPut,
} from "@/services/axios-service";
import {
	prepareParams,
	modes,
	catchServiceErrors,
} from "@/utils/services-global";
import { isUndefined } from "lodash";
const ROUTE = require("../api/routes").EVENT;
const ENTITY: string = "event";

class EventService {
	/**
	 * Obtener recursos paginados
	 * @param params
	 * @returns
	 */
	async paginated(params: {
		paginated: Paginated;
		filters?: EventFilters;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.EVENT_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			console.error("EventService::paginated", error);
			return await catchServiceErrors(error);
		}
	}

	/**
	 * Obtener recursos en forma de lista (para opciones de combos)
	 * @param params
	 * @returns
	 */
	async resource(params: {
		route_type: string;
		filters?: EventFilters;
		options?: SortingOption;
	}) {
		try {
			const route = `${matchedRoutes()[params.route_type]}`;

			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: route,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);

			return Promise.resolve(GetData(response));
		} catch (error) {
			console.error("list", { error: error });
			return await catchServiceErrors(error);
		}
	}

	async create(event: EventCreate): Promise<EventUpdate> {
		try {
			const response = await AxiosPost(ROUTE.EVENT_ROUTE, event);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async update(event: EventUpdate, id: Number): Promise<EventUpdate> {
		try {
			const response = await AxiosPatch(
				`${ROUTE.EVENT_ROUTE}/${id}`,
				event
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async show(id: number): Promise<EventEntity> {
		try {
			const response = await AxiosGet(`${ROUTE.EVENT_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async delete(id: number): Promise<EventID> {
		try {
			const response = await AxiosDelete(`${ROUTE.EVENT_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async archive(id: number, archived: Boolean): Promise<EventUpdate> {
		try {
			const response = await AxiosPut(
				`${ROUTE.EVENT_ROUTE}/${id}/set/${
					archived ? TYPE_ARCHIVED.ARCHIVED : TYPE_ARCHIVED.UNARCHIVED
				}`,
				{}
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async generateTag(
		eventId: number,
		eventTagTypeId: number
	): Promise<EventTagResponse> {
		try {
			const response = await AxiosPost(
				`${ROUTE.EVENT_GENERATE_TAG_ROUTE}`,
				{
					event_id: eventId,
					event_tag_type_id: eventTagTypeId,
				}
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async getEventType() {
		try {
			const response = await AxiosGet(`${ROUTE.EVENT_TYPES_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async getEventAttributions() {
		try {
			const response = await AxiosGet(
				`${ROUTE.EVENT_ATTRIBUTIONS_ROUTE}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
	
	/**
	 * Associar campaigns | line items a un evento
	 * @param route_type //associate_campaigns | associate_line_items
	 * @param payload 
	 * @returns 
	 */
	async associate(route_type: ASSOCIATION_ROUTE_TYPE, payload: any): Promise<EventEntity> {
		try {
			const route = `${matchedRoutes()[route_type]}`;
			const response = await AxiosPost(route, payload);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async createAssociationLine(entity: any) {
		try {
			const response = await AxiosPost(
				ROUTE.EVENT_ASSOCIATE_LINE_ROUTE,
				entity
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
	async createAssociationCampaign(entity: any) {
		try {
			const response = await AxiosPost(
				ROUTE.EVENT_ASSOCIATE_CAMPAIGN_ROUTE,
				entity
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
	async list(params: { filters?: EventFilters; options?: SortingOption }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.EVENT_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			console.error("list", { error: error });
			return await catchServiceErrors(error);
		}
	}

	async all(params: { filters?: EventFilters; options?: SortingOption }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.EVENT_ROUTE,
				entity: ENTITY,
				mode: modes.ALL,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			console.error("all", { error: error });
			return await catchServiceErrors(error);
		}
	}
}

function matchedRoutes() {
	return {
		event_attribution_id: ROUTE.EVENT_ATTRIBUTIONS_ROUTE,
		event_type_id: ROUTE.EVENT_TYPES_ROUTE,
		event_tag_type_id: ROUTE.EVENT_TAG_TYPES_ROUTE,
		associate_campaigns: ROUTE.EVENT_ASSOCIATIONS_CAMPAIGN_ROUTE,
		associate_line_items: ROUTE.EVENT_ASSOCIATIONS_LINE_ROUTE,
	};
}

function getFilters(filters: EventFilters): string {
	let filter = "";

	const id = !!filters.id ? filters.id : "";
	const name = !!filters.name ? filters.name : "";
	const external_id = !!filters.external_id ? filters.external_id : "";
	const advertiser_id = !!filters.advertiser_id ? filters.advertiser_id : "";	
	const event_attribution_extra = filters.event_attribution_extra ? filters.event_attribution_extra : "";
	const event_attribution_id = !!filters.event_attribution_id
		? filters.event_attribution_id
		: "";
	const segment_id = !!filters.segment_id ? filters.segment_id : "";
	const value = !!filters.value ? filters.value : "";
	const click_window_days = !!filters.click_window_days
		? filters.click_window_days
		: "";
	const view_window_days = !!filters.view_window_days
		? filters.view_window_days
		: "";
	const advertiser_name = !!filters.advertiser_name
		? filters.advertiser_name
		: "";
	const event_attribution = !!filters.event_attribution
		? filters.event_attribution
		: "";
	const event_type = !!filters.event_type ? filters.event_type : "";
	const active = !!filters.active ? filters.active : "";
	var symbol = "";

	if (external_id) {
		filter += `${symbol}filters[external_id]=${external_id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[name]=${name}`;
		symbol = "&";
	}

	if (advertiser_id) {
		filter += `${symbol}filters[advertiser_id]=${advertiser_id}`;
		symbol = "&";
	}

	if (event_attribution_extra) {
		filter += `${symbol}filters[event_attribution.extra]=${event_attribution_extra}`;
		symbol = "&";
	}

	if (event_attribution_id) {
		filter += `${symbol}filters[event_attribution_id]=${event_attribution_id}`;
		symbol = "&";
	}

	if (segment_id) {
		filter += `${symbol}filters[segment_id]=${segment_id}`;
		symbol = "&";
	}

	if (value) {
		filter += `${symbol}filters[value]=${value}`;
		symbol = "&";
	}

	if (click_window_days) {
		filter += `${symbol}filters[click_window_days]=${click_window_days}`;
		symbol = "&";
	}

	if (view_window_days) {
		filter += `${symbol}filters[view_window_days]=${view_window_days}`;
		symbol = "&";
	}
	if (advertiser_name) {
		filter += `${symbol}filters[advertisers.name]=${advertiser_name}`;
		symbol = "&";
	}

	if (event_attribution) {
		filter += `${symbol}filters[event_attribution=${event_attribution}`;
		symbol = "&";
	}

	if (event_type) {
		filter += `${symbol}filters[event_type]=${event_type}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[active]=${active ? 1 : 0}`;
		symbol = "&";
	}

	return filter;
}
export default new EventService();
