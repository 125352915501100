import { InitTargeting, InitTerm, Term } from "@/models/tabs/Targeting";
import initTargetingValue, { TargetingValue } from "@/models/TargetingValue";
import { head, isEmpty } from "lodash";

export default class TargetingResolve {
	public targeting: InitTargeting = new InitTargeting();
	public targeting_expressions: Array<any> = [];
	public targeting_expression: any = null;
	public targeting_values: TargetingValue[] = [];
	public terms: Term[] = [];

	constructor(parametters: Array<any>, targeting?: InitTargeting) {
		if (targeting) {
			this.targeting = targeting;
		}
		this.targeting_expressions = parametters;
		this.targeting_expression = !isEmpty(parametters)
			? head(parametters)
			: null;
		this.targeting.setId = this.targeting_expression?.id;
		this.setTargetingValues(this.targeting_expression?.targeting_values);
		this.setTargetingTerms(this.targeting_values);
	}

	public setTargetingValues(parametters: Array<TargetingValue>) {
		if (!parametters) return;
		parametters.forEach((element) => {
			this.targeting_values.push(new initTargetingValue(element));
		});
	}

	public setTargetingTerms(parametters: Array<TargetingValue>) {
		if (!parametters) return;
		parametters.forEach((element) => {
			this.terms.push(
				new InitTerm({ targeting_key_id: element.targeting_key_id })
			);
			this.targeting.setTermValue(
				element.module_name,
				element.key_name,
				element
			);
		});
	}

	public get getTargeting() {
		return this.targeting;
	}
}
