import {
	clearAllStorage,
	getTokenFromStorage,
} from "@/services/storage-service";
import AuthService from "@/services/auth-service";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import i18n from "@/plugins/i18n";
import { catchError } from "@/store/Util";
import { AccountDataUpdate } from "@/interfaces/account";
import { Credential } from "@/interfaces/credential";
import { TwoFactor, TwoFactorCode } from '@/interfaces/user';

const token = getTokenFromStorage();

export const AuthModule = {
	namespaced: true,
	state: () => ({
		loggedIn: Boolean(token),
		token: token,
	}),
	mutations: {
		SET_TOKEN(state, _token = "") {
			state.token = _token;
			state.loggedIn = Boolean(_token);
		},
	},
	getters: {
		token(state) {
			return state.token;
		},
		isLoggedIn(state) {
			return state.loggedIn;
		},
	},
	actions: {
		async init({}, initData: any) {
			try {
				const response = await AuthService.init(initData);
				notificationService.notifySuccess(this, {
					message: i18n.t("init.messages.success"),
					btn_text: i18n.t("init.actions.go_login"),
					to: "Login",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async forgotPassword({}, { email }) {
			try {
				const response = await AuthService.forgotPassword(email);
				notificationService.notifySuccess(this, {
					message: i18n.t("forgotPassword.messages.success"),
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async resetPassword({}, { email, password, password_confirmation, token }) {
			try {
				const response = await AuthService.resetPassword({
					email,
					password,
					password_confirmation,
					token,
				});
				notificationService.notifySuccess(this, {
					message: i18n.t("passwordReset.messages.success"),
				} as Notification);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async changePassword({}, account: AccountDataUpdate) {
			try {
				const response = await AuthService.changePassword(account);
				notificationService.notifySuccess(this, {
					message: i18n.t("passwordChange.messages.success"),
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async logIn({ commit }, credential: Credential) {
			try {
				const response = await AuthService.login(credential);
				if(response.hasOwnProperty("two_factor")){
					return await Promise.resolve(response);
				};
				commit("SET_TOKEN", response.token);
				return await Promise.resolve(response);
				
			} catch (error) {
				commit("SET_TOKEN");
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async validateCredentials({ commit }, credential: Credential) {
			try {
				const response = await AuthService.login(credential);
				return await Promise.resolve(response);
			} catch (error) {
				//catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async signOff({ commit }) {
			try {
				const response = await AuthService.logout();
				commit("SET_TOKEN");
				clearAllStorage();
				window.location.href = "/";
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async getCodeQRGoogle({}){
			try {
				const response = await AuthService.getCodeQRGoogle();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async setTwoFactorAuthenticator({ commit }, params: TwoFactor){
			try{
				const response = await AuthService.setTwoFactorAuthenticator(params);
				return Promise.resolve(response);
			}catch(error){
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async TwoFactorAuthenticator({ commit }, params: TwoFactorCode){
			try{
				const response = await AuthService.TwoFactorAuthenticator(params);
				commit("SET_TOKEN", response.token);
				return await Promise.resolve(response);
			}catch(error){
				catchError(this, error);
				return await Promise.reject(error);
			}
		}
	},
};
