import { LIMIT, Mode } from "@/interfaces/persons/v10/query/global";
import {
	Paginate,
	PaginateData,
	PostData,
} from "@/interfaces/persons/v10/query/pois";
import {
	PersonOohKey,
	PersonPoisKey,
	PersonPrivateKey,
} from "@/interfaces/persons/v10/types";

export class PostDataEntity implements PostData {
	paginateData: PaginateDataEntity = new PaginateDataEntity();

	constructor() {}

	// SETTERS //

	setSearch(filterName: string, search: string | null) {
		this.paginateData[filterName].page = 1;
		this.paginateData[filterName].search = search;
		this.paginateData[filterName].searching = true;
	}

	setSearching(filterName: string, searching: Boolean = false) {
		this.paginateData[filterName].searching = searching;
	}

	setCurrentPage(filterName: string, page: number = 1) {
		this.paginateData[filterName].page = page;
	}

	resetAllPage(filterName: string, page: number = 1) {
		let queryData: PaginateEntity | undefined =
			this.paginateData[filterName];
		if (!queryData) return;
		queryData.page = page;
		queryData.max_page = page;
	}

	setFetchingMaxPage(filterName: string, active: Boolean = false) {
		this.paginateData[filterName].setFetchingMaxPage(active);
	}

	setMode(filterName: string, mode: Mode) {
		this.paginateData[filterName].mode = mode;
	}

	// GETTERS //

	async getCurrentPage(filterName: string) {
		return this.paginateData[filterName].page;
	}

	// ACTIONS

	async resetFilters(filterName: string) {
		/**
		 * POIS
		 */
		if (filterName === PersonPoisKey.CATEGORIES) {
			this.paginateData[PersonPoisKey.SUBCATEGORIES] =
				new PaginateEntity();
			this.paginateData[PersonPoisKey.BRANDS] = new PaginateEntity();
			this.paginateData[PersonPoisKey.NAMES] = new PaginateEntity();
		}

		if (filterName === PersonPoisKey.SUBCATEGORIES) {
			this.paginateData[PersonPoisKey.BRANDS] = new PaginateEntity();
			this.paginateData[PersonPoisKey.NAMES] = new PaginateEntity();
		}

		if (filterName === PersonPoisKey.BRANDS) {
			this.paginateData[PersonPoisKey.NAMES] = new PaginateEntity();
		}

		/**
		 * OOH
		 */
		if (filterName === PersonOohKey.OOH_CATEGORIES) {
			this.paginateData[PersonOohKey.OOH_SUBCATEGORIES] =
				new PaginateEntity();
			this.paginateData[PersonOohKey.OOH_BRANDS] = new PaginateEntity();
			this.paginateData[PersonOohKey.OOH_NAMES] = new PaginateEntity();
		}

		if (filterName === PersonOohKey.OOH_SUBCATEGORIES) {
			this.paginateData[PersonOohKey.OOH_BRANDS] = new PaginateEntity();
			this.paginateData[PersonOohKey.OOH_NAMES] = new PaginateEntity();
		}

		if (filterName === PersonOohKey.OOH_BRANDS) {
			this.paginateData[PersonOohKey.OOH_NAMES] = new PaginateEntity();
		}
	}

	async increasePage(filterName: string) {
		this.paginateData[filterName].page++;
		this.paginateData[filterName].max_page++;
	}

	async decreasePage(filterName: string) {
		this.paginateData[filterName].page--;
	}

	isFetchingMaxPage(filterName: string): Boolean {
		return this.paginateData[filterName].isFetchingMaxPage;
	}

	getQuery(filterName: string) {
		const paginateData: PaginateEntity = this.paginateData[filterName];
		return paginateData.getQuery();
	}
}

export class PaginateDataEntity implements PaginateData {
	categories: PaginateEntity = new PaginateEntity();
	subcategories: PaginateEntity = new PaginateEntity();
	brands: PaginateEntity = new PaginateEntity();
	names: PaginateEntity = new PaginateEntity();
	ooh_categories: PaginateEntity = new PaginateEntity();
	ooh_subcategories: PaginateEntity = new PaginateEntity();
	ooh_brands: PaginateEntity = new PaginateEntity();
	ooh_names: PaginateEntity = new PaginateEntity();
	privates: PaginateEntity = new PaginateEntity();

	constructor() {}
}

export class PaginateEntity implements Paginate {
	mode: Mode = Mode.PAGINATED;
	limit: number = LIMIT;
	page: number = 1;
	max_page: number = 1;
	search: string | null = null;
	searching: Boolean = false;
	isFetchingMaxPage: Boolean = false;

	constructor(mode?: Mode) {
		if (mode) {
			this.mode = mode;
		}
	}

	async setMode(mode: Mode) {
		this.mode = mode;
	}

	setFetchingMaxPage(active: Boolean = false) {
		this.isFetchingMaxPage = active;
	}

	getPage() {
		if (this.isFetchingMaxPage) return 1;
		return this.page !== this.max_page ? this.max_page : this.page;
	}

	getLimit() {
		if (this.isFetchingMaxPage) return this.max_page * this.limit;
		return this.limit;
	}

	getQuery() {
		return {
			mode: this.mode,
			limit: this.getLimit(),
			page: this.getPage(),
			search: this.search,
		};
	}
}
