import {
	FilterAudience,
	FilterAudienceDemo,
	FilterAudienceDevice,
	FilterAudienceDigitalBehaviour,
	FilterAudienceHome,
	FilterAudiencePois,
} from "@/interfaces/audience";

export class FilterAudienceEntity implements FilterAudience {
	start_date: string = "";
	end_date: string = "";
	radio: number = 250;
	frecuency: any = {};
	pois: FilterAudiencePois = new FilterAudiencePoisEntity();
	demo: FilterAudienceDemo = new FilterAudienceDemoEntity();
	digital_behaviour: FilterAudienceDigitalBehaviour =
		new FilterAudienceDigitalEntity();
	device: FilterAudienceDevice = new FilterAudienceDeviceEntity();
	home: FilterAudienceHome = new FilterAudienceHomeEntity();

	constructor() {}
}

export class FilterAudiencePoisEntity implements FilterAudiencePois {
	city_poi: string[] = [];
	neighborhood_poi: string[] = [];
	dpto: string[] = [];
	category: string[] = [];
	subcategory: string[] = [];
	marca: string[] = [];
	nombre_poi: string[] = [];
	constructor() {}
}

export class FilterAudienceDemoEntity implements FilterAudienceDemo {
	gender: string[] = [];
	age: string[] = [];
	constructor() {}
}

export class FilterAudienceDigitalEntity
	implements FilterAudienceDigitalBehaviour
{
	iab: string[] = [];
	interest: string[] = [];
	app_name: string[] = [];
	domain: string[] = [];
	city_seen: string[] = [];
	user_type: string[] = [];
	constructor() {}
}

export class FilterAudienceDeviceEntity implements FilterAudienceDevice {
	platform_browser: string[] = [];
	platform_device_language: string[] = [];
	carrier: string[] = [];
	platform_device_make: string[] = [];
	platform_device_type: string[] = [];
	platform_os: string[] = [];
	constructor() {}
}

export class FilterAudienceHomeEntity implements FilterAudienceHome {
	nivsocio: string[] = [];
	nivsoc_brasil: string[] = [];
	city_residence: string[] = [];
	neighborhood_residence: string[] = [];
	constructor() {}
}
