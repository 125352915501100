import {
	AxiosGet,
	GetData,
	GetErrors,
	GetMessage,
	AxiosPost,
} from "@/services/axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import { isEmpty } from "lodash";

const ROUTE = require("../api/routes").CLONE;


class CloneServices {

    async fetchDuplicatedServices(key: string, params: any){
        try {

			let cloneOptions = getCloneOptions(params.clone);
			
			const response = await AxiosPost(
				`${matchedRoutes()[key]}/${params.id}`, cloneOptions
			);

			return Promise.resolve(GetData(response));
		} catch (error) {
			console.log(`ERROR ${error}`);
			return await catchServiceErrors(error);
		}
    }

}

function matchedRoutes() {
	return {
		campaign: ROUTE.CLONE_CAMPAIGN_ROUTE,
		lineItem: ROUTE.CLONE_LINE_ITEM_ROUTE,
		creative: ROUTE.CLONE_CREATIVE_ROUTE
	};
}

function getCloneOptions(options: any){

	let formData = new FormData();

	if (!isEmpty(options)) {
		options.forEach((c) => {
			formData.append("clone[]", c);
		});
	}

	return formData;

}

export default new CloneServices();