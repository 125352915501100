import {
	AudienceFilter,
	DemoLoading,
	DeviceLoading,
	HeatMapLoading,
	InitLoading,
	IntervalDate,
	MapsLoading,
	OnlineLoading,
	OrderOption,
	PanelFilter,
	PoisLoading,
	RadioConfig,
	SortOption,
	TableLoading,
} from "@/interfaces/audience";
import { SortingOption } from "@/interfaces/paginated";
import { AudiencePoint } from "@/interfaces/person";

export const MIN_RADIO: number = 25;
export const MAX_RADIO: number = 250;
const INTERVAL_DATE: IntervalDate = { start: "", end: "" };

export class AudiencePointEntity implements AudiencePoint {
	pois?: {} | undefined = {};
	users?: [] | undefined = [];
	constructor() {}
}

export class PanelFilterEntity implements PanelFilter {
	radio: number = MIN_RADIO;
	intervalTime: any[] = [];
	intervalFecha: IntervalDate = INTERVAL_DATE;
	constructor() {}

	setRadio(radio?: number) {
		this.radio = radio || MIN_RADIO;
	}

	setIntervalFecha(interval?: IntervalDate) {
		this.intervalFecha = interval || INTERVAL_DATE;
	}

	setIntervalTime(interval: any[] = []) {
		this.intervalTime = interval;
	}
}

export class RadioConfigEntity implements RadioConfig {
	min: number = MIN_RADIO;
	max: number = MAX_RADIO;
	constructor() {}

	setMin(min?: number) {
		this.min = min || MIN_RADIO;
	}

	setMax(max?: number) {
		this.max = max || MAX_RADIO;
	}
}

export class AudienceLoading implements InitLoading {
	pois: PoisLoading = {
		users_by_date: true,
		users_by_distance: true,
		users_by_week_day: true,
		users_by_hour_day: true,
		users_by_category: true,
		users_by_subcategory: true,
		users_by_poi_city: true,
		users_by_dpto: true,
		users_by_poi_neighborhood: true,
	} as PoisLoading;

	demographic: DemoLoading = {
		users_by_ciudad_residencia: true,
		users_by_niv_socio: true,
		users_by_age: true,
		users_by_gender: true,
		users_by_barrio_residencia: true,
	} as DemoLoading;

	online_behaviour: OnlineLoading = {
		users_by_type: true,
		users_by_iab: true,
		users_by_intereses: true,
		users_by_app_name: true,
		users_by_domain: true,
		users_by_city_seen: true,
	} as OnlineLoading;

	device: DeviceLoading = {
		users_by_carrier: true,
		users_by_device_make: true,
		users_by_platform_device_type: true,
		users_by_platform_browser: true,
		users_by_device_language: true,
		users_by_plataform_os: true,
	} as DeviceLoading;

	table: TableLoading = {
		users_by_pois_gender_age: true,
	} as TableLoading;

	head_map: HeatMapLoading = {
		users_by_hour_day_week: true,
	} as HeatMapLoading;

	maps: MapsLoading = {
		map: true,
	} as MapsLoading;

	constructor() {}

	resetLoadings() {
		this.pois = {
			users_by_date: true,
			users_by_distance: true,
			users_by_week_day: true,
			users_by_hour_day: true,
			users_by_category: true,
			users_by_subcategory: true,
			users_by_poi_city: true,
			users_by_dpto: true,
			users_by_poi_neighborhood: true,
		} as PoisLoading;

		this.demographic = {
			users_by_ciudad_residencia: true,
			users_by_niv_socio: true,
			users_by_age: true,
			users_by_gender: true,
			users_by_barrio_residencia: true,
		} as DemoLoading;

		this.online_behaviour = {
			users_by_type: true,
			users_by_iab: true,
			users_by_intereses: true,
			users_by_app_name: true,
			users_by_domain: true,
			users_by_city_seen: true,
		} as OnlineLoading;

		this.device = {
			users_by_carrier: true,
			users_by_device_make: true,
			users_by_platform_device_type: true,
			users_by_platform_browser: true,
			users_by_device_language: true,
			users_by_plataform_os: true,
		} as DeviceLoading;

		this.table = {
			users_by_pois_gender_age: true,
		} as TableLoading;

		this.head_map = {
			users_by_hour_day_week: true,
		} as HeatMapLoading;

		this.maps = {
			map: true,
		} as MapsLoading;
	}
}

export class InitAudienceFilter implements AudienceFilter {
	users_by_distance: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_date: SortingOption = {
		order: OrderOption.ASC,
		sort: "",
	};
	users_by_week_day: SortingOption = {
		order: OrderOption.ASC,
		sort: "",
	};
	users_by_hour_day: SortingOption = {
		order: OrderOption.ASC,
		sort: "",
	};
	users_by_category: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_subcategory: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_poi_city: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_dpto: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_poi_neighborhood: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_ciudad_residencia: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_niv_socio: SortingOption = {
		order: OrderOption.DESC,
		sort: SortOption.NAME,
	};
	users_by_age: SortingOption = {
		order: OrderOption.ASC,
		sort: SortOption.NAME,
	};
	users_by_gender: SortingOption = {
		order: OrderOption.ASC,
		sort: SortOption.NAME,
	};
	users_by_barrio_residencia: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_type: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_iab: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_intereses: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_app_name: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_domain: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_city_seen: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_carrier: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_device_make: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_platform_device_type: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_platform_browser: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_device_language: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	users_by_plataform_os: SortingOption = {
		order: OrderOption.DESC,
		sort: "",
	};
	constructor() {}

	public setFilter(key: string, data: SortingOption) {
		this[key] = data;
	}
}
