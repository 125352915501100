import { ElementData } from "@/interfaces/persons/v10/person";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";
import SecureStorageService from "./secure-storage-service";

export const enum storeIndex {
	TOKEN = "token",
	COUNTRY_GLOBAL = "country_global",
	CAMPAIGNS = "campaigns",
}

const secureStorageService = new SecureStorageService();

export function getTokenFromStorage(): string {
	return getFromStorage(storeIndex.TOKEN) || "";
}

export async function setTokenToStorage(token: string = "") {
	return secureStorageService.setItem(storeIndex.TOKEN, token);
}

export async function setPersistentCountry(country_global: ElementData) {
	return secureStorageService.setItem(
		storeIndex.COUNTRY_GLOBAL,
		JSON.stringify(country_global)
	);
}

export async function setPersistentCampaigns(
	campaigns: ComboListOptionsCampaign[]
) {
	return secureStorageService.setItem(
		storeIndex.CAMPAIGNS,
		JSON.stringify(campaigns)
	);
}

export function getFromStorage(key: string) {
	return secureStorageService.getItem(key);
}

export async function setToStorage(key: string, value: string) {
	return secureStorageService.setItem(key, value);
}

export async function removeFromStorage(key: string) {
	secureStorageService.removeItem(key);
}

export async function clearAllStorage() {
	secureStorageService.clear();
}
