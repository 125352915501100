import VueRouter, { Route } from "vue-router";

/**
 * Next Middleware Factory
 * @param router
 */
export function nextMiddlewareFactory(router: VueRouter) {
	router.beforeEach((to, from, next) => {
		window.scrollTo(0, 0);
		if (to?.meta?.middleware) {
			const middleware: Array<any> = to?.meta?.middleware ?? [];
			const context = {
				from,
				next,
				router,
				to,
			};
			const nextMiddleware = nextFactory(context, middleware, 1);
			return middleware[0]({ ...context, next: nextMiddleware });
		}
		return next();
	});
}

/**
 * Next Factory
 * @param context
 * @param middleware
 * @param index
 * @returns
 */
export function nextFactory(
	context: { from?: Route; next: any; router?: VueRouter; to?: Route },
	middleware: any[],
	index: number
) {
	const subsequentMiddleware = middleware[index];
	if (!subsequentMiddleware) return context.next;
	return (...parameters: any) => {
		context.next(...parameters);
		const nextMiddleware = nextFactory(context, middleware, index + 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}
