import { ResultPaginate } from "@/interfaces/paginated";
import CreativeService from "@/services/creative-service";
import notificationService from "@/services/notification-service";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import { CatcherError, catchError, CreateNotification } from "@/store/Util";
import {
	getCreativeTypeByTemplateId,
	resolveDetectAttributes,
	resolveList,
	resolveListAsset,
	resolveListMIMEType,
	resolveListParams,
	resolveTemplates,
} from "@/utils/resolveObjectArray";
import { isArray, isEmpty } from "lodash";
import i18n from "@/plugins/i18n";
import { TagCheck } from "@/interfaces/creative";
import { AssociationDataCreate } from "@/interfaces/creativeAssociation";

export const CreativeModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		result_pag_asso_line: {} as ResultPaginate,
		creative: null,
		asset: {},
		responseTemplates: {},
		creative_sizes: [],
		creative_templates: [],
		creative_types: [],
		advertiser_categories: [],
		mime_types: [],
		creative_rules: [],
		expandable_types: [],
		expandable_directions: [],
		in_banner_videos: [],
		vendors: [],
		addons: [],
		video_api: [],
		video_bid_rate: [],
		audio_mime_types: [],
		assets: [],
		creatives: [],
	}),
	mutations: {
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		},
		SET_RESULT_PAG_ASSOC_LINE(
			state,
			_result_pag_asso_line: ResultPaginate = {} as ResultPaginate
		) {
			state.result_pag_asso_line = _result_pag_asso_line;
		},
		SET_CREATIVE(state, _creative = null) {
			state.creative = _creative;
		},
		PUSH_LINE_ASSOCIATION(state, _line_association = null) {
			state.creative.line_associations.push(_line_association);
		},
		SET_CREATIVE_SIZES(state, _sizes) {
			state.creative_sizes = _sizes;
		},
		SET_CREATIVE_TEMPLATES(state, _templates) {
			state.creative_templates = _templates;
		},
		SET_CREATIVE_TYPES(state, _types = []) {
			state.creative_types = _types;
		},
		SET_ADVERTISER_CATEGORIES(state, _categories) {
			state.advertiser_categories = _categories;
		},
		SET_RESPONSE_TEMPLATES(state, _templates = []) {
			state.responseTemplates = _templates;
		},
		SET_CREATIVE_TYPE_ID(state, _creative_type_id) {
			state.creative.creative_type_id = _creative_type_id;
		},
		SET_MIME_TYPES(state, _mime_types) {
			state.mime_types = _mime_types;
		},
		SET_AUDIO_MIME_TYPES(state, _audio_mime_types) {
			state.audio_mime_types = _audio_mime_types;
		},
		SET_CREATIVE_RULES(state, _creative_rules) {
			state.creative_rules = _creative_rules;
		},
		SET_Expandable_TYPES(state, _expandable_types) {
			state.expandable_types = _expandable_types;
		},
		SET_Expandable_DIRECTIONS(state, _expandable_directions) {
			state.expandable_directions = _expandable_directions;
		},
		SET_IN_BANNER_VIDEOS(state, _in_banner_videos) {
			state.in_banner_videos = _in_banner_videos;
		},
		SET_VENDORS(state, _vendors) {
			state.vendors = _vendors;
		},
		SET_ADDONS(state, _addons) {
			state.addons = _addons;
		},
		SET_ASSETS(state, _assets) {
			state.assets = _assets;
		},
		SET_ASSET(state, _asset) {
			state.asset = _asset;
		},
		SET_VIDEO_API(state, _video_api) {
			state.video_api = _video_api;
		},
		SET_VIDEO_BID_RATE(state, _video_bid_rate) {
			state.video_bid_rate = _video_bid_rate;
		},
		SET_CREATIVE_LIST(state, _list: []) {
			state.creatives = _list;
		},
	},
	getters: {
		getCreative(state){
			return state.creative;
		},
		result_pag_assoc_line(state): ResultPaginate {
			return state.result_pag_asso_line;
		},
	},
	actions: {
		/**
		 * GET AUXILIARY METHODS
		 */
		async creativeSizes({ commit }) {
			try {
				const response = await CreativeService.creativeSizes();
				// commit(
				// 	"SET_CREATIVE_SIZES",
				// 	resolveListParams(response, "id", "name")
				// );
				return await Promise.resolve(resolveListParams(response, "id", "name"));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async creativeTemplates({ commit, state }) {
			try {
				const response = await CreativeService.creativeTemplates();
				commit("SET_RESPONSE_TEMPLATES", response);
				commit(
					"SET_CREATIVE_TEMPLATES",
					resolveTemplates(response, state.creative_types)
				);
				return await Promise.resolve(
					resolveTemplates(response, state.creative_types)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async creativeTypes({ commit }) {
			try {
				const response = await CreativeService.creativeTypes();
				commit(
					"SET_CREATIVE_TYPES",
					resolveListParams(response, "id", "description")
				);
				return await Promise.resolve(
					resolveListParams(response, "id", "description")
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async advertiserCategories({ commit }) {
			try {
				const response = await CreativeService.advertiserCategories();
				// commit(
				// 	"SET_ADVERTISER_CATEGORIES",
				// 	resolveListParams(response, "id", "name")
				// );
				return await Promise.resolve(resolveListParams(response, "id", "name"));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async getCreativeTypeByTemplateId(
			{},
			params: {
				creativeTypes: Array<any>;
				creative_template_id: Number;
			}
		) {
			try {
				if (isEmpty(params.creativeTypes) && params.creative_template_id < 1)
					return await Promise.resolve();
				const response = getCreativeTypeByTemplateId(
					params.creativeTypes,
					params.creative_template_id
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async mimeTypes({ commit }) {
			try {
				const response = await CreativeService.mimeTypes();
				commit("SET_MIME_TYPES", resolveListMIMEType(response));
				return await Promise.resolve(resolveListMIMEType(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async audioTypes({ commit }) {
			try {
				const response = await CreativeService.audioTypes();
				commit("SET_AUDIO_MIME_TYPES", resolveListMIMEType(response));
				return await Promise.resolve(resolveListMIMEType(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async creativeRules({ commit }) {
			try {
				const response = await CreativeService.creativeRules();
				commit(
					"SET_CREATIVE_RULES",
					resolveListParams(response, "id", "description")
				);
				return await Promise.resolve(
					resolveListParams(response, "id", "description")
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async expandableTypes({ commit }) {
			try {
				const response = await CreativeService.expandableTypes();
				commit(
					"SET_Expandable_TYPES",
					resolveListParams(response, "id", "description")
				);
				return await Promise.resolve(
					resolveListParams(response, "id", "description")
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async expandableDirections({ commit }) {
			try {
				const response = await CreativeService.expandableDirections();
				commit(
					"SET_Expandable_DIRECTIONS",
					resolveListParams(response, "id", "description")
				);
				return await Promise.resolve(
					resolveListParams(response, "id", "description")
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async inBannerVideos({ commit }) {
			try {
				const response = await CreativeService.inBannerVideos();
				commit(
					"SET_IN_BANNER_VIDEOS",
					resolveListParams(response, "id", "description")
				);
				return await Promise.resolve(
					resolveListParams(response, "id", "description")
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async vendors({ commit }) {
			try {
				const response = await CreativeService.vendors();
				commit("SET_VENDORS", resolveListParams(response, "id", "description"));
				return await Promise.resolve(
					resolveListParams(response, "id", "description")
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async addons({ commit }, params) {
			try {
				const response = await CreativeService.addons(params);
				commit("SET_ADDONS", resolveList(response));
				return await Promise.resolve(resolveList(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async videoApi({ commit }) {
			try {
				const response = await CreativeService.videoApi();
				commit(
					"SET_VIDEO_API",
					resolveListParams(response, "id", "description", true)
				);
				return await Promise.resolve(
					resolveListParams(response, "id", "description", true)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async videoBidRate({ commit }) {
			try {
				const response = await CreativeService.videoBidRate();
				commit(
					"SET_VIDEO_BID_RATE",
					resolveListParams(response, "extra", "description", true)
				);
				return await Promise.resolve(
					resolveListParams(response, "extra", "description", true)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async assets({}, params) {
			try {
				const response = await CreativeService.assets(params);
				return await Promise.resolve(resolveListAsset(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async creativeAssets({ commit }, params) {
			try {
				const response = await CreativeService.creativeAssets(params);
				commit("SET_ASSET", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async assetTypes({ commit }) {
			try {
				const response = await CreativeService.assetTypes();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async getDetectAttributes({}, params) {
			try {
				const response = await CreativeService.detectAttributes(params);
				return await Promise.resolve(resolveDetectAttributes(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async CreateNewCreative(
			{ commit },
			params: { continue: Boolean; creative: any }
		) {
			try {
				const response = await CreativeService.CreateNewCreative(
					params.creative
				);
				commit("SET_CREATIVE", response);
				notificationService.notifySuccess(this, {
					btn_text: params.continue ? i18n.t("continue") : i18n.t("close"),
					to: params.continue ? "" : "CreativesIndex",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async UpdateCreative(
			{ commit },
			params: { continue: Boolean; creative: any }
		) {
			try {
				const response = await CreativeService.UpdateCreative(params.creative);
				commit("SET_CREATIVE", response);
				notificationService.notifySuccess(this, {
					btn_text: params.continue ? i18n.t("continue") : i18n.t("close"),
					to: params.continue ? "" : "CreativesIndex",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async validateTag({ commit }, tag: TagCheck) {
			try {
				const response = await CreativeService.validateTag(tag);
				commit("SET_ASSET", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async associateLineItem({ commit }, association: AssociationDataCreate) {
			try {
				const response = await CreativeService.associateLineItem(association);
				//commit("PUSH_LINE_ASSOCIATION", response);
				CreateNotification(this.dispatch, {
					type: MessageTypes.SUCCESS,
					title: i18n.t("title-success"),
					message: i18n.t("success"),
					btn_text: i18n.t("continue"),
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async associateLineItemDelete({ commit }, id: number) {
			try {
				const response = await CreativeService.associateLineItemDelete(id);
				CreateNotification(this.dispatch, {
					type: MessageTypes.SUCCESS,
					title: i18n.t("title-success"),
					message: i18n.t("success"),
					btn_text: i18n.t("continue"),
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				CatcherError(this.dispatch, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async associateLineItemUpdate(
			{ commit },
			association: AssociationDataCreate
		) {
			try {
				const response = await CreativeService.associateLineItemUpdate(
					association
				);
				//commit('PUSH_LINE_ASSOCIATION', response)
				CreateNotification(this.dispatch, {
					type: MessageTypes.SUCCESS,
					title: i18n.t("title-success"),
					message: i18n.t("success"),
					btn_text: i18n.t("continue"),
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async download({}, params) {
			try {
				await CreativeService.download(params);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginated({ commit }, params) {
			try {
				const response = await CreativeService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async list({ commit }, params) {
			try {
				const response = await CreativeService.list(params);
				commit(
					"SET_CREATIVE_LIST",
					isArray(response)
						? resolveListParams(response)
						: resolveList(response)
				);
				return await Promise.resolve(response);
			} catch (error) {
				CatcherError(this.dispatch, error);
				return await Promise.reject(error);
			}
		},

		async show({ commit }, id: number) {
			try {
				const response = await CreativeService.show(id);
				//commit("SET_CREATIVE", response);
				//return await Promise.resolve(response);
				commit("SET_CREATIVE", {
					...response,
					budget_type_id: 2,
				});
				return await Promise.resolve({
					...response,
					budget_type_id: 2,
				});
			} catch (error) {
				CatcherError(this.dispatch, error, {
					to: "CreativesIndex",
				});
				return await Promise.reject(error);
			}
		},

		async showCreative({}, id: number) {
			try {
				const response = await CreativeService.show(id);
				return await Promise.resolve(response);
			} catch (error) {
				return await Promise.reject(error);
			}
		},

		async createCreativeBulk({ commit }, params: any){
			try {
				const response = await CreativeService.createCreativeBulk(params);
				notificationService.notifySuccess(this, 
					{
						message: response.message,
						to: "CreativesIndex",
					} as Notification);
				return await Promise.resolve(response.data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async setActive({ commit }, params: { id: number; active: Boolean }) {
			try {
				const response = await CreativeService.setActive(
					params.id,
					params.active
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async listCreativeAssociated({}, params) {
			try {
				const response = await CreativeService.getCreativesAssociated(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginatedAssocLine({ commit }, params) {
			try {
				const response = await CreativeService.paginatedAssoLine(params);
				commit("SET_RESULT_PAG_ASSOC_LINE", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async deleteCreativeByID({ commit }, id: number) {
			try {
				const response = await CreativeService.deleteByID(id);
				notificationService.notifySuccess(this, 
					{ message: response.message, type: MessageTypes.SUCCESS } as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		}
	},
};
