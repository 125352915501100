
export const BreadCrumbsModule = {
    namespaced: true,
    state: () => ({
        history:{
            campaign: [],
            advertisers: []
        },
        navegation:{
            to:{},
            from:{}
        }
	}),
    mutations: {
        SET_NAVEGATION_ROUTE(state, _nav){
            state.navegation[_nav.view] = _nav.data;
        },
    },
    getters: {
        getNavegationData: (state) => (view: string) => {
			return state.navegation[view];
		},
        getHistoryData: (state) => (view: string) => {
			return state.history[view];
		},
        getDataFromHistory: (state) => (_view: string, id: any) => {
			return state.history[_view].find((item: any) => item.id == id);
		},
    },
    actions: {
        async addNavegationRoute({commit}, params){
            try {
                commit("SET_NAVEGATION_ROUTE", params);
            } catch (error) {
                
            }
        },
        async addHistory({state}, params){
            try {
                state.history[params.view].unshift(params.data);
            } catch (error) {
                
            }
        },
        async updateHistoryData({state}, params){
            try {
                let index = state.history[params.view].findIndex((c) => c.id == params.data.id);
                state.history[params.view].splice(index, 1);
                state.history[params.view].push(params.data);
            } catch (error) {
                
            }
        },
        async clearHistoryData({state}, params){
            try {
                state.history.campaign = [];
                state.history.advertisers = [];
            } catch (error) {
                
            }
        }
    }
}