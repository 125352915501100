import { ItemGraphic, ItemHeatMap } from "@/interfaces/graphic";
import { groupBy, head, isNull, isUndefined } from "lodash";
import store from "@/store/index";
import { capitalizeWords, replaceAllVariable } from "@/utils/filter-global";
import * as am5 from "@amcharts/amcharts5";

export const valeDefault = "Others";

export const headerOrder = [
	"name",
	"F_18_24",
	"F_25_34",
	"F_35_44",
	"F_45_54",
	"F_55_plus",
	"F_Others",
	"F_total",
	"M_18_24",
	"M_25_34",
	"M_35_44",
	"M_45_54",
	"M_55_plus",
	"M_Others",
	"M_total",
	"G_total",
];

export const matchedHourText = {
	"0": "12 AM",
	"1": "1 AM",
	"2": "2 AM",
	"3": "3 AM",
	"4": "4 AM",
	"5": "5 AM",
	"6": "6 AM",
	"7": "7 AM",
	"8": "8 AM",
	"9": "9 AM",
	"10": "10 AM",
	"11": "11 AM",
	"12": "12 PM",
	"13": "1 PM",
	"14": "2 PM",
	"15": "3 PM",
	"16": "4 PM",
	"17": "5 PM",
	"18": "6 PM",
	"19": "7 PM",
	"20": "8 PM",
	"21": "9 PM",
	"22": "10 PM",
	"23": "11 PM",
};

export const getCellSize = 20;

export const newYAxisRenderer = {
	minGridDistance: 10,
	cellStartLocation: 0.1,
	cellEndLocation: 0.9,
};

export const optionsAxis = {
	min: 0,
	maxDeviation: 0,
	baseValue: 0,
	maxPrecision: 0,
}

export const optionsYAxisRenderer = {
	//rotation: -90,
	fontSize: 9,
	location: 0.5,
	oversizedBehavior: "truncate",
	maxWidth: 250,
	textAlign: "start",
	fontFamily: "Gotham",
	textTransform: "capitalize",
	textBaseline: "center",
	...newYAxisRenderer,
	minGridDistance: getCellSize,
	...optionsAxis
};

export const getMatchedHours = () => {
	const items: Array<any> = [];

	for (const [index, hourText] of Object.entries(matchedHourText)) {
		items.push({
			hour: hourText,
		});
	}

	return items.reverse();
};

export const prepareWeeks = () => {
	const getWeekDays: string[] =
		store?.getters["audience_graphics/getWeeDays"];
	return getWeekDays.map((w: string) => {
		return {
			weekday: w,
		};
	});
};

/**
 * Sort by specific fields
 * @param points
 * @param order
 * @param field
 * @returns
 */
export const getSortedPoints = (
	points: any[],
	order: string[],
	field: string
) =>
	points.sort((a, b) => {
		return order.indexOf(a[field]) - order.indexOf(b[field]);
	});

export const chartMaxHeight: number = 2000;
/**
 * keys enrichment
 */
export const enrichmentKeys: Array<string> = [];

/**
 * keys demographic
 */
export const demographicKeys: Array<string> = [
	"gender",
	"age",
	"ciudad residencia",
	"niv socio",
];

/**
 * keys device
 */
export const deviceKeys: Array<string> = ["platform", "make", "carrier"];

/**
 * keys digital_behavior
 */
export const digitalBehaviorKeys: Array<string> = ["iab", "interest"];

/**
 * keys audience_per_point
 */
export const audiencePointKeys: Array<string> = [
	"ciudad",
	"dpto",
	"cat",
	"sub",
];

/**
 * keys proximity_point
 */
export const proximityPointKeys: Array<string> = ["distancia"];

/**
 * keys day_week
 */
export const dayWeekKeys: Array<string> = [];

/**
 * keys time_day
 */
export const timeDayKeys: Array<string> = [];

/**
 * keys user_date
 */
export const userDateKeys: Array<string> = ["type"];

/**
 * Matcher soure_keys
 */
export const matchedTypeKeys: any = {
	enrichment: enrichmentKeys,
	demographic: demographicKeys,
	device: deviceKeys,
	digital_behavior: digitalBehaviorKeys,
	audience_per_point: audiencePointKeys,
	proximity_point: proximityPointKeys,
	day_week: dayWeekKeys,
	time_day: timeDayKeys,
	user_date: userDateKeys,
};

/**
 * Prepare graphic data
 * @param graphics Object provided API
 * @param type Type of source Ex.: demographic | device
 * @returns
 */
export async function prepareGroupedGraphic(graphics: any, type: string) {
	let items: Array<ItemGraphic> = [];

	let keys = Object.keys(graphics);

	keys.map((key) => {
		if (!matchedTypeKeys[type].includes(key)) return;

		let values = graphics[key];

		values.map((_i: any) => {
			let item: any = {};

			if (type == "user_date") {
				item = { type: "", name: _i.fecha, ..._i };
			} else {
				item = { type: key, ..._i };
			}

			items.push(item);
		});
	});

	return items;
}

/**
 * Prepare graphic data
 * @param graphics Object provided API
 * @param type Type of source Ex.: demographic | device
 * @returns
 */
export async function prepareDefaultGraphic(graphics: any, type: string) {
	let items: Array<ItemGraphic> = [] as Array<ItemGraphic>;

	items = graphics.map((g: any) => {
		const name = g.type.charAt(0).toUpperCase() + g.type.slice(1);
		return {
			type: "",
			name: name,
			uniques: g.uniques,
		} as ItemGraphic;
	});

	return items;
}

/**
 * Prepare graphic data
 * @param graphics Object provided API
 * @param type Type of source Ex.: demographic | device
 * @returns
 */
export async function prepareDayWeekGraphic(graphics: Array<any>) {
	let items: Array<ItemGraphic> = [] as Array<ItemGraphic>;

	const weekKeys: Array<any> = Object.keys(graphics);

	/**
	 * Se agregan los ItemGraphic por cada dia-semana [weekKeys]
	 */
	weekKeys.map((k: any) => {
		const item: ItemGraphic = { type: "", name: k, uniques: 0 }; // dia_semana
		items.push(item);
	});

	/**
	 * Por cada dia-semana [weekDay] se actualiza el total:uniques por cada hora [hours]
	 */
	for (const weekDay in graphics) {
		let hours: Array<any> = graphics[weekDay];

		let totalUniques: number | undefined = head(
			items.filter((i) => i.name == weekDay)
		)?.uniques;

		hours.map((hour: any) => {
			totalUniques += hour.uniques;
		});

		items.map((i) => {
			if (i.name == weekDay && totalUniques) {
				i.uniques += totalUniques;
			}
		});
	}

	return items;
}

/**
 * Prepare graphic data
 * @param graphics Object provided API
 * @param type Type of source Ex.: demographic | device
 * @returns
 */
export async function prepareHourDayGraphic(graphics: any) {
	let items: Array<ItemGraphic> = [] as Array<ItemGraphic>;

	const getItem = (key: any) => {
		return items.find((i) => i.name == key);
	};

	const updateItem = (key: any, newValue: number) => {
		for (const item of items) {
			if (item.name === key) {
				item.uniques += newValue;
				break;
			}
		}
	};

	for (var [key, value] of Object.entries(graphics)) {
		const week: Array<any> = value as any;

		week.map((w: any) => {
			const finded = getItem(w.hour);

			if (finded) {
				updateItem(w.hour, w.uniques);
				return;
			}

			items.push({
				type: "",
				name: w.hour,
				uniques: w.uniques,
			} as ItemGraphic);
		});
	}

	//let newItems = sortBy(items, ["name"], ["asc"]);

	items.map((i) => {
		i.name = matchedHourText[i.name];
	});

	return items;
}

/**
 * getUniqueBy
 * @param arr
 * @param prop
 * @returns
 */
export const getUniqueBy = (arr: any[], prop: string) => {
	const set = new Set();
	return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
};

/**
 * Prepare graphic data
 * @param graphics Object provided API
 * @param type Type of source Ex.: demographic | device
 * @returns
 */
export async function prepareUserDateGraphic(graphics: any) {
	let items: Array<ItemGraphic> = [] as Array<ItemGraphic>;

	const weekData: Array<any> = Object.values(graphics);

	weekData.map((week: any, index: any) => {
		week.map((day: any, i: any) => {
			if (!items[i]) {
				items.push({
					type: "", // user_date
					name: day.fecha,
					uniques: day.uniques,
				} as ItemGraphic);
				return;
			}
			items[i].uniques += day.uniques;
		});
	});

	return items;
}

/**
 * Prepare graphic data
 * @param responseData Object provided API
 * @returns
 */
export async function prepareUserGenderGraphic(responseData: any) {
	const usersByGender: Array<any> = Object.values(responseData);

	const rangeGenres = usersByGender.map((g) => g.age);
	const uniqueRangeGenres: string[] = [...new Set(rangeGenres)];

	/**
	 * Headers
	 */
	let headers: Array<any> = prepareUserGenreHeader(uniqueRangeGenres);

	headers = getSortedPoints(headers, headerOrder, "value");
	/**
	 * Items
	 */
	let items: Array<any> = prepareUserGenreItems(usersByGender, headers);

	items = items.slice(0, 100);

	return { headers, items };
}

/**
 * Prepare graphic data
 * @param responseData Array provided API
 * @returns
 */
export async function prepareHeatMapGraphic(responseData: any[]) {
	let items: Array<ItemHeatMap> = [] as Array<ItemHeatMap>;
	let weekDays: Array<string> = [] as string[];
	
	if(isUndefined(responseData)) return items;

	responseData.map((r) => {
		const item: ItemHeatMap = {
			weekday: String(r.dia_semana),
			hour: String(r.hour_of_day),
			value: parseInt(r.uniques),
		} as ItemHeatMap;

		weekDays.push(r.dia_semana);
		items.push(item);
	});

	items.map((i) => {
		i.hour = matchedHourText[i.hour];
	});

	const uniqueWeekDays: string[] = [...new Set(weekDays)];

	// set weekdays
	await store?.dispatch("audience_graphics/setData", {
		key: "week_days",
		value: uniqueWeekDays,
	});

	return items;
}

/**
 * Prepare user distinct
 * @param items
 * @returns
 */
export async function prepareUserCount(graphics: any) {
	return (
		graphics.find((i: { type: string }) => i.type == "total")?.uniques || 0
	);
}

/**
 * Omitir graficos en la generacion de PDF
 * @returns
 */
export function omitExportPDF() {
	return ["categories", "cities", "brands", "names"];
}

/**
 * Generate header
 * @param options
 * @returns
 */
export function generateHeader(options: {
	text: string;
	value: any;
	is_numeric?: Boolean;
	class?: string;
}) {
	return {
		text: options.text,
		align: options.value == "name" ? "start" : "end",
		sortable: false,
		value: options.value,
		isNumeric: options.is_numeric || false,
		class: options.class || "",
		divider: false,
	};
}

/**
 * Prepare values ​​for a starting row
 * @param headers
 * @returns
 */
export function generateInitRow(headers: any[]) {
	let row: any = {};
	headers.map((h) => {
		let obj: any = {};
		obj[h.value] = h.isNumeric ? 0 : "";
		Object.assign(row, obj);
	});

	return row;
}

/**
 * Prepare header data
 * @param uniqueRangeGenres
 * @returns
 */
export function prepareUserGenreHeader(uniqueRangeGenres: any[]) {
	let headers: Array<any> = [] as Array<any>;

	headers.push(generateHeader({ text: "Etiqueta de fila", value: "name" }));

	uniqueRangeGenres.map((name_range) => {
		const f_key = `F_${name_range || valeDefault}`;

		headers.push(
			generateHeader({
				text: name_range || valeDefault,
				value: f_key,
				is_numeric: true,
				class: "grey lighten-5",
			})
		);
	});

	headers.push(
		generateHeader({
			text: "Total F",
			value: "F_total",
			is_numeric: true,
			class: "grey lighten-2",
		})
	);

	uniqueRangeGenres.map((name_range) => {
		const m_key = `M_${name_range || valeDefault}`;
		headers.push(
			generateHeader({
				text: name_range || valeDefault,
				value: m_key,
				is_numeric: true,
				class: "grey lighten-5",
			})
		);
	});

	headers.push(
		generateHeader({
			text: "Total M",
			value: "M_total",
			is_numeric: true,
			class: "grey lighten-2",
		})
	);

	headers.push(
		generateHeader({
			text: "Total General",
			value: "G_total",
			is_numeric: true,
			class: "",
		})
	);

	return headers;
}

/**
 * Prepare items
 * @param usersByGender
 * @param headers
 * @returns
 */
export function prepareUserGenreItems(usersByGender: any[], headers: any[]) {
	let items: Array<any> = [] as Array<any>;

	const getRow: any = generateInitRow(headers);

	const groupByName = groupBy(usersByGender, "nombre_poi");

	Object.entries(groupByName).forEach(([k, v]) => {
		let newItem: any = Object.assign({}, getRow);

		let name = k.toLowerCase();
		name = replaceAllVariable(name, /&quot./g, "");
		name = replaceAllVariable(name, /&quot/g, "");
		newItem.name = capitalizeWords(name);

		v.map((element) => {
			if (isNull(element.gender)) return;

			newItem[`${element.gender}_${element.age || valeDefault}`] +=
				element.uniques;

			if (element.gender == "F") {
				newItem[`F_total`] += element.uniques;
			}

			if (element.gender == "M") {
				newItem[`M_total`] += element.uniques;
			}

			newItem[`G_total`] += element.uniques;
		});

		items.push(newItem);
	});

	return items;
}

/**
 * Find by name
 * @param items
 * @param name
 * @returns
 */
export function findByName(items: any[], name: string) {
	return items.find((i) => i?.name == name);
}

/**
 * Tipos de graficos:
 *
 * enrichment: todo
 * - api: api/persons/total_categories_graph
 * - method: prepareDefaultGraphic
 * - grouped
 *
 *
 * demographic: solo [gender, age, ciudad residencia, nivsocio]
 * - api: api/persons/category_graph
 * - method: prepareGroupedGraphic
 * - grouped
 *
 *
 * device: solo [make,carrier,platform]
 * - api: api/persons/category_graph
 * - method: prepareGroupedGraphic
 * - grouped
 *
 *
 * digital_behavior: solo [iab, interest]
 * - api: api/persons/category_graph
 * - method: prepareGroupedGraphic
 * - grouped
 *
 * audience_per_point: todo
 * - api: api/persons/category_graph
 * - method: prepareGroupedGraphic
 * - grouped
 *
 *
 * proximity_point: solo [???]
 * - api: api/persons/category_graph
 * - method:
 * - grouped
 *
 *
 * day_week: todo
 * - api: api/persons/day_hour_graph
 * - method:
 * - grouped
 *
 *
 * time_day: todo
 * - api: api/persons/day_hour_graph
 * - method:
 * - grouped
 *
 *
 * user_date: todo
 * - api: api/persons/date_graph
 * - method:
 * - grouped
 */
