export enum LoadingI18Message {
	"" = "",
	loading = "loadings.loading",
	long_time = "loadings.long_time",
	thumbnail = "loadings.thumbnail",
	create = "loadings.create",
	update = "loadings.update",
	send = "loadings.send",
	enable = "loadings.enable",
	disable = "loadings.disable",
	user_search = "loadings.user_search",
	creative_display = "loading.creative_display"
}

export enum TypeLoading {
	"" = "",
	loading = "loading",
	long_time = "long_time",
	thumbnail = "thumbnail",
	create = "create",
	update = "update",
	send = "send",
	enable = "enable",
	disable = "disable",
	user_search = "user_search",
	creative_display = "creative_display"
}

export interface LoadingData {
	loading: Boolean;
	type: String;
}
