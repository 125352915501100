import { ElementData } from "@/interfaces/persons/v10/person";
import {
	PaginateFilter,
	PaginateQuery,
	QueryGeo,
	QueryParam,
} from "@/interfaces/persons/v10/query/geo";
import {
	LIMIT,
	MatchFilterName,
	Mode,
} from "@/interfaces/persons/v10/query/global";
import { PersonGeoKey } from "@/interfaces/persons/v10/types";
import { isEmpty } from "lodash";
import { PersonGeoEntity, PersonPoisEntity } from "./Implements";

/**
 * Main
 */
export class QueryParamEntity implements QueryParam {
	country_code?: number = NaN;
	geo: QueryGeoEntity = new QueryGeoEntity();

	constructor(country_code?: number) {
		this.country_code = country_code;
	}

	// SETTERS //

	setCountryCode(country_code: number) {
		this.country_code = country_code;
	}

	setSearch(filterName: string, search: string | null) {
		this.geo[filterName].page = 1;
		this.geo[filterName].search = search;
		this.geo[filterName].searching = true;
	}

	setSearching(filterName: string, searching: Boolean = false) {
		this.geo[filterName].searching = searching;
	}

	setFilter(filterName: string, value: number[]) {
		const source = { [MatchFilterName[filterName]]: value };
		Object.assign(this.geo[filterName].filters, source);
	}

	setCurrentPage(filterName: string, page: number = 1) {
		this.geo[filterName].page = page;
	}

	resetAllPage(filterName: string, page: number = 1) {
		let queryData: PaginateQuery | undefined = this.geo[filterName];
		if(!queryData) return;
		queryData.page = page;
		queryData.max_page = page;
	}

	setFetchingMaxPage(filterName: string, active: Boolean = false) {
		this.geo[filterName].setFetchingMaxPage(active);
	}

	// GETTERS //

	getCurrentPage(filterName: string) {
		return this.geo[filterName].page;
	}

	getPostQuery() {
		let postQuery = {
			country_code: this.country_code,
			filters: [
				{
					geography: {
						codigo_estado: this.geo.states.filters?.codigo_estado,
						codigo_municipio:
							this.geo.cities.filters?.codigo_municipio,
						codigo_barrio:
							this.geo.neighborhoods.filters?.codigo_barrio,
					},
					categoria: [],
					subcategoria: [],
					marca: [],
					nombre: [],
				},
			],
		};
		return postQuery;
	}

	getQuery(
		filterName: string,
		country_global: ElementData,
		pre: PersonGeoEntity | PersonPoisEntity
	) {
		let symbol: string = "?";

		let query = `${symbol}`;

		query += `country_code=${country_global.id}`;
		symbol = "&";

		// filters //

		/**
		 * PersonGeoEntity
		 */
		if (filterName !== "states" && pre instanceof PersonGeoEntity) {
			// states
			if (!isEmpty(pre.states)) {
				pre.states.forEach((element) => {
					query += `${symbol}filters[codigo_estado][]=${element.value}`;
					symbol = "&";
				});
			}

			if (filterName === "cities")
				return this.getPaginateQuery(filterName, query, symbol);

			// cities
			if (!isEmpty(pre.cities)) {
				pre.cities.forEach((element) => {
					query += `${symbol}filters[codigo_ciudad][]=${element.value}`;
					symbol = "&";
				});
			}
		}

		return this.getPaginateQuery(filterName, query, symbol);
	}

	getPaginateQuery(filterName: string, query: string, symbol: string) {
		query += `${symbol}mode=${this.geo[filterName].mode}`;
		query += `${symbol}limit=${this.geo[filterName].getLimit()}`;
		query += `${symbol}page=${this.geo[filterName].getPage()}`;
		query += `${symbol}search=${this.geo[filterName].search ?? ""}`;

		return query;
	}

	// ACTIONS

	async resetFilters(filterName: string) {
		if (filterName === PersonGeoKey.STATES) {
			this.geo[PersonGeoKey.CITIES] = new PaginateQueryEntity();
			this.geo[PersonGeoKey.NEIGHBORHOODS] = new PaginateQueryEntity();
		}

		if (filterName === PersonGeoKey.CITIES) {
			this.geo[PersonGeoKey.NEIGHBORHOODS] = new PaginateQueryEntity();
		}
	}

	async increasePage(filterName: string) {
		this.geo[filterName].page++;
		this.geo[filterName].max_page++;
	}

	decreasePage(filterName: string) {
		this.geo[filterName].page--;
	}

	// CHECKERS

	isSearching(filterName: string): Boolean {
		return this.geo[filterName].searching;
	}

	isFetchingMaxPage(filterName: string): Boolean {
		return this.geo[filterName].isFetchingMaxPage;
	}

	hasFilterApplied(filterName: string): Boolean {
		return this.geo[filterName].hasFilterApplied;
	}
}

/**
 * Dependencies
 */
export class PaginateFilterEntity implements PaginateFilter {
	codigo_municipio: number[] = [];
	codigo_estado: number[] = [];
	codigo_barrio: number[] = [];

	country_code: string = "";
	layer_name: string = "";

	constructor() {}
}

export class PaginateQueryEntity implements PaginateQuery {
	filters: PaginateFilter = new PaginateFilterEntity();
	mode: Mode = Mode.PAGINATED;
	limit: number = LIMIT;
	page: number = 1;
	max_page: number = 1;
	search: string | null = null;
	searching: Boolean = false;
	isFetchingMaxPage: Boolean = false;

	constructor() {}

	setFetchingMaxPage(active: Boolean = false) {
		this.isFetchingMaxPage = active;
	}

	getPage() {
		if (this.isFetchingMaxPage) return 1;
		return this.page !== this.max_page ? this.max_page : this.page;
	}

	getLimit() {
		if (this.isFetchingMaxPage) return this.max_page * this.limit;
		return this.limit;
	}
}

export class QueryGeoEntity implements QueryGeo {
	cities: PaginateQuery = new PaginateQueryEntity();
	neighborhoods: PaginateQuery = new PaginateQueryEntity();
	states: PaginateQuery = new PaginateQueryEntity();

	constructor() {}
}
