import { EnumReportType, GeneratedReport } from "@/interfaces/report";
import { catchError } from "@/store/Util";
import { GenerateReportType } from "@/interfaces/reports/v2/report";
import { ResponseReportEntity } from "@/models/Reports/v2/GenerateReport";
import { SnacktifyEntity } from "@/models/Core/Snacktify";
import GenerateReportService from "@/services/reports/v2/generate-service";
import { SnactType } from "@/interfaces/snack";
import { ResponseJobEntity } from "@/models/Reports/v2/Report";

export const GenerateReportModule = {
	namespaced: true,
	state: () => ({
		responseAttempReport: new ResponseReportEntity(),
		responseJob: new ResponseJobEntity(),
	}),
	mutations: {
		SET_STATE(
			state: { [x: string]: any },
			params: { key: string; value: any }
		) {
			state[params.key] = params.value;
		},
	},
	getters: {
		attempResponse(state: { responseAttempReport: ResponseReportEntity }) {
			return state.responseAttempReport;
		},
		responseJob(state: { responseJob: ResponseJobEntity }) {
			return state.responseJob;
		},
	},
	actions: {
		/**
		 *  Guardar datos en el state usando mutations
		 * @param params
		 * @returns
		 */
		setState<T>({ commit }, params: { key: string; value: T }) {
			return new Promise((resolve) => {
				commit("SET_STATE", params);
				resolve(params);
			});
		},

		/**
		 *
		 * Generar reporte y descargar archivo csv|xlsx
		 * @param params
		 * @returns
		 */
		async generateReport(
			{ dispatch },
			params: {
				reportType: GenerateReportType;
				extension: EnumReportType;
				payload: GeneratedReport;
			}
		) {
			try {
				const result = await GenerateReportService.generateReport(
					params.reportType,
					params.extension,
					params.payload
				);

				if (!result) return await Promise.reject();

				await dispatch("sendNotify", {
					type: SnactType.SECONDARY,
					message: result.message,
					messageParams: {
						minutes: 5,
					},
				});

				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async sendNotify(
			{ rootState }: any,
			payload: {
				type: SnactType;
				message: string;
				messageParams: Record<any, any>;
			}
		) {
			let snacktify: SnacktifyEntity = rootState.snacktify._snacktify;
			snacktify.setSnacktifyData({
				type: payload.type,
				message: payload.message,
				messageParams: payload.messageParams,
			});
		},
	},
};
