export enum LikeType {
	GEO = "geo",
	POIS = "pois",
	OOH = "ooh",
}

export const AssignIDs = [
	-1000, -1001, -1002, -2000, -2001, -2002, -2003, -3000, -3001, -3002, -3003,
];

export enum AssignID {
	states = -1000,
	cities = -1001,
	neighborhoods = -1002,
	categories = -2000,
	subcategories = -2001,
	brands = -2002,
	names = -2003,
	ooh_categories = -3000,
	ooh_subcategories = -3001,
	ooh_brands = -3002,
	ooh_names = -3003,
}

export enum LikeName {
	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",
	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
	NAMES = "names",
	OOH_CATEGORIES = "ooh_categories",
	OOH_SUBCATEGORIES = "ooh_subcategories",
	OOH_BRANDS = "ooh_brands",
	OOH_NAMES = "ooh_names",
	PRIVATES = "privates",
}

export interface LikeData {
	all: Boolean;
	term: string | null;
}

export interface LikeAllGeo {
	states: LikeData;
	cities: LikeData;
	neighborhoods: LikeData;
}

export interface LikeAllPois {
	categories: LikeData;
	subcategories: LikeData;
	brands: LikeData;
	names: LikeData;
}

export interface LikeAllOoh {
	ooh_categories: LikeData;
	ooh_subcategories: LikeData;
	ooh_brands: LikeData;
	ooh_names: LikeData;
}

export interface LikeDataAll {
	geo: LikeAllGeo;
	pois: LikeAllPois;
	ooh: LikeAllOoh;
}
