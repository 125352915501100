
import { isEmpty } from "lodash";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

export default {
	name: "CardSearcher",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {},
		rules: {
			type: Array,
			default: function () {
				return [];
			},
		},
		hint: {
			type: String,
			default: "",
		},
		reference: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		customClassCard: {
			type: String,
			default: "",
		},
		customClass: {
			type: String,
			default: "label-fixed",
		},
		counter: {
			type: [Number, Boolean],
			default: 255,
		},
		persistent_hint: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		error: {
			type: Boolean,
			default: false,
		},
		messages: {
			type: String,
			default: "",
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		error_messages: {
			type: [Array, String],
			default: function () {
				return [];
			},
		},
		append_icon: {
			type: String,
			default: "",
		},
		append_outer_icon: {
			type: String,
			default: "",
		},
		prepend_icon: {
			type: String,
			default: "",
		},
	},
	data: function () {
		return {};
	},
	components: { IconBase },
	mounted() {},
	computed: {
		isLoading() {
			return this.$store.state.proccess.loading_field;
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
		getCustomClass() {
			return `${this.customClass} ${this.disabled ? "disabled" : ""}`;
		},
	},
	methods: {
		isEmpty(data: any) {
			return isEmpty(data);
		},
		handleChange(e: any) {
			this.$emit("change", e);
		},
		handleBlur(e: any) {
			this.$emit("blur", this.modelData);
		},
		enterHandler(e: any) {
			this.$emit("enter", e);
		},
		handleAppend(params: any) {
			this.$emit("click-append", params);
		},
		handleAppendOuter(params: any) {
			this.$emit("click-append-outer", params);
		},
		handlePrepend(params: any) {
			this.$emit("click-prepend", params);
		},
		handleClear(params: any) {
			this.$emit("click-clear", params);
		},
	},
	watch: {},
};
