import UserSettingService from "@/services/user-setting-service";
import { catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { UserSettingBody } from "@/models/Setting";

export const UserSettingModule = {
	namespaced: true,
	state: () => ({}),
	mutations: {},
	getters: {},
	actions: {
		async update(
			{ commit }: any,
			params: { user: UserSettingBody; id: number; }
		) {
			try {
				const response = await UserSettingService.update(
					params.user,
					params.id
				);
				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
