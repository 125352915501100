import Vue from "vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
// @ts-ignore
import {
	SortingOption,
	Paginated,
	ResultPaginate,
} from "@/interfaces/paginated";
// @ts-ignore
import { preparedColumnDefsGrid } from "@/utils/CustomizeViewData";
// @ts-ignore
import { mapActions, mapGetters } from 'vuex';
// @ts-ignore
import { isEmpty, isUndefined, isNull } from "lodash";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { SchedulerReportFilters } from "@/interfaces/report";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import CardActions from "@/components/Content/CardAction.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import ConfirmDeleteReport from "@/views/Admin/Reports/Form/ConfirmDeleteReport.vue";
import { Status } from "@/interfaces/state";
import { sleep } from "@/utils/convert";
import { isProd } from "@/services/process-service";

const DELAY_FETCH: number = 10000;
//@ts-ignore
import { getPermisionReport } from "@/utils/permissionResolve.ts";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "SchedulerReportList",
	props: {
		showTitle: {
			type: Boolean,
			default: true,
		}
	},
	components: {
		GridTable,
		FilterGridAG,
		ActiveFieldTable,
		Separator,
		CardActions,
		ActionsTable,
		ConfirmDeleteReport,
		NotPermission
	},
	data: () => ({
		paginated: {
			page: 1,
			limit: 5,
		} as Paginated,
		filters: {},
		options: getDefaultSortingOption("id"),
		table: {
			items: [],
		},
		context: null,
		selectionRows: "single",
		formDelete: {
			all: false,
			id: null,
			open: false,
			active: false,
			ids: null,
			activeData: {
				id: null,
				status: false,
				rowIndex: null,
			},
		},
	}),
	created() {},
	beforeDestroy() {
		this.clearIntervalData();
	},
	async mounted() {
		this.context = { componentParent: this };
		await this.getPaginated(true);
	},
	computed: {

		...mapGetters("profile", ["getAbility"]),

		showListReport(){
			return !isEmpty(this.getSchedulerReport);
		},

		getResultPaginate(): ResultPaginate {
			return this.$store.state.report.result_paginate;
		},

		prepareTableHeaders() {
			return [
				{
					text: this.$t("report.scheduler.table.subject"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "email_subject",
				},
				{
					text: this.$t("report.scheduler.table.report_type"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "report_type",
				},
				{
					text: this.$t("report.scheduler.table.start_date"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "start_date",
				},
				{
					text: this.$t("report.scheduler.table.end_date"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "end_date",
				},
				{
					text: this.$t("report.scheduler.table.status"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "active",
				},
				{
					text: this.$t("report.scheduler.table.created_user"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "user_name",
				},
			];
		},

		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.prepareTableHeaders,
				this.getConfigColumnDef
			);
		},

		getConfigColumnDef() {
			return {
				context: this.context,
				entityName: "Scheduler_Report_List",
				redirect: "ScruderReportEdit",
				checkDisableEdit: true,
				edit: true,
				delete: true,
				editReport: true,
				eventActive: true,
				minWidthActions: 150,
				maxWidthActions: 150,
				resizableID: true,
				eventActions: true,
                flex: 1,
				permission: this.getPermission
			};
		},

		gerRowsData() {
			return this.table.items || [];
		},

		hasPendingRowsData() {
			const rows: any[] = this.gerRowsData;
			return rows.some((r) => r.status === Status.PENDING);
		},

		getSchedulerReport() {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

		canList(){
			return this.getAbility.can(this.getPermission.actions.index_scheduler, this.getPermission.subject);
		},

		canDelete(){
			return this.getAbility.can(this.getPermission.actions.delete_schedule, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionReport();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("report", ["getSchedulerPaginated"]),

		clearIntervalData() {
			clearInterval(this.intervalId);
			if (!isProd()) {
				console.log(`(DEBUG) Se limpia el intervalId.`);
			}
		},

		async fetchPaginate(interval: number = 10000) {
			/**
			 * Limpiar intervalId
			 */
			this.clearIntervalData();
			/**
			 * Si hay elementos con status pending se llamara cada {interval} segundos
			 */
			if (this.hasPendingRowsData) {
				this.intervalId = setInterval(async () => {
					if (!isProd()) {
						console.log(
							`(DEBUG) Se dispara la llamada de la paginacion de scheduler... (cada ${
								DELAY_FETCH / 1000
							} segundos)`
						);
					}
					await this.getPaginated(false);
				}, interval); // Ejecuta la funciÃ³n cada {interval} segundos
			}
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		getActiveText(active: Boolean) {
			return active
				? i18n.t("common.fields.active")
				: i18n.t("common.fields.inactive");
		},
		async cancelDelete(params: {
			activeActions: boolean;
			id: any;
			status: any;
			rowIndex: any;
			rever: Boolean;
		}) {
			this.formDelete.open = false;
			this.formDelete.all = false;
			this.formDelete.id = null;
			this.formDelete.active = false;
			(this.formDelete.ids = null),
				(this.formDelete.activeData.id = null);
			this.formDelete.activeData.status = false;
			this.formDelete.activeData.rowIndex = null;

			if (!isNull(params) && params.activeActions && params.rever) {
				let report = this.table.items.find(({ id }) => id == params.id);
				report.status = !params.status;
				this.$refs.Grid_Line.updateRowNodeByIndex(
					params.rowIndex,
					report
				);
			}
		},
		async handleAction(action: { type: any }) {
			try {
				switch (action.type) {
					case "delete-all":
						await this.handleDeleteAll();
						break;
				}
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},
		async deleteReportListItem(id: any) {
			return await this.$store.dispatch("report/delete", id, {
				root: true,
			});
		},
		async deleteAllReportItem(id: any) {
			return await this.$store.dispatch("report/deleteAll", id, {
				root: true,
			});
		},
		async getPaginated(enableLoading: Boolean = true) {
			try {
				console.log("getPaginated")
				if (enableLoading)
					await this.setLoadingData(TypeLoading.loading);

				const response = await this.getSchedulerPaginated(
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				this.table.items = response.data;
				console.log("getPaginated", {table: this.table})
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options,
			});
		},

		async updateParams(params: {
			filters: SchedulerReportFilters;
			options: SortingOption;
		}) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption("id");
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async updateTable() {
			await this.getPaginated();
		},
		async deleteReportByID(params: any) {
			this.formDelete.open = true;
			this.formDelete.all = false;
			this.formDelete.id = params.id;
		},
		async handleDeleteAll() {
			let values = this.table.items.map((r) => r.id);
			let obj = {
				ids: values,
			};
			this.formDelete.open = true;
			this.formDelete.all = true;
			this.formDelete.ids = obj;
		},
		async updateList() {
			await this.getPaginated();
		},
		async handleActive(params: any) {
			this.formDelete.open = true;
			this.formDelete.all = true;
			this.formDelete.active = true;
			this.formDelete.activeData.id = params.id;
			this.formDelete.activeData.status = params.active;
			this.formDelete.activeData.rowIndex = params.rowIndex;
		},
		async handleDelete(index: any) {
			let item = this.page.data[index];
			if (!!item.id) {
				delete item.editing;
				this.page.data.splice(index, 1, { ...item, deleted: true });
			} else {
				this.page.data.splice(index, 1);
			}
		},

		async EditReportMethod(params: any) {
			this.$emit("update-Report", params);
		},
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
		async hasPendingRowsData(val: Boolean) {
			if (!isProd()) {
				console.log(
					"(DEBUG) Existe elementos de la lista con estado: pendiente? ",
					val
				);
			}
			if (!val) {
				this.clearIntervalData();
				return;
			}
			await sleep(2000);
			this.fetchPaginate(DELAY_FETCH);
		},
	},
});