import { catchServiceErrors } from "@/utils/services-global";
import { AxiosGet, GetData } from "./axios-service";

export const LINE_ITEM_TYPE_ROUTE = '/api/list/line_items_types'

class LineItemTypeService {
    async list() {
        try {
            const response = await AxiosGet(LINE_ITEM_TYPE_ROUTE)
            return Promise.resolve(GetData(response));

        } catch (error) {
            return await catchServiceErrors(error);
        }
    }
}

export default new LineItemTypeService()