import { AxiosGet, GetData } from '@/services/axios-service'
import { catchServiceErrors } from '@/utils/services-global';

export const CUSTOM_LIST_EXCHANGE_ROUTE = '/api/list/custom_list_exchanges'

class CustomListExchangeService {
    async all() {
        try {
            const response = await AxiosGet(`${CUSTOM_LIST_EXCHANGE_ROUTE}`);
            return Promise.resolve({success: response?.data?.success ? true : false, data: GetData(response)});
        } catch (error) {
            return await catchServiceErrors(error);
        }
    }
}

export default new CustomListExchangeService()