import SecureLS from "secure-ls";

const OPTIONS = {
	encodingType: "aes",
	encryptionSecret: process.env.VUE_APP_SECURE_SECRET,
	isCompression: true,
	isEncode: true,
	encodingTypeFallback: "none",
};

const ERROR = {
	PARSE: "Could not parse JSON",
	MAL_FORMET_UTF_8: "Malformed UTF-8 data",
};

class SecureStorageService {
	private secureLS: SecureLS;

	constructor() {
		// Crea una instancia de SecureLS con las opciones configuradas
		this.secureLS = new SecureLS(OPTIONS);
	}

	getInstance() {
		return this.secureLS;
	}

	setInstance() {
		this.secureLS = new SecureLS(OPTIONS);
	}

	// Método para almacenar datos en el almacenamiento seguro
	setItem<T>(key: string, value: T): void {
		this.secureLS.set(key, value);
	}

	// Método para recuperar datos del almacenamiento seguro
	getItem(key: string): any | null {
		try {
			return this.secureLS.get(key);
		} catch (error) {
			if (error instanceof Error) {
				// Si hay un error de parse JSON o Malformed UTF-8 data se limpia los datos en el storage para volver a obtener datos validos
				if (
					[ERROR.PARSE, ERROR.MAL_FORMET_UTF_8].includes(
						error.message
					)
				) {
					this.clear();
				}
			}
			return null;
		}
	}

	// Método para eliminar datos del almacenamiento seguro
	removeItem(key: string): void {
		this.secureLS.remove(key);
	}

	clear(): void {
		this.secureLS.removeAll();
		this.secureLS.clear();
		this.setInstance();
	}
}

export default SecureStorageService;
