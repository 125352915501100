import {
	ConfigPaginate,
	ConfigPost,
	ConfigPostFilter,
	Geography,
} from "@/interfaces/persons/v10/response";
import { TotalTypeAll } from "@/interfaces/persons/v10/select_all";
import {
	PersonGeoKey,
	PersonOohKey,
	PersonPoisKey,
} from "@/interfaces/persons/v10/types";
import { GetErrors, GetMessage } from "@/services/axios-service";

export class ConfigPaginateEntity implements ConfigPaginate {
	mode: "paginated" = "paginated";
	page: number = 1;
	limit: number = 25;
	search?: string;
	filters?: any;
	country_code?: number;

	constructor(parametters?: { country_code?: number }) {
		this.country_code = parametters?.country_code;
	}
}

export class ConfigPostFilterEntity implements ConfigPostFilter {
	/**
	 * GEO
	 */
	codigo_estado?: string[];
	_like_estado?: string;

	codigo_municipio?: string[];
	_like_municipio?: string;

	codigo_ciudad?: string[];
	_like_ciudad?: string;

	codigo_barrio?: string[];
	_like_barrio?: string;

	/**
	 * POIS
	 */
	categoria?: string[];

	subcategoria?: string[];
	_like_categoria?: string;

	marca?: string[];
	_like_subcategoria?: string;

	nombre?: string[];
	_like_nombre?: string;

	/**
	 * OOH
	 */
	ooh_categoria?: string[];
	_like_ooh_categoria?: string;

	ooh_subcategoria?: string[];
	_like_ooh_subcategoria?: string;

	ooh_marca?: string[];
	_like_ooh_marca?: string;

	ooh_nombre?: string[];
	_like_ooh_nombre?: string;

	/**
	 * PRIVATES
	 */
	layer_name?: string;

	layers: number[] = [];

	country_name?: string;

	constructor() {}
}

const matchedFilterlikeData = {
	geography: {
		states: {
			filter: "codigo_estado",
			like: "_like_dpto",
		},
		cities: {
			filter: "codigo_ciudad",
			like: "_like_ciudad",
		},
		neighborhoods: {
			filter: "codigo_barrio",
			like: "_like_barrio",
		},
	},
	geo: {
		states: {
			filter: "codigo_estado",
			like: "_like_estado",
		},
		cities: {
			filter: "codigo_ciudad",
			like: "_like_ciudad",
		},
		neighborhoods: {
			filter: "codigo_barrio",
			like: "_like_barrio",
		},
	},
	pois: {
		categories: {
			filter: "categoria",
			like: "_like_categoria",
		},
		subcategories: {
			filter: "subcategoria",
			like: "_like_subcategoria",
		},
		brands: {
			filter: "marca",
			like: "_like_marca",
		},
		names: {
			filter: "nombre",
			like: "_like_nombre",
		},
	},
	privates: {
		privates: {
			filter: "privates",
			like: "layer_name",
		},
	},
	ooh: {
		ooh_categories: {
			filter: "ooh_categoria",
			like: "_like_ooh_categoria",
		},
		ooh_subcategories: {
			filter: "ooh_subcategoria",
			like: "_like_ooh_subcategoria",
		},
		ooh_brands: {
			filter: "ooh_marca",
			like: "_like_ooh_marca",
		},
		ooh_names: {
			filter: "ooh_nombre",
			like: "_like_ooh_nombre",
		},
	},
};

export class ConfigPostEntity implements ConfigPost {
	country_code?: number;
	strategy?: number;
	use_case?: number;
	filters: ConfigPostFilter = new ConfigPostFilterEntity();
	constructor(parametters?: {
		country_code?: number;
		strategy?: number;
		use_case?: number;
	}) {
		this.country_code = parametters?.country_code;
		this.strategy = parametters?.strategy;
		this.use_case = parametters?.use_case;
	}

	addLayers(layers: number[] = []) {
		this.filters.layers = layers;
	}

	addGeoFilter(
		filter: PersonGeoKey,
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.geo[filter].filter] = total.checked
			? []
			: value;
		this.filters[matchedFilterlikeData.geo[filter].like] = total.checked
			? total.term
			: undefined;
	}

	addPoisFilter(
		filter: PersonPoisKey,
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.pois[filter].filter] = total.checked
			? []
			: value;

		this.filters[matchedFilterlikeData.pois[filter].like] = total.checked
			? total.term
			: undefined;
	}

	addOohFilter(
		filter: PersonPoisKey | PersonOohKey,
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.ooh[filter].filter] = total.checked
			? []
			: value;

		this.filters[matchedFilterlikeData.ooh[filter].like] = total.checked
			? total.term
			: undefined;
	}
}

export class RejectError {
	success = false;
	message: string;
	errors: any;

	constructor(error: any) {
		this.message = GetMessage(error);
		this.errors = GetErrors(error);
	}
}
