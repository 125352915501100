
import Vue from "vue";
export default Vue.extend({
	props: {
		items: {
			type: Array,
			default: function() {
				return [];
			}
		},
		type: {
			type: String
		},
		attrName:{
			type: Boolean,
			default: false
		}
	},
	data: () => ({}),

	computed: {},

	watch: {},

	methods: {
		removeItem(item: any) {
			this.$emit("remove-item", { type: this.type, item });
		},
		getValue(item: any){
			return this.attrName ? item.name : item.value
		}
	}
});
