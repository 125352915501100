import { ScrollConfig } from "@/interfaces/scroll";

/**
 * ScrollConfigEntity provides a concrete implementation of ScrollConfig
 * with default values for scrolling behavior.
 */
export class ScrollConfigEntity implements ScrollConfig {
	// Define properties with default values and make them readonly if they should not change
	readonly top: number;
	readonly behavior: "auto" | "smooth";

	/**
	 * Constructs a new ScrollConfigEntity instance with the given options.
	 *
	 * @param top The vertical position to scroll to. Defaults to 0.
	 * @param behavior The scrolling behavior. Defaults to 'smooth'.
	 */
	constructor(top: number = 0, behavior: "auto" | "smooth" = "smooth") {
		this.top = top;
		this.behavior = behavior;
	}
}
