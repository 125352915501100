import GraphicsPoisService from "@/services/graphics-pois-service";
import { prepareGraphics } from "@/utils/persons/pois";
import { State } from "@amcharts/amcharts5/.internal/core/util/States";

const initPoisReach = {
	public: 0,
	private: 0,
	total: 0,
};

export const GraphicsPoisModule = {
	namespaced: true,
	state: () => ({
		source: {
			/**
			 * categories
			 */
			categories: [] as any[],

			/**
			 * cities
			 */
			cities: [] as any[],

			/**
			 * brands
			 */
			brands: [] as any[],

			/**
			 * names
			 */
			names: [] as any[],

			pois_reach: initPoisReach,
		},

		/**
		 * Determina si fue ejecutado el view pois en el tab de pois
		 */
		runViewPois: false
	}),
	mutations: {
		SET_ALL_SOURCE(state: any, values: Array<any> = [] as Array<any>) {
			state.source = values;
		},
		SET_SOURCE(state: any, params: { key: string; value: Array<any> }) {
			state.source[params.key] = params.value || ([] as any[]);
		},
		RESET_ALL(state: any) {
			Object.keys(state.source).forEach((s) => {
				state.source[s] = s == "pois_reach" ? initPoisReach : [];
			});
		},
		SET_RUN_VIEW(state: any, isRun: Boolean = false) {
			state.runViewPois = isRun;
		},
	},
	getters: {
		getData: (state: any) => (key: string) => {
			return state[key];
		},
		getSource(state: any): any {
			return state.source;
		},
		getPoisReach(state: any): any {
			return state.source.pois_reach;
		},
		isRunViewPois(state: any): Boolean {
			return state.runViewPois;
		}
	},
	actions: {
		setRunViewPois({ commit }: any, isRun: Boolean = false) {
			commit("SET_RUN_VIEW", isRun);
		},
		async setSource({ commit }: any, params: { key: string; value: any }) {
			return new Promise((resolve) => {
				commit("SET_SOURCE", params);
				resolve(params);
			});
		},
		async resetAll({ commit }: any) {
			return new Promise((resolve) => {
				commit("RESET_ALL");
				resolve({});
			});
		},

		async fetchGraphics({ commit }: any, params: any) {
			const result = await GraphicsPoisService.getGraphics(params);

			const sources: any = await prepareGraphics(result);

			commit("SET_ALL_SOURCE", sources);

			return sources;
		},
	},
};
