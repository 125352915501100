import { Notification } from "@/interfaces/proccess";

export const ProccessModule = {
	namespaced: true,
	state: () => ({
		loading: false, // loading page
		loading_field: false, // loading for inputs
		loading_messager: "",
		openSettings: true,
		errors: {},
		notification: {
			message: "",
			type: "",
			title: "",
			show: false,
			btn_text: "",
			to: undefined,
			btn_text_redirect: "",
			to_redirect: undefined,
			show_redirect: false,
			query: {},
			params: {},
		} as Notification,
	}),
	mutations: {
		SET_LOADING(state: any, _loading = false) {
			state.loading = _loading;
		},
		SET_LOADING_FIELD(state: any, _loading_field = false) {
			state.loading_field = _loading_field;
		},
		SET_LOADING_MESSAGER(state: any, _loading_messager = "") {
			state.loading_messager = _loading_messager;
		},
		SET_NOTIFICATION(
			state,
			_notification: Notification = {
				message: "",
				type: "",
				title: "",
				btn_text: "",
				details: {},
				show: false,
				btn_text_redirect: "",
				to_redirect: undefined,
				show_redirect: false,
				query: {},
				params: {},
			}
		) {
			state.notification = _notification;
		},
		SET_ERRORS(state: any, _errors: Object = {}) {
			state.errors = _errors;
		},
		SET_OPEN_SETTINGS(state, _openSettings: Boolean = false) {
			state.openSettings = _openSettings;
		},
	},
	getters: {
		isLoading(state) {
			return state.loading;
		},
		isOpenSettings(state: any) {
			return state.openSettings;
		},
		loading(state: any) {
			return state.loading;
		},
		notification(state: any) {
			return state.notification;
		},
		isProduction() {
			return process.env.NODE_ENV === "production";
		},
		isDevelopment() {
			return process.env.NODE_ENV === "development";
		},
	},
	actions: {
		async setOpenSettings({ commit }, openSettings: Boolean = false) {
			try {
				commit("SET_OPEN_SETTINGS", openSettings);
				return await Promise.resolve();
			} catch (error) {
				commit("SET_OPEN_SETTINGS");
				return await Promise.reject();
			}
		},
		async setLoading({ commit }, loading: Boolean) {
			try {
				commit("SET_LOADING", loading);
				return await Promise.resolve();
			} catch (error) {
				commit("SET_LOADING");
				return await Promise.reject();
			}
		},
		async setLoadingMessage({ commit }, loading_Message: String) {
			try {
				commit("SET_LOADING_MESSAGER", loading_Message);
				return await Promise.resolve();
			} catch (error) {
				commit("SET_LOADING_MESSAGER");
				return await Promise.reject();
			}
		},
		async setLoadingField({ commit }, loading: Boolean) {
			try {
				commit("SET_LOADING_FIELD", loading);
				return await Promise.resolve();
			} catch (error) {
				commit("SET_LOADING_FIELD");
				return await Promise.reject();
			}
		},
		async setNotification({ commit }, notification: Notification) {
			try {
				commit("SET_NOTIFICATION", notification);
				return await Promise.resolve();
			} catch (error) {
				commit("SET_NOTIFICATION");
				return await Promise.reject();
			}
		},
		async setErrors({ commit }, errors: Object = {}) {
			try {
				commit("SET_ERRORS", errors);
				return await Promise.resolve();
			} catch (error) {
				commit("SET_ERRORS");
				return await Promise.reject();
			}
		},
	},
};
