import { Notification, MessageTypes } from "../interfaces/proccess";
import { isNull } from "lodash";
const NOTFOUNDMESSAGE = "Object not found or not active";
class NotificationService {

    success = {
        type: MessageTypes.SUCCESS,
        title: MessageTypes.TITLE_SUCCESS,
        message: MessageTypes.SUCCESS,
        btn_text: MessageTypes.CONTINUE,
        show: true
    } as Notification;

    error = {
        type: MessageTypes.ERROR,
        title: MessageTypes.TITLE_FAILED,
        message: MessageTypes.ERROR,
        btn_text: MessageTypes.CONTINUE,
        show: true
    } as Notification;

    warning = {
        type: MessageTypes.WARNING,
        title: MessageTypes.TITLE_WARNING,
        message: MessageTypes.ERROR,
        btn_text: MessageTypes.CONTINUE,
        show: true
    } as Notification;

    info = {
        type: MessageTypes.INFO,
        title: MessageTypes.TITLE_INFO,
        message: MessageTypes.ERROR,
        btn_text: MessageTypes.CONTINUE,
        show: true
    } as Notification;

    async notifySuccess(store, notification: Partial<Notification>) {
        if(isNull(notification.message)){
            notification.message = MessageTypes.SUCCESS;
            notification = {...this.success, ...notification};
        }else{
            notification = {...this.success, ...notification};
        }
        await store.dispatch('proccess/setErrors', notification.errors, { root: true });
        await store.dispatch("proccess/setNotification", notification, { root: true });
    }

    async notifyError(store, notification: Partial<Notification>) {
        notification = {...this.error, ...notification}
        if(notification.details) {
            await store.dispatch('proccess/setErrors', notification.details, { root: true })
        }
        await store.dispatch("proccess/setNotification", notification, { root: true });
    }

    async notifyWarning(store, notification : Partial<Notification>) {
        notification = {...this.warning, ...notification}
        if(notification.details) {
            await store.dispatch('proccess/setErrors', notification.details, { root: true })
        }
        await store.dispatch("proccess/setNotification", notification, { root: true });
    }

    async notifyInfo(store, notification : Partial<Notification>) {
        notification = {...this.info, ...notification}
        if(notification.details) {
            await store.dispatch('proccess/setErrors', notification.details, { root: true })
        }
        await store.dispatch("proccess/setNotification", notification, { root: true });
    }

    /**
     * Success
     * @param params // { customMessage, to }
     * @returns
     */
    async SuccessNotification(params: { to: string; title?: string; btn_text?: string; }) {
        return {
            title: params.title,
            message: MessageTypes.SUCCESS,
            type: MessageTypes.SUCCESS,
            to: params.to,
            btn_text: params.btn_text,
            show: true
        } as Notification
    }

    /**
     * Default Notification
     * @param params
     * @returns
     */
    async CreateNotification(params: Notification) {
        return {
            title: params.title,
            message: params.message === "Unauthorized" ? MessageTypes.UNAUTHORIZED : params.message,
            type: params.type,
            to: params.to,
            btn_text: params.btn_text,
            show: true
        } as Notification
    }

    async setCustomNotification(store: any, notification: Notification) {
        return await store.dispatch("proccess/setNotification", notification, { root: true });
    }
}

export function getMessage(customMessage: string) {
    if (!customMessage) return MessageTypes.FAILED;
    return customMessage === NOTFOUNDMESSAGE ? MessageTypes.NOTFOUND : customMessage
}

export default new NotificationService()
