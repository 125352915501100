import { TabData, ViewEntity } from "@/interfaces/custom_view"
import FieldColumnData from "./ColumnData";

export default class SelectedEntity implements ViewEntity {

	dimensions: TabData = {
		all: false,
		data: []
	};
	metrics: TabData = {
		all: false,
		data: []
	}
	columns: Array<FieldColumnData> = [];

	constructor() {}
}
