import {
	ReportResourceData,
	ReportResourceDataAll,
	ReportResourceDataHidden,
	ReportResourceDataLoading,
	ReportResourceDataTerm,
	ResponseJob,
} from "@/interfaces/report";
import { TableItem, TableReport } from "@/interfaces/reports/v2/report";

export class ReportResourceDataAllEntity implements ReportResourceDataAll {
	accounts: any[] = [];
	advertisers: any[] = [];
	line_items: any[] = [];
	campaigns: any[] = [];
	creatives: any[] = [];
	account: any[] = [];
	deal_id: any[] = [];
}

export class ReportResourceDataHiddenEntity
	implements ReportResourceDataHidden
{
	date: boolean = false;
	format: boolean = false;
	run: boolean = false;
	scheduler: boolean = false;
}

export class ReportResourceDataLoadingEntity
	implements ReportResourceDataLoading
{
	account: boolean = false;
	advertisers: boolean = false;
	campaigns: boolean = false;
	line_items: boolean = false;
	creatives: boolean = false;
	deal_id: boolean = false;
	filters: boolean = false;
	report_type: boolean = false;
	dimensions: boolean = false;
	metrics: boolean = false;
}

export class ReportResourceDataTermEntity implements ReportResourceDataTerm {
	advertiser: string = "";
	campaign: string = "";
	line_item: string = "";
	creative: string = "";
	account: string = "";
	deal_id: string = "";
}

/**
 * MAIN
 */
export class ReportResourceDataEntity implements ReportResourceData {
	selected_all: ReportResourceDataAllEntity =
		new ReportResourceDataAllEntity();
	hidden_fields: ReportResourceDataHiddenEntity =
		new ReportResourceDataHiddenEntity();
	loading: ReportResourceDataLoadingEntity =
		new ReportResourceDataLoadingEntity();
	term: ReportResourceDataTermEntity = new ReportResourceDataTermEntity();
}

export class ResponseJobEntity implements ResponseJob {
	no_data: boolean = false;
	report_job_id?: number | undefined;

	constructor(payload?: ResponseJob) {
		if(payload) {
			this.setData(payload);
		}
	}

	setData(payload: ResponseJob) {
		Object.assign(this, payload);
	}

	hasReportJobId() {
		return Boolean(this.report_job_id);
	}

	getReportJobId() {
		return this.report_job_id;
	}
}

/**
 * Table
 */

export class TableReportEntity implements TableReport {
	items: TableItem[] = [];

	constructor(items?: TableItem[]) {
		this.setItems(items);
	}

	setItems(items?: TableItem[]) {
		this.items = items || [];
	}
}
