import { LoadingData } from "@/interfaces/loading";
import { isEmpty } from "lodash";

export default class LoadingEntity implements LoadingData {
	loading: Boolean = false;
	type: string = "";

	constructor(parametters?: { type: string }) {
		this.type = parametters?.type ?? "";
		this.loading = !isEmpty(this.type);
	}

	public set Type(v: string) {
		this.type = v;
		this.loading = !isEmpty(v);
	}

	setLoading(v: string = "") {
		this.type = v;
		this.loading = !isEmpty(this.type);
	}
}
