import {
	InputConfig,
	Schedule,
	ScheduleConfig,
	ScheduleCreate,
	ScheduleForm,
	ScheduleResource,
	ScheduleUpdate,
} from "@/interfaces/reports/v2/scheduler";
import { ValidationRules } from "@/models/Rules/Builder";
import { validationRules } from "./validate";
import { isEmail } from "@/services/rule-services";
import {
	DataItem,
	EnumReportType,
	FiltersEntity,
	ReportDataEntity,
} from "@/interfaces/report";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
import { converJsonToFilters } from "./utils";
import { GenerateReportTypeEnum } from "@/interfaces/reports/v2/report";

export class ScheduleFormEntity implements ScheduleForm {
	id: number | null = null;
	report_type: string = GenerateReportTypeEnum.CAMPAIGN_REPORT_SP;
	type: string = EnumReportType.CSV;
	data_range: string = "7";
	start_date: string = "";
	end_date: string = "";
	dimensions: string[] = [];
	metrics: string[] = [];
	filters: Record<string, (number | string)[]> = {};
	file_type: string = EnumReportType.CSV;

	scheduler_type: string = "";
	email_subject: string = "";
	emails: string[] = [];
	email_to: string = "";
	schedule_end_date: string = "";
	schedule_start_date: string = "";
	include_account_email: boolean = false;

	job_id?: number;

	constructor(schedule?: any) {
		if (schedule) {
			this.setSchedule(schedule);
		} else {
			this.resetForm();
		}
	}

	isSelected(type: EnumReportType.CSV | EnumReportType.XLS) {
		return this.file_type === type;
	}

	resetForm() {
		this.id = null;
		this.report_type = GenerateReportTypeEnum.CAMPAIGN_REPORT_SP;
		this.type = EnumReportType.CSV;
		this.data_range = "7";
		this.start_date = "";
		this.end_date = "";
		this.dimensions = [];
		this.metrics = [];
		this.filters = {};
		this.file_type = EnumReportType.CSV;

		this.scheduler_type = "";
		this.email_subject = "";
		this.emails = [];
		this.schedule_end_date = "";
		this.schedule_start_date = "";
		this.include_account_email = false;
		this.job_id = undefined;
	}

	mergeToReport(form?: Partial<ReportDataEntity>) {
		if (form) {
			this.report_type =
				form.report_type || GenerateReportTypeEnum.CAMPAIGN_REPORT_SP;
			this.type = form.type || EnumReportType.CSV;
			this.file_type = form.type || EnumReportType.CSV;
			this.data_range = form.data_range || "7";
			this.start_date = form.start_date || "";
			this.end_date = form.end_date || "";
			this.dimensions = this.prepareKeys(form.dimensions);
			this.metrics = this.prepareKeys(form.metrics);
			this.filters = this.prepareFilters(form.filters);

			//this.debugLog();
		}
		return this;
	}

	async setSchedule(schedule: Partial<any>) {
		this.id = schedule?.id;
		this.scheduler_type = schedule?.scheduler_type || "";
		this.email_subject = schedule?.email_subject || "";
		this.emails = schedule?.emails || [];
		this.email_to = schedule?.email_to || "";
		this.schedule_end_date = schedule?.schedule_end_date || "";
		this.schedule_start_date = schedule?.schedule_start_date || "";
		this.include_account_email = Boolean(schedule?.include_account_email);
		if (Object.keys(schedule?.filters).length) {
			this.filters = await converJsonToFilters(schedule?.filters);
		}
		this.job_id = undefined;
	}

	async setJobId(report_job_id?: number) {
		this.job_id = report_job_id;
	}

	async setFiltersKeys(prepared: Record<string, (number | string)[]>) {
		this.filters = prepared;
	}

	prepareFilters(
		filters?: FiltersEntity
	): Record<string, (number | string)[]> {
		if (typeof filters?.convertToKeys !== "function") return {};
		return filters.convertToKeys();
	}

	prepareKeys(value?: { [key: string]: number }) {
		if (!value) return [];
		return Object.keys(value);
	}

	appendEmail(_email: string) {
		const email = _email.trim();

		const isValid = isEmail(email);

		if (typeof isValid !== "boolean") return;
		/**
		 * Validacion: isEmail
		 */
		if (!isEmail(email)) return;

		/**
		 * Verifica si ya esta agregado el :email
		 */
		if (this.hasEmail(email)) return;

		/**
		 * Agrega el email
		 */
		this.emails.push(email);

		/**
		 * Reset de email_to
		 */
		this.resetEmailTo();
	}

	removeEmail(_email: string) {
		const email = _email.trim();
		this.emails = this.emails.filter((e) => e !== email);
	}

	/**
	 * Validar email
	 * Con parametros: valida si el email ya esta agregado
	 * Sin parametro: valida si existe al menos un email
	 * @param email
	 * @returns
	 */
	hasEmail(email?: string) {
		if (!email) return !isEmpty(this.emails);
		return this.emails.some((e) => e === email);
	}

	resetEmailTo() {
		this.email_to = "";
	}

	/**
	 * Prepara payload para create schedule
	 * @returns
	 */
	getPayloadCreate(): ScheduleCreate {
		return {
			report_type: this.report_type,
			type: this.file_type,
			data_range: this.data_range,
			start_date: this.formatDate(this.start_date),
			end_date: this.formatDate(this.end_date),
			dimensions: this.dimensions,
			metrics: this.metrics,
			filters: this.filters,
			scheduler_type: this.scheduler_type,
			email_subject: this.email_subject,
			emails: this.emails,
			schedule_start_date: this.formatDate(this.schedule_start_date),
			schedule_end_date: this.formatDate(this.schedule_end_date),
			include_account_email: this.include_account_email,
			job_id: this.isReportType(GenerateReportTypeEnum.CAMPAIGN_REPORT_SP)
				? this.job_id
				: undefined,
		};
	}

	getPayloadUpdate(): ScheduleUpdate {
		return {
			id: Number(this.id),
			report_type: this.report_type,
			type: this.file_type,
			data_range: this.data_range,
			start_date: this.formatDate(this.start_date),
			end_date: this.formatDate(this.end_date),
			dimensions: this.dimensions,
			metrics: this.metrics,
			filters: this.filters,
			scheduler_type: this.scheduler_type,
			email_subject: this.email_subject,
			emails: this.emails,
			schedule_start_date: this.formatDate(this.schedule_start_date),
			schedule_end_date: this.formatDate(this.schedule_end_date),
			include_account_email: this.include_account_email,
		};
	}

	formatDate(date: string) {
		if (!date) return date;
		return moment(date).format(DEFAULT_DATE_TIME_FORMAT);
	}

	hasID() {
		return this.id !== null && this.id > 0;
	}

	isReportType(type: GenerateReportTypeEnum) {
		return this.report_type === type;
	}

	debugLog() {
		console.debug("(DEBUG) ", { payloadCreate: this.getPayloadCreate() });
	}
}

export class ScheduleResourceEntity implements ScheduleResource {
	scheduler_type: DataItem[] = [];
	file_type: DataItem[] = [];

	constructor() {}

	/**
	 * Sets a resource value for a given key.
	 * @param key - The key of the resource to set.
	 * @param value - The value to set for the resource, matching the type of the key.
	 */
	async setResource<K extends keyof ScheduleResource>(
		key: K,
		value: ScheduleResource[K]
	) {
		this[key] = value;
	}

	matcheResource<K extends keyof ScheduleResource>(key: K) {
		return {
			scheduler_type: "report/getSchedulerTypes",
			file_type: "report/",
		}[key];
	}
}

export class ScheduleConfigEntity implements ScheduleConfig {
	report_type: InputConfigEntity = new InputConfigEntity();
	data_range: InputConfigEntity = new InputConfigEntity();
	start_date: InputConfigEntity = new InputConfigEntity();
	end_date: InputConfigEntity = new InputConfigEntity();
	dimensions: InputConfigEntity = new InputConfigEntity();
	metrics: InputConfigEntity = new InputConfigEntity();
	filters: InputConfigEntity = new InputConfigEntity();

	//**Scheduler */
	id: InputConfigEntity = new InputConfigEntity({
		item_value: "id",
		hint: "report.scheduler.fields.id",
		reference: "id",
		placeholder: "report.scheduler.fields.id",
		label: "report.scheduler.fields.id",
		required: true,
	});
	scheduler_type: InputConfigEntity = new InputConfigEntity({
		item_text: "value",
		item_value: "id",
		hint: "report.scheduler.fields.scheduler_type",
		reference: "scheduler_type",
		placeholder: "report.scheduler.fields.scheduler_type_selected",
		label: "report.scheduler.fields.scheduler_type",
		required: true,
	});
	file_type: InputConfigEntity = new InputConfigEntity({
		item_text: "value",
		item_value: "id",
		hint: "report.scheduler.fields.format",
		reference: "scheduler_type",
		placeholder: "report.scheduler.fields.format_selected",
		label: "report.scheduler.fields.format",
		required: true,
	});
	email_subject: InputConfigEntity = new InputConfigEntity({
		hint: "report.scheduler.fields.subject",
		reference: "email_subject",
		placeholder: "report.scheduler.fields.subject",
		label: "report.scheduler.fields.subject",
		required: true,
	});
	emails: InputConfigEntity = new InputConfigEntity();
	email_to: InputConfigEntity = new InputConfigEntity({
		hint: "report.scheduler.fields.to_hint",
		reference: "email_to",
		placeholder: "report.scheduler.fields.to_placeholder",
		label: "report.scheduler.fields.to",
		required: true,
	});
	schedule_start_date: InputConfigEntity = new InputConfigEntity({
		item_value: "id",
		hint: "report.scheduler.fields.start_date",
		reference: "start_date",
		placeholder: "report.scheduler.fields.start_date",
		label: "report.scheduler.fields.start_date",
		required: true,
	});
	schedule_end_date: InputConfigEntity = new InputConfigEntity({
		item_value: "id",
		hint: "report.scheduler.fields.end_date",
		reference: "end_date",
		placeholder: "report.scheduler.fields.end_date",
		label: "report.scheduler.fields.end_date",
		required: true,
	});
	include_account_email: InputConfigEntity = new InputConfigEntity({
		item_value: "include",
		hint: "report.scheduler.fields.include",
		reference: "include",
		placeholder: "report.scheduler.fields.include",
		label: "report.scheduler.fields.include",
		required: false,
	});
}

export class InputConfigEntity implements InputConfig {
	item_text: string;
	item_value: string;
	hint: string;
	reference: string;
	placeholder: string;
	label: string;
	required: boolean;

	constructor(config?: Partial<InputConfig>) {
		this.item_text = config?.item_text || "";
		this.item_value = config?.item_value || "";
		this.hint = config?.hint || "";
		this.reference = config?.reference || "";
		this.placeholder = config?.placeholder || "";
		this.label = config?.label || "";
		this.required = Boolean(config?.required);
	}
}

/**
 * Main class
 */
export class ScheduleEntity implements Schedule {
	form: ScheduleFormEntity = new ScheduleFormEntity();
	resource: ScheduleResourceEntity = new ScheduleResourceEntity();
	rules: ValidationRules = validationRules();
	config: ScheduleConfigEntity = new ScheduleConfigEntity();

	constructor() {}

	resetForm() {
		this.form = new ScheduleFormEntity();
		this.rules = validationRules();
	}
}
