import { Field, ViewField } from "@/interfaces/custom_view"

export default class FieldEntity implements ViewField {

	default: string[] = [];
	route_name: string = "";
	hold: string[] = [];
	dimensions: Field[] = [];
	metrics: Field[] = [];

	constructor() {}
}
