import { decode } from "jsonwebtoken";

export function hasBearer(token: string): Boolean {
	return !!token && token.startsWith("Bearer ");
}

export function DecodeToken(token: string) {
	return decode(ParsedToken(token));
}

export function ParsedToken(token: string): string {
	return token.replace("Bearer ", "");
}

export function isValidToken(token: string) {
	return hasBearer(token) && !IsTokenExpired(token);
}

export const IsTokenExpired = (token: string): boolean => {
	try {
		const decoded = DecodeToken(token);

		if (!decoded) return true;

		const { exp } = decoded as { exp: number };

		if (!exp) return true;

		const expired = Date.now() >= exp * 1000;

		return expired;
	} catch (error) {
		console.error("JwtService:IsTokenExpired", { error: error });
		return true;
	}
};
