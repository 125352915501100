import BulkService from "@/services/bulk-service";
import { catchError } from "@/store/Util";
import { BulkCampaign, BulkLine } from "@/interfaces/bulk";
import { GetData } from "@/services/axios-service";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";

const creativeRulesKeysPermit: string[] = ["dfa", "sizmek", "flash_talking"];
const creativeRulesKeyWordsPermit: string[] = ["dfa", "sizmek", "flashtalking"];

export const BulkModule = {
	namespaced: true,
	state: () => ({}),
	mutations: {},
	getters: {},
	actions: {
		/**
		 * Fetch Resource
		 * @param params
		 * @returns
		 */
		async getResource(
			{ commit }: any,
			params: {
				key: string;
			}
		) {
			try {
				const response = await BulkService.getResource(params.key);
				let result: any[] = GetData(response);

				if (params.key == "creative_rule_id") {
					result = result.filter(
						(r: { key: string; description: string }) => {
							return creativeRulesKeyWordsPermit.some(
								(keyWord: string) => {
									const lowerDescription: string = String(
										r.description
									).toLowerCase();

									return (
										creativeRulesKeysPermit.includes(
											r.key
										) && lowerDescription.includes(keyWord)
									);
								}
							);
						}
					);
				}

				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * Active Bulk
		 * @param params
		 * @returns
		 */
		async activeBulk(
			{ commit }: any,
			params: {
				entity: string;
				data: BulkLine | BulkCampaign;
				active: Boolean;
			}
		) {
			try {
				const response = await BulkService.activeBulk(
					params.entity,
					params.data,
					params.active
				);
				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
