
import Vue from "vue";

export default Vue.extend({
	name: "NotPermission",
	props:{
		subtitle: {
			type: String,
			default: "User does not have the right permissions."
		},
	},
	data: () => ({}),
	created() {},
	computed: {
		getSubtitle(){
			return this.subtitle
		}
	},
	methods: {
		handleBack() {
			this.$router.push({ name: "Root" });
		},
	},
});
