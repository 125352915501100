
import Vue from "vue";
//@ts-ignore
import { Status } from "@/interfaces/state";
//@ts-ignore
import { getMatchedName } from "@/utils/report";

import { mapGetters } from "vuex";

const SUBJECT_MODIFIERS = "Modifier";

export default Vue.extend({
	name: "ActionsTable",
	props: {},
	components: {},

	data: () => ({}),

	created() {},
	mounted() {},

	computed: {
		...mapGetters("profile", ["getAbility"]),

		checkStatus() {
			return this.params.checkStatus;
		},
		
		isDisabledElegible() {
			return !this.params.data.elegible;
		},

		isCheckDisableEdit() {
			return this.params.checkDisableEdit;
		},

		ischeckDisableEditPrivate() {
			return this.params.checkDisableEditPrivate;
		},

		disabledDownload() {
			if (this.checkStatus) {
				return this.params.data.status !== Status.READY;
			}
			return false;
		},

		disabledEdit() {
			if (!this.isCheckDisableEdit) return false;

			if (this.isArchived) return true;
			
			// verificar status_name para listado de private pois
			// habilitar solo si el status_name = processed | failed
			if (this.ischeckDisableEditPrivate) {
				return ![Status.PROCESSED, Status.FAILED].includes(
					this.params.data.status_name.toLowerCase()
				);
			}

			return [Status.FAIL, Status.PENDING].includes(
				this.params.data.status
			);
		},

		showEditElegible() {
			return this.params.elegible;
		},

		getArchived() {
			return this.params.archived;
		},

		isArchived(): Boolean {
			return this.params.data.archived;
		},

		getRedirect() {
			return this.params.redirect;
		},
		
        getEntityName(){
            return this.params.entityName;
        },

		getSendEmail(){
            return this.params.sendEmail;
        },

        getTagEdit(){
            return "Edit " + this.getEntityName;
        },

		getID() {
			return this.params.data.id;
		},

		getName() {
			return this.params.data.name;
		},

		getExternalID() {
			return this.params.data.external_id;
		},
        getTagDownload(){
            return "Download";
        },

		getTagSendEmail(){
            return "Send Email";
        },

        getTagList(){
            return "View Lines";
        },
        
        getPermissions(){
            return this.params.permission;
        },

		getToActivityLog() {
			return this.params.activityLog;
		},

        showEdit(){
            return this.params.edit;
        },

		showSendEmail(){
            return this.params.sendEmail;
        },

		getElegibleText() {
			return this.translateActionText("events.elegible_objects.label");
		},

		getTagDuplicated() {
			return this.translateActionText("table.actions.duplicate");
		},

		getTagLog() {
			return this.translateActionText("table.actions.activityLog");
		},
        showDownload(){
            return this.params.download;
        },

        showList(){
            return this.params.list;
        },

        canEdit(){
            const modifiers = "Modifier";
			const report = "Report";
            if(this.getPermissions.subject == modifiers){
                let response = false;
                switch(this.params.data.type_description){
                    case "Bid Modifier":
                        response = this.getAbility.can(this.getPermissions.subComponent.bid_modifier
											.update_bid, this.getPermissions.subject);
                        break;
                    case "Delivery Modifier":
                        response = this.getAbility.can(this.getPermissions.subComponent.delivery_modifier
										.update_delivery, this.getPermissions.subject);
                        break;
                }
                return response;
            }
			if(this.getPermissions.subject == report){
				return this.getAbility.can(this.getPermissions.actions.update_scheduler, this.getPermissions.subject);
			}
            return this.getAbility.can(this.getPermissions.actions.update, this.getPermissions.subject);
        },

        canAudit(){
			return this.getAbility.can(this.getPermissions.actions.audit, this.getPermissions.subject);
        },

        canDelete(){
			const report = "Report";
			if(this.getPermissions.subject == report){
				return this.getAbility.can(this.getPermissions.actions.delete_schedule, this.getPermissions.subject);
			}
			return this.getAbility.can(this.getPermissions.actions.delete, this.getPermissions.subject);
        },

        canClone(){
			return this.getAbility.can(this.getPermissions.actions.clone, this.getPermissions.subject);
        },

        canView(){
			return this.getAbility.can(this.getPermissions.actions.index, this.getPermissions.subject);
        },

        canSendEmail(){
			return this.getAbility.can(this.getPermissions.actions.send_mail, this.getPermissions.subject);
        },

        canConvertToPrivatePois(){
            return this.getAbility.can(this.getPermissions.complement?.actions?.index, this.getPermissions.complement?.subject);
        },

        canViewLineItems(){
            return this.getAbility.can(this.getPermissions.complement?.actions?.index, this.getPermissions.complement?.subject);
        },

		getTagDelete() {
			return this.translateActionText("table.actions.delete");
		},

		showConvertLatLong() {
			return (
				this.params.convertLatLong &&
				this.params.contextT.componentParent.shouldShowConvertLatLong(
					this.getID
				)
			);
		},

		isEditReport() {
			return this.params.editReport;
		},

		showDelete() {
			return this.params.delete;
		},
		showDeleteActions() {
			return this.params.deleteAction;
		},

		showDuplicated() {
			return this.params.duplicated;
		},

		showActivityLog() {
			return this.params.log;
		}
	},

	methods: {
		translateActionText(actionText: string) {
			const text =
				getMatchedName(this.params.entityName) ||
				this.params.entityName;
			const transl = this.$t(text);
			return this.$t(actionText, { text: transl });
		},
		redirectToEdit(event: PointerEvent) {
			// Detect if the click is with ctrl, to redirect on new tab
			const newWindow = event.ctrlKey;
			if (this.isEditReport) {
				this.invokeEditReportMethod();
				return;
			}
			this.redirectToNewTab(
				{
					name: this.getRedirect,
					params: { id: this.getID },
				},
				newWindow
			);
		},

		redirectToActivityLog(event: PointerEvent) {
			// Detect if the click is with ctrl, to redirect on new tab
			const newWindow = event.ctrlKey;
			this.redirectToNewTab(
				{
					name: this.getToActivityLog,
					query: { id: this.getID },
				},
				newWindow
			);
		},
		redirectToEditElegible() {
			this.redirectToNewTab({
				name: "EventEdit",
				params: { id: this.getID },
				query: { tab: 1 },
			});
		},

		redirectToLines(event: PointerEvent) {
			// Detect if the click is with ctrl, to redirect on new tab
			const newWindow = event.ctrlKey;
			this.redirectToNewTab(
				{
					name: "LineItemList",
					query: { campaign_id: this.getID },
				},
				newWindow
			);
		},

		/**
		 * Redirect in a new tab just when is Ctrl + Click
		 * @param params Route params
		 * @param newTab If its needed to open in new tab
		 */
		redirectToNewTab(params: any, newTab: boolean = true) {
			if (newTab) {
				const routeData = this.$router.resolve(params);
				window.open(routeData.href, "_blank");
			} else {
				this.$router.push(params);
			}
		},

		invokeParentDuplicatedMethod() {
			let param = {
				id: this.getID,
				external_id: this.getExternalID,
			};
			this.params.contextT.componentParent.openDialogDuplicated(param);
		},

		invokeEditReportMethod() {
			this.params.contextT.componentParent.EditReportMethod(
				this.params.data
			);
		},

		invokeParentSendEmail() {
			let param = {
				id: this.getID,
			};
			this.params.contextT.componentParent.sendEmailCPI(param);
		},
		invokeParentDownloadMethod() {
			let param = {
				id: this.getID,
			};
			this.params.contextT.componentParent.downloadReport(param);
		},

		invokeConvertToPrivatePois() {
			let param = {
				id: this.getID,
			};
			this.params.contextT.componentParent.openDialogPrivatePois(param);
		},

		invokeParentDelete() {
			let param = {
				id: this.getID,
			};
			this.params.contextT.componentParent.deleteReportByID(param);
		},

		invokeMethodDelete() {
			let param = {
				id: this.getID,
				name: this.getName,
				ext_id: this.getExternalID,
			};
			this.params.contextT.componentParent.deleteByID(param);
		},

		handleAction(type: any) {
			this.$emit("action", { type: type });
		},
	},

	watch: {},
});
