import {
	Modifier,
	ModifierDataCreate,
	ModifierList,
} from "@/interfaces/modifier";
import { ResultPaginate } from "@/interfaces/paginated";
import ModifierService from "@/services/modifier-service";
import { catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { resolveList, resolveListParams } from "@/utils/resolveObjectArray";

export const ModifierModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		modifiers: [] as Modifier[],
		modifier: {} as Modifier,
		modifiers_list: [] as Modifier[],
		modifier_types_list: [] as any[],
		modifier_modules_list: [] as any[],
		delivery_modifier_list: [] as any[],
	}),
	mutations: {
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		},
		SET_MODIFIER(state, _modifier: Modifier = {} as Modifier) {
			state.modifier = _modifier;
		},
		SET_MODIFIERS(state, _modifiers: Modifier[] = [] as Modifier[]) {
			state.modifiers = _modifiers;
		},
		SET_MODIFIERS_LIST(
			state,
			_modifiers_list: ModifierList[] = [] as ModifierList[]
		) {
			state.modifiers_list = _modifiers_list;
		},
		SET_MODIFIER_TYPES_LIST(state, _modifier_types_list: any[] = [] as any[]) {
			state.modifier_types_list = _modifier_types_list;
		},
		SET_MODIFIER_MODULES_LIST(
			state,
			_modifier_modules_list: any[] = [] as any[]
		) {
			state.modifier_modules_list = _modifier_modules_list;
		},
		SET_DELIVERY_MODIFIER_LIST(
			state,
			_delivery_modifier_list:ModifierList[] = [] as ModifierList[]
		) {
			state.delivery_modifier_list = _delivery_modifier_list;
		},
	},
	getters: {
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
	},
	actions: {
		async paginated({ commit }, params) {
			try {
				const response = await ModifierService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async createModifier(
			{ commit },
			params: {
				modifier: ModifierDataCreate;
				isBidModifier: Boolean;
				isDeliveryModifier: Boolean
			}
		) {
			try {
				const response = await ModifierService.create(
					params.modifier,
					params.isBidModifier,
					params.isDeliveryModifier
				);
				commit("SET_MODIFIER", response);
				/* notificationService.notifySuccess(this, {
							to: "ModifiersIndex"
						} as Notification); */
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async showModifier({ commit }, id: number) {
			try {
				const response = await ModifierService.show(id);
				commit("SET_MODIFIER", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "ModifiersIndex" });
				return await Promise.reject(error);
			}
		},

		async updateModifier(
			{ commit },
			params: {
				modifier: ModifierDataCreate;
				isBidModifier: Boolean;
				isDeliveryModifier: Boolean;
			}
		) {
			try {
				const response = await ModifierService.update(
					params.modifier,
					params.isBidModifier,
					params.isDeliveryModifier
				);
				commit("SET_MODIFIER", response);
				notificationService.notifySuccess(this, {
					to: "ModifiersIndex",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "ModifiersIndex" });
				return await Promise.reject(error);
			}
		},

		async list({ commit }, params) {
			try {
				const response = await ModifierService.list(params);
				commit("SET_MODIFIERS_LIST", resolveList(response));
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async listDelivery({ commit }, params) {
			try {
				const response = await ModifierService.listDeliveryModifier(params);
				return await Promise.resolve(response.data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async download({}, params) {
			try {
				await ModifierService.download(params);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getModifierTypes({ commit }, params) {
			try {
				const response = await ModifierService.typeList(params);
				commit("SET_MODIFIER_TYPES_LIST", resolveList(response));
				return await Promise.resolve(resolveList(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getModulesList({ commit }, params) {
			try {
				const response = await ModifierService.moduleList(params);
				const items = response.map(item => ({
					id: item.id,
					value: item.description,
					extra: item.extra
				}))

				commit(
					"SET_MODIFIER_MODULES_LIST",
					items
				);

				return await Promise.resolve(items);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getModifierData({ commit }, id: number) {
			try {
				const response = await ModifierService.show(id);
				commit("SET_MODIFIER", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getModifiersOptions({ commit }) {
			try {
				const response = await ModifierService.moduleOption();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getDeliveryModifiersType({ }) {
			try {
				const response = await ModifierService.deliveryModifiersType();
				return await Promise.resolve(resolveListParams(response, "id", "value", true));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
