
import Vue from "vue";
//@ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
import { mapGetters } from "vuex";

const STATUS_PENDING = "pending";
const STATUS_FAIL = "fail";

const REPORT_SCHEDULER_LIST = "Scheduler_Report_List";
const LIST = ["List"];

export default Vue.extend({
	name: "ActiveFieldTable",
	props: {},
	components: {
        CardSwitch
    },
	data: () => ({
        status: false,
    }),
	created() {},
	mounted() {
        this.load();
    },
	computed: {
        ...mapGetters("profile", ["getAbility"]),

		getFieldFilter(){
            return this.params.colDef.field;
        },

		getFieldName(){
            return this.params.colDef.headerName;
        },

        getEntity(){
            return this.params.entityName;
        },

        isCustomReport(){
            return this.params?.entityName == REPORT_SCHEDULER_LIST;
        },

        isEventActive(){
            if(this.isCustomReport && (this.isStatusPending || this.isStatusFail)){
                return false;
            }
            return this.params.eventActive;
        },

        isFlagText(){
            return this.params.flagText;
        },

        isReadOnly(){
            return this.params.readonly;
        },

        isString(){
            return typeof this.params.data.status;
        },

        isStatusPending(){
            return this.params.data.status == STATUS_PENDING;
        },

        isStatusFail(){
            return this.params.data.status == STATUS_FAIL;
        },

        getPermissions(){
            return this.params.permission;
        },

        canActive(){
            if(this.isCustomReport){
                return !this.getAbility.can(this.getPermissions.actions.active_scheduler, this.getPermissions.subject);
            }
			return !this.getAbility.can(this.getPermissions.actions.active, this.getPermissions.subject);
        },
	},
	methods: {

        load(){
            let option = String(this.params.field).toUpperCase();
            switch(option){
                case "ACTIVE":
                    if(this.isCustomReport){
                        if(this.isString == "string"){
                            this.status = this.params.data?.status == "active" ? true : false;
                        }else{
                            this.status = this.params.data?.status || this.params.data?.active;
                        }
                    }else{
                        this.status = this.params.data?.active;
                    }
                    break;
                case "APPNEXUS":
                    this.status = this.params.data.appnexus_submit;
                    break;
                case "STATUS":
                    if(this.isFlagText){
                        this.status = this.params.data.status == "active" ? true : false;
                    }else if(this.isString == "string"){
                        this.status = this.params.data.status == "active" ? true : false;
                    }else{
                        this.status = this.params.data.status;
                    }
                    break;
                default:
                    this.status = false;
            }
        },

		getColor(active: Boolean) {
            if(this.isCustomReport && this.isStatusPending){
                return "font-weight-regular";
            }
            if(this.isCustomReport && this.isStatusFail){
                return "text-bold red--text";
            }
			return active ? "text-bold green--text" : "text-bold red--text";
		},

		getActiveText(active: Boolean) {
            if(this.isCustomReport && this.isStatusPending){
                return this.$t("pending");
            }
            if(this.isCustomReport && this.isStatusFail){
                return this.$t("fails");
            }
			return active ? this.$t("common.fields.active") : this.$t("common.fields.inactive");
		},

        invokeParentActiveMethod() {    
            if(this.isReadOnly) return false;
            let param = {
                id: this.params.data.id,
                active: this.status,
                rowIndex: this.params.rowIndex
            }
            this.params.contextT.componentParent.handleActive(param);
        },
	},
	watch: {
	},
});
