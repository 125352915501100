import { SortingOption } from "./paginated";
import { Filters } from "./person";

export enum FilterMode {
	ALL = "all",
	FILTERED = "filtered",
}

export enum Status {
	PENDING = "pending",
	PROCESSING = "processing",
	FAILED = "failed",
}

export enum SortOption {
	UNIQ = "uniques",
	NAME = "name",
}

export enum OrderOption {
	ASC = "asc",
	DESC = "desc",
}

export enum OmitSocioEc {
	BRASIL = 76,
	COLOMBIA = 170,
}

export interface ResourceParam {
	key: string;
	filters: Filters;
}

export interface SourceParam {
	key: string;
	isFiltered: Boolean;
}

export interface SourceDataFilter {
	key: string;
	mode: string;
}

export interface FilterPois {
	city_poi: string[];
	neighborhood_poi: string[];
}

export interface NameUnique {
	name: string;
	uniques: number;
}

export interface FilterOption {
	total_users: NameUnique[];
	user_type: NameUnique[];
	max_days?: number;
	max_distance?: number;
	day_options?: any;
}

/**
 * Generate audience
 * Begin
 */

export interface FilterAudiencePois {
	city_poi: string[];
	neighborhood_poi: string[];
	dpto: string[];
	category: string[];
	subcategory: string[];
	marca: string[];
	nombre_poi: string[];
}

export interface FilterAudienceDemo {
	gender: string[];
	age: string[];
}

export interface FilterAudienceDigitalBehaviour {
	iab: string[];
	interest: string[];
	app_name: string[];
	domain: string[];
	city_seen: string[];
	user_type: string[];
}

export interface FilterAudienceDevice {
	platform_browser: string[];
	platform_device_language: string[];
	carrier: string[];
	platform_device_make: string[];
	platform_device_type: string[];
	platform_os: string[];
}

export interface FilterAudienceHome {
	nivsocio: string[] | undefined;
	nivsoc_brasil: string[] | undefined;
	city_residence: string[];
	neighborhood_residence: string[];
}

export interface FilterAudience {
	start_date: string;
	end_date: string;
	radio: number;
	frecuency: any;
	pois: FilterAudiencePois;
	demo: FilterAudienceDemo;
	digital_behaviour: FilterAudienceDigitalBehaviour;
	device: FilterAudienceDevice;
	home: FilterAudienceHome;
}

export interface PanelFilter {
	radio: number;
	intervalTime: any[];
	intervalFecha: IntervalDate
}

export interface RadioConfig {
	min: number;
	max: number;
}

export interface IntervalDate {
	start: string;
	end: string;
}

/**
 * Generate audience
 * End
 */

export interface AccountColor {
	header_color_1: string;
	header_color_2: string;
	top_menu_color_1: string;
	top_menu_color_2: string;
	buttons_color: string;
	links_color: string;
	icons_color: string;
	titles_color: string;
	account_name_color: string;
	menu_item_color: string;
	menu_item_hover_color: string;
	user_name_color: string;
}

export interface PoisLoading {
	users_by_date: Boolean;
	users_by_distance: Boolean;
	users_by_week_day: Boolean;
	users_by_hour_day: Boolean;
	users_by_category: Boolean;
	users_by_subcategory: Boolean;
	users_by_poi_city: Boolean;
	users_by_dpto: Boolean;
	users_by_poi_neighborhood: Boolean;
}

export interface DemoLoading {
	users_by_ciudad_residencia: Boolean;
	users_by_niv_socio: Boolean;
	users_by_age: Boolean;
	users_by_gender: Boolean;
	users_by_barrio_residencia: Boolean;
}

export interface OnlineLoading {
	users_by_type: Boolean;
	users_by_iab: Boolean;
	users_by_intereses: Boolean;
	users_by_app_name: Boolean;
	users_by_domain: Boolean;
	users_by_city_seen: Boolean;
}
export interface DeviceLoading {
	users_by_carrier: Boolean;
	users_by_device_make: Boolean;
	users_by_platform_device_type: Boolean;
	users_by_platform_browser: Boolean;
	users_by_device_language: Boolean;
	users_by_plataform_os: Boolean;
}
export interface TableLoading {
	users_by_pois_gender_age: Boolean;
}
export interface HeatMapLoading {
	users_by_hour_day_week: Boolean;
}
export interface MapsLoading {
	map: Boolean;
}

export interface InitLoading {
	pois: PoisLoading;
	demographic: DemoLoading;
	online_behaviour: OnlineLoading;
	device: DeviceLoading;
	table: TableLoading;
	head_map: HeatMapLoading;
	maps: MapsLoading;
}

export interface AudienceFilter {
	users_by_distance: SortingOption;
	users_by_date: SortingOption;
	users_by_week_day: SortingOption;
	users_by_hour_day: SortingOption;
	users_by_category: SortingOption;
	users_by_subcategory: SortingOption;
	users_by_poi_city: SortingOption;
	users_by_dpto: SortingOption;
	users_by_poi_neighborhood: SortingOption;
	users_by_ciudad_residencia: SortingOption;
	users_by_niv_socio: SortingOption;
	users_by_age: SortingOption;
	users_by_gender: SortingOption;
	users_by_barrio_residencia: SortingOption;
	users_by_type: SortingOption;
	users_by_iab: SortingOption;
	users_by_intereses: SortingOption;
	users_by_app_name: SortingOption;
	users_by_domain: SortingOption;
	users_by_city_seen: SortingOption;
	users_by_carrier: SortingOption;
	users_by_device_make: SortingOption;
	users_by_platform_device_type: SortingOption;
	users_by_platform_browser: SortingOption;
	users_by_device_language: SortingOption;
	users_by_plataform_os: SortingOption;
}