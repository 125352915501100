import { TYPE_FETCH, TableHeader } from "@/interfaces/event";
import { ConfigDirectiveFieldEntity } from "./Field";
import { CampaingFilters } from "@/interfaces/campaign";
import { SortingOption } from "@/interfaces/paginated";
import { LineItemFilters } from "@/interfaces/line_item";

export const tableHeaderCampaign = [
	// {
	// 	text: "Campaign ID",
	// 	align: "start",
	// 	sortable: false,
    //     filterable: true,
	// 	value: "id",
	// },
	{
		text: "Campaign External ID",
		align: "start",
		sortable: false,
        filterable: true,
		value: "external_id",
	},
	{
		text: "Campaign Name",
		align: "start",
		sortable: false,
        filterable: true,
		value: "name",
	},
	{
		text: "Associated Line Items",
		align: "center",
		sortable: false,
        filterable: true,
		value: "associated",
	},
	{
		text: "Active",
		align: "center",
		sortable: false,
        filterable: true,
		value: "active",
	},
	{
		text: "Actions",
		align: "center",
		value: "actions",
		sortable: false,
        filterable: true,
	},
] as TableHeader[];

export const tableHeaderLineItem = [
	// {
	// 	text: "Line Item ID",
	// 	align: "start",
	// 	sortable: false,
    //     filterable: true,
	// 	value: "id",
	// },
	{
		text: "Line Item ID",
		align: "start",
		sortable: false,
        filterable: true,
		value: "external_id",
	},
	{
		text: "Line Item Name",
		align: "start",
		sortable: false,
        filterable: true,
		value: "name",
	},
	{
		text: "Associated Creatives",
		align: "center",
		sortable: false,
        filterable: true,
		value: "associated",
	},
	{
		text: "Active",
		align: "center",
		sortable: false,
        filterable: true,
		value: "active",
	},
	{
		text: "Actions",
		align: "center",
		value: "actions",
		sortable: false,
        filterable: true,
	},
] as TableHeader[];

export function getConfigFieldData(type: TYPE_FETCH) {
	return {
		form: new ConfigDirectiveFieldEntity({
			ref: type,
			hint: `events.elegible_objects.form.${type}.hint`,
			label: `events.elegible_objects.form.${type}.label`,
			placeholder: `events.elegible_objects.form.${type}.placeholder`,
		}),
		action: new ConfigDirectiveFieldEntity({
			ref: `add_associated_${type}`,
			label: `events.elegible_objects.buttons.associated_${type}`,
		}),
	};
}

export function getPayloads(advertiser_id?: number) {
	return {
		campaign_id: {
			filters: {
				advertiser_id: advertiser_id,
				active: true,
			} as CampaingFilters,
			options: {
				order: "desc",
				sort: "name",
			} as SortingOption,
			getId: true,
			parsed: true,
		},
		line_item_id: {
			filters: {
				advertiser_id: advertiser_id,
				active: true,
			} as LineItemFilters,
			options: {
				order: "desc",
				sort: "name",
			} as SortingOption,
		},
	};
}

export const ROUTE_DISPATCH = {
	eventCreate: "events/create",
	eventEdit: "events/update",
	eventShow: "events/show",
};
