import {
	PrivatePoisCreated,
	PrivatePoisForm,
	PrivatePoisResource,
	PrivatePoisRules,
	SelectedPrivatePois,
} from "@/interfaces/persons/v11/privatepois";
import { PaginatedEntity } from "@/models/Paginated";
import { isUndefined } from "lodash";

export class PrivatePoisCreatedEntity implements PrivatePoisCreated {
	id?: number;
	country_code?: number;
	country_name?: string;
	layer_name?: string;
	total_errors?: number;
	total_pois?: number;
	updated_at?: string;

	constructor(properties?: PrivatePoisCreated) {
		if (properties) {
			this.setData(properties);
		}
	}

	setData(properties: PrivatePoisCreated) {
		this.id = properties.id;
		this.country_code = properties.country_code;
		this.country_name = properties.country_name;
		this.layer_name = properties.layer_name;
		this.total_errors = properties.total_errors;
		this.total_pois = properties.total_pois;
		this.updated_at = properties.updated_at;
	}

	isCreated() {
		return !isUndefined(this.id) && this.id > 0;
	}
}

export class SelectedPrivatePoisEntity implements SelectedPrivatePois {
	private_pois: PrivatePoisCreated = new PrivatePoisCreatedEntity();
	items: PaginatedEntity = new PaginatedEntity();

	constructor(properties?: {
		private_pois: PrivatePoisCreatedEntity;
		items: PaginatedEntity;
	}) {
		if (properties) {
			this.setData(properties);
		}
	}

	hasSelected() {
		return Boolean(this.private_pois.id && this.private_pois.id > 0);
	}

	setData(properties: {
		private_pois: PrivatePoisCreated;
		items: PaginatedEntity;
	}) {
		this.private_pois = new PrivatePoisCreatedEntity(
			properties.private_pois
		);
		this.items = new PaginatedEntity(properties.items);
	}

	setPrivatePoisData(private_pois: PrivatePoisCreated) {
		this.private_pois = private_pois;
	}
}

export class PrivatePoisResourceEntity implements PrivatePoisResource {
	country_code: any[] = [];

	constructor(parameters?: PrivatePoisResource) {
		if (parameters) {
			this.country_code = parameters?.country_code;
		}
	}

	setResource(type: "country_code", items: any[] = []) {
		this[type] = items;
	}
}

export class PrivatePoisFormEntity implements PrivatePoisForm {
	country_code?: number;
	name_layer?: string;
	file?: File;

	constructor(parameters?: PrivatePoisForm) {
		this.country_code = parameters?.country_code;
		this.name_layer = parameters?.name_layer;
		this.file = parameters?.file;
	}
}

export class PrivatePoisRulesEntity implements PrivatePoisRules {
	country_code: any[] = [];
	name_layer: any[] = [];
	file: any[] = [];

	constructor(parameters?: PrivatePoisRules) {
		if (parameters) {
			this.country_code = parameters?.country_code;
			this.name_layer = parameters.name_layer;
			this.file = parameters.file;
		}
	}

	addRules(type: keyof PrivatePoisRules, rules: any[] = []) {
		this[type] = rules;
	}
}
