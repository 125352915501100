import {
	AxiosGet,
	AxiosPost,
} from "@/services/axios-service";
import { isNull } from "lodash";
import { ItemGraphic } from "@/interfaces/graphic";

import { V10 } from "@/interfaces/persons/v10/route";
import { ResultData } from "@/interfaces/persons/v10/response";
import { FilterParam, IExportSegmentAudience, TAudienceType } from "@/interfaces/persons/v10/audience";
import { RejectError } from "@/models/persons/v10/response";
import { ElementData, ITotalPois } from "@/interfaces/persons/v10/person";
import { matchedAudienceDummyData } from "@/store/Modules/Persons/v10/utils";
import store from "@/store";
import { AnalyzeAudienceType } from "@/interfaces/persons/v10/types";
const ROUTE: V10 = require("@/api/routes").V10;

/**
 * Rutas para Analize Audience
 */
const analizeAudienceRoutes = {
	[AnalyzeAudienceType.GEO]: ROUTE.AUDIENCE.GEO.DEMO_HOME_AUDIENCE,
	[AnalyzeAudienceType.POIS]: ROUTE.AUDIENCE.POIS.AUDIENCE,
};

class AudienceService {

	/**
	 * Personas 12
	 * Analyze Audience by type
	 * @param type 
	 * @returns 
	 */
	async fetchAnalizeAudienceByType(type: AnalyzeAudienceType) {
		try {
			const analyzeType = analizeAudienceRoutes[type];
			const response = await AxiosPost(analyzeType, {});
			const resultData: ResultData = response.data;
			// console.log(`AudienceService::fetchAnalizeAudienceByType ${type}`, { analyzeType, response, resultData });
			return Promise.resolve(resultData);
		} catch (error) {
			return Promise.reject(new RejectError(error));
		}
	}

	/**
	 * Personas 10
	 * Analyze Audience
	 * @returns 
	 */
	async fetchAnalizeAudience() {
		try {
			const response = await AxiosPost(
				ROUTE.AUDIENCE.POIS.ANALIZE_AUDIENCE,
				{}
			);
			const resultData: ResultData = response.data;
			return Promise.resolve(resultData);
		} catch (error) {
			return Promise.reject(new RejectError(error));
		}
	}

	async fetchFilterAudience(params: FilterParam) {
		try {
			const response = await AxiosPost(
				ROUTE.AUDIENCE.FILTER_GRAPHIC,
				params
			);
			const resultData: ResultData = response.data;
			return Promise.resolve(resultData);
		} catch (error) {
			return Promise.reject(new RejectError(error));
		}
	}
	
	/**
	 * Get the audince type of the analysis
	* @returns AudienceType
	 */
	async fetchAudienceType() {
		try {
			const response = await AxiosGet(
				ROUTE.AUDIENCE.AUDIENCE_TYPE
			);
			const resultData: ResultData<TAudienceType> = response.data;
			return Promise.resolve(resultData);
		} catch (error) {
			return Promise.reject(new RejectError(error));
		}
	}

	async fetchPoisCount(breakdown?: Boolean) {
		try {
			const url: string = breakdown ? ROUTE.PERSONS.TOTAL_POIS_TYPE : ROUTE.PERSONS.TOTAL_POIS;
			const response = await AxiosGet(url);

			if (!response.data.success) {
				return Promise.reject(new RejectError("Request failed"))
			}
			return response.data as ResultData<ITotalPois[]>;
		} catch (err) {
			let error = new RejectError(err);

			console.error("AudienceService::fetchPoisCount", { error });

			if (error.message === "You must first run analyze_pois.") {
				return { response: [] as ITotalPois[] } as ResultData<ITotalPois[]>
			}
			return Promise.reject(error);
		}
	}

	/**
	 * Get resource by key
	 * @param source SourceDataFilter
	 * @param isGraphic Boolean
	 * @returns
	 */
	async fetchGraphicBykey(name: string, isAnalyzeGeo = false) {
		try {
			let type = isAnalyzeGeo ? "GEO" : "POIS";
			let url = ROUTE.AUDIENCE[type][name.toUpperCase()];
			let resultData: ResultData<ItemGraphic[]>;
			const country: ElementData = store.getters["person/getCountry"]

			/**
			 * Verify if the data has dummy logic, and then retrieve the data from utils
			 * Use in cases like Store Attributions for example, where there's no data to show
			 * 
			 * - Only for development
			 * NOTE: Deprecated on Planning 11
			 */
			// if(["rich_attributions", "rich_attributions_daily"].includes(name) && process.env.NODE_ENV === "development") {
			// 	return Promise.resolve(matchedAudienceDummyData(name));
			// }

			switch (name) {
				case "niv_socio":
					const matchedCountrySocio = {
						"76": ROUTE.AUDIENCE[type]["NIV_SOCIO_BR"],
						"all": ROUTE.AUDIENCE[type]["NIV_SOCIO_ALL_EXP_BR"]
					}

					let response_all = await AxiosGet(
						matchedCountrySocio[country.id] || matchedCountrySocio["all"]
					);

					resultData = response_all.data;

					if (response_all.data.response == "failed")
						return Promise.reject(new RejectError("Request failed"));

					return Promise.resolve(resultData);

				default:
					let response = await AxiosGet(url);
					resultData = response.data;

					if (response.data.response == "failed")
						return Promise.reject(new RejectError("Request failed"));


					return Promise.resolve(resultData);
			}
		} catch (error) {
			/**
			 * Verify if the data has dummy logic, and then retrieve the data from utils
			 * Use in cases like Store Attributions for example, where there's no data to show
			 * 
			 * - Only for development
			 */
			if (["rich_attributions"].includes(name) && process.env.NODE_ENV === "development") {
				return Promise.resolve(matchedAudienceDummyData(name));
			}

			return Promise.reject(new RejectError(error));
		}
	}

	async fetchPreviousFilters(commit): Promise<FilterParam | null> {
		try {
			let filters = await AxiosGet(ROUTE.AUDIENCE.GET_FILTER);
			let { response } = filters.data;

			commit("SET_FILTER_CHANGES", { filters: !isNull(response) })

			return response;
		} catch (error) {
			return Promise.reject(new RejectError(error));
		}
	}

	async fetchExportAudience(data: IExportSegmentAudience) {
		try {
			const response = await AxiosPost(
				ROUTE.AUDIENCE.CREATE_SEGMENT,
				data
			);
			return response;
		} catch (error) {
			return Promise.reject(new RejectError(error));
		}
	}

	/**
	 * Post current section to load graphics related to it
	 * @param type analysis type, if is geo or pois
	 * @param key key of section to be fetched
	 */
	async postSectionByKey(type: AnalyzeAudienceType, key: string) {
		try {
			let url = ROUTE.AUDIENCE[type.toUpperCase()][key.toUpperCase()];

			if (url) {
				let response = await AxiosPost(url, {});
				const resultData = response.data;

				if (response.data.response == "failed")
					return Promise.reject(new RejectError("Request failed"));


				return Promise.resolve(resultData);
			}
		} catch (error) {
			return Promise.reject(new RejectError(error));
		}
	}
}

export default new AudienceService();
