import { isArray, isObject } from "lodash";

class ParamService {

    async getParams(paginated?: {}, filters?: {}, options?: {}, formatter: Boolean = false, fields?: []) {
        var params = {};
        if (
            isObject(paginated) &&
            Object.entries(paginated).length
        ) {
            params["paginated"] = paginated;
        }
        if (isObject(filters) && Object.entries(filters).length) {
            params["filters"] = filters;
        }
        if (isObject(options) && Object.entries(options).length) {
            params["options"] = options;
        } else {
            params["options"] = { sort: "external_id", order: "desc" };
        }
        if (isArray(fields) && fields.length) {
            params["fields"] = fields;
        }

		params["formatter"] = formatter;

        return params;
    }
}

export default new ParamService()
