import { getTargetingID } from "@/utils/initData";
import { head, isEmpty } from "lodash";
import { TargetingExpression, TargetingValue } from "../TargetingValue";

const booleanstringify: Array<string> = ["topframe"];

/**
 * *** INTERFACES ***
 */

export interface TargetingTerm {
	value: any;
	value_name: any;
	targeting_expression_id: number | undefined;
	targeting_key_id: number | undefined;
	targeting_predicate_id: number | undefined;
	segment_name: string | null;
	verb_usage: string | null;
}

export interface Term {
	value: any[];
	targeting_terms: InitTargetingTerm[];
	targeting_key_id: number | undefined;
	targeting_predicate_id: number | undefined;
	unique: boolean;
	by_attribute: string;
}
export interface SummaryTargeting {
	name_module: String;
	all:  PredicateSummaryTargeting[];
	none: PredicateSummaryTargeting[];
}
export interface PredicateSummaryTargeting {
	name_term: Term;
	id: any;
	value: any;
}
export interface AppSite {
	app_bundle_list: Term;
	app_id_list: Term;
	app_name: Term;
	deal_id: Term;
	deal_id_list: Term;
	domain_list: Term;
	placement_id?: Term;
	placement_list: Term;
	publisher_id: Term;
	publisher_id_list: Term;
	site: Term;
	site_list: Term;
}

export interface Geo {
	country: Term;
	city: Term;
	lat_long_list: Term;
	location_type: Term;
	region: Term;
}

export interface Exchange {
	auction_type: Term;
	inventory_source: Term;
}

export interface Environment {
	environment_type: Term;
	topframe: Term;
	video_api: Term;
	rewarded: Term;
}

export interface Platform {
	browser: Term;
	browser_version: Term;
	carrier: Term;
	device_type: Term;
	device_make: Term;
	device_model: Term;
	device_screen_size: Term;
	operating_system: Term;
	operating_system_version: Term;
}

export interface Targeting {
	app_site: AppSite;
	geo: Geo;
	exchange: Exchange;
	environment: Environment;
	platform: Platform;
	content: Content;
	time: Time;
	user: User;
}

export interface Content {
	content_category: Term;
	language: Term;
}

export interface Time {
	user_time_of_week: Term;
}

export interface User {
	segment: Term;
}

/**
 * *** CLASS ***
 */

export class InitTargetingExpression implements TargetingExpression {
	id: number | undefined;
	line_item_id: number | undefined;
	targeting_values: TargetingValue[] = [];

	constructor(parametters?: {
		id: any;
		line_item_id: any;
		targeting_values?: TargetingValue[];
	}) {
		this.id = parametters?.id;
		this.line_item_id = parametters?.line_item_id;
		this.targeting_values =
			parametters?.targeting_values ?? ([] as TargetingValue[]);
	}

	public set setId(parametter: number) {
		this.id = parametter;
	}

	public set setLineItemId(parametter: number) {
		this.line_item_id = parametter;
	}

	public set setTargetingValues(parametters: TargetingValue[]) {
		this.targeting_values = parametters;
	}
}

export class InitTargeting implements Targeting {
	public id: number | undefined = undefined;
	public app_site: AppSite = new InitAppSite();
	public geo: Geo = new InitGeo();
	public exchange: Exchange = new InitExchange();
	public environment: Environment = new InitEnvironment();
	public platform: Platform = new InitPlatform();
	public content: Content = new InitContent();
	public time: Time = new InitTime();
	public user: User = new InitUser();

	constructor(parametters?: any) {}

	public set setId(parametter: number) {
		this.id = parametter;
	}

	public getValue(
		module_name: string,
		key_name: string
	): Array<any> | string {
		return this[module_name][key_name].value;
	}

	public getUnique(module_name: string, key_name: string): Boolean {
		return this[module_name][key_name].unique;
	}

	public hasTargetingTermUnique(
		module_name: string,
		key_name: string
	): Boolean {
		const term: Term = this[module_name][key_name];
		const targeting_term: TargetingTerm | undefined = !isEmpty(
			term.targeting_terms
		)
			? head(term.targeting_terms)
			: undefined;
		return !isEmpty(targeting_term?.value);
	}

	public hasTargetingTerm(module_name: string, key_name: string): Boolean {
		const term: Term = this[module_name][key_name];
		if (term.unique) {
			const targeting_term: TargetingTerm | undefined = !isEmpty(
				term.targeting_terms
			)
				? head(term.targeting_terms)
				: undefined;
			return !isEmpty(targeting_term?.value);
		} else {
			return !isEmpty(term.targeting_terms);
		}
	}

	public convertValue(key_name: string, value: string) {
		if (booleanstringify.includes(key_name)) {
			return value === "1" ? "true" : "false";
		}
		return value;
	}

	public setValue(module_name: string, key_name: string, value: string) {
		let term: Term = this[module_name][key_name];
		// if (term.unique) {
		// 	term.value.push(this.convertValue(key_name, value));
		// } else {
		// 	term.value = this.convertValue(key_name, value);
		// }
		term.value.push(this.convertValue(key_name, value));
	}

	public setTermValue(
		module_name: string,
		key_name: string,
		targeting_value: TargetingValue
	) {
		let term: Term = this[module_name][key_name];

		if (term.unique) {
			const hasTerm: Boolean = this.hasTargetingTerm(
				module_name,
				key_name
			);

			term.value.push(this.convertValue(key_name, targeting_value.value));

			if (hasTerm) {
				term.targeting_terms[0].value.push(
					this.convertValue(key_name, targeting_value.value)
				);
				term.targeting_terms[0].value_name.push(
					targeting_value.value_name
				);
			} else {
				term.targeting_terms.push(
					new InitTargetingTerm({
						value: [
							this.convertValue(key_name, targeting_value.value),
						],
						value_name: [targeting_value.value_name],
						targeting_key_id: term.targeting_key_id,
						targeting_predicate_id:
							targeting_value?.targeting_predicate_id,
						targeting_expression_id:
							targeting_value.targeting_expression_id,
						segment_name: targeting_value.segment_name,
						verb_usage: targeting_value.verb_usage,
					})
				);
			}
		} else {
			//term.value = this.convertValue(key_name, targeting_value.value);
			term.value.push(this.convertValue(key_name, targeting_value.value));
			term.targeting_terms.push(
				new InitTargetingTerm({
					value: this.convertValue(key_name, targeting_value.value),
					value_name: targeting_value.value_name,
					targeting_key_id: term.targeting_key_id,
					targeting_predicate_id:
						targeting_value?.targeting_predicate_id,
					targeting_expression_id:
						targeting_value.targeting_expression_id,
					segment_name: targeting_value.segment_name,
					verb_usage: targeting_value.verb_usage,
				})
			);
		}
		this[module_name][key_name] = term;
	}
}

export class InitTerm implements Term {
	targeting_key_id: number | undefined;
	targeting_predicate_id: number | undefined;
	targeting_terms: InitTargetingTerm[] = [];
	unique: boolean = false;
	value: string[] = [];
	by_attribute: string = "";

	constructor(parametters: { targeting_key_id: number|undefined; unique?: boolean; by_attribute?: string; }) {
		this.targeting_key_id = parametters.targeting_key_id;
		if (parametters.unique) {
			this.unique = parametters.unique;
		}
		this.value = [];
		if(parametters.by_attribute) {
			this.setByAttribute = parametters.by_attribute;
		}
	}

	public set setByAttribute(parametter: string) {
		this.by_attribute = parametter;
	}
}

export class InitAppSite implements AppSite {
	app_bundle_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "app_bundle_list"),
		unique: false,
	});
	app_id_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "app_id_list"),
		unique: false,
	});
	app_name: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "app_name"),
		unique: true,
		by_attribute: "app_name"
	});
	deal_id: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "deal_id"),
		unique: true,
	});
	deal_id_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "deal_id_list"),
		unique: false,
	});
	domain_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "domain_list"),
		unique: false,
	});
	// placement_id: Term = new InitTerm({
	// 	targeting_key_id: getTargetingID("app_site", "placement_id",
	// 	unique: true,
	// by_attribute: "placement_id",
	// });
	placement_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "placement_list"),
		unique: false,
	});
	publisher_id: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "publisher_id"),
		unique: true,
	});
	publisher_id_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "publisher_id_list"),
		unique: false,
	});
	site: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "site"),
		unique: true,
		by_attribute: "site_id"
	});
	site_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("app_site", "site_list"),
		unique: false,
	});

	constructor(parametters?: any) {}
}

export class InitGeo implements Geo {
	country: Term = new InitTerm({
		targeting_key_id: getTargetingID("geo", "country"),
		unique: true,
	});
	city: Term = new InitTerm({
		targeting_key_id: getTargetingID("geo", "city"),
		unique: true,
	});
	lat_long_list: Term = new InitTerm({
		targeting_key_id: getTargetingID("geo", "lat_long_list"),
		unique: false,
	});
	location_type: Term = new InitTerm({
		targeting_key_id: getTargetingID("geo", "location_type"),
		unique: false,
	});
	region: Term = new InitTerm({
		targeting_key_id: getTargetingID("geo", "region"),
		unique: true,
	});

	constructor(parametters?: any) {}
}

export class InitExchange implements Exchange {
	auction_type: Term = new InitTerm({
		targeting_key_id: getTargetingID("exchange", "auction_type"),
		unique: true,
	});
	inventory_source: Term = new InitTerm({
		targeting_key_id: getTargetingID("exchange", "inventory_source"),
		unique: true,
	});

	constructor(parametters?: any) {}
}

export class InitEnvironment implements Environment {
	environment_type: Term = new InitTerm({
		targeting_key_id: getTargetingID("environment", "environment_type"),
		unique: true,
	});
	topframe: Term = new InitTerm({
		targeting_key_id: getTargetingID("environment", "topframe"),
		unique: true,
	});
	video_api: Term = new InitTerm({
		targeting_key_id: getTargetingID("environment", "video_api"),
		unique: true,
	});
	rewarded: Term = new InitTerm({
		targeting_key_id: getTargetingID("environment", "rewarded"),
		unique: true,
	});
	constructor(parametters?: any) {}
}

export class InitPlatform implements Platform {
	browser: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "browser"),
		unique: true,
	});
	browser_version: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "browser_version"),
		unique: true,
	});
	carrier: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "carrier"),
		unique: true,
	});
	device_type: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "device_type"),
		unique: true,
	});
	device_make: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "device_make"),
		unique: true,
	});
	device_model: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "device_model"),
		unique: true,
	});
	device_screen_size: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "device_screen_size"),
		unique: true,
	});
	operating_system: Term = new InitTerm({
		targeting_key_id: getTargetingID("platform", "operating_system"),
		unique: true,
	});
	operating_system_version: Term = new InitTerm({
		targeting_key_id: getTargetingID(
			"platform",
			"operating_system_version"
		),
		unique: true,
	});

	constructor(parametters?: any) {}
}

export class InitContent implements Content {
	content_category: Term = new InitTerm({
		targeting_key_id: getTargetingID("content", "content_category"),
		unique: true,
	});
	language: Term = new InitTerm({
		targeting_key_id: getTargetingID("content", "language"),
		unique: true,
	});

	constructor(parametters?: any) {}
}

export class InitTime implements Time {
	user_time_of_week: Term = new InitTerm({
		targeting_key_id: getTargetingID("time", "user_time_of_week"),
		unique: true,
	});

	constructor(parametters?: any) {}
}

export class InitUser implements User {
	segment: Term = new InitTerm({
		targeting_key_id: getTargetingID("user", "segment"),
		unique: false,
	});

	constructor(parametters?: any) {}
}

export class InitTargetingTerm implements TargetingTerm {
	public targeting_expression_id: number | undefined;
	public targeting_key_id: number | undefined;
	public targeting_predicate_id: number | undefined;
	public value: any = undefined;
	public value_name: any = undefined;
	public segment_name: string | null = null;
	public verb_usage: string | null = null;

	constructor(parametters: TargetingTerm) {
		this.setTargetingTerm(parametters);
	}

	public set setTargetingKey(parametter: number) {
		this.targeting_key_id = parametter;
	}

	public setTargetingTerm(parametters: TargetingTerm) {
		this.targeting_expression_id = parametters.targeting_expression_id;
		this.targeting_key_id = parametters.targeting_key_id;
		this.targeting_predicate_id = parametters.targeting_predicate_id;
		this.value = parametters.value;
		this.value_name = parametters.value_name;
		this.segment_name = parametters.segment_name;
		this.verb_usage = parametters.verb_usage;
	}

	public get getTargetingTerm(): TargetingTerm {
		return this;
	}
}
