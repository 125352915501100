import {
	ConfigFieldEntity,
	ConfigFields,
	RuleBuilder,
	ValidationRules,
} from "@/models/Rules/Builder";

const MIN_INT = 1;
const MAX_INT = 30;

export const configFields: ConfigFields = {
	id: new ConfigFieldEntity(),
	report_type: new ConfigFieldEntity({
		required: true,
	}),
	type: new ConfigFieldEntity(),
	file_type: new ConfigFieldEntity({
		required: true,
	}),
	data_range: new ConfigFieldEntity(),
	start_date: new ConfigFieldEntity(),
	end_date: new ConfigFieldEntity(),
	dimensions: new ConfigFieldEntity(),
	metrics: new ConfigFieldEntity(),
	filters: new ConfigFieldEntity(),

	scheduler_type: new ConfigFieldEntity({
		required: true,
	}),
	email_subject: new ConfigFieldEntity({
		required: true,
	}),
	emails: new ConfigFieldEntity({
		required: true,
	}),
	email_to: new ConfigFieldEntity({
		required: true,
		email: true,
	}),
	schedule_end_date: new ConfigFieldEntity({
		required: true,
	}),
	schedule_start_date: new ConfigFieldEntity({
		required: true,
	}),
	include_account_email: new ConfigFieldEntity(),
};

/**
 * Funcion para crear reglas de validacion dinamicamente
 * @returns
 */
export function validationRules(): ValidationRules {
	const validationRules: ValidationRules = {};

	for (const fieldName in configFields) {
		if (configFields.hasOwnProperty(fieldName)) {
			const ruleBuilder = new RuleBuilder(configFields[fieldName]);
			const rules = ruleBuilder.buildRules();
			validationRules[fieldName] = rules;
		}
	}

	return validationRules;
}
