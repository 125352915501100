import {
	CustomListFilters,
	Type,
	CustomListDataCreate,
	PrivatePoisCustomList,
} from "@/interfaces/custom_list";
import { Paginated } from "@/interfaces/paginated";
import {
	AxiosGet,
	AxiosPost,
	AxiosPatch,
	GetData,
	GetErrors,
	GetMessage,
	AxiosUpload,
} from "@/services/axios-service";
import { prepareParams, modes, downloadFile, catchServiceErrors } from "@/utils/services-global";
import { isNull, isUndefined } from "lodash";
import { SortingOption } from "../interfaces/paginated";
import { V10 } from "@/interfaces/persons/v10/route";

export const VERB_USAGE = "api/list/verb_usages";
export const SEGMENT_TAG_TYPE = "api/list/segment_tag_types";

const ROUTE = require("../api/routes").CUSTOM_LIST;
const PACING = require("../api/routes").PACING;
const STRATEGIES = require("../api/routes").STRATEGIES;
const BUDGET = require("../api/routes").BUDGET;
const KPI = require("../api/routes").KPI;
const UNIT_TIME = require("../api/routes").UNIT_TIME;
const CREATIVES = require("../api/routes").CREATIVES;
const BID = require("../api/routes").BID;
const PERSONS: V10 = require("../api/routes").V10;

const ENTITY: string = "custom_list";

class CustomListService {
	async paginated(params: {
		paginated?: Paginated;
		filters?: CustomListFilters;
		options?: SortingOption;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.CUSTOM_LIST_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async download(params: {
		paginated?: Paginated;
		filters?: CustomListFilters;
		options?: SortingOption;
		fields: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			await downloadFile({
				entity: ENTITY,
				route: ROUTE.CUSTOM_LIST_ROUTE,
				filter,
				mode: modes.DOWNLOAD,
				...params,
			});

			return Promise.resolve({});
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async update(params: any) {
		const { 
			id, 
			name, 
			active, 
			custom_list_type_id, 
			default_radius_km,
			edited,
			deleted,
			created
		} = params;
		try {
			const response = await AxiosPatch(
				`${ROUTE.CUSTOM_LIST_ROUTE}/${id}/with_items`,
				{
					name,
					active,
					custom_list_type_id, 
					default_radius_km,
					edited,
					deleted,
					created,
				}
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async uploadItems(payload: any, callback: any) {
		try {
			const response = await AxiosUpload(
				`${ROUTE.LIST_ITEM_ROUTE}/upload`,
				payload,
				callback
			);
			return Promise.resolve({data: GetData(response), message: response.data.message});
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async getViewByTypeSelected(type: Type) {
		try {
			return Promise.resolve(await MatchedView(type.key));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async getViewByTypeSelectedById(id: number) {
		try {
			return Promise.resolve(await MatchedViewById(id));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async create(customList: CustomListDataCreate) {
		try {
			const response = await AxiosPost(
				ROUTE.CUSTOM_LIST_ROUTE,
				customList
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async convertToPrivatePois(customList: PrivatePoisCustomList) {
		try {
			const response = await AxiosPost(
				PERSONS.PERSONS.LIST_TO_PRIVATE_POIS,
				customList
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async show(id: Number) {
		try {
			const response = await AxiosGet(`${ROUTE.CUSTOM_LIST_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async budgetTypes() {
		try {
			const response = await AxiosGet(`${BUDGET.BUDGET_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async campaignPacing() {
		try {
			const response = await AxiosGet(`${PACING.CAMPAING_PACING_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async optimizationStrategies() {
		try {
			const response = await AxiosGet(
				`${STRATEGIES.STRATEGY_OPTIMIZATION_ROUTE}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async kpiCampaigns() {
		try {
			const response = await AxiosGet(`${KPI.CAMPAING_KPI_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async strategies() {
		try {
			const response = await AxiosGet(`${STRATEGIES.STRATEGY_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async unitTimes() {
		try {
			const response = await AxiosGet(`${UNIT_TIME.UNIT_TIME_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async CreativeWeightingMethods() {
		try {
			const response = await AxiosGet(
				`${CREATIVES.CREATIVE_METHOD_ROUTE}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async bidStrategyList() {
		try {
			const response = await AxiosGet(`${BID.BID_STRATEGY_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async linePacingList() {
		try {
			const response = await AxiosGet(`${PACING.LINE_PACING_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async biddingShadingList() {
		try {
			const response = await AxiosGet(`${BID.BID_SHADING_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async verbUsage() {
		try {
			const response = await AxiosGet(`${VERB_USAGE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async segmentTagType() {
		try {
			const response = await AxiosGet(`${SEGMENT_TAG_TYPE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async typesFrecuecyCaps() {
		try {
			const response = await AxiosGet(`${ROUTE.TYPES_FRECUENCY_CAPS_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
}

function getFilters(filters: CustomListFilters): string {
	let filter = "";

	const id = !!filters.id ? filters.id : "";
	const name = !!filters.name ? filters.name : "";
	const type = !!filters.type_name ? filters.type_name : "";
	const active =
		isUndefined(filters.active) || isNull(filters.active)
			? ""
			: !!filters.active
			? 1
			: 0;

	var symbol = "";

	if (id) {
		filter += `${symbol}filters[custom_lists.id]=${id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[custom_lists.name]=${name}`;
		symbol = "&";
	}

	if (type) {
		filter += `${symbol}filters[list_types.name]=${type}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[custom_lists.active]=${active}`;
		symbol = "&";
	}

	return filter;
}

function ModelOneList() {
	return [
		{ id: 10, key: "zip_code" },
		{ id: 4, key: "domain" },
	];
}

function ModelTwoList() {
	return [{ id: 6, key: "lat_long" }];
}

function ModelThreeList() {
	return [
		{ id: 1, key: "app_bundle" },
		{ id: 2, key: "app_id" },
		{ id: 3, key: "deal_id" },
		{ id: 5, key: "ip_address" },
		{ id: 7, key: "placement_id" },
		{ id: 8, key: "publisher_id" },
		{ id9: 9, key: "site_id" },
	];
}

async function MatchedView(key: string) {
	let modelView = "";

	if (ModelOneList().find((m) => m.key == key)) {
		modelView = "ModelOne";
	}

	if (ModelTwoList().find((m) => m.key == key)) {
		modelView = "ModelTwo";
	}

	if (ModelThreeList().find((m) => m.key == key)) {
		modelView = "ModelTree";
	}

	return modelView;
}

async function MatchedViewById(id: number) {
	let modelView = "";

	if (ModelOneList().find((m) => m.id == id)) {
		modelView = "ModelOne";
	}

	if (ModelTwoList().find((m) => m.id == id)) {
		modelView = "ModelTwo";
	}

	if (ModelThreeList().find((m) => m.id == id)) {
		modelView = "ModelTree";
	}

	return modelView;
}

export default new CustomListService();
