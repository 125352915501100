
import { isEmpty, isNull, sortBy, isArray } from "lodash";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

const STATUS_PROCESSED = "PROCESSED";

export default {
	name: "CardAutocomplete",
	model: {
		prop: "model_data",
		event: "input",
	},
	components: { IconBase },
	props: {
		model_data: {},
		sort_by_value: {
			type: Boolean,
			default: true
		},
		rules: {
			type: Array,
			default: function () {
				return [];
			},
		},
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		showLabel: {
			type: Boolean,
			default: true,
		},
		hint: {
			type: [String, Object],
			default: "",
		},
		reference: {
			type: String,
			default: "",
		},
		placeholder: {
			type: [String, Object],
			default: "",
		},
		label: {
			type: [String, Object],
			default: "",
		},
		hide_label: {
			type: Boolean,
			default: false
		},
		customClass: {
			type: String,
			default: "label-fixed static",
		},
		cardStyles: {
			type: String,
			default: "px-2 mb-2 static",
		},
		counter: {
			type: [Number, Boolean],
			default: 255,
		},
		item_text: {
			type: String,
			default: "value",
		},
		item_value: {
			type: String,
			default: "id",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		chips: {
			type: Boolean,
			default: false,
		},
		deletable_chips: {
			type: Boolean,
			default: false,
		},
		small_chips: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		persistent_hint: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		auto_select_first: {
			type: Boolean,
			default: false,
		},
		search_input_sync: {
			type: [Object, String, null],
			default: null,
		},
		colapse_selection: {
			type: Boolean,
			default: false,
		},
		show_id: {
			type: Boolean,
			default: false,
		},
		hide_no_data: {
			type: Boolean,
			default: true,
		},
		append_outer_data: {
			type: Object,
			default: function () {
				return {};
			},
		},
		append_icon: {
			type: String,
			default: "",
		},
		prepend_icon: {
			type: String,
			default: "",
		},
		prepend_inner_icon: {
			type: String,
			default: "",
		},
		item_select_all: {
			type: Boolean,
			default: false,
		},
		error_messages: {
			type: [Array, String],
			default: function () {
				return [];
			},
		},
		return_object: {
			type: Boolean,
			default: false,
		},
		selected_all: {
			type: Array,
			default: function () {
				return [];
			},
		},
		single_line: {
			type: Boolean,
			default: false,
		},
		additional: {
			type: Object,
			default: function () {
				return {};
			},
		},
		hide_details: {
			type: [Boolean, String],
			default: false,
		},
		persistent_placeholder: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		withMessageRequire: {
			type: Boolean,
			default: false,
		},
		messageRequire: {
			type: String,
			default: "",
		},
		dataCy: {
			type: String,
			default: ""
		},
	},
	data: () => ({
		subtractionIndex: 1,
	}),

	mounted() {},
	computed: {
		hasAdditional() {
			return Object.keys(this.additional).length > 0;
		},

		showStatus() {
			return this.hasAdditional && !!this.additional?.status;
		},

		showTotal() {
			return this.hasAdditional && !!this.additional?.total;
		},

		showDevices() {
			return this.hasAdditional && !!this.additional?.devices;
		},

		isLoading() {
			return this.loading || this.$store.state.proccess.loading_field;
		},
		selectedAll: {
			set(val: Array<number>) {
				this.$emit("all", val);
			},
			get() {
				return this.selected_all;
			},
		},
		searchInputSync: {
			set(val: any) {
				this.$emit("sync", val);
			},
			get() {
				return this.search_input_sync;
			},
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				if (!this.multiple) return this.model_data;
				if (isEmpty(this.model_data)) return this.model_data;
				if (isArray(this.model_data)) {
					return this.model_data.sort();
				} else {
					return this.model_data;
				}
			},
		},
		getItems() {
			if (!this.multiple || !this.sort_by_value) return this.items;
			return sortBy(this.items, ["value"], ["asc"]);
		},
		getCustomClass() {
			return `${this.customClass} ${
				this.disabled || this.readonly ? "disabled" : ""
			}`;
		},
		hasAppendOuterData() {
			return (
				!isNull(this.append_outer_data) &&
				Object.entries(this.append_outer_data).length > 0
			);
		},
		isClearable() {
			if (!this.clearable) return false;
			return !this.disabled && !this.readonly;
		},
	},
	methods: {
		clickEvent(e: any) {
			this.$emit("click", e);
		},
		changeEvent(e: any) {
			this.$emit("change", e);
		},
		focusEvent(e: any) {
			this.$emit("focus", e);
		},
		clearHandler(e: any) {
			this.$emit("clear", e);
		},
		enterHandle(e: any) {
			this.$emit("keydown", e);
		},
		appendOuterHandler(e: any) {
			this.$emit("click-outer", e);
		},
		handlePrepend(e: any) {
			this.$emit("click-append", e);
		},
		enterHandler(e: any) {
			this.$emit("enter", e);
		},
		getText(item: any) {
			if (item.id == 0 && this.items.length > 1) {
				this.subtractionIndex = 2;
				return this.items[1].text;
			} else {
				this.subtractionIndex = 1;
				return item.text;
			}
		},
		getTextStatus(item: any) {
			if (item.status && item.status?.toUpperCase() != STATUS_PROCESSED) {
				return "(" + item.status + ")";
			} else {
				return "";
			}
		},
	},
	watch: {},
};
