import { Profile, User, UserRules } from "@/interfaces/user";
import { head } from "lodash";
import VueI18n from "vue-i18n";

export enum UserSettingField {
	ID = "id",
	EMAIL = "email",
	NAME = "name",
	LAST_NAME = "last_name",
	AVATAR_FILE = "avatar_file",
	UPLOAD_FILE = "upload_file",
	LANGUAGE = "language",
	ROLE_ID = "role_id",
}

export default class UserSetting implements User {
	id: number = NaN;
	email: string = "";
	name: string = "";
	last_name: string = "";
	avatar_file: string | null = "";
	upload_file?: File | null = null;
	language: string | null = "";
	role_id?: number;

	constructor(user?: Profile) {
		if (user) {
			this.setUser(user);
			this.role_id = head(user.roles)?.id;
		}
	}

	setUser(user: User) {
		this.id = user.id;
		this.email = user.email || "";
		this.name = user.name || "";
		this.last_name = user.last_name || "";
		this.avatar_file = user.avatar_file || null;
		this.language = user.language;
	}

	setData<T>(key: string, value: T) {
		this[key] = value;
	}
}

export class UserRulesEntity implements UserRules {
	id: VueI18n.TranslateResult[] = [];
	email: VueI18n.TranslateResult[] = [];
	name: VueI18n.TranslateResult[] = [];
	last_name: VueI18n.TranslateResult[] = [];
	avatar_file: VueI18n.TranslateResult[] = [];
	language: VueI18n.TranslateResult[] = [];
	role_id: VueI18n.TranslateResult[] = [];
	active: VueI18n.TranslateResult[] = [];

	constructor() {}

	async setRules(userRules: UserRules) {
		this.id = userRules.id ?? [];
		this.email = userRules.email ?? [];
		this.name = userRules.name ?? [];
		this.last_name = userRules.last_name ?? [];
		this.avatar_file = userRules.avatar_file ?? [];
		this.language = userRules.language ?? [];
	}

	async setRule(key: string, rules: VueI18n.TranslateResult[]) {
		this[key] = rules;
	}
}

export interface UserSettingBody {
	name: string;
	last_name: string;
	role_id: string;
	language: string;
	avatar_file: any;
}
