import { TabItem } from "@/interfaces/Core/tab";

export class TabItemEntity implements TabItem {
	key: number = NaN;
	tab: string = "";
	disabled: boolean = true;
	show: boolean = false;

	constructor(tabItem?: TabItem) {
		// Initialize properties using the provided TabItem object or default values
		this.key = tabItem?.key || 0;
		this.tab = tabItem?.tab || "";
		this.disabled = Boolean(tabItem?.disabled) || false;
		this.show = Boolean(tabItem?.show) || false;
	}

	// Generic getter method
	getValue<K extends keyof TabItem>(prop: K): TabItem[K] {
		return this[prop];
	}

	// Generic setter method
	setValue<K extends keyof TabItem>(prop: K, value: TabItem[K]): void {
		this[prop] = value as this[K];
	}
}
