
import Vue from "vue";

export default Vue.extend({
	name: "Separator",
	props: {
		title: {
			type: String,
		},
		titleClass: {
			type: String,
			default: "",
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {},
	methods: {},
	watch: {},
});
