import { EventEntity } from "@/models/events/Event";
import { CampaingFilters } from "./campaign";
import { SortingOption } from "./paginated";
import { LineItemFilters } from "./line_item";

export interface EventRelation {
	id: number;
	external_id?: number;
	value?: string;
	name?: string;
	description?: string;
	extra?: string;
}

export interface EventTagResponse {
	event_id: number;
	event_name: string;
	tag: string;
}

export enum TYPE_ARCHIVED {
	ARCHIVED = 1,
	UNARCHIVED = 0,
}

export enum TYPE_FETCH {
	CAMPAIGN_ID = "campaign_id",
	LINE_ITEM_ID = "line_item_id",
	FETCH = "fetch",
}

export enum EVENT_ASSOCIATION_TYPE {
	CAMPAIGN_ID = "campaigns",
	LINE_ITEM_ID = "line_items",
}

export enum ASSOCIATION_ROUTE_TYPE {
	CAMPAIGN_ID = "associate_campaigns",
	LINE_ITEM_ID = "associate_line_items",
}

export enum EVENT_FIELD {
	ID = "id",
	NAME = "name",
	ACTIVE = "active",
	ARCHIVED = "archived",
	EXTERNAL_ID = "external_id",
	ACCOUNT_ID = "account_id",
	ADVERTISER_ID = "advertiser_id",
	EVENT_ATTRIBUTION_ID = "event_attribution_id",
	EVENT_TYPE_ID = "event_type_id",
	SEGMENT = "segment",
	SEGMENT_ID = "segment_id",
	VALUE = "value",
	CLICK_WINDOW_DAYS = "click_window_days",
	VIEW_WINDOW_DAYS = "view_window_days",
	EVENTS_FIRED_TODAY = "events_fired_today",
	EVENTS_FIRED_YESTERDAY = "events_fired_yesterday",
	EVENTS_FIRED_PAST_7_DAYS_AVG = "events_fired_past_7_days_avg",
	EVENTS_FIRED_PAST_30_DAYS_LOOK_BACK = "events_fired_past_30_days_look_back",
	CREATED_BY = "created_by",
	UPDATED_BY = "updated_by",
	DELETED_BY = "deleted_by",
	CREATED_AT = "created_at",
	UPDATED_AT = "updated_at",
	CAMPAIGNS = "campaigns",
	LINE_ITEMS = "line_items",
	ADVERTISER_NAME = "advertiser_name",
	EVENT_TYPE = "event_type",
	EVENT_ATTRIBUTION = "event_attribution",
	EVENT_TAG_TYPE_ID = "event_tag_type_id",
}

export interface EventField {
	id: number | null;
	name: string;
	active: Boolean;
	archived: Boolean;
	external_id?: number | null;
	account_id?: number | null;
	advertiser_id?: number | null;
	event_attribution_id?: number | null;
	event_type_id?: number | null;
	segment?: EventRelation | null;
	segment_id: number | null;
	segment_name?: string;
	value?: number | null;
	click_window_days?: number | null;
	view_window_days?: number | null;
	events_fired_today?: any;
	events_fired_yesterday?: any;
	events_fired_past_7_days_avg?: any;
	events_fired_past_30_days_look_back?: any;
	created_by: number | null;
	updated_by: number | null;
	deleted_by: number | null;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	campaigns?: AssociatedItem[];
	line_items?: AssociatedItem[];
	advertiser_name?: string;
	event_type?: EventRelation | string | null;
	event_attribution?: EventRelation | string | null;
	elegible?: Boolean | null;
	event_tag_type_id?: number | null;
}

export type EventID = Pick<Required<EventField>, "id">;

export type EventCreate = Pick<
	Required<EventField>,
	| "name"
	| "advertiser_id"
	| "event_attribution_id"
	| "event_type_id"
	| "segment_id"
	| "value"
	| "click_window_days"
	| "view_window_days"
	| "event_tag_type_id"
	| "active"
>;

export type EventUpdate = Pick<
	Required<EventField>,
	| "id"
	| "name"
	| "advertiser_id"
	| "event_attribution_id"
	| "event_type_id"
	| "segment_id"
	| "value"
	| "click_window_days"
	| "view_window_days"
	| "event_tag_type_id"
	| "active"
>;

export type EventTagCreate = Pick<
	Required<EventField>,
	"id" | "event_tag_type_id"
>;

export interface EventFilters {
	id?: number;
	name?: string;
	event_attribution_extra?: string;
	external_id?: number;
	advertiser_id?: number;
	event_attribution_id?: number;
	segment_id?: number;
	value?: number;
	click_window_days?: number;
	view_window_days?: number;
	advertiser_name?: string;
	event_attribution?: {
		id: number;
		description: string;
	};
	event_type?: {
		id: number;
		description: string;
	};
	active?: Boolean;
}

export interface DataField {
	tag: TagData;
	resources: {
		advertiser_id: ResourceItem[];
		event_attribution_id: ResourceItem[];
		event_type_id: ResourceItem[];
		segment_id: ResourceItem[];
		event_tag_type_id: ResourceItem[];
	};
	loading: {
		advertiser_id: Boolean;
		event_attribution_id: Boolean;
		event_type_id: Boolean;
		segment_id: Boolean;
		event_tag_type_id: Boolean;
	};
}

export interface TagData {
	loading: Boolean;
	generated: string;
}

export interface ResourceConfig {
	dispatch: string;
	params: ResourceConfigOption;
}

export interface ResourceConfigOption {
	route_type?: string;
	filters: Record<string, any>;
	options: Record<string, any>;
}

export type ConfigDirectiveFields = {
	[key: string]: ConfigDirectiveField;
};

export type ResourceItem = {
	id: number;
	value: string;
};

export type ResourceSegment = {
	id: number;
	external_id: number;
	advertiser_id: number;
	name: string;
};

export type ConfigContinue<T> = {
	data: T;
	continue: Boolean;
	redirect: Boolean;
};

export interface ConfigDirectiveField {
	ref: string;
	label: string;
	hint: string;
	placeholder: string;
}

export interface EventArchived {
	hasError: Boolean;
	id: number;
	archived: Boolean;
	active: Boolean;
	rowIndex: Boolean;
}

export interface ICompactEventInfo {
	id: number;
	name: string;
	external_id: number;
	active: boolean;
}

export interface TableHeader {
	text: string;
	align: "start" | "center" | "end";
	sortable: Boolean;
	filterable: Boolean;
	value: string;
}

export interface AssociatedItem {
	id: number;
	external_id: number;
	name: string;
	associated?: number;
	campaigns?: number;
	line_items?: number;
	active: Boolean;
}

export interface TableAssociatedData {
	headers: TableHeader[];
	items: AssociatedItem[];
}

export interface FormAssociation {
	[TYPE_FETCH.CAMPAIGN_ID]: AssociatedItem[];
	[TYPE_FETCH.LINE_ITEM_ID]: AssociatedItem[];
}

export interface DialogDelete {
	type: DialogDeleteType;
	data: DialogDeleteData;
	open: Boolean;
	loading: Boolean;
	message: string;
}

export interface DialogDeleteData {
	event?: EventEntity;
	association?: AssociatedItem;
	selected?: AssociatedItem[];
}

export enum DialogDeleteType {
	SINGLE = "single",
	MULTIPLE = "multiple",
}

export interface CampaignPayload {
	filters: CampaingFilters;
	options: SortingOption;
	getId: Boolean;
	parsed: Boolean;
}

export interface LineItemPayload {
	filters: LineItemFilters;
	options: SortingOption;
}

export interface AsyncSetItemsPayload {
	type: TYPE_FETCH;
	items: any[];
}

export interface AddItemsPayload {
	type: TYPE_FETCH;
	items: any[];
}

export interface CampaignPayload {
	filters: CampaingFilters;
	options: SortingOption;
	getId: Boolean;
	parsed: Boolean;
}

export interface LineItemPayload {
	filters: LineItemFilters;
	options: SortingOption;
}

export interface TableFilter {
	id: number | null;
	external_id: number | null;
	name: string | null;
	creative_associations_count: number | null;
	line_items_count: number | null;
	active?: Boolean | null;
}
