export interface State {
    authenticated: boolean;
    token: string;
}

export enum Status {
	PROCESSED = "processed",
	FAILED = "failed",
	PENDING = "pending",
	FAIL = "fail",
	ACTIVE = "active",
	INACTIVE = "inactive",
	READY = "ready",
}