import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { nextMiddlewareFactory } from "@/middlewares/before-each";
import Auth from "@/middlewares/auth";
import Guest from "@/middlewares/guest";
import Permission from "@/middlewares/permissions";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	/**
	 * Root
	 */
	{
		path: "/",
		name: "Root",
		redirect: {name: 'Dashboard'},
		meta: {
			requiresAuth: true,
			excludes: [],
			middleware: [Auth],
		},
	},

	/**
	 * Auth
	 */
	{
		path: "/auth",
		name: "Auth",
		meta: {
			requiresAuth: false,
			middleware: [Guest],
		},
		component: () => import("@/views/Layouts/Auth.vue"),
		children: [
			{
				path: "login",
				name: "Login",
				component: () => import("@/views/Login/Login.vue"),
				meta: {
					requiresAuth: false,
					middleware: [Guest],
				},
			},
		],
	},

	/**
	 * Gues Root
	 */
	{
		path: "/",
		name: "GuesRoot",
		component: () => import("@/views/Layouts/Auth.vue"),
		meta: {
			requiresAuth: false,
			middleware: [Guest],
		},
		children: [
			{
				path: "init/:token",
				name: "Init",
				component: () => import("@/views/Init/index.vue"),
				meta: {
					requiresAuth: false,
					middleware: [Guest],
				},
			},
		],
	},

	/**
	 * ForgotPassword
	 */
	{
		path: "/",
		name: "Password",
		component: () => import("@/views/Layouts/Auth.vue"),
		meta: {
			requiresAuth: false,
			middleware: [Guest],
		},
		children: [
			{
				path: "password/forgot",
				name: "ForgotPassword",
				component: () => import("@/views/ForgotPassword/forgot.vue"),
				meta: {
					requiresAuth: false,
					middleware: [Guest],
				},
			},
			{
				path: "password/reset/:token",
				name: "ResetPassword",
				component: () => import("@/views/ForgotPassword/reset.vue"),
				meta: {
					requiresAuth: false,
					middleware: [Guest],
				},
			},
			{
				path: "password/twoFactorAuthentication",
				name: "TwoFactorAuthentication",
				component: () => import("@/views/AuthPassword/AuthPassword.vue"),
				meta: {
					requiresAuth: false,
					middleware: [Guest],
				},
			},
		],
	},

	/**
	 * Admin
	 */
	{
		path: "/admin",
		name: "Admin",
		component: () => import("@/views/Layouts/Admin.vue"),
		meta: {
			requiresAuth: true,
			breadcrumb: "menu.dashboard",
			middleware: [Auth],
		},
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				component: () =>
					import("@/views/Admin/Dashboard/Report/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "menu.dashboard",
					excludes: [],
					middleware: [Auth, Permission],
				},
			},
			{
				path: "advertisers",
				name: "Advertiser",
				component: () => import("@/views/Admin/Advertisers/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "advertisers.breadcrumb.title",
					excludes: [],
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "AdvertisersIndex",
						component: () =>
							import("@/views/Admin/Advertisers/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "advertisers.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "AdvertiserCreate",
						component: () =>
							import(
								"@/views/Admin/Advertisers/Create/create.vue"
							),
						meta: {
							requiresAuth: true,
							breadcrumb: "advertisers.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "AdvertiserEdit",
						component: () =>
							import("@/views/Admin/Advertisers/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "advertisers.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "activityLog",
						name: "ActivityLogAdvertiser",
						component: () =>
							import("@/views/Admin/Commons/ActivityLog.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "advertisers.log.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "users",
				name: "Users",
				component: () => import("@/views/Admin/Users/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "users.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "UsersIndex",
						component: () =>
							import("@/views/Admin/Users/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "users.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "UserCreate",
						component: () =>
							import("@/views/Admin/Users/Create/create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "users.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "UserEdit",
						component: () =>
							import("@/views/Admin/Users/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "users.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "account",
				name: "Account",
				component: () => import("@/views/Admin/Account/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "app-bar.menu-account.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "password",
						name: "changePassword",
						component: () =>
							import(
								"@/views/Admin/Account/Password/password.vue"
							),
						meta: {
							requiresAuth: true,
							breadcrumb: "passwordChange.breadcrumb",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "custom_lists",
				name: "CustomList",
				component: () => import("@/views/Admin/CustomList/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "customList.list.title",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "CustomListIndex",
						component: () =>
							import("@/views/Admin/CustomList/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "customList.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "CustomListCreate",
						component: () =>
							import(
								"@/views/Admin/CustomList/Create/create.vue"
							),
						meta: {
							requiresAuth: true,
							breadcrumb: "customList.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "CustomListEdit",
						component: () =>
							import("@/views/Admin/CustomList/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "customList.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "show/:id",
						name: "CustomListShow",
						component: () =>
							import("@/views/Admin/CustomList/Show/show.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "customList.add.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "modifiers",
				name: "Modifiers",
				component: () => import("@/views/Admin/Modifiers/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "modifier.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "ModifiersIndex",
						component: () =>
							import("@/views/Admin/Modifiers/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "modifier.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "ModifiersCreate",
						component: () =>
							import("@/views/Admin/Modifiers/Create/create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "modifier.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "ModifiersEdit",
						component: () =>
							import("@/views/Admin/Modifiers/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "modifier.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "campaigns",
				name: "Campaigns",
				component: () => import("@/views/Admin/Campaigns/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "campaign.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "CampaignsIndex",
						component: () =>
							import("@/views/Admin/Campaigns/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "campaign.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "CampaignCreate",
						component: () =>
							import("@/views/Admin/Campaigns/Create/create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "campaign.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "CampaignEdit",
						component: () =>
							import("@/views/Admin/Campaigns/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "campaign.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "activityLog",
						name: "ActivityLogCampaing",
						component: () =>
							import("@/views/Admin/Commons/ActivityLog.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "campaign.log.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "lineitem",
				name: "LineItem",
				component: () => import("@/views/Admin/LineItem/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "lineItem.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "LineItemList",
						component: () =>
							import("@/views/Admin/LineItem/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "lineItem.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "LineItemCreate",
						component: () =>
							import("@/views/Admin/LineItem/Create/create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "lineItem.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "LineItemEdit",
						component: () =>
							import("@/views/Admin/LineItem/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "lineItem.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "activityLog",
						name: "ActivityLogLineItem",
						component: () =>
							import("@/views/Admin/Commons/ActivityLog.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "lineItem.log.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "creatives",
				name: "creatives",
				component: () => import("@/views/Admin/Creatives/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "creative.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "CreativesIndex",
						component: () =>
							import("@/views/Admin/Creatives/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "creative.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "CreativesCreate",
						component: () =>
							import("@/views/Admin/Creatives/Create/create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "creative.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "CreativeEdit",
						component: () =>
							import("@/views/Admin/Creatives/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "creative.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "bulk",
						name: "CreativeBulk",
						component: () =>
							import("@/views/Admin/Bulks/Creatives/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "CreativeBulkFile.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "activityLog",
						name: "ActivityLogCreative",
						component: () =>
							import("@/views/Admin/Commons/ActivityLog.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "creative.log.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "segments",
				name: "Segments",
				component: () => import("@/views/Admin/Segments/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "segment.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "SegmentsIndex",
						component: () =>
							import("@/views/Admin/Segments/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "segment.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "SegmentsCreate",
						component: () =>
							import("@/views/Admin/Segments/Create/create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "segment.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "SegmentsEdit",
						component: () =>
							import("@/views/Admin/Segments/Edit/edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "segment.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "segmentCategory",
				name: "SegmentCategory",
				component: () =>
					import("@/views/Admin/SegmentsCategories/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "segment.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "create",
						name: "SegmentCategoryCreate",
						component: () =>
							import(
								"@/views/Admin/SegmentsCategories/Create/create.vue"
							),
						meta: {
							requiresAuth: true,
							breadcrumb: "segmentCategory.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "segmentUpload",
				name: "SegmentUpload",
				component: () =>
					import("@/views/Admin/Segments/SegmentsUpload/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "segment.breadcrumb",
					middleware: [Auth],
				},
				children: [
					{
						path: "create",
						name: "SegmentUploadCreate",
						component: () =>
							import(
								"@/views/Admin/Segments/SegmentsUpload/Create/create.vue"
							),
						meta: {
							requiresAuth: true,
							breadcrumb: "segmentUpload.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "toolsRappi",
				name: "ToolsRappi",
				component: () => import("@/views/Admin/ToolsRappi/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "menu.tools.tools_rappi",
					middleware: [Auth, Permission],
				},
				children: [
					{
						path: "index",
						name: "ToolRappi",
						component: () =>
							import("@/views/Admin/ToolsRappi/Main/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "oneLink.breadcrumb",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "events",
				name: "Events",
				component: () => import("@/views/Admin/Event/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "events.breadcrumb",
					middleware: [Auth, Permission],
				},
				children: [
					{
						path: "index",
						name: "EventIndex",
						component: () =>
							import("@/views/Admin/Event/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "events.routes.list",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "EventCreate",
						component: () =>
							import("@/views/Admin/Event/Create/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "events.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "EventEdit",
						component: () =>
							import("@/views/Admin/Event/Edit/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "events.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "reporting",
				name: "Reporting",
				component: () => import("@/views/Admin/Reports/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "report.index",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "Report",
						component: () =>
							import("@/views/Admin/Reports/Main/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "report.form",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "index_report_all",
						name: "ReportAll_Beta",
						component: () =>
							import("@/views/Admin/Reports/V2/Main/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "report.form",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "reportingOnDemand",
				name: "ReportingOnDemand",
				component: () => import("@/views/Admin/ReportsOnDemand/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "report.index",
					middleware: [Auth],
				},
				children: [
					{
						path: "index",
						name: "ReportOnDemandIndex",
						component: () =>
							import("@/views/Admin/ReportsOnDemand/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "reportOnDemand.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "ReportOnDemandCreate",
						component: () =>
							import("@/views/Admin/ReportsOnDemand/Create/Create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "reportOnDemand.Create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "privatepois",
				name: "PrivatePois",
				component: () =>
					import("@/views/Admin/PrivatePois/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "privatepois.breadcrumb",
					middleware: [Auth, Permission],
				},
				children: [
					{
						path: "create",
						name: "PrivatePoisCreate",
						component: () =>
							import(
								"@/views/Admin/PrivatePois/Create/index.vue"
							),
						meta: {
							requiresAuth: true,
							breadcrumb: "privatepois.title.create",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "PrivatePoisEdit",
						component: () =>
							import(
								"@/views/Admin/PrivatePois/Edit/index.vue"
							),
						meta: {
							requiresAuth: true,
							breadcrumb: "privatepois.title.edit",
							excludes: ["account-report"],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "persons",
				name: "Persons",
				component: () => import("@/views/Admin/Persons/V10/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "menu.persons",
				},
				children: [
					{
						path: "index",
						name: "PersonsIndex",
						component: () =>
							import("@/views/Admin/Persons/V10/Index/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "persons.report.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "audience",
						name: "PersonsAudience",
						component: () =>
							import("@/views/Admin/Persons/V10/Audience/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "persons.report.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "store_attribution",
						name: "PersonsStoreAttribution",
						component: () =>
							import("@/views/Admin/Persons/V10/Audience/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "persons.report.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "requestCampaign",
				name: "RequestCampaign",
				component: () => import("@/views/Admin/RequestCampaign/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "requestCampaign.breadcrumb",
					middleware: [Auth, Permission],
				},
				children: [
					{
						path: "index",
						name: "RequestCampaignIndex",
						component: () =>
							import("@/views/Admin/RequestCampaign/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "requestCampaign.list.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "create",
						name: "RequestCampaignCreate",
						component: () =>
							import("@/views/Admin/RequestCampaign/Create/Create.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "requestCampaign.create.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
					{
						path: "edit/:id",
						name: "RequestCampaignEdit",
						component: () =>
							import("@/views/Admin/RequestCampaign/Edit/Edit.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "requestCampaign.edit.title",
							excludes: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "notifications",
				name: "Notifications",
				component: () => import("@/views/Admin/Notifications/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb:"notifications.routes.index",
				},
				children: [
					{
						path: "index",
						name: "NotificationsIndex",
						component: () =>
							import("@/views/Admin/Notifications/List/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "notifications.routes.list",
							excluded: [],
							middleware: [Auth, Permission],
						},
					},
				],
			},
			{
				path: "settings",
				name: "Settings",
				component: () => import("@/views/Admin/Settings/index.vue"),
				meta: {
					requiresAuth: true,
					breadcrumb: "users.breadcrumb",
				},
				children: [
					{
						path: "index",
						name: "settingsEdit",
						component: () =>
							import("@/views/Admin/Settings/Edit/index.vue"),
						meta: {
							requiresAuth: true,
							breadcrumb: "users.setting.title",
							excluded: [],
							middleware: [Auth, Permission],
						},
					}
				],
			},
		],
	},

	/**
	 * Errors
	 */
	{
		path: "/errors",
		name: "Errors",
		component: () => import("@/views/Layouts/Error.vue"),
		meta: {
			requiresAuth: true,
			excludes: [],
			middleware: [Auth],
		},
		children: [
			{
				path: "403",
				name: "Denied",
				component: () => import("@/views/Errors/denied.vue"),
				meta: {
					requiresAuth: true,
					excludes: [],
					middleware: [Auth],
				},
			},
		],
	},

	/**
	 * Not Found
	 */
	{
		path: "/",
		name: "NotFoundRoot",
		component: () => import("@/views/Layouts/Error.vue"),
		children: [
			{
				path: ":patchMatch(.*)*",
				name: "NotFound",
				component: () => import("@/views/Errors/not-found.vue"),
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	let view_to = {
		view: "to",
		data:{
			fullPath: to.fullPath,
			path: to.path,
			name: to.name,
			params: to.params,
			query: to.query
		}
	};
	let view_from = {
		view: "from",
		data:{
			fullPath: from.fullPath,
			path: from.path,
			name: from.name,
			params: from.params,
			query: from.query
		}
	};
	store.dispatch("breadcrumbsM/addNavegationRoute", view_to);
	store.dispatch("breadcrumbsM/addNavegationRoute", view_from);
	next()
})

nextMiddlewareFactory(router);

/**
 * Actualiza la ruta: el parámetro de idioma
 * @param language
 */
export function changeQueryLanguage(language: string) {
	// Elimina el parámetro de idioma si el idioma está vacío
	const query = language
		? { ...router.currentRoute.query, language: language }
		: { ...router.currentRoute.query };

	router.replace({
		path: router.currentRoute.path.replace(/\/(es|en|fr)/, ""),
		query: query,
	});
}

export default router;
