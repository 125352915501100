
import Vue from "vue";

export default Vue.extend({
	name: "CustomizeRowObjectGrid",
	props: {},
	components: {},

	data: () => ({}),

	created() {},
	mounted() {},

	computed: {
		getValueField() {
			return this.params.data.parameters;
		},
	},

	methods: {
		getDataItems(item: any) {
			const obj = JSON.parse(item);
			let response = new Array();
			for (const [key, value] of Object.entries(obj)) {
				let entity = new Array();
				entity[0] = key;
				entity[1] = value;
				response.push(entity);
			}
			return response;
		},

		getHeightItemPanel(count: number) {
			if (!count) return "10";
			if (count > 10) {
				return (count * 30) / 2;
			} else {
				return count * 30;
			}
		},
	},

	watch: {},
});
