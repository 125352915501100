import PrivatePoisService from "@/services/privatePois/private-pois-service";
import { catchError } from "../../Util";
import { resolveCountryPrivatePois } from "@/utils/resolveObjectArray";
import notificationService from "@/services/notification-service";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import { ResultPaginate } from "@/interfaces/paginated";
import { PrivatePoisCreated } from "@/interfaces/persons/v11/privatepois";
import {
	PrivatePoisCreatedEntity,
	SelectedPrivatePoisEntity,
} from "@/models/persons/v12/PrivatePois";

export const PrivatePoisModule = {
	namespaced: true,
	state: () => ({
		private_pois: {} as ResultPaginate, // listado de private pois
		created: new PrivatePoisCreatedEntity(), // respues de creacion de private pois
		selected_private_pois: new SelectedPrivatePoisEntity(), // private pois seleccionado con sus items
	}),
	mutations: {
		SET_PRIVATE_POIS_PAGINATED(
			state: { private_pois: ResultPaginate },
			_result_paginated: ResultPaginate = {} as ResultPaginate
		) {
			state.private_pois = _result_paginated;
		},

		SET_SELECTED_PRIVATE_POIS(
			state: {
				selected_private_pois: SelectedPrivatePoisEntity;
			},
			_selected: SelectedPrivatePoisEntity
		) {
			state.selected_private_pois = _selected;
		},
		SET_CREATED(
			state: { created: PrivatePoisCreatedEntity },
			_created: PrivatePoisCreatedEntity
		) {
			state.created = new PrivatePoisCreatedEntity(_created);

			setTimeout(() => {
				state.created = new PrivatePoisCreatedEntity();
			}, 1000);
		},
	},
	getters: {
		getResultPaginatedPrivatePOIs(state: any) {
			return state.private_pois;
		},
		getSelectedPrivatePois(state: any) {
			return state.selected_private_pois;
		},
		getCreated(state: { created: PrivatePoisCreatedEntity }) {
			return state.created;
		},
		isCreated(state: { created: PrivatePoisCreatedEntity }) {
			return state.created.isCreated();
		},
	},
	actions: {
		async setCreated({ commit }, data: PrivatePoisCreated) {
			try {
				commit("SET_CREATED", data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async setSelectedPrivatePois(
			{ commit },
			data: SelectedPrivatePoisEntity = new SelectedPrivatePoisEntity()
		) {
			try {
				commit("SET_SELECTED_PRIVATE_POIS", data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async fetchCountry({ commit }, params: { type: string }) {
			try {
				const response = await PrivatePoisService.fetchData(params);
				return await Promise.resolve(
					resolveCountryPrivatePois(response)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async createPrivatePOIs({ commit }, params) {
			try {
				const response = await PrivatePoisService.createPrivatePOis(
					params
				);
				commit("SET_CREATED", response.data as PrivatePoisCreated);
				notificationService.notifySuccess(this, {
					message: response.message,
				} as Notification);
				return await Promise.resolve(response.data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginatedPrivatePois({ commit }: any, params: any) {
			try {
				const response = await PrivatePoisService.paginatedPrivatePOIs(
					params
				);
				commit("SET_PRIVATE_POIS_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginatedItemsPrivatePois(
			{ commit }: any,
			params: { id: number }
		) {
			try {
				const { id } = params;

				const response =
					await PrivatePoisService.paginatedItemsPrivatePOIs(
						params,
						Number(id)
					);

				let selectedPrivatePois: SelectedPrivatePoisEntity =
					new SelectedPrivatePoisEntity();

				const { object } = response;

				const privatePois: PrivatePoisCreated = {
					id: object?.id,
					layer_name: object?.layer_name,
					country_code: object?.country_code,
					country_name: object?.country_name,
					total_pois: object?.total_pois,
					total_errors: object?.total_errors,
					updated_at: object?.updated_at,
				};

				selectedPrivatePois.setData({
					private_pois: privatePois,
					items: response,
				});

				commit("SET_SELECTED_PRIVATE_POIS", selectedPrivatePois);

				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async postData(
			{},
			params: { type: string; payload: FormData; has_file: boolean, continue: boolean }
		) {
			try {
				const { type, payload, has_file } = params;
				const result = await PrivatePoisService.postData(
					type,
					payload,
					has_file
				);
				notificationService.notifySuccess(this, {
					type: MessageTypes.SUCCESS,
					to: params.continue ? "" : "PrivatePoisCreate",
					btn_text: MessageTypes.CONTINUE
				} as Notification);
				return await Promise.resolve(result);
			} catch (error) {
				console.error("PrivatePoisModule::postData", { error });
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		
		/**
		 * Eliminar items de private pois
		 * @param params 
		 * @returns 
		 */
		async deleteData({}, params: { id: number; payload: any }) {
			try {
				const { id, payload } = params;
				const result = await PrivatePoisService.deleteData(id, payload);
				notificationService.notifySuccess(this, {
					type: MessageTypes.SUCCESS,
					btn_text: MessageTypes.CONTINUE
				} as Notification);
				return await Promise.resolve(result);
			} catch (error) {
				console.error("PrivatePoisModule::deleteData", { error });
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
