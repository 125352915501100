
export default {
	name: "CardTList",
	model: {
		prop: "model_data",
		event: "input"
	},
	props: {
		model_data: {},
		items: {
			type: Array,
			default: function() {
				return [];
			}
		},
		cardStyles: {
			type: String,
			default: "px-2 mb-2"
		},
		report:{
			type: Boolean,
			default: false
		},
		sortData:{
			type: Boolean,
			default: false
		}
	},
	data: function() {
		return {};
	},
	mounted() {},
	computed: {
		isLoading() {
			return this.$store.state.proccess.loading_field;
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			}
		},
		getItems() {
			if(this.sortData){
				let sorted = this.items.sort(function (x, y) {
					if(x.name.toLowerCase() < y.name.toLowerCase()) return -1;
					if(x.name.toLowerCase() > y.name.toLowerCase()) return 1;
					return 0;
				});
				return sorted;
			}
			return this.items;
		},
	},
	methods: {
		getValue(item){
			return this.report ? item.name: item.value;
		}
	},
	watch: {}
};
