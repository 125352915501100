import {
	LikeData,
	LikeDataAll,
	LikeAllGeo,
	LikeAllPois,
	LikeName,
	LikeType,
	LikeAllOoh,
} from "@/interfaces/persons/v10/select_like";

/**
 * Like All Data
 */
export class LikeDataEntity implements LikeData {
	all: Boolean = false;
	term: string | null = null;

	constructor() {}
}

export class LikeAllGeoEntity implements LikeAllGeo {
	states: LikeData = new LikeDataEntity();
	cities: LikeData = new LikeDataEntity();
	neighborhoods: LikeData = new LikeDataEntity();

	constructor() {}
}

export class LikeAllPoisEntity implements LikeAllPois {
	categories: LikeData = new LikeDataEntity();
	subcategories: LikeData = new LikeDataEntity();
	brands: LikeData = new LikeDataEntity();
	names: LikeData = new LikeDataEntity();
	privates: LikeData = new LikeDataEntity();

	constructor() {}
}

export class LikeAllOohEntity implements LikeAllOoh {
	ooh_categories: LikeData = new LikeDataEntity();
	ooh_subcategories: LikeData = new LikeDataEntity();
	ooh_brands: LikeData = new LikeDataEntity();
	ooh_names: LikeData = new LikeDataEntity();

	constructor() {}
}

/**
 * Main Entity
 */
export class LikeAllDataEntity implements LikeDataAll {
	geo: LikeAllGeo = new LikeAllGeoEntity();
	pois: LikeAllPois = new LikeAllPoisEntity();
	ooh: LikeAllOoh = new LikeAllOohEntity();

	constructor() {}

	// getters //

	/**
	 * Get Like Data
	 * @param type LikeType { geo | pois }
	 * @param name LikeName { states, cities, neighborhoods, categories, subcategories, brands, names }
	 * @param key string { term, all }
	 * @returns
	 */
	getLikeData(type: LikeType, name: LikeName, key: string) {
		return this[type] && this[type][name] && this[type][name][key]
			? this[type][name][key]
			: null;
	}

	// setters //

	resetLikeData(type: LikeType, name: LikeName) {
		this[type][name] = new LikeDataEntity();
	}

	clear() {
		this.geo = new LikeAllGeoEntity();
		this.pois = new LikeAllPoisEntity();
	}

	async setLikeData(
		type: LikeType,
		name: LikeName,
		key: string,
		data: string | Boolean | null
	) {
		this[type][name][key] = data;
	}

	// checkers //

	isSelectedAll(type: LikeType, name: LikeName): Boolean {
		return this[type][name].all && !Boolean(this[type][name].term);
	}

	isSelectedLike(type: LikeType, name: LikeName): Boolean {
		return this[type][name].all && Boolean(this[type][name].term);
	}

	hasSelectAll(type: LikeType, name: LikeName) {
		return (
			this.isSelectedAll(type, name) || this.isSelectedLike(type, name)
		);
	}

	// components //

	chipped(likeType: LikeType, filterName: LikeName): string {
		// solo si selecciono [select all]
		const isAll = this.isSelectedAll(likeType, filterName);
		if (isAll) return `SELECT ALL ${filterName.toLocaleUpperCase()}`;

		// si selecciono [select all] && [realizo una busqueda]
		const isLikeAll = this.isSelectedLike(likeType, filterName);
		if (isLikeAll) {
			return `SELECT ALL ${filterName.toLocaleUpperCase()} [LIKE: ${
				this[likeType][filterName].term
			}]`;
		}

		return "";
	}

	chippedAll(likeName: LikeName): string {
		return `ALL ${likeName.toLocaleUpperCase()}`;
	}

	chippedLike(type: LikeType, name: LikeName): string {
		return `ALL ${name.toLocaleUpperCase()} [LIKE: ${
			this[type][name].term
		}]`;
	}
}
