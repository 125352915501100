import { SnactType } from "@/interfaces/snack";
import { SnacktifyEntity } from "@/models/Core/Snacktify";

export const SnacktifyModule = {
	namespaced: true,
	state: () => ({
		_snacktify: new SnacktifyEntity(),
	}),
	mutations: {
		SET_STATE(
			state: { [x: string]: any },
			params: { key: string; value: any }
		) {
			state[params.key] = params.value;
		},
		SET_SNACKTIFY_DATA(
			state: { _snacktify: SnacktifyEntity },
			payload?: {
				type?: SnactType;
				message?: string;
				messageParams?: Record<any, any>;
			}
		) {
			state._snacktify.setSnacktifyData(payload);
		},
	},
	getters: {
		getterSnacktify(state: { _snacktify: SnacktifyEntity }) {
			return state._snacktify;
		},
	},
	actions: {
		setState<T>({ commit }, params: { key: string; value: T }) {
			return new Promise((resolve) => {
				commit("SET_STATE", params);
				resolve(params);
			});
		},

		setSnacktifyData<T>(
			{ commit },
			payload?: {
				type?: SnactType;
				message?: string;
				messageParams?: Record<any, any>;
			}
		) {
			return new Promise((resolve) => {
				commit("SET_SNACKTIFY_DATA", payload);
				resolve(payload);
			});
		},
	},
};
