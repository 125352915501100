export interface TargetingValue {
	id: number | undefined;
	key_name: string;
	module_name: string;
	segment_name: string | null;
	verb_usage: string | null;
	value: string;
	value_name: string;
	targeting_key_id: number | undefined;
	targeting_predicate_id: number | undefined;
	targeting_expression_id: number | undefined;
	created_by: number | undefined;
	updated_by: number | undefined;
	deleted_by: number | undefined;
	created_at: string;
	updated_at: string;
}

export interface TargetingExpression {
	id: number | undefined;
	line_item_id: number | undefined;
	targeting_values: TargetingValue[];
}

export default class initTargetingValue implements TargetingValue {
	public id: number | undefined = undefined;
	public value: string = "";
	public value_name: string = "";
	public module_name: string = "";
	public key_name: string = "";
	public segment_name: string | null = null;
	public verb_usage: string | null = null;
	public targeting_expression_id: number | undefined = undefined;
	public targeting_key_id: number | undefined = undefined;
	public targeting_predicate_id: number | undefined = undefined;
	public created_at: string = "";
	public created_by: number | undefined = undefined;
	public deleted_by: number | undefined = undefined;
	public updated_at: string = "";
	public updated_by: number | undefined = undefined;

	constructor(parametters?: TargetingValue) {
		if(!parametters) return;
		this.setTargetingValue(parametters);
	}

	/**
	 * Getters
	 */

	public get getId() {
		return this.id;
	}

	public get getValue() {
		return this.value;
	}

	public get getValueName() {
		return this.value_name;
	}

	public get getTargeting() {
		return this;
	}

	/**
	 * Setters
	 */

	public set setId(parametter: any) {
		this.id = parametter;
	}

	public set setValue(parametter: any) {
		this.value = parametter;
	}

	public set setValueName(parametter: any) {
		this.value_name = parametter;
	}

	public setTargetingValue(parametters: TargetingValue) {
		this.id = parametters.id;
		this.value = parametters.value;
		this.value_name = parametters.value_name;
		this.module_name = parametters.module_name;
		this.key_name = parametters.key_name;
		this.segment_name = parametters.segment_name;
		this.verb_usage = parametters.verb_usage;
		this.targeting_expression_id = parametters.targeting_expression_id;
		this.targeting_key_id = parametters.targeting_key_id;
		this.targeting_predicate_id = parametters.targeting_predicate_id;
		this.created_at = parametters.created_at;
		this.created_by = parametters.created_by;
		this.deleted_by = parametters.deleted_by;
		this.updated_at = parametters.updated_at;
		this.updated_by = parametters.updated_by;
		if(parametters.targeting_key_id == 35)
		{	
			this.setTargetingValueRewarded(parametters);
		}
	}

	public setTargetingValueRewarded(parametters: TargetingValue)
	{
		this.value = parametters.value == "1" ? "true" : "false";
		this.value_name = parametters.value_name == "1" ? "true" : "false";
	}
}
