
import Vue from "vue";

export default Vue.extend({
	props: {
		colorFill: {
			type: String,
			default: "#AD0075",
		},
		colorStroke: {
			type: String,
			default: "#AD0075",
		},
	},
});
