import { Snacktify } from "@/interfaces/Core/snacktify";
import { EnumMessageType } from "@/interfaces/reports/v2/report";
import { SnactType } from "@/interfaces/snack";
import { isProd } from "@/services/process-service";
import { getValidityLimitSeg } from "@/utils/env";

export class SnacktifyEntity implements Snacktify {
	open: boolean = false;
	message: string = "";
	messageParams?: Record<any, any>;
	timeout?: number = 10000;
	multiLine?: boolean = true;
	absolute?: boolean = false;
	app?: boolean = true;
	right?: boolean = true;
	top?: boolean = false;
	rounded?: boolean = true;
	centered?: boolean = false;
	shaped?: boolean = true;
	outlined?: boolean = false;
	vertical?: boolean = true;
	dark?: boolean = true;
	color?: SnactType = SnactType.SECONDARY;
	elevation?: number = 24;
	minWidth?: number = 300;
	maxWidth?: number = 400;
	width?: number = undefined;

	constructor(properties?: Snacktify) {
		if (properties) {
			this.setSnacktify(properties);
		}

		//this.debug();
	}

	setSnacktifyData(data?: {
		type?: SnactType;
		message?: string;
		messageParams?: Record<any, any>;
	}) {
		this.open = Boolean(data?.message);
		this.color = data?.type || undefined;
		this.message = data?.message || "";
		this.messageParams = data?.messageParams || {};
		//this.debug();
	}

	setSnacktify(properties?: Snacktify) {
		if (properties) {
			Object.assign(this, properties);
		}
	}

	async close() {
		this.setSnacktifyData();
	}

	/**
	 * Solo para debug
	 */

	debug() {
		if (!isProd()) {
			console.debug(
				`DEBUG SNACKTIFY open: ${this.open} - message: ${this.message}`,
				{ this: this, env: getValidityLimitSeg }
			);
		}
	}

	addDebugData() {
		this.setSnacktifyData({
			type: SnactType.PRIMARY,
			message: EnumMessageType.REPORT_GENERATED,
			messageParams: {
				minutes: 5,
			},
		});
	}
}
