export const LIMIT: number = 50;

export const PRESENCE_DELAY_AT: number = 50;

export const DELAY_MAKE_SELECTION: number = 100;

export const DELAY_INIT: number = 10;

export const ITEMS_PER_PAGE: number = 10;

export enum Mode {
	PAGINATED = "paginated",
	ALL = "all",
}

export enum MatchFilterName {
	states = "codigo_estado",
	citites = "codigo_municipio",
}

export function getDelaySelection(count: number) {
	if (count <= 50) return 0;
	return Math.abs(Math.round((count / 5) * 0.9));
}
